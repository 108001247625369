export const Tag = {
  baseStyle: {
    container: {
      borderRadius: 'lg'
    }
  },
  variants: {
    solid: {
      container: {
        bg: '#161616'
      }
    }
  }
}
