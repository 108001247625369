import { useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { createRef, useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useUserStore from 'stores/user'
import useFetch from 'use-http'
import { Form, FormDataType } from './components/Form'
import { HeaderLoginForm } from '../../components/organisms/HeaderLoginForm'
import { LoginTemplate } from '../../components/templates/Login'

export const Login = (props: any) => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const toast = useToast()
  const formRef: any = createRef()
  const [showPass, setShowPass] = useState(false)
  const { setUser } = useUserStore()

  const { post: login, loading: loadingLogin } = useFetch(Endpoints.auth.login)

  const onAuthCheckUser = useCallback(
    async (email: string) => {
      const { response: res } = await login({ email, password: 'yellow.REC0' })

      if (res?.code === 'UserNotConfirmedException') {
        navigate('primeiro-acesso', {
          state: {
            email,
            password: 'yellow.REC0'
          }
        })
        return
      }

      if (res?.message === 'UserNotExist') {
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: `Por favor verifique e tente novamente.`,
          position: 'top-right',
          status: 'warning',
          title: 'E-mail não encontrado.',
          variant: 'subtle'
        })
        return
      }

      if (res?.message === 'UserInactive') {
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: `Por favor verifique e tente novamente.`,
          position: 'top-right',
          status: 'warning',
          title: 'Este email não está mais ativo.',
          variant: 'subtle'
        })
        return
      }

      setShowPass(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, toast]
  )

  const onAuthLogin = async (email: string, password: string) => {
    const userLogged = await login({ email, password })

    if (userLogged?.status >= 400) {
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: `Por favor, verifique e tente novamente.`,
        position: 'top-right',
        status: 'warning',
        title: 'Email ou senha incorretos.',
        variant: 'subtle'
      })
      return
    }

    const userToSet = {
      ...userLogged,
      user: {
        ...userLogged.user,
        password
      }
    }

    if (userLogged) setUser(userToSet)

    if (params.get('returnUrl')) {
      navigate(params.get('returnUrl') as string, { replace: true })
    } else {
      navigate('/')
    }
  }

  return (
    <LoginTemplate {...props}>
      <HeaderLoginForm
        title="Bem-vindo!"
        description="Insira seu e-mail para acessar nossa plataforma"
      />
      <Form
        formRef={formRef}
        onSubmit={({ email, password }: FormDataType) =>
          showPass ? onAuthLogin(email, password) : onAuthCheckUser(email)
        }
        showPass={showPass}
        loading={loadingLogin}
      />
    </LoginTemplate>
  )
}
