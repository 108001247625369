/* eslint-disable camelcase */
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  VStack
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'
import { Select } from 'components/atoms/Select'
import { Tag, TagCloseButton, TagLabel } from 'components/atoms/Tag'
import { Textarea } from 'components/atoms/Textarea'
import { useErrorToast } from 'hooks/useErroToast'
import { useEffect, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FiPlus, FiTrash } from 'react-icons/fi'
import useFetch, { Res } from 'use-http'
import {
  BrazilianStates,
  Genders,
  LanguageSkillLevelType
} from '../../../../data/userProfile'
import { useEditJob } from '../../../../hooks/useEditJob'
import { capitalizeNames } from '../../../../utils/strings'

// const formatResponseStates = async ({ response }: any) => {
//   const res = response

//   res.data = res.data.sort((a: any, b: any) =>
//     Intl.Collator('pt-BR').compare(a.nome, b.nome)
//   )

//   return res
// }

const Language = ({
  index,
  onAdd,
  onRemove,
  control,
  stepName,
  languages
}: {
  index: number
  onAdd: (_language: any) => void
  onRemove: (index: number) => void
  control: any
  stepName: string
  languages: any[]
}) => (
  <HStack width="100%" alignItems="center" spacing={4}>
    <Controller
      control={control}
      name={`${stepName}.${index}.language_id`}
      render={({ field: { ref, ...props } }) => (
        <Menu {...props} label="Língua" options={languages} />
      )}
    />

    <Controller
      control={control}
      name={`${stepName}.${index}.level`}
      render={({ field: { ref, ...props } }) => (
        <Menu {...props} label="Nível" options={LanguageSkillLevelType} />
      )}
    />

    <HStack pt={6} alignItems="center" spacing={4}>
      <IconButton
        onClick={() => onRemove(index)}
        variant="ghost"
        size="xs"
        colorScheme={index === 0 ? 'gray' : 'black'}
        icon={<FiTrash fontSize="1.5rem" />}
        aria-label="Delete"
        disabled={index === 0}
      />
      <IconButton
        isRound
        onClick={() => onAdd({})}
        variant="solid"
        size="sm"
        colorScheme="brand"
        icon={<FiPlus fontSize="1.2rem" color="white" />}
        aria-label="Add"
      />
    </HStack>
  </HStack>
)

const Age = ({ value, setValue }: any) => {
  const handleChange = ([
    profile_personal_min_age,
    profile_personal_max_age
  ]: number[]) => {
    setValue('profile_personal_min_age', profile_personal_min_age)
    setValue('profile_personal_max_age', profile_personal_max_age)
  }

  return (
    <Box width="45%" height={20}>
      <FormLabel>Faixa etária</FormLabel>
      <Box pr={4} mt={2}>
        <RangeSlider
          position="relative"
          min={14}
          max={70}
          defaultValue={[18, 30]}
          value={value}
          onChange={(val) => handleChange(val)}>
          <Flex
            width="100%"
            justifyContent="center"
            position="absolute"
            top={8}>
            <Text
              color="black"
              textAlign="center">{`De ${value[0]} anos a ${value[1]} anos`}</Text>
          </Flex>
          <RangeSliderTrack>
            <RangeSliderFilledTrack bg="brand.500" />
          </RangeSliderTrack>
          <RangeSliderThumb boxSize={6} index={0} />
          <RangeSliderThumb boxSize={6} index={1} />
        </RangeSlider>
      </Box>
    </Box>
  )
}

export const Requirements = () => {
  const onError = useErrorToast()
  const { control, setValue, watch, getValues } = useFormContext()
  const canEdit = useEditJob(getValues())

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'job_languages'
  })

  useEffect(() => {
    if (!fields.length) {
      setValue('job_languages', [
        {
          language_id: '',
          level: ''
        }
      ])
    }
  }, [])

  const { data: superiorCourses } = useFetch(
    Endpoints.lists.superiorCourses,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os cursos.'
        }),
      interceptors: {
        response: async ({ response }) => {
          response.data = response.data
            .filter(
              (value: any, index: any, self: any) =>
                index ===
                self.findIndex(
                  (s: any) => s.name === value.name && s.name === value.name
                )
            )
            .map((d: any) => ({
              label: d.name,
              value: d.id
            }))
          return response
        }
      }
    },
    []
  )

  const { data: hardSkills } = useFetch(
    Endpoints.lists.hardSkills,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar as hard skills.'
        }),
      interceptors: {
        response: async ({ response }) => {
          response.data = response.data.map((d: any) => ({
            label: d.name,
            value: d.id
          }))
          return response
        }
      }
    },
    []
  )

  const { data: languages } = useFetch(
    Endpoints.lists.languages,
    {
      interceptors: {
        response: async ({ response }: { response: Res<any> }) => {
          if (!response.data) return response

          const newResponse: any = []
          response.data.forEach((lang: any) =>
            newResponse.push({
              label: lang.name,
              value: lang.id
            })
          )
          response.data = newResponse

          return response
        }
      },

      onError: () =>
        onError({
          title: 'Não foi possível carregar as hard skills.'
        })
    },

    []
  )

  // const { data: state } = useFetch(
  //   Endpoints.lists.states,
  //   {
  //     onError: () =>
  //       onError({
  //         title: 'Não foi possível carregar a lista de estados.'
  //       }),
  //     interceptors: {
  //       response: formatResponseStates
  //     }
  //   },
  //   []
  // )

  const listStates = BrazilianStates.map((s) => ({
    nome: s.label,
    sigla: s.value
  }))

  const { data: cities } = useFetch(
    Endpoints.lists.citiesByState(watch('profile_personal_state')),
    { retries: 0 },
    [watch('profile_personal_state')]
  )

  const handleAddLanguage = () => {
    append({
      language_id: '',
      level: ''
    })
  }

  const [coursesText, setCoursesText] = useState('')

  const onAddCourses = () => {
    if (!coursesText) return

    setValue('courses', [...(watch('courses') || []), coursesText])
    setCoursesText('')
  }

  const onRemove = (key: string, text: string) => {
    setValue(
      key,
      watch(key).filter((word: string) => word !== text)
    )
  }

  return (
    <Box
      bg="white"
      maxH="2xl"
      overflowY="scroll"
      borderRadius="lg"
      sx={{
        '&::-webkit-scrollbar': {
          height: '9px',
          width: '10px',
          backgroundColor: `rgba(0, 0, 0, 0.05)`
        },
        '&::-webkit-scrollbar-thumb': {
          height: '9px',
          borderRadius: '6px',
          backgroundColor: `rgba(0, 0, 0, 0.15)`
        }
      }}
      p={6}
      pointerEvents={canEdit ? 'auto' : 'none'}>
      <Flex flexDirection="column" py="6" px="2" width="100%" gap="8">
        <Textarea
          label="Principais responsabilidades"
          cols={5}
          rows={7}
          variant="filled"
          placeholder="Digite aqui"
          onChange={(e) => setValue('main_responsibilities', e.target.value)}
          value={watch('main_responsibilities')}
        />
        <Textarea
          label="Requisitos técnicos"
          cols={5}
          rows={7}
          variant="filled"
          placeholder="Digite aqui"
          onChange={(e) => setValue('technical_requirements', e.target.value)}
          value={watch('technical_requirements')}
        />
        <Textarea
          label="Perfil comportamental"
          cols={5}
          rows={7}
          variant="filled"
          placeholder="Digite aqui"
          onChange={(e) => setValue('behavioral_profile', e.target.value)}
          value={watch('behavioral_profile')}
        />
        <Divider color="#E9E9E9" my="4" />
        <HStack width="100%" spacing={8} alignItems="flex-start">
          <Box width="100%" zIndex={200}>
            <Select
              isMulti
              label="Escolaridade"
              placeholder="Selecione"
              control={control}
              name="job_superior_courses"
              options={superiorCourses}
            />
          </Box>
          <HStack alignItems="flex-start" w="100%">
            <VStack width="100%" alignItems="flex-start">
              <Input
                label="Adicionar Cursos"
                variant="filled"
                placeholder="Digite aqui"
                value={coursesText}
                onChange={(e) => setCoursesText(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    onAddCourses()
                  }
                }}
              />
              {watch('courses')?.length && (
                <Box
                  w="100%"
                  borderColor="gray.200"
                  borderWidth="1px"
                  p={2}
                  borderRadius={4}>
                  {watch('courses')?.map((rc: string) => (
                    <Tag variant="solid" key={rc} mr={2} mb={2}>
                      <TagLabel> {rc}</TagLabel>
                      <TagCloseButton
                        key={rc}
                        onClick={() => onRemove('courses', rc)}
                      />
                    </Tag>
                  ))}
                </Box>
              )}
            </VStack>
            <Container w="-moz-fit-content" p={0} pt={8}>
              <Button
                onClick={onAddCourses}
                m={0}
                borderRadius="md"
                height={14}>
                Add
              </Button>
            </Container>
          </HStack>
        </HStack>
        <Select
          isMulti
          label="Hard Skills"
          placeholder="Selecione"
          options={hardSkills}
          control={control}
          name="job_hard_skills"
        />
        <VStack width="50%" spacing={4}>
          {fields.map((language: any, idx: number) => (
            <Language
              key={`language_${idx}`}
              index={idx}
              control={control}
              stepName="job_languages"
              onRemove={remove}
              onAdd={handleAddLanguage}
              languages={languages}
            />
          ))}
        </VStack>
        <Divider color="#E9E9E9" my="4" />
        <Heading as="h2" fontSize="lg" fontWeight="semibold">
          Perfil pessoal
        </Heading>
        <HStack width="100%" spacing={4}>
          <Controller
            control={control}
            name="profile_personal_gender"
            render={({ field: { ref, ...props } }) => (
              <Menu {...props} label="Gênero" options={Genders} />
            )}
          />

          <Controller
            control={control}
            name="profile_personal_county"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="País"
                options={[
                  {
                    value: 'Brasil',
                    label: 'Brasil'
                  }
                ]}
              />
            )}
          />

          <Select
            label="Estado"
            placeholder="Selecione"
            variant="filled"
            control={control}
            name="profile_personal_state">
            {listStates?.length &&
              listStates?.map((state: { nome: string; sigla: string }) => (
                <option key={`city_${state?.nome}`} value={state?.sigla}>
                  {capitalizeNames(state?.nome)}
                </option>
              ))}
          </Select>

          <Select
            label="Cidade"
            placeholder="Selecione"
            variant="filled"
            control={control}
            name="profile_personal_city"
            disabled={!watch('profile_personal_state')}>
            {!!cities?.length &&
              cities?.map((city: { nome: string; codigo_ibge: string }) => (
                <option key={`city_${city?.nome}`} value={city?.nome}>
                  {capitalizeNames(city?.nome)}
                </option>
              ))}
          </Select>
        </HStack>

        <Age
          setValue={setValue}
          value={[
            watch('profile_personal_min_age') || 18,
            watch('profile_personal_max_age') || 65
          ]}
        />
      </Flex>
    </Box>
  )
}
