import { useParams } from 'react-router-dom'
import { Backoffice } from './components/Backoffice'
import { Master } from './components/Master'
import { Recruitment } from './components/Recruitment'
import { Support } from './components/Support'

const pages: any = {
  backoffice: Backoffice,
  master: Master,
  recrutamento: Recruitment,
  suporte: Support
}

export const Hit = () => {
  const { category }: any = useParams()

  const Page = pages[category]

  return <Page />
}
