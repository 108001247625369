import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea as TextareaChakra,
  TextareaProps
} from '@chakra-ui/react'
import { useMemo } from 'react'

interface TextareaType extends TextareaProps {
  label?: string
  disabled?: boolean
  errorMessage?: string
  required?: boolean
  maxLength?: number
}

export const Textarea = ({
  label,
  errorMessage,
  ...rest
}: TextareaType): JSX.Element => {
  const lengthValue = useMemo(
    () => rest.value?.toString()?.length,
    [rest.value]
  )

  const isError = useMemo(
    () => rest?.isInvalid && errorMessage,
    [errorMessage, rest.isInvalid]
  ) as boolean

  return (
    <FormControl isInvalid={isError} isRequired={rest.required}>
      {!!label && (
        <FormLabel
          htmlFor={rest.id}
          color={rest.isInvalid ? 'negative.500' : 'black'}>
          {label}
        </FormLabel>
      )}
      <TextareaChakra
        {...rest}
        resize="none"
        lineHeight="125%"
        color="BlackText"
        errorBorderColor="transparent"
        sx={{
          '&::-webkit-scrollbar': {
            width: '10px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
            backgroundColor: `rgba(0, 0, 0, 0.15)`
          }
        }}
      />
      {rest.maxLength && (
        <Text width="100%" textAlign="right" fontSize="sm" mt="2" mr="10">{`${
          lengthValue || 0
        } / ${rest.maxLength} caracteres`}</Text>
      )}
      {isError && (
        <FormErrorMessage justifyContent="end" color="negative.500">
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
