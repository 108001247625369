/* eslint-disable indent */
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Tag,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Checkbox } from 'components/atoms/Checkbox'
import { Input } from 'components/atoms/Input'
import useDebounce from 'hooks/useDebounce'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useFetch from 'use-http'

type Props = {
  total: number
  onFilter: (key: string, value: any) => void
}

export const Customer = ({ total, onFilter }: Props) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedOptions, setSelectedOptions] = useState<boolean[]>([])

  const debouncedSearchTerm = useDebounce(searchTerm)

  const { control, handleSubmit } = useForm()

  const { get, loading, data } = useFetch(
    Endpoints.customers.list(0, debouncedSearchTerm)
  )

  const results = useMemo(() => data?.data ?? [], [data])
  const allChecked = useMemo(
    () => !!selectedOptions.length && selectedOptions.every(Boolean),
    [selectedOptions]
  )
  const isIndeterminate = useMemo(
    () => !allChecked && selectedOptions.some(Boolean),
    [allChecked, selectedOptions]
  )

  const handleCheck = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSelectedOptions((prevOptions) => {
      const newOptions = [...prevOptions]
      newOptions[index] = checked
      return newOptions
    })
  }

  const onSubmit = useCallback(() => {
    onFilter(
      'customers',
      selectedOptions
        .map((key, idx) => key && results[idx].id)
        .filter((values) => values)
    )
    onToggle()
  }, [onFilter, onToggle, results, selectedOptions])

  useEffect(() => {
    if (isOpen) get()
  }, [get, isOpen])

  useEffect(() => {
    if (!searchTerm && total === 0) {
      setSearchTerm('')
      setSelectedOptions(results?.map(() => false))
    }
  }, [results, searchTerm, total])

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start">
      <PopoverTrigger>
        <Button
          type="button"
          size="lg"
          variant="ghost"
          rightIcon={<ChevronDownIcon />}
          onClick={onToggle}>
          Cliente
          {total > 0 && (
            <Tag
              bg="neutralDark"
              ml="2"
              color="white"
              borderRadius="full"
              fontSize="xs"
              textAlign="center"
              justifyContent="center">
              {total}
            </Tag>
          )}
        </Button>
      </PopoverTrigger>
      <Portal>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PopoverContent>
            <PopoverHeader>
              <Input
                placeholder="Pesquisar"
                type="search"
                variant="filled"
                loading={false}
                disabled={false}
                value={searchTerm}
                onChange={(e) => {
                  e.preventDefault()
                  setSearchTerm(e.target.value)
                }}
              />
            </PopoverHeader>
            <PopoverBody py={0}>
              {loading && (
                <Box
                  width="100%"
                  height="155px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <Spinner color="brand.500" />
                </Box>
              )}
              {!loading && results?.length > 0 && (
                <VStack
                  width="100%"
                  py={2}
                  pl={2}
                  maxHeight="155px"
                  overflowX="hidden"
                  overflowY="auto">
                  <Checkbox
                    label="Selecionar todos"
                    value="all"
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={(e) =>
                      setSelectedOptions(results?.map(() => e.target.checked))
                    }
                  />
                  {results
                    .sort((a: any, b: any) =>
                      Intl.Collator('pt-BR').compare(a.name, b.name)
                    )
                    .map((option: any, idx: number) => (
                      <Controller
                        key={`customer_${idx}`}
                        control={control}
                        name={`customers[${idx}]`}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Checkbox
                            {...rest}
                            label={option.name}
                            value={option.id}
                            isChecked={selectedOptions[idx]}
                            onChange={(event) => handleCheck(event, idx)}
                          />
                        )}
                      />
                    ))}
                </VStack>
              )}
            </PopoverBody>
            <PopoverFooter>
              <Button
                disabled={loading}
                type="submit"
                width="100%"
                height="32px"
                variant="solid"
                colorScheme="brand"
                borderRadius="lg"
                size="sm">
                Filtrar
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </form>
      </Portal>
    </Popover>
  )
}
