/* eslint-disable indent */
// @ts-nocheck

import {
  Accordion,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Spinner,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Input } from 'components/atoms/Input'
import { useErrorToast } from 'hooks/useErroToast'
import { useCallback, useMemo, useState } from 'react'
import { FiPlusCircle, FiRepeat } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import useFetch from 'use-http'
import {
  addToLongListErr,
  addToLongListSucc,
  candidateDuplicate,
  creatCandidateErr,
  creatCandidateSucc
} from 'utils/commonToastMessages'
import { transformDatePayload } from 'utils/formatDate'
import { orderBy } from 'utils/orderBy'

import { CandidateCardForTheJob } from '../Cards/CandidateCardForTheJob'
import { NoDataMessage } from '../NoDataMessage'

export const TabLongList = ({ canEdit, ...job }: any) => {
  const [filters, setFilters] = useState<{ [key: string]: string } | null>(null)
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const [checks, setChecks] = useState<string[]>([])
  const [newCandidate, setNewCandidate] = useState()
  const [manualLoding, setManualLoading] = useState(false)
  const toast = useToast()
  const onError = useErrorToast()

  const {
    data: reqData,
    loading,
    get: refetch
  } = useFetch(
    Endpoints.jobs.getLonglist(job.id) + `&where=${JSON.stringify(filters)}`,
    {},
    [filters]
  )
  const data = reqData?.data
  if (data?.[0]?.job_list_candidate) {
    data[0].job_list_candidate = data?.[0]?.job_list_candidate.sort(
      (candidateA: any, candidateB: number) => {
        if (candidateB?.candidate_status === 'SUITABLE_CANDIDATE') {
          return 1
        }
        if (candidateA?.candidate_status === 'SUITABLE_CANDIDATE') {
          return -1
        }

        return 0
      }
    )
  }

  const { data: preShortlist } = useFetch(
    Endpoints.jobs.getShortlist(job.id),
    {},
    []
  )

  const { put: onSendToLonglist, response: sendToLonglistRes } = useFetch(
    Endpoints.jobs.addCandidatesToList(data?.[0]?.id || '')
  )

  const { post: onCreateLonglist, response: createLonglistRes } = useFetch(
    Endpoints.jobs.list
  )

  const { post: onCreateShortlist, response: resCreateShortlist } = useFetch(
    Endpoints.jobs.list
  )

  const { put: onSendToShortlist, response: resAddToShortlist } = useFetch(
    Endpoints.jobs.addCandidatesToList(
      preShortlist?.data?.[preShortlist?.data?.length - 1]?.id
    )
  )

  const { request, response: resRemoveCandidate } = useFetch(
    Endpoints.jobs.removeCandidateFromList(data?.[0]?.id),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )

  const { put: onUpdateCandidateStatus, response: resUpdateStatus } = useFetch(
    Endpoints.jobs.candidateStatus(data?.[0]?.id)
  )

  const {
    loading: loadingCreate,
    post: createCompany,
    response: resCreateCompany
  } = useFetch(Endpoints.companies['/companies'])

  const { data: companies } = useFetch(
    Endpoints.companies['/companies'],
    {
      interceptors: {
        response: async ({ response }) => {
          if (response.data)
            response.data = response.data.map((company: any) => ({
              label: company.company_name,
              value: company.id
            }))
          return response
        }
      }
    },
    [resCreateCompany, loadingCreate]
  )

  const {
    post: addCandidate,
    response: candidateReqsRes,
    loading: loadingCandidate
  } = useFetch(Endpoints.usersCandidate.root)

  const {
    loading: loadingExport,
    post: exportLonglist,
    response: responseExportLonglist
  } = useFetch(Endpoints.jobs.exportList(), {
    onError: () =>
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: 'Não foi possível exportar a lista.',
        position: 'top-right',
        status: 'warning',
        title: 'Ops!',
        variant: 'subtle'
      })
  })

  const { get: onGetLinkedinData, response: getLinkedinResponse } = useFetch(
    Endpoints.usersCandidate.linkedin
  )

  const canSendToShortlist = useMemo(
    () =>
      !!preShortlist &&
      preShortlist?.data?.length > 0 &&
      preShortlist?.data?.[preShortlist?.data?.length - 1]?.status !==
        'DECLINED',
    [preShortlist]
  )

  const allCandidatesHaveResume = useMemo(() => {
    return checkedItems.every(
      (candidate) => !!candidate?.candidate?.professional_resume
    )
  }, [checkedItems])

  const handleCreateShortlist = async () => {
    await onCreateShortlist({
      candidates_id: checkedItems.map((candidate) => candidate?.candidate_id),
      job_id: job.id,
      is_long_list: false
    })

    if (!resCreateShortlist.ok && resCreateShortlist.status >= 400) {
      onError({
        title: 'Não foi possível enviar candidato(s) para shortlist.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      title: 'Sucesso!',
      description: 'Candidato(s) enviado(s) para shortlist.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })
  }

  const handleAddToShortlist = async () => {
    await onSendToShortlist({
      candidatesId: checks
    })

    if (!resAddToShortlist.ok && resAddToShortlist.status >= 400) {
      onError({
        title: 'Não foi possível enviar candidato(s) para shortlist.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      title: 'Sucesso!',
      description: 'Candidato(s) enviado(s) para shortlist.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })
  }

  const onAddToShortlist = async () => {
    if (!allCandidatesHaveResume) {
      onError({
        title: 'Não foi possível enviar candidato(s) para shortlist.',
        description: `Todos os candidatos selecionados precisam ter seu resumo preenchido.
          Por favor, preencha o resumo do candidato e atualize a lista.`
      })
      return
    }

    if (canSendToShortlist) {
      await handleAddToShortlist()
      resetChecks()
      await refetch()
      return
    }

    await handleCreateShortlist()
    resetChecks()
    await refetch()
  }

  const onDownload = useCallback(async () => {
    await exportLonglist(
      data?.[0]?.job_list_candidate?.map(({ candidate }: any) => {
        const actualJob = candidate.professional_experiences.find(
          (experience: { actual_job: boolean }) => experience.actual_job
        )

        const mostRecentDate = new Date(
          Math.max.apply(
            null,
            candidate.professional_experiences.map(
              (experience: { end_at: string }) => new Date(experience.end_at)
            )
          )
        )

        const mostRecentJob =
          candidate.professional_experiences.filter(
            (experience: { end_at: string }) => {
              const d = new Date(experience.end_at)
              return d.getTime() === mostRecentDate.getTime()
            }
          )?.[0] ?? null

        const currentJob = actualJob ?? mostRecentJob

        return {
          name: candidate.name,
          birth_date: candidate?.birth_date ?? 'Não informada',
          phone: candidate?.phone ?? '',
          state: candidate?.state ? candidate.state : '',
          city: candidate?.city ? candidate.city : '',
          salary: currentJob?.salary ?? '',
          position: currentJob?.position_in_the_company
            ? currentJob?.position_in_the_company
            : '',
          company: currentJob?.company_name ?? '',
          salary_expectation: candidate?.salary_expectation ?? ''
        }
      })
    )

    const blob = await responseExportLonglist.blob()
    const filename = `longlist${
      data?.[0]?.job?.job_code ? `_${data[0].job.job_code}` : ''
    }.csv`

    const linkElement = document.createElement('a')
    // @ts-ignore
    const file = new Blob([blob], { type: 'text/csv' }, filename)
    const url = URL.createObjectURL(file)

    linkElement.href = url
    linkElement.setAttribute('download', filename)

    document.body.appendChild(linkElement)
    linkElement.click()

    linkElement.remove()
  }, [data, exportLonglist, responseExportLonglist])

  const handleRemove = async (candidate) => {
    if (!candidate?.id) return

    const body = { candidatesIds: [candidate?.id] }

    await request.delete(body)

    if (resRemoveCandidate.status >= 400) {
      toast({
        title: 'Não foi possível remover candidato da lista.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

      throw new Error(resRemoveCandidate.data.message)
    }

    toast({
      title: 'Sucesso!',
      description: 'Candidato removido da lista.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })

    await refetch()
  }

  const handleUpdateStatus = async (candidate, status) => {
    await onUpdateCandidateStatus({
      candidateId: candidate?.id,
      status
    })

    if (resUpdateStatus.status >= 400) {
      toast({
        title: 'Não foi possível atualizar o status do candidato.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

      throw new Error(resUpdateStatus.data.message)
    }

    toast({
      title: 'Sucesso!',
      description: 'Status do candidato atualizado.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })

    await refetch()
  }

  const handleFilter = (values: {}) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      ...values
    }))
  }

  const onCheckCandidate = (event: any, candidate: any) => {
    if (!event.target.checked) {
      setCheckedItems(
        checkedItems.filter(
          (item) => item.candidate_id !== candidate.candidate_id
        )
      )
      setChecks(checks.filter((id) => id !== candidate.candidate_id))
      return
    }

    setCheckedItems((checked) => [...checked, candidate])
    setChecks((checks) => [...checks, candidate.candidate_id])
  }

  const resetChecks = () => {
    setCheckedItems([])
    setChecks([])
  }

  const addLinkedinCandidate = async (linkedinParam: string) => {
    setManualLoading(true)

    const proExpToModel = []
    let linkedinUrl = linkedinParam
    if (linkedinUrl.includes('?')) linkedinUrl = linkedinParam?.split('?')[0]

    const linkedinCandidate = await onGetLinkedinData(
      `?linkedinUrl=${linkedinUrl}`
    )
    setManualLoading(false)
    if (getLinkedinResponse.status >= 400) {
      toast({
        title: 'Não foi buscar este usuário.',
        description:
          'Por favor, tente novamente ou adicione pela aba de candidatos.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })
      return
    }

    const proExperiences = orderBy(
      linkedinCandidate.candidateExperience.map((exp: any) => ({
        ...exp,
        end_at: exp.end_at === 'Ainda ativo' ? null : exp.end_at
      })),
      'desc'
    )

    for (const experience of proExperiences) {
      if (
        !companies.find(
          (company: { label: string; value: string }) =>
            company.label === experience.company
        )
      ) {
        createCompany({ company_name: experience.company })
          .then((data) =>
            proExpToModel.push({
              company: {
                label: data.company_name,
                value: data.id
              },
              position_in_the_company: experience.title,
              started_at: experience.started_at,
              end_at: experience.end_at ?? null,
              actual_job: experience?.end_at === null
            })
          )
          .catch(() => {
            onError({
              title: 'Erro',
              description: 'Falha ao carregar empresas.'
            })
          })
      }

      proExpToModel.push({
        company: companies.find(
          (company: { label: string; value: string }) =>
            company.label === experience.company
        ),
        position_in_the_company: experience.title,
        started_at: experience.started_at,
        end_at: experience.end_at ?? null,
        actual_job: experience?.end_at === null
      })
    }

    const linkedinUserToModel = {
      name: linkedinCandidate?.name,
      linkedin_profile_url: linkedinUrl,
      gender: linkedinCandidate?.gender?.toUpperCase() || 'INDIFFERENT',
      ...(linkedinCandidate.email && {
        email: linkedinCandidate?.email
      }),
      ...(linkedinCandidate.professional_linkedin_resume && {
        professional_linkedin_resume:
          linkedinCandidate?.professional_linkedin_resume
      }),
      ...(proExpToModel && {
        professional_experiences: proExpToModel.map(
          ({
            company,
            benefits,
            ...experiences
          }: Candidate['professional_experiences']) => ({
            ...experiences,
            ...(benefits?.filter(({ value }) => value)?.length && {
              benefits
            }),
            company_id: company?.value,
            started_at: experiences?.started_at
              ? new Date(transformDatePayload(experiences?.started_at))
              : null,
            end_at: experiences?.end_at
              ? new Date(transformDatePayload(experiences?.end_at))
              : null,
            salary:
              experiences?.salary &&
              parseFloat(experiences?.salary.replace('.', '').replace(',', '.'))
          })
        )
      })
    }

    const userAdded = await addCandidate(linkedinUserToModel)

    if (candidateReqsRes.status === 409) {
      candidateDuplicate(candidateReqsRes?.data?.response?.id, toast)
      return
    }

    if (![200, 201].includes(candidateReqsRes.status)) {
      creatCandidateErr(candidateReqsRes.status, toast)
      return
    }

    setNewCandidate('')
    creatCandidateSucc(toast)

    if (data?.length === 0) {
      await onCreateLonglist({
        job_id: job?.id,
        candidates_id: [userAdded?.id],
        is_long_list: true
      })
    } else await onSendToLonglist({ candidatesId: [userAdded?.id] })

    if (sendToLonglistRes.status >= 400 || createLonglistRes.status >= 400) {
      addToLongListErr(toast)
      return
    }
    addToLongListSucc(toast)
    refetch()
  }

  if (loading) {
    return (
      <Flex flexDir="column" py={8} alignItems="center">
        <Spinner />
      </Flex>
    )
  }

  return (
    <Flex flexDir="column" px={4} pointerEvents={canEdit ? 'auto' : 'none'}>
      <HStack alignItems="flex-end">
        <VStack w="70%">
          <Input
            type="text"
            w="100%"
            label="Cadastrar candidato"
            variant="filled"
            placeholder="Digite aqui"
            value={newCandidate}
            onChange={(event) => {
              setNewCandidate(event.target.value)
            }}
          />
        </VStack>

        <HStack pb={2}>
          {!loadingCandidate && !manualLoding ? (
            <Button
              variant="outline"
              colorScheme="brand"
              fontSize={10}
              px="6"
              py="4"
              onClick={() => {
                addLinkedinCandidate(newCandidate)
              }}
              borderRadius="md">
              Cadastrar
            </Button>
          ) : (
            <Box px="8">
              <Spinner color="brand.500" />
            </Box>
          )}

          <Button
            variant="solid"
            bg="brand.500"
            fontSize={10}
            px="6"
            py="4"
            borderRadius="md">
            <Link
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              to={{
                pathname: '/candidatos/criar',
                search: `name=${newCandidate || ''}&jobId=${
                  job?.id
                }&longlistId=${data?.[0]?.id}&createLonglist=${
                  data?.length === 0
                }&returnUrl=/pipeline-de-vagas/v/${job?.id}`
              }}>
              <Text>Novo candidato</Text>
              <Icon as={FiPlusCircle} ml="3" w={5} h={5} color="blackText" />
            </Link>
          </Button>
        </HStack>
      </HStack>

      {/*      <Divider my={6} />
      <Box
        width="100%"
        overflowX="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            height: '9px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`
          },
          '&::-webkit-scrollbar-thumb': {
            height: '9px',
            borderRadius: '6px',
            backgroundColor: `rgba(0, 0, 0, 0.15)`
          }
        }}>
        <HStack
          alignItems="flex-start"
          width="fit-content"
          overflow="hidden"
          mb="4">
          <Filters
            bg="grayBackground"
            options={filterOptions({
              state: filters?.state?.toLowerCase()
            })?.map((item) => ({ ...item, disabled: true }))}
            filters={filters}
            onClearFilters={() => setFilters({})}
            onFilter={handleFilter}
          />
        </HStack>
      </Box>

      <Divider mb={5} color="neutralLight.600" /> */}

      <Flex justifyContent="flex-end" gap={3} mb="1" mt="5" width="100%">
        <Button
          variant="outline"
          colorScheme="brand"
          fontSize={10}
          px="6"
          py="4"
          borderRadius="md"
          rightIcon={<FiRepeat size={12} transform="rotate(90)" />}
          onClick={() => {
            resetChecks()
            refetch()
          }}>
          Recarregar
        </Button>
        <Button
          variant="solid"
          bg="brand.500"
          borderRadius="md"
          fontSize={10}
          px="6"
          py="4"
          disabled={!data?.[0]?.job_list_candidate?.length}
          loading={loadingExport}
          onClick={onDownload}>
          Exportar
        </Button>
        <Button
          variant="solid"
          bg="brand.500"
          borderRadius="md"
          fontSize={10}
          px="6"
          py="4"
          disabled={!checkedItems.length}
          onClick={onAddToShortlist}>
          Enviar para pré-shortlist
        </Button>
      </Flex>

      <Text fontSize="sm" fontWeight="500">
        {`Lista possui ${
          data?.[0]?.job_list_candidate?.length ?? 0
        } candidatos.`}
      </Text>
      <Accordion allowMultiple w="full">
        {data?.[0]?.job_list_candidate?.map((candidate: any, index: number) => (
          <CandidateCardForTheJob
            key={candidate.candidate_id}
            candidate={candidate?.candidate}
            jobId={job.id}
            position={++index}
            candidateStatus={candidate?.candidate_status}
            candidateObs={candidate?.observation}
            listId={data?.[0]?.id}
            onClick={(event) => {
              onCheckCandidate(event, candidate)
            }}
            checked={checks.includes(candidate.candidate_id)}
            onRemove={handleRemove}
            onUpdateStatus={handleUpdateStatus}
            refetch={refetch}
          />
        ))}
      </Accordion>
      {!loading &&
        (!data ||
          data.length === 0 ||
          data?.[0]?.job_list_candidate?.length === 0) && <NoDataMessage />}
    </Flex>
  )
}
