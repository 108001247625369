/* eslint-disable sort-keys */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Button } from 'components/atoms/Button'
import { Tooltip } from 'components/atoms/Tooltip'
import { Table } from 'components/organisms/Table'
import { Fragment, useRef, useState } from 'react'
import {
  FiAlertCircle,
  FiEdit,
  FiMoreVertical,
  FiUserPlus
} from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import type { ClientType } from '../types/clients'

type TableClientsType = {
  data: ClientType[]
  refetch: any
  loading?: boolean
  onDeleteClient: (id: string, callback: () => void) => void
  onToggleClient: (id: string, callback: () => void) => void
}

const CustomText = ({ bold, children }: any) => (
  <Text fontSize="sm" color="black" fontWeight={bold ? 'bold' : 'normal'}>
    {children}
  </Text>
)

export const TableClients = ({
  data,
  onToggleClient,
  onDeleteClient,
  refetch,
  loading
}: TableClientsType) => {
  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selected, setSelected] = useState<any>({})

  const cancelRef: any = useRef()

  return (
    <Fragment>
      <Table
        headers={[
          { title: 'Empresa' },
          { title: 'CNPJ' },
          { title: 'Cliente/Nome' },
          { title: 'E-mail' },
          { title: 'Status' },
          { title: '' }
        ]}
        data={data.map((client) => ({
          name: <CustomText bold>{client.name}</CustomText>,
          cnpj: <CustomText>{client?.cnpj}</CustomText>,
          client: <CustomText>{client?.contacts?.[0]?.name ?? '–'}</CustomText>,
          clientEmail: (
            <CustomText>{client?.contacts?.[0]?.email ?? '–'}</CustomText>
          ),
          status: (
            <Badge
              variant="outline"
              colorScheme={client.is_active ? 'positive' : 'negative'}>
              {client.is_active ? 'Ativa' : 'Inativa'}
            </Badge>
          ),
          action: (
            <Box width="10%%" textAlign="right">
              <Menu placement="start" autoSelect={false}>
                <MenuButton
                  as={IconButton}
                  aria-label="More"
                  icon={<Icon as={FiMoreVertical} w={6} h={6} />}
                  variant="unstyled"
                />
                <MenuList
                  bg="#F9F9F9"
                  border="0"
                  zIndex="3"
                  borderRadius={8}
                  minWidth={31.75}>
                  <MenuItem
                    onClick={() => navigate(`editar/${client.id}`)}
                    px={3}
                    py="4"
                    fontSize="md"
                    fontWeight="500"
                    icon={<Icon as={FiEdit} w={4} h={4} />}
                    _hover={{ bg: 'neutralLight.300' }}>
                    Editar empresa
                  </MenuItem>
                  <MenuDivider color="#E9E9E9" m={0} />
                  {/* <MenuItem
                    onClick={() => {
                      onOpen()
                      setSelectId(client.id)
                    }}
                    p={3}
                    icon={<Icon as={FiTrash} w={4} h={4} />}
                    _hover={{ bg: 'neutralLight.300' }}>
                    Excluir empresa
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      onOpen()
                      setSelected(client)
                    }}
                    fontSize="md"
                    fontWeight="500"
                    px={3}
                    py="4"
                    icon={<Icon as={FiAlertCircle} w={4} h={4} mt={1} />}
                    _hover={{ bg: 'neutralLight.300' }}>
                    Ativar/desativar empresa
                  </MenuItem>
                  <MenuDivider color="#E9E9E9" m={0} />
                  <Tooltip label="Em breve">
                    <MenuItem
                      /* onClick={() => {
                        navigate(`/usuarios/criar/${client.id}`, {
                          state: {
                            clientProps: { name: client.name }
                          }
                        })
                        window.location.reload()
                      }} */
                      px={3}
                      py="4"
                      fontSize="md"
                      fontWeight="500"
                      disabled
                      color="#aaa"
                      icon={<Icon as={FiUserPlus} w={4} h={4} />}
                      _hover={{ bg: '#0000' }}>
                      Adicionar usuário
                    </MenuItem>
                  </Tooltip>
                </MenuList>
              </Menu>
            </Box>
          )
        }))}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose()
          refetch()
        }}
        isOpen={isOpen}
        size="3xl"
        isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader fontSize="xl" px={8} py={6}>
            Ativar/desativar empresa
            <Divider mt={6} borderBottom={0.5} borderBottomColor="#E9E9E9" />
          </AlertDialogHeader>
          <AlertDialogCloseButton
            top={6}
            right={6}
            color="#C8CCD4"
            fontSize="md"
          />
          <AlertDialogBody pt={0} px={8} fontSize="md" color="#8F8F8F">
            Tem certeza que deseja realizar esta ação?
          </AlertDialogBody>
          <AlertDialogFooter px={8} pb={8} mt={16}>
            <Button
              px={5}
              variant="outline"
              colorScheme="brand"
              ref={cancelRef}
              onClick={onClose}>
              Cancelar
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              px={5}
              colorScheme="brand"
              ml={6}
              onClick={() => onToggleClient(selected, onClose)}>
              Confirmar e fechar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  )
}
