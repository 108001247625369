/* eslint-disable indent */
import { Box, Flex, HStack, Icon, Text } from '@chakra-ui/react'
import { compareAsc, format, getDate, getMonth, getYear } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useId, useMemo } from 'react'
import { FiCalendar } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { normalizeNumber } from 'theme/normalization'

export type TimeProps = {
  date: Date
  name: string
  description: string
}

export type TimelineProps = {
  kickoffDate: string
  successEmailDate: string
  endDate: string
  timeline: TimeProps[]
}

const Highlighter = ({
  dateHasPassed,
  hide,
  multiple
}: {
  dateHasPassed: boolean
  hide?: boolean
  multiple?: number
}) => {
  const heigth = useMemo(() => {
    if (!multiple) return 20

    if (multiple <= 2) return multiple * normalizeNumber(24)

    if (multiple <= 5) return multiple * normalizeNumber(20)

    return multiple * 12
  }, [multiple])

  return (
    <Flex w={7.5} alignItems="center" flexDir="column" mb={1.5}>
      <Box
        top={2}
        h={'3'}
        w={'3'}
        bg={dateHasPassed ? 'brand.500' : 'gray.300'}
        borderRadius={6}
      />

      {hide ? (
        <Box mt={1} w={0.5} h={6} bg="transparent" borderRadius={1.5} />
      ) : (
        <Box
          mt={1.5}
          w={0.5}
          h={`${heigth}px`}
          bg={dateHasPassed ? 'brand.500' : 'gray.300'}
          borderRadius={1.5}
        />
      )}
    </Flex>
  )
}

const DateComponent = ({ date }: { date?: string }) => (
  <HStack>
    <Icon as={FiCalendar} h={3.5} w={3.5} color="GrayText" />
    <Text fontSize="xs" color="GrayText" lineHeight={6} fontWeight="normal">
      {date
        ? // @ts-ignore
          format(new Date(date), "dd 'de' MMMM", {
            locale: ptBR
          })
        : 'A definir'}
    </Text>
  </HStack>
)

const DateDescription = ({
  description,
  date,
  onJobDescription,
  dateHasPassed,
  hide
}: {
  description?: string
  date?: string
  onJobDescription: () => void
  dateHasPassed: boolean
  hide?: boolean
}) => (
  <HStack alignItems="flex-start">
    <Highlighter dateHasPassed={dateHasPassed} hide={hide} />
    <Box>
      <Text
        cursor="pointer"
        onClick={onJobDescription}
        fontSize="sm"
        lineHeight="none"
        fontWeight="bold">
        {description}
      </Text>
      <DateComponent date={date} />
    </Box>
  </HStack>
)

export const TimeLine = ({
  jobId,
  kickoffDate,
  successEmailDate,
  endDate,
  timeline,
  onCloseModal,
  simple = false
}: TimelineProps & any) => {
  const navigate = useNavigate()

  const dateHasPassed = (date: string) => {
    if (!date) return false
    // @ts-ignore
    const compare = compareAsc(new Date(), new Date(date))

    return compare > 0
  }

  const timelineYW = timeline.filter((tl: any) => tl.key === 'YELLOW')
  const timelineClient = timeline.filter((tl: any) => tl.key === 'CLIENT')

  const handleJobDescription = () => {
    if (!jobId) return

    onCloseModal()
    navigate('/pipeline-de-vagas/editar/' + jobId, {
      state: {
        fromTimeline: true
      }
    })
  }

  return (
    <Box mt={1}>
      <DateDescription
        description="Data de Kickoff"
        dateHasPassed={dateHasPassed(kickoffDate)}
        date={kickoffDate}
        onJobDescription={handleJobDescription}
      />
      {!simple ? (
        <DateDescription
          description="Data de Email de sucesso"
          dateHasPassed={dateHasPassed(successEmailDate)}
          date={successEmailDate}
          onJobDescription={handleJobDescription}
        />
      ) : null}

      <HStack alignItems="flex-start">
        <Highlighter
          dateHasPassed={dateHasPassed(timelineYW[0]?.date)}
          multiple={timelineYW.length}
        />
        <Box>
          <Text fontSize="sm" lineHeight="none" fontWeight="bold">
            Datas envio das Shortlist
          </Text>
          {timelineYW.length ? (
            timelineYW.map((tl: TimeProps, index: number) => (
              <HStack
                key={index}
                mb={index === timelineYW.length - 1 ? 2 : 0}
                mt={index === 0 ? 1 : 0}>
                <Icon as={FiCalendar} h={3.5} w={3.5} color="GrayText" m={0} />
                <Text
                  fontSize="xs"
                  color="GrayText"
                  lineHeight={4}
                  fontWeight="normal">
                  {format(new Date(tl.date), "dd 'de' MMMM", {
                    locale: ptBR
                  })}
                </Text>
              </HStack>
            ))
          ) : (
            <HStack>
              <Icon as={FiCalendar} h={3.5} w={3.5} color="GrayText" />
              <Text
                fontSize="xs"
                color="GrayText"
                lineHeight={6}
                fontWeight="normal">
                A definir
              </Text>
            </HStack>
          )}
        </Box>
      </HStack>

      {!simple ? (
        <HStack alignItems="flex-start">
          <Highlighter
            dateHasPassed={dateHasPassed(timelineClient[0]?.date)}
            multiple={timelineClient.length}
          />
          <Box>
            <Text fontSize="sm" lineHeight="none" fontWeight="bold">
              Datas previsão Cliente ITW
            </Text>
            {timelineClient.length ? (
              timelineClient.map((tl: TimeProps, index: number) => (
                <HStack
                  key={index}
                  mb={index === timelineClient.length - 1 ? 2 : 0}
                  mt={index === 0 ? 1 : 0}>
                  <Icon as={FiCalendar} h={3.5} w={3.5} color="GrayText" />
                  <Text
                    fontSize="xs"
                    color="GrayText"
                    lineHeight={4}
                    fontWeight="normal">
                    {format(new Date(tl.date), "dd 'de' MMMM", {
                      locale: ptBR
                    })}
                  </Text>
                </HStack>
              ))
            ) : (
              <HStack>
                <Icon as={FiCalendar} h={3.5} w={3.5} color="GrayText" />
                <Text
                  fontSize="xs"
                  color="GrayText"
                  lineHeight={6}
                  fontWeight="normal">
                  A definir
                </Text>
              </HStack>
            )}
          </Box>
        </HStack>
      ) : null}

      <DateDescription
        hide
        description="Data de fechamento previsto"
        dateHasPassed={dateHasPassed(endDate)}
        date={endDate}
        onJobDescription={handleJobDescription}
      />
    </Box>
  )
}
