/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable camelcase */
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Switch,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Breadcrumb } from 'components/atoms/Breadcrumb'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'
import { Select } from 'components/atoms/Select'
import { Tag } from 'components/atoms/Tag'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useUserStore from 'stores/user'
import { UserType } from 'stores/users'
import { useFetch } from 'use-http'
import { formatResponse } from 'utils/formatObjectValuesResponse'
import * as yup from 'yup'
import { YWOffices } from './../../../data/yellowInfo'

type FormType = {
  onSubmit: (data: any) => Promise<void> | void
  loading: boolean
  userId?: string
  userYW?: any
  client?: {
    uuid: string
    name: string
  }
}

const formSchema = yup.object().shape({
  accessCategory: yup.string().required('Campo obrigatório'),
  // accessSubcategory: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  is_lead: yup.boolean().optional().default(false),
  name: yup.string().required('Campo obrigatório'),
  team: yup.string().required('Campo obrigatório'),
  unity: yup.string().required('Campo obrigatório')
})

export const Form = ({ userId, userYW, onSubmit, loading }: FormType) => {
  const currentUser = useUserStore()
  const [tempUsers, setTempUsers] = useState<UserType[]>([])

  const {
    control,
    watch,
    resetField,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      accessCategory: userYW?.area?.[0]?.area?.name ?? '',
      accessSubcategory: userYW?.subarea?.[0]?.subarea?.name ?? '',
      is_lead: userYW?.is_lead ?? false,
      team: userYW?.team ?? '',
      unity: userYW?.yw_unity ?? '',
      name: userYW?.user?.name ?? '',
      email: userYW?.user?.email ?? ''
    },
    mode: 'onChange',
    resolver: yupResolver(formSchema)
  })

  const fields = watch()

  const { data: areas } = useFetch('/yw/areas', {}, [])
  const { data: subareas, loading: loadingSubareas } = useFetch(
    '/yw/subareas',
    {},
    []
  )

  const allowSubarea = useMemo(
    () => fields?.accessCategory === 'RECRUTAMENTO',
    [fields]
  )

  const allowTeams = useMemo(
    () =>
      !!(
        fields?.accessCategory &&
        fields?.accessCategory !==
          areas?.find((area: any) => area.name === 'RECRUTAMENTO').id
      ) || !!fields?.accessSubcategory,
    [fields, areas]
  )

  const area = useMemo(
    () => areas?.find((area: any) => area.name === fields?.accessCategory),
    [fields.accessCategory, areas]
  )

  const subarea = useMemo(
    () =>
      subareas?.find(
        (subarea: any) => subarea.name === fields?.accessSubcategory
      ),
    [fields.accessSubcategory, subareas]
  )

  const addUser = () => {
    const fieldsEdit = {
      ...fields,
      accessCategory: area,
      accessSubcategory: subarea
    }

    setTempUsers([...tempUsers, fieldsEdit] as any)
    resetField('name')
    resetField('email')
  }

  const handleSubmitUsers = useCallback(async () => {
    if (errors && Object.keys(errors).length > 0) return

    if (userYW) {
      try {
        delete fields?.accessCategory
        delete fields?.accessSubcategory

        const fieldsEdit = {
          ...fields,
          area,
          subarea
        }

        onSubmit(fieldsEdit)
      } catch (err: any) {
        throw new Error(err?.message)
      }
      return
    }

    try {
      onSubmit(tempUsers)
      setTempUsers([])
    } catch (err: any) {
      throw new Error(err?.message)
    }
  }, [area, errors, fields, onSubmit, subarea, tempUsers, userYW])

  return (
    <form
      style={{ height: '100%', width: '100%' }}
      // eslint-disable-next-line no-console
      onSubmit={handleSubmit(handleSubmitUsers)}>
      <HStack
        spacing={6}
        width="100%"
        display="flex"
        alignItems="stretch"
        flexDirection={{ base: 'column', md: 'row' }}>
        <Box bg="white" height="100%" flex={1} borderRadius="lg" p={6}>
          <Flex
            alignItems="flex-start"
            flexDir="column"
            py="6"
            px="2"
            width="100%"
            gap="8">
            <Box width="100%">
              <Breadcrumb
                menus={[
                  {
                    current: false,
                    slug: '/usuarios',
                    title: 'Gestão de Usuários'
                  },
                  {
                    current: true,
                    slug: userId
                      ? `/usuarios/editar/${userId}`
                      : '/usuarios/criar',
                    title: `${userId ? 'Editar' : 'Cadastrar'} Usuário`
                  }
                ]}
              />
            </Box>

            <Box width="100%">
              <Input
                control={control}
                name="name"
                label="Nome"
                type="text"
                variant="filled"
                requiredInfo={true}
                errorMessage={errors?.name?.message}
                isInvalid={!tempUsers && errors?.name}
              />
            </Box>

            <Box width="100%">
              <HStack spacing={6} display="flex">
                <Box flex={1}>
                  <Input
                    control={control}
                    name="email"
                    label="E-mail"
                    type="email"
                    variant="filled"
                    requiredInfo={true}
                    errorMessage={errors?.email?.message}
                    isInvalid={!tempUsers && errors?.email}
                  />
                </Box>

                <Box flex={1} zIndex={200}>
                  <Controller
                    control={control}
                    name="unity"
                    defaultValue={userYW?.yw_unity}
                    rules={{ required: true }}
                    render={({ field: { ref, value, ...props } }) => (
                      <Menu
                        {...props}
                        options={YWOffices}
                        label="Unidade Yellow"
                        requiredInfo={true}
                        errorMessage={errors?.unity?.message}
                        value={value || userYW?.yw_unity}
                      />
                    )}
                  />
                </Box>
              </HStack>
            </Box>

            <Fragment>
              <Box width="100%">
                <Select
                  label="Categoria de acesso"
                  control={control}
                  name="accessCategory"
                  variant="filled"
                  requiredInfo={true}
                  isInvalid={!tempUsers && errors?.accessCategory}
                  errorMessage={errors.accessCategory?.message}
                  placeholder="Selecione">
                  {!!areas &&
                    areas.map((area: any) => (
                      <option key={area?.id} value={area?.name}>
                        {formatResponse(area?.name)}
                      </option>
                    ))}
                </Select>
              </Box>

              <Box width="100%">
                <Select
                  control={control}
                  name="accessSubcategory"
                  label="Subcategoria de acesso"
                  variant="filled"
                  isInvalid={!tempUsers && errors?.accessSubcategory}
                  isDisabled={!allowSubarea}
                  errorMessage={errors?.accessSubcategory?.message}
                  requiredInfo={!allowSubarea}
                  placeholder="Selecione">
                  {allowSubarea &&
                    !loadingSubareas &&
                    subareas.map((subarea: any) => (
                      <option key={subarea?.id} value={subarea?.name}>
                        {subarea?.name}
                      </option>
                    ))}
                </Select>
              </Box>

              <Box width="100%">
                <Select
                  label="Time"
                  control={control}
                  name="team"
                  variant="filled"
                  id="team"
                  isInvalid={!tempUsers && errors?.team}
                  isDisabled={!allowTeams}
                  errorMessage={errors?.team?.message}
                  requiredInfo={true}
                  placeholder="Selecione">
                  {area?.name === 'RECRUTAMENTO'
                    ? subarea?.vertical.map((vertical: any) => (
                        <option key={vertical} value={vertical}>
                          {formatResponse(vertical)}
                        </option>
                      ))
                    : area?.vertical.map((vertical: any) => (
                        <option key={vertical} value={vertical}>
                          {formatResponse(vertical)}
                        </option>
                      ))}
                </Select>
              </Box>

              {currentUser?.team === 'MASTER' && currentUser?.is_lead && (
                <Box width="100%">
                  <Controller
                    control={control}
                    name="is_lead"
                    rules={{ required: true }}
                    defaultValue={userYW?.is_lead}
                    render={({ field: { ref, value, ...props } }) => (
                      <HStack mt={2} spacing={3}>
                        <Switch
                          colorScheme="brand"
                          id="is_lead"
                          isChecked={value}
                          {...props}
                        />
                        <FormLabel
                          fontWeight="bold"
                          fontSize="md"
                          color="black"
                          htmlFor="is_lead">
                          Líder de time?
                        </FormLabel>
                      </HStack>
                    )}
                  />
                </Box>
              )}
            </Fragment>
          </Flex>

          {!userYW && (
            <Box width="100%">
              <Button
                variant="outline"
                colorScheme="brand"
                mt={34}
                width="100%"
                py="0.5"
                borderRadius="md"
                fontSize="sm"
                fontWeight="500"
                isDisabled={!isValid}
                onClick={addUser}>
                Adicionar usuário
              </Button>
            </Box>
          )}
        </Box>

        <Box flex={1} p={6} bg="white" borderRadius="lg">
          <VStack align="flex-start">
            {tempUsers.map((user: any, idx) => (
              <Tag
                key={`user_${idx}`}
                size="lg"
                variant="solid"
                title={`${user.name} | ${user.unity} | ${
                  user.accessCategory?.name
                } | ${user.team || 'Sem time'} | ${
                  !user.is_lead ? 'Não' : ''
                } Líder`}
                onClick={() =>
                  setTempUsers(tempUsers.filter((u, i) => i !== idx))
                }
              />
            ))}
          </VStack>
        </Box>
      </HStack>

      <Box my={6}>
        <VStack spacing={6} pb={6} align={{ base: 'center', md: 'flex-end' }}>
          <Divider />
          <Button
            type="submit"
            isDisabled={!userYW && !tempUsers.length}
            loading={loading}
            variant="solid"
            colorScheme="brand"
            borderRadius="md"
            width={230}
            onClick={handleSubmitUsers}>
            Salvar e fechar
          </Button>
        </VStack>
      </Box>
    </form>
  )
}
