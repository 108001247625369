export const lowerCaseRegex = /.*[a-z].*/
export const upperCaseRegex = /.*[A-Z].*/
export const numberRegex = /.*\d.*/
export const specialCharacterRegex =
  // eslint-disable-next-line no-useless-escape
  /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
export const minLength = 8
export const passwordComplexityRegex =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/
