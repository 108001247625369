import { HStack, Stack } from '@chakra-ui/react'
import IconGoogle from '../../../assets/icons/google.svg'
import IconOutlook from '../../../assets/icons/outlook.svg'
import { Button } from '../../../components/atoms/Button'

export const SocialLogin = () => (
  <Stack spacing={6}>
    <Button disabled variant="outline" borderColor="black" borderRadius={24}>
      <HStack>
        <img src={IconGoogle} width={24} height={24} alt="Logo Google" />
        <span>Continuar com Google</span>
      </HStack>
    </Button>
    <Button disabled variant="outline" borderColor="black" borderRadius={24}>
      <HStack>
        <img src={IconOutlook} width={24} height={24} alt="Logo Outlook" />
        <span>Continuar com Outlook</span>
      </HStack>
    </Button>
  </Stack>
)
