import { HStack, IconButton } from '@chakra-ui/react'
import { Input } from 'components/atoms/Input'
import { Controller } from 'react-hook-form'
import { FiPlus, FiTrash } from 'react-icons/fi'

export const ComplementaryCourse = ({ control, idx, onAdd, onDelete }: any) => (
  <HStack width="100%" alignItems="flex-start" spacing={4}>
    <Controller
      control={control}
      name={`candidate_technical_or_complementary_courses.${idx}.institution`}
      render={({ field: { ref, value, ...props } }) => (
        <Input
          {...props}
          label="Instituição de ensino"
          variant="filled"
          placeholder="Digite aqui"
          value={value ?? ''}
        />
      )}
    />
    <Controller
      control={control}
      name={`candidate_technical_or_complementary_courses.${idx}.complementary_course`}
      render={({ field: { ref, value, ...props } }) => (
        <Input
          {...props}
          label="Curso"
          variant="filled"
          placeholder="Digite aqui"
          value={value ?? ''}
        />
      )}
    />
    <HStack pt={10} alignItems="center" spacing={4}>
      <IconButton
        onClick={onDelete}
        variant="ghost"
        size="xs"
        colorScheme="black"
        icon={<FiTrash fontSize="1.5rem" />}
        aria-label="Delete"
      />
      <IconButton
        isRound
        onClick={onAdd}
        variant="solid"
        size="sm"
        colorScheme="brand"
        icon={<FiPlus fontSize="1.2rem" color="white" />}
        aria-label="Add"
      />
    </HStack>
  </HStack>
)
