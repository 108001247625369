/* eslint-disable sort-keys */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text as TextChakra,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Table } from 'components/organisms/Table'
import { YWOffices } from 'enum/user'
import { Fragment, useRef, useState } from 'react'
import { FiAlertCircle, FiEdit, FiMoreVertical } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import useFetch from 'use-http'
import { formatResponse } from 'utils/formatObjectValuesResponse'

type TableUsersType = {
  data: any[]
  refetch: any
}

type TextProps = {
  bold?: boolean
  children: JSX.Element | string
}

const Text = ({ bold, children }: TextProps) => (
  <TextChakra fontSize="sm" color="black" fontWeight={bold ? 'bold' : 'normal'}>
    {children}
  </TextChakra>
)

export const TableUsers = ({ data, refetch }: TableUsersType) => {
  const toast = useToast()
  const navigate = useNavigate()

  const {
    isOpen: isOpenStaus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus
  } = useDisclosure()
  const [selectedUser, setSelectedUser] = useState<any>({})

  const cancelRef: any = useRef()
  const cancelStatusRef: any = useRef()

  const { put, response, error } = useFetch(
    Endpoints.users.userById(selectedUser?.id)
  )

  const onChangeStatus = async () => {
    await put({
      status: !selectedUser.status
    })

    if (error) {
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description:
          'Não foi possível atualizar o usuário. Por favor, tente novamente.',
        position: 'top-right',
        status: 'warning',
        title: response.status === 500 ? 'Erro interno' : 'Erro',
        variant: 'subtle'
      })

      return
    }

    setSelectedUser({})
    onCloseStatus()
    refetch()
  }

  return (
    <Fragment>
      <Table
        headers={[
          { title: 'Nome' },
          { title: 'Área YW' },
          { title: 'Vertical YW' },
          { title: 'Unidade YW' },
          { title: 'Líder' },
          { title: 'Status' },
          { title: '' }
        ]}
        data={data.map((user) => ({
          name: <Text bold>{user.user.name}</Text>,
          role: <Text>{formatResponse(user?.area[0]?.area?.name)}</Text>,
          team: <Text>{formatResponse(user?.team) || '–'}</Text>,
          unity: <Text>{YWOffices[user?.yw_unity] ?? '–'}</Text>,
          is_lead: <Text>{user?.is_lead ? 'Sim' : 'Não'}</Text>,
          status: (
            <Badge
              variant="outline"
              colorScheme={user?.status ? 'green' : 'red'}>
              {user?.status ? 'Ativo' : 'Inativo'}
            </Badge>
          ),
          action: (
            <Box textAlign="right">
              <Menu placement="start" autoSelect={false}>
                <MenuButton
                  as={IconButton}
                  aria-label="More"
                  bg="transparent"
                  icon={<Icon as={FiMoreVertical} w={6} h={6} />}
                />
                <MenuList
                  bg="#F9F9F9"
                  border="0"
                  zIndex="3"
                  borderRadius={8}
                  minWidth={31.75}>
                  <MenuItem
                    color="black"
                    fontSize="md"
                    fontWeight="500"
                    px={3}
                    py="4"
                    icon={<Icon as={FiEdit} w={4} h={4} />}
                    _hover={{ bg: 'neutralLight.300' }}
                    onClick={() =>
                      navigate(`editar/${user.id}`, { state: user })
                    }>
                    Editar usuário
                  </MenuItem>
                  <MenuDivider color="#E9E9E9" m={0} />
                  <MenuItem
                    onClick={() => {
                      setSelectedUser(user)
                      onOpenStatus()
                    }}
                    color="black"
                    fontSize="md"
                    fontWeight="500"
                    px={3}
                    py="4"
                    icon={<Icon as={FiAlertCircle} w={4} h={4} mt={1} />}
                    _hover={{ bg: 'neutralLight.300' }}>
                    Ativar/desativar usuário
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          )
        }))}
      />
      <AlertDialog
        isCentered
        isOpen={isOpenStaus}
        onClose={onCloseStatus}
        leastDestructiveRef={cancelStatusRef}
        motionPreset="slideInBottom"
        size="3xl">
        <AlertDialogOverlay />

        <AlertDialogContent bg="white">
          <AlertDialogHeader
            color="black"
            fontSize={20}
            fontWeight={700}
            px={8}
            py={6}>
            Ativar/desativar usuário
            <Divider mt={6} borderBottom="2px" borderBottomColor="#E9E9E9" />
          </AlertDialogHeader>
          <AlertDialogCloseButton
            top={6}
            right={6}
            color="#C8CCD4"
            fontSize="md"
            fontWeight="500"
          />
          <AlertDialogBody pt={0} px={8} fontSize="md" color="grayText">
            Tem certeza que deseja realizar esta ação?
          </AlertDialogBody>
          <AlertDialogFooter px={8} pb={8} mt={16}>
            <Button
              width={230}
              variant="outline"
              colorScheme="brand"
              ref={cancelRef}
              onClick={onCloseStatus}>
              Cancelar
            </Button>
            <Button
              width={230}
              colorScheme="brand"
              ml={6}
              onClick={onChangeStatus}>
              Confirmar e fechar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isCentered
        isOpen={isOpenStaus}
        onClose={onCloseStatus}
        leastDestructiveRef={cancelStatusRef}
        motionPreset="slideInBottom"
        size="3xl">
        <AlertDialogOverlay />

        <AlertDialogContent bg="white">
          <AlertDialogHeader
            color="black"
            fontSize={20}
            fontWeight={700}
            px={8}
            py={6}>
            Ativar/desativar usuário
            <Divider mt={6} borderBottom="2px" borderBottomColor="#E9E9E9" />
          </AlertDialogHeader>
          <AlertDialogCloseButton
            top={6}
            right={6}
            color="#C8CCD4"
            fontSize="md"
          />
          <AlertDialogBody pt={0} px={8} fontSize="md" color="grayText">
            Tem certeza que deseja realizar esta ação?
          </AlertDialogBody>
          <AlertDialogFooter px={8} pb={8} mt={16}>
            <Button
              width={230}
              variant="outline"
              colorScheme="brand"
              ref={cancelRef}
              onClick={onCloseStatus}>
              Cancelar
            </Button>
            <Button
              width={230}
              colorScheme="brand"
              ml={6}
              onClick={onChangeStatus}>
              Confirmar e fechar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  )
}
