/* eslint-disable camelcase */
import { Avatar, Box, HStack, Text, VStack } from '@chakra-ui/react'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export type CommentProps = {
  id: string
  name: string
  comment: string
  created_at: number
}

export const Comment = ({ id, name, comment, created_at }: CommentProps) => {
  return (
    <HStack key={id} mt={6} alignItems="flex-start">
      <Avatar size="sm" />
      <VStack alignItems="flex-start" pt="1">
        <HStack>
          <HStack>
            <Text fontWeight="medium" fontSize="sm">
              {name}
            </Text>
            <Text fontSize="sm">adicionou comentário</Text>
          </HStack>
        </HStack>

        <Box bg="grayBackground" py={2} px={4} borderRadius="md">
          <Text fontSize="sm">{comment}</Text>
        </Box>

        <Text fontSize="xs" color="GrayText" lineHeight="3" fontWeight="normal">
          {format(new Date(created_at), "dd 'de' MMM 'de' yyyy 'às' HH:mm", {
            locale: ptBR
          })}
        </Text>
      </VStack>
    </HStack>
  )
}
