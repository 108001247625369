/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import {
  Box,
  Button,
  Circle,
  Flex,
  HStack,
  Input as InputChrakra,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox } from 'components/atoms/Checkbox'
import { Input } from 'components/atoms/Input'
import useDebounce from 'hooks/useDebounce'
import { isEmpty } from 'radash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FiSearch } from 'react-icons/fi'
import { useFetch } from 'use-http'
import * as yup from 'yup'
import { Endpoints } from '../../../api/endpoints'
import { capitalizeNames } from '../../../utils/strings'
import { Tooltip } from '../../atoms/Tooltip'

export type FiltersOptionType = {
  button: string
  disabled?: boolean
  filters: {
    field: string
    filterType: 'checkbox' | 'input' | 'range'
    disableSearch?: boolean
    endpointFields?: string[]
    endpoint?: string
    searchField?: string
    filtersOptions?: {
      label: string
      value: string
    }[]
  }
}

const defaultSelected = (options: any[] | undefined) => {
  return options ? options?.map(() => false) : []
}

const FilterCheckbox = ({
  data: filters,
  ready,
  onFilter,
  values
}: {
  data: FiltersOptionType['filters']
  ready: boolean
  onFilter: (_values: { [key: string]: string }) => void
  values: any
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [selectedFilters, setSelectedFilters] = useState<boolean[]>(
    defaultSelected(filters?.filtersOptions)
  )

  const debouncedSearchTerm = useDebounce(searchTerm)

  const [dataFilters, setDataFilters] = useState<
    { label: string; value: string }[]
  >(
    filters?.filtersOptions ?? [
      {
        label: '',
        value: ''
      }
    ]
  )
  const [filteredList] = useState(dataFilters)

  const allChecked = !!selectedFilters.length && selectedFilters.every(Boolean)
  const isIndeterminate =
    selectedFilters.some(Boolean) || !!(allChecked && searchTerm)

  const { control, handleSubmit } = useForm()

  const onHandleFilter = useCallback(() => {
    onFilter(
      allChecked && !isIndeterminate
        ? { [filters.field]: '' }
        : {
            [filters.field]: selectedFilters
              .map((key, idx) => key && dataFilters[idx].value)
              .filter((f: any) => f)
              .join(',')
          }
    )
  }, [dataFilters, filters.field, onFilter, selectedFilters])

  const { loading, get } = useFetch(filters.endpoint)

  const label = useMemo(
    () =>
      filters?.endpointFields?.[0]?.split('.') ?? [
        filters?.endpointFields?.[0]
      ],
    [filters?.endpointFields]
  )

  const formatSearch = (search: any): string => {
    if (filters.endpoint === Endpoints.customers.allCustomers) {
      return `&${search.field}=${search.term}`
    }

    if (filters?.endpoint?.includes('/users/yw')) {
      return `&${search.field}=${search.term}`
    }

    return `?${search.field}=${search.term}`
  }

  const getData = useCallback(
    async (search?: { term: string; field: string }) => {
      const data = await get(search ? formatSearch(search) : '')

      setDataFilters(
        (data?.data || data)?.map((d: any) => ({
          label:
            label.length === 2
              ? d[label?.[0] as string]?.[label?.[1] as string]
              : d[label?.[0] as string],
          value: d[filters?.endpointFields?.[1] as string]
        }))
      )
      setSelectedFilters((state) =>
        (data?.data || data)?.map((item: any, index: number) =>
          Boolean(state[index])
        )
      )
      setIsSearching(false)
    },
    [filters?.endpointFields, get, label, debouncedSearchTerm]
  )

  useEffect(() => {
    if (filters.field === 'city' || filters.field === 'state') {
      setIsSearching(false)

      setDataFilters((data) => {
        return data.filter((item) =>
          item.label.toUpperCase().includes(searchTerm.toUpperCase())
        )
      })
    }
  }, [searchTerm, filters])

  useEffect(() => {
    if (
      filters.searchField &&
      filters.endpoint &&
      filters.field !== 'city' &&
      filters.field !== 'state'
    ) {
      if (debouncedSearchTerm) {
        setIsSearching(true)
        getData({ field: filters.searchField, term: debouncedSearchTerm })
      } else {
        getData()
      }
    }
  }, [debouncedSearchTerm, filters.endpoint, filters.searchField, getData])

  useEffect(() => {
    if (!filters.endpoint && !filters.searchField) {
      if (searchTerm) {
        setDataFilters(filteredList.filter((f) => f.label.includes(searchTerm)))
      } else {
        setDataFilters(filters?.filtersOptions ?? [])
      }
    }
  }, [
    filteredList,
    filters.endpoint,
    filters?.filtersOptions,
    filters.searchField,
    searchTerm
  ])

  useEffect(() => {
    ready && filters.endpoint && getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.endpoint, ready])

  return (
    <VStack
      as="form"
      width="100%"
      spacing={4}
      onSubmit={handleSubmit(onHandleFilter)}>
      {!filters?.disableSearch && (
        <Input
          placeholder="Pesquisar"
          type="search"
          variant="filled"
          loading={
            filters.field !== 'city' && filters.field !== 'state' && isSearching
          }
          disabled={loading}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
      {loading && (
        <Box
          width="100%"
          height="155px"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Box>
      )}
      {!loading && dataFilters?.length && (
        <VStack
          width="100%"
          pl={2}
          maxHeight="155px"
          overflowX="hidden"
          overflowY="auto">
          {!searchTerm && (
            <Checkbox
              label="Selecionar todos"
              value="all"
              isChecked={allChecked}
              isIndeterminate={isIndeterminate}
              onChange={(e) =>
                setSelectedFilters(dataFilters?.map(() => e.target.checked))
              }
            />
          )}

          {dataFilters
            .sort((a, b) => Intl.Collator('pt-BR').compare(a.label, b.label))
            ?.map((filter, idx: number) => (
              <Controller
                key={`filter_${idx}`}
                control={control}
                name={`${filters.field}[${idx}]`}
                render={({ field: { ref, value, ...rest } }) => (
                  <Checkbox
                    {...rest}
                    label={
                      filters.field === 'city'
                        ? capitalizeNames(filter.label)
                        : filter.label
                    }
                    value={filter.value}
                    isChecked={selectedFilters[idx]}
                    onChange={(e) =>
                      setSelectedFilters((state) => [
                        ...state.slice(0, idx),
                        e.target.checked,
                        ...state.slice(idx + 1)
                      ])
                    }
                  />
                )}
              />
            ))}
        </VStack>
      )}

      <Button
        disabled={loading}
        type="submit"
        width="100%"
        height="32px"
        variant="solid"
        colorScheme="brand"
        borderRadius="lg"
        size="sm">
        Filtrar
      </Button>
    </VStack>
  )
}

const FilterInput = ({
  bg,
  field,
  onFilter
}: {
  bg?: string
  field: string
  onFilter: (_values: { [key: string]: string }) => void
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        [field]: yup.string()
      })
    )
  })

  const debouncedSearchTerm = useDebounce(searchTerm, 3000)

  const onHandleFilter = useCallback((payload: any) => {
    onFilter({ ...payload })
  }, [])

  useEffect(() => {
    debouncedSearchTerm && onFilter({ [field]: searchTerm })
  }, [debouncedSearchTerm, field, searchTerm])

  return (
    <HStack
      as="form"
      mt="3"
      spacing={4}
      onSubmit={handleSubmit(onHandleFilter)}>
      <Controller
        control={control}
        name={field}
        rules={{ required: true }}
        render={({ field: { ref, ...props } }) => (
          <InputGroup width="21rem">
            <InputLeftElement
              pointerEvents="none"
              h="100%"
              justifyContent="center"
              alignItems="center">
              <FiSearch color="gray.300" />
            </InputLeftElement>
            <InputChrakra
              {...props}
              py="5"
              type="text"
              placeholder="Digite aqui"
              variant="filled"
              bg={bg}
              _hover={{ bg: 'white' }}
              _focus={{ background: 'white' }}
              onChange={(e) => {
                // eslint-disable-next-line react/prop-types
                props.onChange(e)
                setSearchTerm(e.target.value)
              }}
            />
          </InputGroup>
        )}
      />
      {watch()?.[field]?.length && (
        <Button
          variant="unstyled"
          size="xs"
          height="30px"
          onClick={() => {
            onFilter({ [field]: '' })
            setValue(field, '')
            setSearchTerm('')
          }}>
          Limpar
        </Button>
      )}
    </HStack>
  )
}

const FilterRange = ({
  field,
  onFilter
}: {
  field: string
  onFilter: (_values: { [key: string]: string }) => void
}) => {
  const [range, setRange] = useState({ min: 18, max: 25 })

  return (
    <VStack w="100%" alignItems="flex-start" mt="3">
      <Text fontSize="md">Entre:</Text>
      <RangeSlider
        w="60%"
        min={14}
        max={65}
        onChange={(e) => setRange({ min: e[0], max: e[1] })}
        defaultValue={[range.min, range.max]}>
        <RangeSliderTrack bg="neutralLight.500">
          <RangeSliderFilledTrack bg="brand.500" />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={5} index={0}>
          <Text fontSize="xs">{range.min}</Text>
        </RangeSliderThumb>
        <RangeSliderThumb boxSize={5} index={1}>
          <Text fontSize="xs">{range.max}</Text>
        </RangeSliderThumb>
      </RangeSlider>
    </VStack>
  )
}

type FiltersType = {
  scrollable?: boolean
  bg?: string
  options: FiltersOptionType[]
  onFilter: (_values: { [key: string]: string }) => void
  onClearFilters: () => void
  filters: any
}

export const Filters = ({
  options,
  onFilter,
  onClearFilters,
  filters = {},
  bg = 'white',
  scrollable = false
}: FiltersType) => {
  const [currentField, setCurrentField] = useState<string | null>(null)
  const [toggleInput, setToggleInput] = useState<boolean>(false)
  const [toggleRange, setToggleRange] = useState<boolean>(false)

  const isActive = (option: FiltersOptionType): boolean => {
    return Object.keys(filters)?.includes(option?.filters?.field)
  }

  useEffect(() => {
    if (!toggleInput && !toggleRange) {
      setCurrentField((old) => {
        old && onFilter({ [old]: '' })
        return null
      })
    }
  }, [onFilter, toggleInput, toggleRange])

  return (
    <Box width="100%">
      <Box width="100%" overflowX={scrollable ? 'scroll' : 'hidden'}>
        <Flex gap={2} wrap="nowrap" overflow="hidden" width="fit-content">
          {options
            .filter((filter) => !filter.disabled)
            .filter(({ filters }: any) => filters?.filterType === 'checkbox')
            .map((option, idx) => (
              <Popover key={`option_${idx}`} placement="bottom-start">
                {({ isOpen }) => (
                  <>
                    <PopoverTrigger>
                      <Button
                        disabled={option.disabled}
                        variant="solid"
                        bg={isActive(option) ? '#1B1C1E' : bg}
                        color={isActive(option) ? 'white' : '#1B1C1E'}
                        size="xs"
                        px={5}
                        borderRadius="md"
                        fontWeight="400"
                        height={30}>
                        {option.button}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent width="15rem" zIndex={9}>
                      <PopoverHeader
                        borderBottom={0}
                        color="#1B1C1E"
                        fontWeight="medium"
                        fontSize="md">
                        {option.button}
                      </PopoverHeader>
                      <PopoverBody>
                        <FilterCheckbox
                          data={option.filters}
                          ready={isOpen}
                          onFilter={onFilter}
                          values={filters}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </>
                )}
              </Popover>
            ))}

          {options
            .filter((filter) => !filter.disabled)
            .filter(({ filters }: any) => filters?.filterType === 'input')
            .map((option, idx) => (
              <Button
                key={`option_input_${idx}`}
                disabled={option.disabled}
                variant="solid"
                bg={isActive(option) ? '#1B1C1E' : bg}
                color={isActive(option) ? 'white' : '#1B1C1E'}
                size="xs"
                px={5}
                borderRadius="md"
                fontWeight="400"
                height={30}
                onClick={() => {
                  setCurrentField(option.filters.field)
                  setToggleInput(!currentField)
                }}>
                {option.button}
              </Button>
            ))}

          {options
            .filter((filter) => !filter.disabled)
            .filter(({ filters }: any) => filters?.filterType === 'range')
            .map((option, idx) => (
              <Button
                key={`option_input_${idx}`}
                disabled={option.disabled}
                variant="solid"
                bg={isActive(option) ? '#1B1C1E' : bg}
                color={isActive(option) ? 'white' : '#1B1C1E'}
                size="xs"
                px={5}
                borderRadius="md"
                fontWeight="400"
                w="-webkit-max-content"
                height={30}
                onClick={() => {
                  setCurrentField(option.filters.field)
                  setToggleRange(!currentField)
                }}>
                {option.button}
              </Button>
            ))}

          {options
            .filter((filter) => filter.disabled)
            .map((option, idx) => (
              <Tooltip
                key={`option_input_${idx}`}
                label={
                  option.filters.field === 'city'
                    ? 'Selecione um estado'
                    : 'Em breve'
                }
                placement="top-start">
                <Button
                  disabled
                  variant="solid"
                  bg={bg}
                  color="black"
                  size="xs"
                  px={5}
                  borderRadius="md"
                  fontWeight="400"
                  w="-webkit-max-content"
                  height={30}>
                  {option.button}
                </Button>
              </Tooltip>
            ))}

          {!isEmpty(filters) && (
            <>
              <Flex
                bg="#1B1C1E"
                px={4}
                height={30}
                rounded="lg"
                borderRadius="md"
                alignItems="center">
                <Text color="white" fontSize={12} mr={2}>
                  Filtros
                </Text>
                <Circle bg="white" color="#1B1C1E" size={6}>
                  <Text fontSize={12}>{Object.keys(filters)?.length}</Text>
                </Circle>
              </Flex>

              <Button
                variant="outline"
                size="xs"
                borderRadius="md"
                px={4}
                height={30}
                onClick={onClearFilters}>
                Limpar
              </Button>
            </>
          )}
        </Flex>
      </Box>

      <Box>
        {toggleInput && (
          <FilterInput bg={bg} field={currentField ?? ''} onFilter={onFilter} />
        )}
        {toggleRange && (
          <FilterRange field={currentField ?? ''} onFilter={onFilter} />
        )}
      </Box>
    </Box>
  )
}
