/* eslint-disable camelcase */
import { Box, VStack, useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Breadcrumb } from 'components/atoms/Breadcrumb'
import { Tabs } from 'components/atoms/Tabs'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useFetch, { Res } from 'use-http'
import { deepEqual, isBoolean } from 'utils/helper'
import { Form } from './components/Form'
import { ClientType } from './types/clients'

const formatCustomerResponse = async ({ response }: { response: Res<any> }) => {
  const res = response
  if (res.data) {
    const contacts = res.data.contacts[0]

    res.data = {
      ...res.data,
      contact_email: contacts?.email,
      contact_name: contacts?.name,
      contact_phone: contacts?.phone
    }
  }
  return response
}

const deleteKAMS = (kams: any, currentKams: any) => {
  if (!currentKams.length) return []
  const filterKams = currentKams.filter((ck: any) =>
    kams.find((k: { value: string }) => ck.user_yw_id !== k.value)
  )

  return filterKams.map(({ id }: { id: string }) => ({
    id
  }))
}

const createKAMS = (kams: any, currentKams: any) => {
  if (deepEqual(kams, currentKams)) return null

  if (!currentKams.length) {
    return kams.map(({ value, id }: { value: string; id: string }) => ({
      user_yw: {
        connect: {
          id: value || id
        }
      }
    }))
  }

  const filterKam = kams.filter((k: { value: string }) =>
    currentKams.find((ck: any) => ck.user_yw_id !== k.value)
  )

  return filterKam.map(({ value }: { value: string }) => ({
    user_yw: {
      connect: {
        id: value
      }
    }
  }))
}

export const EditClient = () => {
  const toast = useToast()
  const location = useLocation()
  const navigate = useNavigate()
  const onError = useErrorToast()
  const { uuid }: any = useParams()

  const {
    put: updateCustomer,
    loading: loadingUpdate,
    response: resUpdate
  } = useFetch(Endpoints.customers.update(uuid), {
    onError: () =>
      onError({
        title: 'Não foi possível atualizar o cliente.',
        description: 'Por favor, tente novamente.'
      })
  })

  const { data: client, loading } = useFetch(
    Endpoints.customers.findOne(uuid),
    {
      interceptors: {
        response: formatCustomerResponse
      },
      onError: () =>
        onError({
          title: 'Erro ao carregar dados do cliente.',
          description: 'Por favor, tente novamente.'
        })
    },
    [location.key, uuid, loadingUpdate]
  )

  const handleUpdateCustomer = async (data: ClientType) => {
    const body = {
      ...data,
      is_active: isBoolean(data.is_active),

      contacts: {
        name: data.contact_name,
        email: data.contact_email,
        phone: data.contact_phone
      },

      kams: {
        ...(!deepEqual(data.kams, client.kams)
          ? { delete: deleteKAMS(data.kams, client.kams) }
          : {}),
        ...(!deepEqual(data.kams, client.kams)
          ? { create: createKAMS(data.kams, client.kams) }
          : {})
      }
    }

    // @ts-ignore
    delete body.kam
    // @ts-ignore
    delete body.contact_email
    // @ts-ignore
    delete body.contact_name
    // @ts-ignore
    delete body.contact_phone
    // @ts-ignore
    delete body.business_area_id

    await updateCustomer(body)

    if (resUpdate.status !== 200) return

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Cliente atualizado com sucesso',
      variant: 'subtle'
    })

    navigate('/clientes')
  }

  return (
    <Fragment>
      <VStack spacing={2} alignItems="flex-start">
        <HeaderPage title="Empresas e Usuários" />
        <Breadcrumb
          menus={[
            {
              current: false,
              slug: '/clientes',
              title: 'Empresas e Usuários'
            },
            {
              current: true,
              slug: `/clientes/editar/${uuid}`,
              title: 'Editar empresa'
            }
          ]}
        />
      </VStack>
      {client && (
        <Box mt={9}>
          <Tabs
            tabs={[
              {
                content: (
                  <Form
                    clientUuid={uuid}
                    loading={loadingUpdate || loading}
                    values={client}
                    onSubmit={handleUpdateCustomer}
                  />
                ),
                title: 'Empresa'
              }
              /*       {
              content: <TableUsers client={client} />,
              title: 'Usuários'
            } */
            ]}
          />
        </Box>
      )}
    </Fragment>
  )
}
