/* eslint-disable prettier/prettier */
import { Box, Divider, Flex, HStack, Heading, IconButton } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'
import { Select } from 'components/atoms/Select'
import { Textarea } from 'components/atoms/Textarea'
import { SuperiorCoursesTypes } from 'enum/user'
import { Fragment, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { Res, useFetch } from 'use-http'
import { LanguageSkillLevelType } from './../../../../data/userProfile'
import { ComplementaryCourse } from './Profile/ComplementaryCourse'

export const Resume = () => {
  const { control, setValue } = useFormContext()

  const {
    loading: loadingSuperiorCourses,
    data: dataSuperiorCourses,
    get: getSuperiorCourses
  } = useFetch(Endpoints.lists.superiorCourses, {
    interceptors: {
      response: async ({ response }) => {
        response.data = response.data.filter((value: any, index: any, self: any) =>
          index === self.findIndex((s: any) => (
            s.name === value.name && s.name === value.name
          ))
        )

        return response
      }
    }
  })

  const {
    loading: loadingUniversities,
    data: dataUniversities,
    get: getUniversities,
  } = useFetch(`${Endpoints.lists.universities}?take=100&skip=0`)

  const {
    loading: loadingCreateUniversities,
    post: createUniversity,
    response: restCreateUniversity
  } = useFetch(Endpoints.lists.universities)

  const {
    loading: loadingCreateSuperiorCourse,
    post: createSuperiorCourse,
    response: restCreateSuperiorCourse
  } = useFetch(Endpoints.lists.superiorCourses)

  const {
    loading,
    data: dataLanguages,
    get: getLanguages
  } = useFetch(`${Endpoints.lists.languages}?take=100&skip=0`, {
    interceptors: {
      response: async ({ response }: { response: Res<any> }) => {
        if (!response.data) return response

        const newResponse: any = []
        response.data.forEach((lang: any) =>
          newResponse.push({
            label: lang.name,
            value: lang.id
          })
        )
        response.data = newResponse

        return response
      }
    }
  })

  const {
    loading: loadingHardSkills,
    data: dataHardSkills,
    get: getHardSkills
  } = useFetch(`${Endpoints.lists.hardSkills}?take=100&skip=0`)

  const {
    fields: fieldsSuperiorCourses,
    append: appendSuperiorCourses,
    remove: removeSuperiorCourses
  } = useFieldArray({
    name: 'superior_courses',
    control
  })

  const {
    fields: fieldsLanguages,
    append: appendLanguages,
    remove: removeLanguages
  } = useFieldArray({
    name: 'languages',
    control
  })

  const {
    fields: fieldsComplementaryCourses,
    append: appendComplementaryCourses,
    remove: removeComplementaryCourses
  } = useFieldArray({
    name: 'candidate_technical_or_complementary_courses',
    control
  })

  const handleCreateUniversity = async (key: string, name: string) => {
    await createUniversity({
      state: 'Brasil',
      level_one: 'Custom',
      level_two: '',
      name,
    })

    if (restCreateUniversity.ok) {
      setValue(key, {
        label: restCreateUniversity.data.name,
        value: restCreateUniversity.data.id
      })
    }
  }

  const handleCreateSuperiorCourse = async (key: string, name: string) => {
    await createSuperiorCourse({
      name,
      type: null
    })

    if (restCreateSuperiorCourse.ok) {
      setValue(key, {
        label: restCreateSuperiorCourse.data.name,
        value: restCreateSuperiorCourse.data.id
      })
    }
  }

  useEffect(() => {
    getSuperiorCourses()
    getUniversities()
    getLanguages()
    getHardSkills()
  }, [])

  return (
    <Flex flexDirection="column" py="6" px="8" width="100%" gap="8" bg="white">
      <Heading
        width="100%"
        as="h2"
        fontSize="lg"
        fontWeight="semibold"
        textAlign="left">
        Escolaridade
      </Heading>
      {fieldsSuperiorCourses?.map((field, idx) => (
        <Fragment key={field.id}>
          <HStack width="100%" alignItems="flex-start" spacing={4}>
            <Box width="100%" zIndex={200}>
              <Controller
                control={control}
                name={`superior_courses.${idx}.university_ref`}
                render={({ field: { ref, ...props } }) => (
                  <Select
                    {...props}
                    placeholder="Selecione"
                    options={dataUniversities?.map(({ id, name }: { id: string; name: string }) => ({
                      label: name,
                      value: id
                    }))}
                    onCreateOption={(university: string) => {
                      handleCreateUniversity(
                        `superior_courses.${idx}.university_ref`,
                        university
                      )
                    }}
                    loading={loadingUniversities || loadingCreateUniversities}
                    isDisabled={loadingUniversities}
                    creatable
                    label="Instituição de ensino"
                    variant="filled"
                  />
                )}
              />
            </Box>
            <Box width="100%" zIndex={200}>
              <Controller
                control={control}
                name={`superior_courses.${idx}.level`}
                render={({ field: { ref, ...props } }) => (
                  <Menu
                    {...props}
                    label="Nível de graduação"
                    options={Object.keys(SuperiorCoursesTypes).map((key) => ({
                      value: key,
                      label: SuperiorCoursesTypes[key]
                    }))}
                  />
                )}
              />
            </Box>
          </HStack>
          <Box width="100%">
            <Controller
              control={control}
              name={`superior_courses.${idx}.superiorCourse_ref`}
              render={({ field: { ref, ...props } }) => (
                <Select
                  {...props}
                  placeholder="Selecione"
                  options={dataSuperiorCourses?.map(({ id, name }: { id: string; name: string }) => ({
                    label: name,
                    value: id
                  }))}
                  onCreateOption={(superiorCourse: string) => {
                    handleCreateSuperiorCourse(
                      `superior_courses.${idx}.superiorCourse_ref`,
                      superiorCourse
                    )
                  }}
                  loading={loadingSuperiorCourses || loadingCreateSuperiorCourse}
                  isDisabled={loadingSuperiorCourses}
                  creatable
                  label="Área de estudo"
                  variant="filled"
                />
              )}
            />
          </Box>
          <HStack width="100%" alignItems="center" spacing={4}>
            <Controller
              control={control}
              name={`superior_courses.${idx}.start_date`}
              render={({ field: { ref, value, ...props } }) => (
                <Input
                  {...props}
                  type="text"
                  label="Data de início"
                  variant="filled"
                  value={value ?? ''}
                  mask="99/99/9999"
                  placeholder="--/--/----"
                />
              )}
            />
            <Controller
              control={control}
              name={`superior_courses.${idx}.end_date`}
              render={({ field: { ref, value, ...props } }) => (
                <Input
                  {...props}
                  type="text"
                  label="Data de término"
                  variant="filled"
                  value={value ?? ''}
                  mask="99/99/9999"
                  placeholder="--/--/----"
                />
              )}
            />
          </HStack>
          <Controller
            control={control}
            name={`superior_courses.${idx}.description`}
            render={({ field: { ref, value, ...props } }) => (
              <Textarea
                {...props}
                variant="filled"
                label="Descrição"
                placeholder="Digite aqui"
                maxLength={2600}
                value={value ?? ''}
                rows={7}
              />
            )}
          />
          <Button
            width="100%"
            size="sm"
            variant="outline"
            color="negative"
            py="0.5"
            borderRadius="md"
            colorScheme="negative"
            onClick={() => removeSuperiorCourses(idx)}>
            Remover
          </Button>
        </Fragment>
      ))}
      <Button
        width="100%"
        py="0.5"
        borderRadius="md"
        fontSize="sm"
        fontWeight="500"
        variant="outline"
        colorScheme="neutralLight"
        onClick={() => appendSuperiorCourses({})}>
        Adicionar mais informações
      </Button>
      <Divider />
      <Heading
        width="100%"
        as="h2"
        fontSize="lg"
        fontWeight="semibold"
        textAlign="left">
        Cursos técnicos/complementares
      </Heading>
      {fieldsComplementaryCourses?.map((field, idx) => (
        <ComplementaryCourse
          key={field.id}
          control={control}
          idx={idx}
          onAdd={() => appendComplementaryCourses({})}
          onDelete={() => removeComplementaryCourses(idx)}
        />
      ))}
      {fieldsComplementaryCourses?.length === 0 && (
        <Button
          width="100%"
          py="0.5"
          borderRadius="md"
          fontSize="sm"
          fontWeight="500"
          variant="outline"
          colorScheme="neutralLight"
          onClick={() => appendComplementaryCourses({})}>
          Adicionar curso
        </Button>
      )}
      <Divider />
      <Heading
        width="100%"
        as="h2"
        fontSize="lg"
        fontWeight="semibold"
        textAlign="left">
        Idioma
      </Heading>
      {fieldsLanguages?.map((field, idx) => (
        <HStack key={field.id} width="100%" spacing={4}>
          <Box width="100%" zIndex={200}>
            <Controller
              control={control}
              name={`languages.${idx}.language_id`}
              render={({ field: { ref, ...props } }) => (
                <Menu {...props} label="Língua" options={dataLanguages} />
              )}
            />
          </Box>
          <Box width="100%" zIndex={200}>
            <Controller
              control={control}
              name={`languages.${idx}.level`}
              render={({ field: { ref, ...props } }) => (
                <Menu
                  {...props}
                  disabled={loading}
                  label="Nível"
                  options={LanguageSkillLevelType}
                />
              )}
            />
          </Box>
          <HStack pt={10} alignItems="center" spacing={4}>
            <IconButton
              onClick={() => removeLanguages(idx)}
              variant="ghost"
              size="xs"
              colorScheme="black"
              icon={<FiTrash fontSize="1.5rem" />}
              aria-label="Delete"
            />
            <IconButton
              isRound
              onClick={() => appendLanguages({})}
              variant="solid"
              size="sm"
              colorScheme="brand"
              icon={<FiPlus fontSize="1.2rem" color="white" />}
              aria-label="Add"
            />
          </HStack>
        </HStack>
      ))}
      {fieldsLanguages.length === 0 && (
        <Button
          width="100%"
          py="0.5"
          borderRadius="md"
          fontSize="sm"
          fontWeight="500"
          variant="outline"
          colorScheme="neutralLight"
          onClick={() => appendLanguages({})}>
          Adicionar idioma
        </Button>
      )}
      <Divider />
      <Heading
        width="100%"
        as="h2"
        fontSize="lg"
        fontWeight="semibold"
        textAlign="left">
        Hard Skills
      </Heading>
      <Controller
        control={control}
        name="candidate_hard_skills"
        render={({ field: { ref, value, ...props } }) => (
          <Select
            {...props}
            loading={loadingHardSkills}
            isMulti
            width="100%"
            label="Competências"
            variant="filled"
            placeholder="Selecione"
            options={
              dataHardSkills?.map((skill: { id: string; name: string }) => ({
                label: skill?.name,
                value: skill?.id
              })) ?? []
            }
            value={value}
          />
        )}
      />
    </Flex>
  )
}
