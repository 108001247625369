import { Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { RefObject, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { Button } from '../../../components/atoms/Button'
import { Input } from '../../../components/atoms/Input'
import { minLength } from '../../../utils/validations'

export type FormDataType = {
  email: string
  password: string
}

type FormType = {
  formRef: RefObject<HTMLFormElement>
  onSubmit: (_payload: FormDataType) => void
  loading: boolean
}

const formSchema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  password: yup
    .string()
    .min(minLength, 'Deve conter pelo menos 8 caracteres')
    .required('Campo obrigatório')
})

export const FormClient = ({ formRef, onSubmit, loading }: FormType) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema)
  })

  const handleSubmitForm = useCallback(() => {
    formRef?.current?.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    )
  }, [formRef])

  return (
    <>
      <form
        ref={formRef}
        onSubmit={handleSubmit((data: any) => onSubmit(data))}>
        <Stack spacing={9}>
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field: { ref, value, ...props } }) => (
              <Input
                label="E-mail"
                type="email"
                placeholder="Digite seu e-mail"
                variant="filled"
                id="email"
                tabIndex={0}
                value={value ?? ''}
                isInvalid={errors?.email}
                errorMessage={errors?.email?.message}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field: { ref, value, ...props } }) => (
              <Input
                label="Insira sua senha"
                type="password"
                placeholder="Digite aqui"
                variant="filled"
                id="password"
                tabIndex={0}
                value={value ?? ''}
                isInvalid={errors?.password}
                errorMessage={errors?.password?.message}
                {...props}
              />
            )}
          />
          <Button
            disabled={!isValid}
            loading={loading}
            type="button"
            variant="solid"
            colorScheme="brand"
            onClick={() => !loading && handleSubmitForm()}
            width="100%">
            Acessar
          </Button>
        </Stack>
      </form>

      <Text textAlign="center" fontSize="sm" color="#4D4D4D">
        Esqueceu a senha?{' '}
        <Link to="/recuperar-senha">
          <Text as="span" fontWeight="bold" color="brand.500">
            Clique aqui
          </Text>
        </Link>
        .
      </Text>
    </>
  )
}
