import { useCallback } from 'react'

const useStorage = () => {
  const set = useCallback((key: string, value: any) => {
    window.localStorage.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value
    )
  }, [])

  const get = useCallback((key: string) => window.localStorage.getItem(key), [])

  const remove = useCallback(
    (key: string) => window.localStorage.removeItem(key),
    []
  )

  return { set, get, remove }
}

export default useStorage
