/* eslint-disable camelcase */
import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text
} from '@chakra-ui/react'
import { UrgencyLevel } from 'components/atoms/UrgencyLevel/UrgencyLevel'
import { JobProductsType } from 'data/jobInfo'
import { VerticalTypes } from 'enum/user'
import { useState } from 'react'
import { BsCheck2Square } from 'react-icons/bs'
import {
  FiCheck,
  FiChevronDown,
  FiChevronUp,
  FiFileText,
  FiX
} from 'react-icons/fi'
import { RiHandCoinLine } from 'react-icons/ri'
import { foreCastColors, forecastFontColors } from 'styles/forecast'
import { forescastFormatter } from 'utils/forecast'
import { formatCapitalizeUndeline } from 'utils/formatObjectValuesResponse'

export type HeaderModalJobProps = {
  title: string
  clientName: string
  forecast: string
  urgencyLevel: number
  job_code: string
  yw_unity: string
  vertical: string
  onJobDescription: () => void
  onFormClose: () => void
  onChangeForecast: (forecast: string) => void
  isCloseFormActive: boolean
  onFormDelivery: () => void
  jobProductType: string
}

const options = ['AA', 'A', 'B', 'C', 'OK', 'INDEFINIDO', 'RPO', 'FIN']

export const HeaderModalJob = ({
  title,
  clientName,
  forecast,
  urgencyLevel,
  job_code,
  vertical,
  yw_unity,
  isCloseFormActive,
  onJobDescription,
  onFormClose,
  onChangeForecast,
  onFormDelivery,
  jobProductType
}: HeaderModalJobProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)

  const onClose = () => {
    setIsMenuOpen(false)
    setConfirmIsOpen(false)
  }

  return (
    <Box p="8">
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="2xl" lineHeight="150%" fontWeight="bold" pb="0.5">
          {title}
        </Text>

        <Flex>
          <UrgencyLevel urgencyLevel={urgencyLevel} />
          <Menu
            autoSelect={false}
            direction="ltr"
            onClose={() => onClose()}
            onOpen={() => setIsMenuOpen(true)}>
            <MenuButton pl={7} pt={1} height={30} position="relative">
              {isMenuOpen ? (
                <Icon as={FiChevronUp} h={8} w={8} color="neutralDark.500" />
              ) : (
                <Icon as={FiChevronDown} h={8} w={8} color="neutralDark.500" />
              )}
            </MenuButton>
            <MenuList
              bg="#F9F9F9"
              border="0"
              borderRadius={6}
              minWidth={31.75}
              overflow="hidden">
              {!confirmIsOpen && (
                <Box>
                  <MenuItem
                    onClick={() => onJobDescription()}
                    pr="4"
                    alignItems="center"
                    fontSize="md"
                    px={3}
                    py="4"
                    fontWeight="500"
                    icon={<Icon as={FiFileText} w={4} h={4} />}
                    _hover={{ bg: 'neutralLight.300' }}>
                    Job description
                  </MenuItem>
                  <Divider color="neutralLight.600" />
                  {jobProductType === JobProductsType.tech && (
                    <MenuItem
                      pr="4"
                      isDisabled={!isCloseFormActive}
                      onClick={() => {
                        setConfirmIsOpen(true)
                      }}
                      closeOnSelect={false}
                      px={3}
                      py="4"
                      alignItems="center"
                      fontSize="md"
                      fontWeight="500"
                      icon={<Icon as={RiHandCoinLine} w={4} h={4} />}
                      _hover={{ bg: 'neutralLight.300' }}>
                      Form de delivery
                    </MenuItem>
                  )}
                  <MenuItem
                    pr="4"
                    isDisabled={!isCloseFormActive}
                    onClick={() => onFormClose()}
                    px={3}
                    py="4"
                    alignItems="center"
                    fontSize="md"
                    fontWeight="500"
                    icon={<Icon as={BsCheck2Square} w={4} h={4} />}
                    _hover={{ bg: 'neutralLight.300' }}>
                    Form de fechamento
                  </MenuItem>
                </Box>
              )}
              {confirmIsOpen && (
                <Box>
                  <MenuItem
                    pr="4"
                    isDisabled={!isCloseFormActive}
                    onClick={() => onFormDelivery()}
                    px={3}
                    py="4"
                    alignItems="center"
                    fontSize="md"
                    fontWeight="500"
                    icon={<Icon as={FiCheck} w={4} h={4} />}
                    _hover={{ bg: 'brand.400' }}>
                    Confirmar envio
                  </MenuItem>
                  <MenuItem
                    pr="4"
                    isDisabled={!isCloseFormActive}
                    closeOnSelect={false}
                    onClick={() => setConfirmIsOpen(false)}
                    px={3}
                    py="4"
                    alignItems="center"
                    fontSize="md"
                    fontWeight="500"
                    icon={<Icon as={FiX} w={4} h={4} />}
                    _hover={{ bg: 'negative.200' }}>
                    Cancelar
                  </MenuItem>
                </Box>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between">
        <Box>
          <Box
            mt="1"
            bg="assistant.500"
            borderRadius="md"
            py="1.5"
            px="3"
            w="fit-content">
            <Text color="neutralLight.200" fontWeight="500" fontSize="xs">
              {clientName}
            </Text>
          </Box>
          <HStack mt="5">
            <Text
              color="grayText"
              fontSize="xs"
              fontWeight="semibold"
              noOfLines={1}>
              {job_code ? `${job_code} • ` : ''}
              {vertical
                ? VerticalTypes[vertical]
                : vertical || 'Sem vertical'}{' '}
              • YW {formatCapitalizeUndeline(yw_unity)}
            </Text>
          </HStack>
          <Select
            bg={foreCastColors[forecast] || foreCastColors.Indefinido}
            mt="6"
            p="0"
            size="md"
            w="fit-content"
            variant="filled"
            borderRadius="md"
            placeholder="Forecast"
            defaultValue={forecast}
            onChange={(e) => onChangeForecast(e.target.value)}
            fontSize="xs"
            fontWeight="500"
            color={
              forecastFontColors[forecast] || forecastFontColors.Indefinido
            }
            _focusVisible={{
              _placeholder: {
                color: 'black'
              },
              bg: foreCastColors[forecast] || foreCastColors.Indefinido,
              borderColor: 'transparent'
            }}
            _hover={{
              bg: foreCastColors[forecast] || foreCastColors.Indefinido
            }}>
            {options.map((opt) => (
              <option
                key={opt}
                value={opt}
                style={{
                  color: '#000',
                  padding: '5px 0 5px 0px',
                  fontSize: '12px'
                }}>
                Forecast: {forescastFormatter(opt)}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
    </Box>
  )
}
