/* eslint-disable camelcase */
/* eslint-disable indent */
import { Box, Flex, Spinner, VStack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { useErrorToast } from 'hooks/useErroToast'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import useFetch from 'use-http'
import { jobCurrencyFormatter } from 'utils/formatCurrency'
import * as yup from 'yup'
import { TabsJobDescription } from './components/Form/Tabs'
import { formatResponse, validateData } from './validationAndFormat'

const formSchema = yup.object().shape({
  title: yup
    .string()
    .nullable()
    .test(
      'empty-check',
      'Campo obrigatório!',
      (title: any) => title?.length >= 0
    )
    .required('Campo obrigatório!'),
  level: yup.string().required('Campo obrigatório!'),
  vertical: yup.string().required('Campo obrigatório!'),
  yw_unity: yup.string().required('Campo obrigatório!'),
  customer_code: yup.string().required('Campo obrigatório!'),
  card_model: yup.string().required('Campo obrigatório!'),
  job_code: yup
    .string()
    .nullable()
    .test(
      'empty-check',
      'Campo obrigatório!',
      (job_code: any) => job_code?.length >= 0
    )
    .required('Campo obrigatório!'),
  yellow_product_id: yup.string().required('Campo obrigatório!'),
  urgency: yup.string().required('Campo obrigatório!')
})

const startPage = (state: { fromCreate: boolean; fromTimeline: boolean }) => {
  if (state?.fromCreate) return 1
  if (state?.fromTimeline) return 4

  return 0
}

const strToNum = (text: string): number => {
  const changeToInt = text?.toString()?.replace(',', '')?.replace('.', '')
  return Number(changeToInt)
}

const minMaxValidation = (numMax: string, numMin: string) => {
  const intMax = strToNum(numMax)
  const intMin = strToNum(numMin)

  return intMax < intMin
}

export const UpdateJob = () => {
  const toast = useToast()
  const location = useLocation()
  const onError = useErrorToast()
  const { uuid }: any = useParams()
  const errorToast = useErrorToast()
  // @ts-ignore
  const [index, setIndex] = useState(startPage(location.state))
  const [invoiceObs, setInvoiceObs] = useState()

  const methods = useForm({
    resolver: yupResolver(formSchema)
  })
  const { formState } = methods
  const { errors } = formState

  useEffect(() => {
    Object.keys(errors).length > 0 &&
      onError({
        title: 'Não foi possível salvar.',
        description: 'Preencha os campos obrigatórios, tente novamente.'
      })
  }, [errors, onError])

  const { data: job, loading } = useFetch(
    Endpoints.jobs.findOne(uuid || ''),
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os dados da vaga.',
          description: 'Por favor, tente novamente.'
        }),
      interceptors: {
        response: ({ response }: any) => formatResponse({ response, methods })
      }
    },
    [uuid]
  )

  const {
    put: updateJob,
    response,
    loading: loadingUpdate
  } = useFetch(Endpoints.jobs.findOne(uuid || ''))

  const { data: invoices } = useFetch(
    Endpoints.forms.findOne(uuid, 'opening'),
    {
      onError: () =>
        onError({
          title: 'Não foi possivel encontrar o invoice.',
          description: 'Por favor tente novamente'
        })
    },
    []
  )
  const invoiceId = invoices ? invoices[0]?.id : null

  const { put: updateInvoice, response: responseForm } = useFetch(
    Endpoints.forms.update(invoiceId)
  )

  const handleObservation = async (invoiceObs: string) => {
    await updateInvoice({
      observation: invoiceObs
    })

    if (responseForm.status >= 400) {
      onError({
        title: 'Não foi possível atualizar a observação.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'observação atualizado com sucesso.',
      variant: 'subtle'
    })
  }

  const onSubmit = async (data: any) => {
    const [errorValidate, validatedData] = validateData(
      methods.getValues(),
      onError
    )

    if (errorValidate) return

    const maxSalary = validatedData.max_salary_range
    const minSalary = validatedData.min_salary_range

    if (maxSalary && minSalary && minMaxValidation(maxSalary, minSalary)) {
      errorToast({
        title: 'Valor mínimo do salário não pode ser maior que o valor máximo.',
        description: `Campo "faixa salarial" (Aba de detalhamento)`
      })
      return
    }

    if (invoiceObs) {
      await handleObservation(invoiceObs)
    }

    const returnData = {
      ...validatedData,
      salaryOnOpen: !!validatedData.salaryOnOpen
        ? jobCurrencyFormatter(validatedData.salaryOnOpen)
        : null,
      max_salary_range: !!validatedData.max_salary_range
        ? jobCurrencyFormatter(validatedData.max_salary_range)
        : null,
      min_salary_range: !!validatedData.min_salary_range
        ? jobCurrencyFormatter(validatedData.min_salary_range)
        : null,
      users_qualifier: validatedData?.users_qualifier?.map?.(
        ({ qualifier_id }: { qualifier_id: string }) => qualifier_id
      )
    }
    !validatedData.salaryOnOpen && delete returnData.salaryOnOpen
    !validatedData.max_salary_range && delete returnData.max_salary_range
    !validatedData.min_salary_range && delete returnData.min_salary_range

    await updateJob(returnData)

    if (response.status >= 400) {
      onError({
        title: 'Não foi possível atualizar a vaga.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Vaga atualizada com sucesso',
      variant: 'subtle'
    })
  }

  useEffect(() => {
    invoices && setInvoiceObs(invoices[0]?.observation)
  }, [invoices])

  return (
    <Box height="100%">
      <VStack spacing={2} alignItems="flex-start" height="10%">
        <HeaderPage title="Pipeline de vagas" subTitle="Job Description" />
      </VStack>
      {loading && !job && (
        <Box
          width="100%"
          height="90%"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Box>
      )}
      {job && (
        <Box height="90%">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box height="3.5xl">
                <Box h="93%">
                  <TabsJobDescription
                    defaultIndex={index}
                    onChange={(i: number) => setIndex(i)}
                    index={index}
                    errors={errors}
                    loading={loadingUpdate}
                    invoiceObs={invoiceObs}
                    setInvoiceObs={setInvoiceObs}
                  />
                </Box>
                <Flex
                  h="7%"
                  width="100%"
                  textAlign="right"
                  alignItems="end"
                  justifyContent="end">
                  <Button
                    isDisabled={loading || loadingUpdate}
                    loading={loading || loadingUpdate}
                    px={7}
                    height={12}
                    variant="solid"
                    borderRadius="md"
                    colorScheme="brand"
                    type="submit">
                    Salvar
                  </Button>
                </Flex>
              </Box>
            </form>
          </FormProvider>
        </Box>
      )}
    </Box>
  )
}
