/* eslint-disable react/jsx-indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
// @ts-nocheck
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  VStack
} from '@chakra-ui/react'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'
import { Select } from 'components/atoms/Select'
import { Fragment } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { transformDate } from 'utils/formatDate'
import { v4 as uuidv4 } from 'uuid'
import { JobFormat } from './../../../../data/jobInfo'
import { useEditJob } from '../../../../hooks/useEditJob'

const Step = ({
  id,
  stepName,
  control,
  name,
  onRemove,
  onAdd,
  canRemove,
  canAdd,
  divider = '.'
}: any) => {
  return (
    <Fragment key={id}>
      <Heading as="h3" fontSize="lg" fontWeight="semibold">
        {stepName}
      </Heading>
      <HStack width="100%" spacing={4} justifyContent="space-between">
        <HStack width="100%" alignItems="flex-start" spacing={4} mb={6}>
          <Input
            variant="filled"
            label="Descrição"
            control={control}
            name={`${name}${divider}description`}
          />
          <Select
            label="Shortlist"
            variant="filled"
            control={control}
            name={`${name}${divider}shortlist_number`}>
            {[...new Array(20)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                Shortlist {i + 1}
              </option>
            ))}
          </Select>
          <Controller
            control={control}
            name={divider === '_' ? name : `${name}${divider}date`}
            render={({ field: { ref, value, ...props } }) => {
              return (
                <Input
                  {...props}
                  variant="filled"
                  label="Data"
                  value={
                    value && value !== '31/12/1969'
                      ? transformDate(value.split('T')[0])
                      : null
                  }
                  type="text"
                  mask="99/99/9999"
                  placeholder="--/--/----"
                  pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                />
              )
            }}
          />
          <Input
            label="Duração"
            type="time"
            variant="filled"
            control={control}
            name={`${name}${divider}duration`}
            // value={content?.duration}
          />
          <Controller
            control={control}
            name={`${name}${divider}format`}
            render={({ field: { ref, value, ...props } }) => (
              <Menu
                {...props}
                label="Formato"
                options={JobFormat}
                value={value}
              />
            )}
          />

          <Input
            label="Responsável"
            variant="filled"
            control={control}
            name={`${name}${divider}responsible`}
          />
        </HStack>
        <HStack width={20} pt={2} alignItems="center" spacing={4}>
          {canRemove && (
            <IconButton
              onClick={onRemove}
              variant="ghost"
              size="xs"
              colorScheme="black"
              icon={<FiTrash fontSize="1.5rem" />}
              aria-label="Delete"
            />
          )}
          {canAdd && (
            <IconButton
              isRound
              onClick={onAdd}
              variant="solid"
              size="sm"
              colorScheme="brand"
              icon={<FiPlus fontSize="1.2rem" color="white" />}
              aria-label="Add"
            />
          )}
        </HStack>
      </HStack>
    </Fragment>
  )
}

const StepCustomer = () => {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'timelineClient'
  })

  return (
    <>
      {fields?.map((content, i) => {
        return (
          <Step
            id={content.id}
            key={`step_customer_${i}`}
            stepName={`${i + 1}ª fase`}
            onAdd={() =>
              append({
                id: uuidv4(),
                shortlist_number: 1,
                key: 'hide'
              })
            }
            onRemove={() => remove(i)}
            control={control}
            name={`timelineClient.${i}`}
            canAdd={i === fields.length - 1}
            canRemove={i === fields.length - 1 && i >= 1}
          />
        )
      })}
      <Fragment key={`step_customer_${uuidv4()}`}>
        <Heading as="h3" fontSize="lg" fontWeight="semibold">
          Última etapa
        </Heading>
        <HStack width="55%" spacing={4} justifyContent="space-between">
          <HStack width="100%" alignItems="flex-start" spacing={4} mb={2}>
            <Input
              isReadOnly
              variant="filled"
              label="Descrição"
              value="Data prevista de fechamento da vaga"
              name="end_date_description"
            />
            <Input
              variant="filled"
              label="Data"
              type="text"
              mask="99/99/9999"
              placeholder="--/--/----"
              control={control}
              name="end_date"
              pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
              width="50%"
            />
          </HStack>
        </HStack>
      </Fragment>
    </>
  )
}

export const Timeline = () => {
  const { control, watch, getValues } = useFormContext()
  const canEdit = useEditJob(getValues())

  const {
    fields,
    append: add,
    remove,
    update
  } = useFieldArray({
    control,
    name: 'timelineYellow'
  })

  const handleAddNewStepYw = () => {
    add({
      description: '',
      date: null
    })
  }

  const handleRemoveStepYw = (index) => remove(index)

  const handleEditStepYw = (index, key, value) => {
    // data de kickoff
    if (index === 0) {
      //   key === 'date' && setValue('kickoff_date', value)
      update(index, {
        date: value,
        description: '1º Shortlist',
        key: 'first_shortlist_date'
      })
      return
    }
    update(index, { [key]: value })
  }

  return (
    <Box
      bg="white"
      maxH="2xl"
      overflowY="scroll"
      borderRadius="lg"
      sx={{
        '&::-webkit-scrollbar': {
          height: '9px',
          width: '10px',
          backgroundColor: `rgba(0, 0, 0, 0.05)`
        },
        '&::-webkit-scrollbar-thumb': {
          height: '9px',
          borderRadius: '6px',
          backgroundColor: `rgba(0, 0, 0, 0.15)`
        }
      }}
      p={6}
      pointerEvents={canEdit ? 'auto' : 'none'}>
      <Flex flexDirection="column" py="6" px="2" width="100%" gap="8">
        <Heading as="h2" fontSize="lg" fontWeight="semibold">
          Fases do processo seletivo interno na empresa
        </Heading>
        <VStack width="100%" alignItems="flex-start" spacing={4}>
          <StepCustomer content={{}} />
        </VStack>
        <Divider color="#E9E9E9" my="4" />
        <Heading as="h2" fontSize="lg" fontWeight="semibold">
          Fases do processo seletivo na Yellow
        </Heading>
        <HStack width="60%" alignItems="flex-start" spacing={4} mt={6}>
          <Input
            variant="filled"
            label="Data de kickoff"
            type="text"
            mask="99/99/9999"
            placeholder="--/--/----"
            defaultValue={null}
            name={'kickoff_date'}
            control={control}
          />
        </HStack>
        <HStack width="60%" alignItems="flex-start" spacing={4} my={6}>
          <Input
            variant="filled"
            label="Data de email de sucesso "
            type="text"
            mask="99/99/9999"
            placeholder="--/--/----"
            defaultValue={null}
            name="success_email_date"
            control={control}
          />
        </HStack>
        <Box>
          <Flex alignItems="center" mb="2">
            <Box flex="2">
              <FormLabel fontWeight="500" fontSize="md" color="black" m={0}>
                Descrição da etapa
              </FormLabel>
            </Box>
            <Box flex="2">
              <FormLabel fontWeight="500" fontSize="md" color="black" m={0}>
                Data
              </FormLabel>
            </Box>

            <Flex flex="1"></Flex>
          </Flex>
          <Flex alignItems="center">
            <Box flex="2">
              <Input
                isReadOnly
                variant="filled"
                value="Data de envio da 1ª shortlist"
                width={300}
              />
            </Box>
            <Box flex="2">
              <Input
                variant="filled"
                type="text"
                mask="99/99/9999"
                placeholder="--/--/----"
                onChange={(e) => handleEditStepYw(0, 'date', e.target.value)}
                value={watch('timelineYellow.0.date')}
                width={180}
              />
            </Box>

            <Flex flex="1" justifyContent="end" gap={4}>
              <IconButton
                // disabled={stepsYw.length}
                isRound
                onClick={fields.length > 1 ? () => {} : handleAddNewStepYw}
                variant="solid"
                size="sm"
                colorScheme={fields.length > 1 ? 'transparent' : 'brand'}
                icon={<FiPlus fontSize="1.2rem" color="white" />}
                aria-label="Add"
              />
            </Flex>
          </Flex>
        </Box>
        {fields?.map(
          (stepyw, i) =>
            i > 0 && (
              <Flex
                key={`stepYw_${i}`}
                alignItems="center"
                justifyContent="flex-start"
                spacing={4}
                mt={6}>
                <Box flex="2">
                  <Input
                    isReadOnly
                    variant="filled"
                    name={`timelineYellow.${i}.description`}
                    value={`Data de envio da ${i + 1}ª shortlist`}
                    control={control}
                    width={300}
                  />
                </Box>
                <Box flex="2">
                  <Input
                    variant="filled"
                    name={`timelineYellow.${i}.date`}
                    control={control}
                    type="text"
                    mask="99/99/9999"
                    defaultValue={null}
                    placeholder="--/--/----"
                    width={180}
                  />
                </Box>
                <Flex flex="1" justifyContent="end" gap={4}>
                  <IconButton
                    variant="ghost"
                    size="xs"
                    colorScheme="black"
                    aria-label="Delete"
                    mt="0.5"
                    icon={<FiTrash fontSize="1.3rem" />}
                    onClick={() => handleRemoveStepYw(i)}
                  />

                  {i === fields.length - 1 && (
                    <IconButton
                      isRound
                      variant="solid"
                      size="sm"
                      colorScheme="brand"
                      aria-label="Add"
                      icon={<FiPlus fontSize="1.2rem" color="white" />}
                      onClick={handleAddNewStepYw}
                    />
                  )}
                </Flex>
              </Flex>
            )
        )}
      </Flex>
    </Box>
  )
}
