import { Box, Flex } from '@chakra-ui/react'
import { Textarea } from 'components/atoms/Textarea'
import { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'
import { useEditJob } from '../../../../hooks/useEditJob'

export const Investigation = () => {
  const { setValue, watch, getValues } = useFormContext()
  const canEdit = useEditJob(getValues())

  return (
    <Box
      bg="white"
      maxH="2xl"
      overflowY="scroll"
      borderRadius="lg"
      sx={{
        '&::-webkit-scrollbar': {
          height: '9px',
          width: '10px',
          backgroundColor: `rgba(0, 0, 0, 0.05)`
        },
        '&::-webkit-scrollbar-thumb': {
          height: '9px',
          borderRadius: '6px',
          backgroundColor: `rgba(0, 0, 0, 0.15)`
        }
      }}
      p={6}
      pointerEvents={canEdit ? 'auto' : 'none'}>
      <Flex flexDirection="column" py="6" px="2" width="100%" gap="8">
        <Textarea
          label="Maior desafio na função"
          cols={5}
          rows={7}
          mb="2"
          variant="filled"
          placeholder="Digite aqui"
          value={watch('investigation_biggest_challenge')}
          onChange={(e) =>
            setValue('investigation_biggest_challenge', e.target.value)
          }
        />
        <Textarea
          label="Qual pergunta eu, como recrutador Yellow, não posso deixar de fazer?"
          cols={5}
          rows={7}
          mb="2"
          variant="filled"
          placeholder="Digite aqui"
          value={watch('investigation_question')}
          onChange={(e) => setValue('investigation_question', e.target.value)}
        />
        <Textarea
          label="Principal fator de sucesso na empresa"
          cols={5}
          rows={7}
          mb="2"
          variant="filled"
          placeholder="Digite aqui"
          value={watch('investigation_main_success_factor')}
          onChange={(e) =>
            setValue('investigation_main_success_factor', e.target.value)
          }
        />
      </Flex>
    </Box>
  )
}
