export const foreCastColors: { [key: string]: string } = {
  AA: '#d90429',
  A: '#E27272',
  B: '#FAC3C3',
  C: '#FFD6A2',
  OK: '#30B436',
  Indefinido: '#DDDDDD',
  RPO: '#DDDDDD',
  FIN: '#91F39A'
}

export const forecastFontColors: { [key: string]: string } = {
  AA: 'neutralLight.200',
  A: 'neutralLight.200',
  B: 'BlackText',
  C: 'BlackText',
  OK: 'neutralLight.200',
  Indefinido: 'BlackText',
  RPO: 'BlackText',
  FIN: 'BlackText'
}
