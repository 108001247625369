type keyValueType = {
  value: string
  label: string
}

export const execToSpot = (id: string, yellowProducts: keyValueType[]) => {
  let spotId
  const includeId = yellowProducts?.find((item: keyValueType) => {
    if (item.label === 'Spot') spotId = item.value
    return item.value === id
  })

  return !includeId && id ? spotId : id
}
