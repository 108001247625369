/* eslint-disable camelcase */
import { Box, HStack, Select, Text, VStack } from '@chakra-ui/react'
import { Tag } from 'components/atoms/Tag'
import { VerticalTypes, YWOffices } from 'enum/user'
import { max, min } from 'radash'
import { InterviewTimeline } from './Timeline'

const options = ['AA', 'A', 'B', 'C', 'OK', 'Indefinido', 'RPO', 'FIN']

export const InterviewHeader = ({
  data,
  onChangeForecast,
  loading,
  showShortlist = true
}: any) => {
  const currentStepInTimeLine = (time_line: any) => {
    if (time_line.length === 0) return

    const timeLineFiltered = time_line.filter((t: any) => t.key === 'CLIENT')

    const biggestNumberShortlist = max(
      timeLineFiltered,
      (t: any) => t?.shortlist_number
    )?.shortlist_number

    const filteredByShortList = timeLineFiltered.filter(
      (t: any) => t?.shortlist_number === biggestNumberShortlist
    )

    return min(filteredByShortList, (f: any) => f.date)
  }

  return (
    <Box mb="4" mt="2">
      <VStack alignItems="flex-start" mb="6" spacing="4">
        <Text fontWeight="bold" fontSize="xl">
          {data?.title ?? '–'}
        </Text>
        <HStack alignItems="center">
          {data?.customer && (
            <Tag
              size="md"
              h="8"
              fontSize="sm"
              borderRadius="md"
              variant="solid"
              bg="#17929E">
              <Text fontSize="xs" noOfLines={1}>
                {data?.customer?.name ?? '–'}
              </Text>
            </Tag>
          )}
          {!loading && (
            <Select
              disabled={loading}
              bg="positive.100"
              p="0"
              size="sm"
              w="fit-content"
              variant="filled"
              placeholder="Forecast"
              defaultValue={data?.forecast}
              onChange={(e) => onChangeForecast(e.target.value)}
              fontSize="xs"
              borderRadius="md"
              color="positive.500"
              _focusVisible={{
                _placeholder: {
                  color: 'black'
                },
                bg: 'positive.100',
                borderColor: 'transparent'
              }}
              _hover={{
                bg: 'positive.100'
              }}>
              {options.map((opt) => (
                <option key={opt} value={opt}>
                  Forecast: {opt}
                </option>
              ))}
            </Select>
          )}
        </HStack>
        <HStack alignItems="center">
          <Text fontSize="xs" color="#8F8F8F">
            {[
              data?.job_code,
              VerticalTypes[data?.vertical],
              YWOffices[data?.yw_unity]
            ]
              .filter((d) => d)
              .join(' • ')}
          </Text>
        </HStack>
      </VStack>
      {showShortlist && (
        <InterviewTimeline
          data={currentStepInTimeLine(data?.time_line ?? [])}
        />
      )}
    </Box>
  )
}
