import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export type UserType = {
  name: string
  email: string
  id: string
  password: string
}

interface UserStateType {
  is_lead: boolean
  id: string
  team: string
  accessToken: string
  refreshToken: string
  user: UserType | null
  setUser: (user: UserStateType | null) => void
  resetUser: () => void
}

const initialState = {
  id: '',
  accessToken: '',
  refreshToken: '',
  team: '',
  is_lead: false,
  user: {
    email: '',
    name: '',
    id: '',
    password: ''
  }
}

const useUserStore = create<UserStateType>()(
  devtools(
    persist((set) => ({
      ...initialState,
      setUser: (state: UserStateType | null) => set({ ...state }),
      resetUser: () => set(initialState)
    }))
  )
)

export default useUserStore

/* 
 persist((set) => ({
      ...initialState,
      setUser: (user: UserType | null) => set({ user })
    }))
*/
