import { useCallback, useEffect, useState } from 'react'

type NotificationsItemType = {
  title: string
  description: string
  date: any
  type: 'default' | 'error'
  category: string
}

const fakeRequest = (delay: number, value: any) =>
  new Promise((resolve) => setTimeout(resolve, delay, value))

export const useNotifications = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<
    Array<NotificationsItemType>
  >([])

  const getNotifications = useCallback(async () => {
    setLoading(true)

    try {
      const data: any = await fakeRequest(3000, [
        {
          title: 'Atualize seus dados cadastrais',
          description: `Do ullamco ex velit anim do proident
            exercitation et anim tempor. Lorem sunt deserunt labore non excepteur...
          `,
          date: new Date().toISOString(),
          type: 'default',
          category: 'Meu perfil'
        },
        {
          title: 'Novo e-mail pra você',
          description: `Do ullamco ex velit anim do proident
            exercitation et anim tempor. Lorem sunt deserunt labore non excepteur...
          `,
          date: new Date().toISOString(),
          type: 'error',
          category: 'E-mail'
        }
      ])
      setNotifications(data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  return { loading, notifications }
}
