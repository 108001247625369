/* eslint-disable max-len */
/* eslint-disable indent */
import { Box, Spinner } from '@chakra-ui/react'
import {
  Document,
  Font,
  G,
  Image,
  Link,
  PDFViewer,
  Page,
  Path,
  Polyline,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import { Endpoints } from 'api/endpoints'
import Logo from 'assets/images/pdf/logo.png'
import currency from 'currency-formatter'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from 'use-http'
import { currentJob } from 'utils/currentJob'
import { capitalize } from 'utils/helper'

const LinkedInIcon = () => (
  <Svg width="28" height="28" viewBox="0 0 48 48">
    <Path
      fill="#0288d1"
      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
    />
    <Path
      fill="#ffffff"
      d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
    />
  </Svg>
)

const WhatsAppIcon = () => (
  <Svg width="26" height="26" viewBox="0 0 24 24">
    <Path
      fill="#25d366"
      d="M22,6.55a12.61,12.61,0,0,0-.1-1.29,4.29,4.29,0,0,0-.37-1.08,3.66,3.66,0,0,0-.71-1,3.91,3.91,0,0,0-1-.71,4.28,4.28,0,0,0-1.08-.36A10.21,10.21,0,0,0,17.46,2H6.55a12.61,12.61,0,0,0-1.29.1,4.29,4.29,0,0,0-1.08.37,3.66,3.66,0,0,0-1,.71,3.91,3.91,0,0,0-.71,1,4.28,4.28,0,0,0-.36,1.08A10.21,10.21,0,0,0,2,6.54C2,6.73,2,7,2,7.08v9.84c0,.11,0,.35,0,.53a12.61,12.61,0,0,0,.1,1.29,4.29,4.29,0,0,0,.37,1.08,3.66,3.66,0,0,0,.71,1,3.91,3.91,0,0,0,1,.71,4.28,4.28,0,0,0,1.08.36A10.21,10.21,0,0,0,6.54,22H17.45a12.61,12.61,0,0,0,1.29-.1,4.29,4.29,0,0,0,1.08-.37,3.66,3.66,0,0,0,1-.71,3.91,3.91,0,0,0,.71-1,4.28,4.28,0,0,0,.36-1.08A10.21,10.21,0,0,0,22,17.46c0-.19,0-.43,0-.54V7.08C22,7,22,6.73,22,6.55ZM12.23,19h0A7.12,7.12,0,0,1,8.8,18.1L5,19.1l1-3.72a7.11,7.11,0,0,1-1-3.58A7.18,7.18,0,1,1,12.23,19Zm0-13.13A6,6,0,0,0,7.18,15l.14.23-.6,2.19L9,16.8l.22.13a6,6,0,0,0,3,.83h0a6,6,0,0,0,6-6,6,6,0,0,0-6-6Zm3.5,8.52a1.82,1.82,0,0,1-1.21.85,2.33,2.33,0,0,1-1.12-.07,8.9,8.9,0,0,1-1-.38,8,8,0,0,1-3.06-2.7,3.48,3.48,0,0,1-.73-1.85,2,2,0,0,1,.63-1.5.65.65,0,0,1,.48-.22H10c.11,0,.26,0,.4.31s.51,1.24.56,1.33a.34.34,0,0,1,0,.31,1.14,1.14,0,0,1-.18.3c-.09.11-.19.24-.27.32s-.18.18-.08.36a5.56,5.56,0,0,0,1,1.24,5,5,0,0,0,1.44.89c.18.09.29.08.39,0s.45-.52.57-.7.24-.15.4-.09,1.05.49,1.23.58.29.13.34.21A1.56,1.56,0,0,1,15.73,14.36Z"
    />
  </Svg>
)

const MailIcon = () => (
  <Svg width="26" height="26" viewBox="0 0 24 24">
    <Path
      d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Polyline points="22,6 12,13 2,6" stroke="currentColor" />
  </Svg>
)

const FileIcon = () => (
  <Svg width="18" height="22" viewBox="0 0 18 22">
    <G
      fill="none"
      fill-rule="evenodd"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      transform="translate(1 1)">
      <Path d="M10 0H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6l-6-6z" />
      <Path d="M10 0v6h6M12 11H4M12 15H4M6 7H4" />
    </G>
  </Svg>
)

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf'
})

Font.register({
  family: 'RobotoBold',
  src: 'https://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf'
})

Font.registerHyphenationCallback((word) => [word])

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingHorizontal: 28
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70,
    borderBottomWidth: 1,
    borderBottomColor: '#cecece',
    borderBottomStyle: 'solid'
  },
  header_title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  customer: {
    backgroundColor: '#17929e',
    height: 20,
    paddingHorizontal: 12,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8
  },
  customer_text: {
    fontSize: 9,
    fontFamily: 'Roboto',
    color: '#ffffff'
  },
  logo: {
    width: 99,
    height: 19
  },
  title: {
    color: '#000000',
    fontSize: 18,
    fontFamily: 'RobotoBold',
    fontWeight: 'black'
  },
  label: {
    color: '#000000',
    fontSize: 12,
    fontFamily: 'RobotoBold',
    fontWeight: 'black'
  },
  display: {
    color: '#000000',
    fontSize: 16,
    fontFamily: 'RobotoBold',
    fontWeight: 'black'
  },
  text: {
    color: '#8F8F8F',
    fontSize: 11,
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  }
})

const groupedItems = (data: any) => {
  return data.reduce((resultArray: any, item: any, index: number) => {
    const chunkIndex = Math.floor(index / 3)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

export const Shortlist = () => {
  const { uuid }: any = useParams()
  const [candidates, setCandidates] = useState<any>([])

  const { data: list, loading } = useFetch(
    Endpoints.public.shortlist(uuid),
    {},
    []
  )

  const documentTitle = useMemo(
    () =>
      `SL${list?.list_number}_${
        !list?.job?.job_code ? '' : `${list?.job?.job_code}_`
      }${list?.job?.title}`,
    [list]
  )

  useEffect(() => {
    if (list) {
      document.title = documentTitle?.trim() ?? ''

      setCandidates(
        groupedItems(
          list.job_list_candidate.sort(
            (
              a: { candidate_position: number },
              b: { candidate_position: number }
            ) => a.candidate_position - b.candidate_position
          )
        )
      )
    }
  }, [list, documentTitle])

  return (
    <>
      {loading && !list ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Box>
      ) : (
        <PDFViewer showToolbar height="100%" width="100%">
          <Document title={documentTitle}>
            {candidates.map((c: any, idx: number) => (
              <Page
                wrap
                size="A4"
                orientation="landscape"
                style={styles.page}
                key={`page_${idx}`}>
                <View style={styles.header}>
                  <View>
                    <View style={styles.header_title}>
                      <Text style={styles.title}>{list?.job?.title}</Text>
                      <View style={styles.customer}>
                        <Text style={styles.customer_text}>
                          {list?.job?.customer?.name}
                        </Text>
                      </View>
                    </View>
                    <Text style={{ ...styles.text, marginTop: 2 }}>
                      {format(new Date(), 'dd/MMMM/yyyy', { locale: ptBR })}
                    </Text>
                  </View>
                  <View style={styles.logo}>
                    <Image src={Logo} />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 10,
                    height: 478
                  }}>
                  {c?.map(({ candidate }: any, index: number) => {
                    const actualJob = currentJob(
                      candidate.professional_experiences
                    )

                    const actualExperience = actualJob?.actual_job

                    return (
                      <View
                        key={`candidate_${index}`}
                        style={{
                          width: '33.33%',
                          height: '100%',
                          ...(index + 1 < c.length && {
                            borderRight: '1px solid #E9E9E9'
                          }),
                          ...(index === 0
                            ? { paddingRight: 16 }
                            : { paddingHorizontal: 16 })
                        }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: 4,
                            marginLeft: -4
                          }}>
                          {candidate?.linkedin_profile_url && (
                            <Link
                              style={{
                                width: 23,
                                height: 23,
                                marginTop: 4,
                                marginRight: 2
                              }}
                              src={candidate.linkedin_profile_url}>
                              <LinkedInIcon />
                            </Link>
                          )}
                          {candidate?.phone && (
                            <Link
                              style={{
                                width: 20,
                                height: 20,
                                marginTop: 4,
                                marginRight: 6
                              }}
                              src={`https://whatsa.me/55${candidate.phone}`}>
                              <WhatsAppIcon />
                            </Link>
                          )}
                          {candidate?.email && (
                            <Link
                              style={{
                                width: 18,
                                height: 18,
                                marginTop: 4
                              }}
                              src={`mailto:${candidate.email}`}>
                              <MailIcon />
                            </Link>
                          )}
                          <Link
                            style={{
                              width: 18,
                              height: 18,
                              marginTop: 4,
                              marginLeft: 10
                            }}
                            src={`${process.env.REACT_APP_URL}/curriculum/${candidate?.id}`}>
                            <FileIcon />
                          </Link>
                        </View>
                        <Text style={styles.display}>{candidate?.name}</Text>
                        <Text style={{ ...styles.text, marginTop: 2 }}>
                          {`${
                            candidate?.city?.length > 0
                              ? capitalize(candidate?.city)
                              : '-'
                          } / ${candidate?.state ?? '-'}`}
                        </Text>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 8,
                            marginBottom: 4,
                            gap: 4
                          }}>
                          <Text style={styles.label}>
                            {actualExperience
                              ? 'Empresa atual'
                              : 'Última empresa'}
                          </Text>
                          <Text style={styles.text}>
                            {actualJob?.company_name}
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: 4,
                            gap: 4
                          }}>
                          <Text style={styles.label}>Cargo:</Text>
                          <Text style={styles.text}>
                            {actualJob?.position_in_the_company}
                          </Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 12 }}>
                          <View style={{ flex: 1 }}>
                            <Text style={{ ...styles.label, marginBottom: 8 }}>
                              {actualExperience
                                ? 'Salário atual'
                                : 'Último salário'}
                            </Text>
                            <Text wrap style={styles.text}>
                              {actualJob?.salary
                                ? `${currency.format(actualJob?.salary, {
                                    code: actualJob?.salary_currency
                                  })} - ${actualJob?.job_type}`
                                : '-'}
                            </Text>
                          </View>
                          <View style={{ flex: 1 }}>
                            <Text style={{ ...styles.label, marginBottom: 8 }}>
                              Pretensão salarial
                            </Text>
                            <Text wrap style={styles.text}>
                              {candidate?.salary_expectation
                                ? `${currency.format(
                                    candidate?.salary_expectation,
                                    {
                                      code: candidate?.salary_currency
                                    }
                                  )} - ${candidate?.job_type_expectation}`
                                : '-'}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            height: 70,
                            marginTop: 12
                          }}>
                          <Text style={{ ...styles.label, marginBottom: 8 }}>
                            Benefícios
                          </Text>
                          <Text wrap style={styles.text}>
                            {actualJob?.benefits
                              ?.map(
                                ({ benefit, value }: any) =>
                                  `${benefit.name} ${value ?? ''}`
                              )
                              .join(' + ')}
                          </Text>
                        </View>
                        <View style={{ marginTop: 12 }}>
                          <Text style={{ ...styles.label, marginBottom: 8 }}>
                            Resumo do candidato
                          </Text>
                          <Text style={styles.text}>
                            {candidate?.professional_resume}
                          </Text>
                        </View>
                      </View>
                    )
                  })}
                </View>
              </Page>
            ))}
          </Document>
        </PDFViewer>
      )}
    </>
  )
}
