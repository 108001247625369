import { Box, HStack, Icon, IconButton, VStack } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'

import { Fragment, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { FiPlus, FiPlusCircle, FiTrash } from 'react-icons/fi'
import useFetch from 'use-http'

type BenefitsType = {
  control: any
  values: any
  index: number
  setValue: any
  getValues: any
}

type keyValueType = {
  value: string
  label: string
}

const formatBenefits = async ({ response }: any) => {
  const res = response
  const newRes: keyValueType[] = []

  res?.data?.forEach((benefits: any) => {
    const benefitsNewObject = {
      label: benefits?.name,
      value: benefits?.id
    }

    newRes.push(benefitsNewObject)
  })
  res.data = newRes

  return res
}

const newBenefit = { benefit_id: '', value: '' }

export const Benefits = ({
  control,
  values,
  index,
  setValue,
  getValues
}: BenefitsType) => {
  const { loading, get, data } = useFetch(Endpoints.lists.benefits, {
    interceptors: {
      response: formatBenefits
    }
  })

  useEffect(() => {
    get()
  }, [get])

  return (
    <Fragment>
      <VStack width="100%" spacing={4}>
        {(!values.benefits || values?.benefits?.length === 0) && (
          <Box width="100%" mt={8}>
            <Button
              type="button"
              width="100%"
              variant="outline"
              py="0.5"
              fontSize="sm"
              fontWeight="500"
              borderRadius="md"
              colorScheme="neutralLight"
              onClick={() => {
                setValue(`professional_experiences.${index}.benefits`, [
                  newBenefit
                ])
                getValues()
              }}>
              <Icon as={FiPlusCircle} mr={2} /> Adicionar benefício
            </Button>
          </Box>
        )}

        {values?.benefits?.map((benefit: any, idx: number) => {
          return (
            <HStack
              width="100%"
              alignItems="flex-start"
              spacing={4}
              key={`benefit_${idx}`}>
              <Controller
                control={control}
                name={`professional_experiences.${index}.benefits.${idx}.benefit_id`}
                render={({ field: { ref, ...props } }) => (
                  <Menu {...props} label="Benefício" options={data} />
                )}
              />

              <Controller
                control={control}
                name={`professional_experiences.${index}.benefits.${idx}.value`}
                render={({ field: { ref, value, ...props } }) => {
                  return (
                    <Input
                      {...props}
                      disabled={loading}
                      variant="filled"
                      label="Valor/Variável"
                      placeholder="Digite aqui"
                      value={value ?? ''}
                    />
                  )
                }}
              />
              <HStack pt={10} alignItems="center" spacing={4}>
                <IconButton
                  onClick={() => {
                    setValue(
                      `professional_experiences.${index}.benefits`,
                      values?.benefits?.filter((_: any, valueIdx: number) => {
                        return idx !== valueIdx
                      })
                    )

                    getValues()
                  }}
                  variant="ghost"
                  size="xs"
                  colorScheme="black"
                  icon={<FiTrash fontSize="1.5rem" />}
                  aria-label="Delete"
                />
                <IconButton
                  isRound
                  onClick={() => {
                    setValue(`professional_experiences.${index}.benefits`, [
                      ...values.benefits,
                      newBenefit
                    ])

                    getValues()
                  }}
                  variant="solid"
                  size="sm"
                  colorScheme="brand"
                  icon={<FiPlus fontSize="1.2rem" color="white" />}
                  aria-label="Add"
                />
              </HStack>
            </HStack>
          )
        })}
      </VStack>
    </Fragment>
  )
}
