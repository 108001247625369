/* eslint-disable camelcase */
/* eslint-disable indent */
import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { TablePagination } from 'components/organisms/Table'
import { format } from 'date-fns'
import { InvoiceStatusTypes } from 'enum/form'
import { JobOptions, JobTypes } from 'enum/jobs'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useMemo } from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi'
import useFetch from 'use-http'
import { formatCurrency } from 'utils/formatCurrency'
import { formatYellowProducts } from 'utils/formatYellowProduct'

type TableClosureType = {
  data: any
  total: number
  onChange: (_page: number) => void
  pagination: {
    take: number
    total: number
  }
  refresh: any
}

export const TableClosure = ({
  data,
  total,
  onChange,
  pagination,
  refresh
}: TableClosureType) => {
  const onError = useErrorToast()
  const toast = useToast()

  const { put: updateForm, response: responseForm, loading } = useFetch()

  const { data: yellowProducts } = useFetch(
    Endpoints.lists.yellowProduct,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os Produtos Yellow.'
        }),
      interceptors: { response: formatYellowProducts }
    },
    []
  )

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <IconButton
              colorScheme="neutralDark"
              variant="outline"
              {...row.getToggleRowExpandedProps()}
              title="Expandir">
              {row.isExpanded ? <FiMinus /> : <FiPlus />}
            </IconButton>
          ) : null
      },
      {
        Header: 'Vaga',
        columns: [
          {
            Header: 'Status',
            accessor: 'status'
          },
          {
            Header: 'Situação',
            accessor: 'situation'
          },
          {
            Header: 'Cód. vaga',
            accessor: 'job_code'
          },
          {
            Header: 'Cliente',
            accessor: 'customer'
          },
          {
            Header: 'Produto',
            accessor: 'product'
          },
          {
            Header: 'Nome da vaga',
            accessor: 'job_title'
          },
          {
            Header: 'Condutor',
            accessor: 'conductor'
          },
          {
            Header: 'Qualificador',
            accessor: 'qualifier'
          },
          {
            Header: 'Vaga sigilosa',
            accessor: 'is_secret'
          }
        ]
      },
      {
        Header: 'Candidato',
        columns: [
          {
            Header: 'Nome placement',
            accessor: 'candidate'
          },
          {
            Header: 'Data de fechamento',
            accessor: 'closeDate'
          },
          {
            Header: 'Salário Final',
            accessor: 'salary'
          },
          {
            Header: 'Contratação',
            accessor: 'hiring'
          },
          {
            Header: 'Observação',
            accessor: 'obs'
          }
        ]
      }
    ],
    []
  )

  const handleStatus = async (status: string, formId: string) => {
    await updateForm(Endpoints.forms.update(formId), {
      status: status === '' ? null : status
    })

    if (responseForm.status >= 400) {
      onError({
        title: 'Não foi possível atualizar o form.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Form atualizado com sucesso.',
      variant: 'subtle'
    })

    refresh()
  }

  return (
    <Fragment>
      <TablePagination
        total={total}
        columns={columns}
        onChange={onChange}
        pagination={pagination}
        data={data?.map((form: any) => {
          const { ClosingFormJob } = form.job

          const closing_form = ClosingFormJob ?? {}
          const placements =
            closing_form?.closing_form_job_candidates?.filter(
              ({ is_placement }: { is_placement: boolean }) => is_placement
            ) ?? []

          return {
            id: form.id,
            status: (
              <Box w="150px">
                <Select
                  placeholder="Selecione"
                  bg="neutralLight.400"
                  p="0"
                  ml="4"
                  size="sm"
                  w="fit-content"
                  variant="filled"
                  defaultValue={form.status}
                  value={form?.status}
                  onChange={(e) => handleStatus(e.target.value, form.id)}
                  fontSize="xs"
                  borderRadius="md"
                  color="BlackText"
                  disabled={loading}
                  _focusVisible={{
                    _placeholder: {
                      color: 'black'
                    },
                    bg: 'neutralLight.400',
                    borderColor: 'transparent'
                  }}
                  _hover={{
                    bg: 'neutralLight.400'
                  }}>
                  {Object.keys(InvoiceStatusTypes)
                    .filter((s) => s !== 'CREDIT')
                    .map((key) => (
                      <option key={`invoice_${key}`} value={key}>
                        {InvoiceStatusTypes[key]}
                      </option>
                    ))}
                </Select>
              </Box>
            ),
            situation: (
              <Text fontSize="sm">
                {closing_form.job_options
                  ? JobOptions[form.job.ClosingFormJob.job_options]
                  : '–'}
              </Text>
            ),
            job_code: <Text fontSize="sm">{form.job.job_code || '–'}</Text>,
            customer: (
              <Text fontSize="sm">{form?.job.customer.name ?? '–'}</Text>
            ),
            product: (
              <Text fontSize="sm">
                {yellowProducts
                  ? yellowProducts[form.job?.yellow_product_id]
                  : '–'}
              </Text>
            ),
            job_title: <Text fontSize="sm"> {form.job.title}</Text>,
            conductor: (
              <Text fontSize="sm">
                {form.job.user_conductor?.user?.name ?? '–'}
              </Text>
            ),
            qualifier: (
              <Text fontSize="sm">
                {form.job.users_qualifier?.length
                  ? form.job.users_qualifier
                      .map(({ qualifier }: any) => qualifier.user.name)
                      .join(', ')
                  : '–'}
              </Text>
            ),
            is_secret: (
              <Text fontSize="sm">
                {!form.job.is_secret ? 'Não' : form.job.email_is_secret}
              </Text>
            ),
            ...(placements.length === 1 && {
              candidate: (
                <Text fontSize="sm">
                  {placements[0].candidate?.name ?? '–'}
                </Text>
              ),
              end_at: (
                <Text fontSize="sm">
                  {placements[0].closing_date
                    ? format(new Date(placements[0].closing_date), 'dd/MM/yyyy')
                    : '–'}
                </Text>
              ),
              closeDate: (
                <Text fontSize="sm">
                  {form.job.ClosingFormJob.closing_form_job_candidates[0]
                    .closing_date
                    ? format(
                        new Date(
                          form.job.ClosingFormJob.closing_form_job_candidates[0].closing_date
                        ),
                        'dd/MM/yyyy'
                      )
                    : '–'}
                </Text>
              ),
              salary: (
                <Text fontSize="sm">
                  {placements[0].final_salary
                    ? formatCurrency(placements[0].final_salary)
                    : '–'}
                </Text>
              ),
              hiring: (
                <Text fontSize="sm">
                  {placements[0].hiring_model
                    ? JobTypes[placements[0].hiring_model]
                    : '–'}
                </Text>
              )
            }),
            ...(placements.length > 1 && {
              subRows: placements.map(
                ({
                  id,
                  closing_date,
                  admission_date,
                  hiring_model,
                  final_salary,
                  candidate
                }: any) => ({
                  id,
                  candidate: (
                    <Text fontSize="sm">{candidate?.name ?? '–'}</Text>
                  ),
                  end_at: (
                    <Text fontSize="sm">
                      {closing_date
                        ? format(new Date(closing_date), 'dd/MM/yyyy')
                        : '–'}
                    </Text>
                  ),
                  start_at: (
                    <Text fontSize="sm">
                      {admission_date
                        ? format(new Date(admission_date), 'dd/MM/yyyy')
                        : '–'}
                    </Text>
                  ),
                  salary: (
                    <Text fontSize="sm">
                      {final_salary ? formatCurrency(final_salary) : '–'}
                    </Text>
                  ),
                  hiring: (
                    <Text fontSize="sm">
                      {hiring_model ? JobTypes[hiring_model] : '–'}
                    </Text>
                  )
                })
              )
            }),
            obs: (
              <Text fontSize="sm">
                {' '}
                {form.observation ? (
                  <>
                    <Popover>
                      <PopoverTrigger>
                        <Button
                          variant="unstyled"
                          fontWeight="normal"
                          _hover={{
                            textDecoration: 'underline'
                          }}>
                          Visualizar
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader px="5" py="3">
                          Observação forms de fechamento
                        </PopoverHeader>
                        <PopoverBody p="5">
                          <Text whiteSpace="normal" lineHeight="1.2">
                            {form.observation}
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </>
                ) : (
                  '–'
                )}
              </Text>
            )
          }
        })}
      />
    </Fragment>
  )
}
