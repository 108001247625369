/* eslint-disable indent */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Divider,
  Heading,
  Icon,
  IconButton,
  Link,
  Text,
  VStack
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Tooltip } from 'components/atoms/Tooltip'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { BsCardList, BsKanban } from 'react-icons/bs'
import { FaHome, FaUserAlt, FaUsers } from 'react-icons/fa'
import {
  FiChevronLeft,
  FiChevronRight,
  FiFileText,
  FiLogOut,
  FiMonitor,
  FiPlusCircle
} from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import useSidebarStore from 'stores/sidebar'
import useUserStore from 'stores/user'
import { normalizeNumber } from 'theme/normalization'
import useFetch from 'use-http'
import { formsMenu } from 'utils/sidebarFormsSubmenu'

type SidebarType = {
  active: string
  userLogged: any
}

const userType = ['MASTER', 'FINANCEIRO', 'MARKETING']
const canAcessForms = (userArea: string) =>
  userType.some((area: string) => area.includes(userArea)) && {
    icon: <Icon as={FiFileText} w={6} h={6} mr={4} />,
    title: 'Forms',
    slug: '/forms',
    submenu: formsMenu(userArea)
  }

const menus = (userArea: string) => [
  {
    icon: <Icon as={BsKanban} w={6} h={5} mr={4} />,
    slug: '/pipeline-de-vagas',
    title: 'Pipeline de vagas'
  },
  {
    icon: <Icon as={BsCardList} w={6} h={6} mr={4} />,
    slug: '/usuarios',
    title: 'Gestão de usuários'
  },
  {
    icon: <Icon as={FaUserAlt} w={6} h={5} mr={4} />,
    slug: '/clientes',
    title: 'Gestão de clientes'
  },
  {
    icon: <Icon as={FaUsers} w={6} h={6} mr={4} />,
    slug: '/candidatos',
    title: 'Candidatos'
  },
  /*   {
    icon: <Icon as={FiPlusCircle} w={6} h={6} mr={4} />,
    slug: '/listas/ambiente-yellow',
    title: 'Cadastro de listas',
    disabled: true
  },
  {
    icon: <Icon as={FiMonitor} w={6} h={6} mr={4} />,
    slug: '/acessos/recrutamento',
    title: 'Permissão de acessos',
    disabled: true
  }, */
  canAcessForms(userArea)
]

export const Sidebar = ({ active, userLogged }: SidebarType) => {
  const [expandButton, toggleExpandButton] = useState<boolean>(false)
  const { resetUser } = useUserStore()

  const { opened, changeState } = useSidebarStore()
  const { user } = useUserStore()

  const { get, data: completeUser } = useFetch(
    Endpoints.users.userByEmail(userLogged.email),
    {},
    [userLogged]
  )

  const currentMenuActive = useMemo(() => active, [active])

  useEffect(() => {
    get()
  }, [get])

  return (
    <Box
      bg="#1B1C1E"
      width={opened ? normalizeNumber(352) : normalizeNumber(78)}
      height={`calc(100% - ${normalizeNumber(70)}px)`}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      position="fixed"
      zIndex="1"
      onMouseEnter={() => toggleExpandButton(true)}
      onMouseLeave={() => toggleExpandButton(false)}>
      <Box
        position="absolute"
        top={20}
        right={expandButton ? -3 : -2}
        transition="all 0.1s linear"
        opacity={expandButton ? 1 : 0}>
        <IconButton
          isRound
          type="button"
          bg="white"
          size="xs"
          variant="solid"
          aria-label="Toggle View Sidebar"
          icon={<Icon as={opened ? FiChevronLeft : FiChevronRight} />}
          onClick={() => changeState(!opened)}
          boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
          _hover={{
            bg: 'brand.500'
          }}
          _active={{
            bg: 'brand.500'
          }}
        />
      </Box>
      <Box py={6}>
        <Tooltip label="Em breve">
          <Heading
            fontSize="lg"
            fontWeight="normal"
            color="#777"
            position="relative"
            alignItems="center"
            display="flex"
            py={4}
            pl={8}
            pr={4}
            _before={{
              bg: 'white',
              borderRadius: 'full',
              content: `${active === '/' ? '""' : 'unset'}`,
              display: 'inline-block',
              h: 6,
              left: 0,
              position: 'absolute',
              w: 1
            }}>
            <Icon as={FaHome} w={6} h={6} mr={4} />
            <Box as="span" display={opened ? 'block' : 'none'}>
              Home
            </Box>
          </Heading>
        </Tooltip>

        <Box ml={4} mt={4} mr={{ base: 4, md: 0 }}>
          <nav>
            <VStack w="full" alignItems="flex-start" spacing={4}>
              {menus(completeUser?.team).map((menu: any, idx: number) => {
                if (menu.submenu) {
                  return (
                    <Accordion
                      allowToggle
                      w="full"
                      index={
                        currentMenuActive.includes(menu?.slug) ? 0 : undefined
                      }>
                      <AccordionItem
                        w="full"
                        display="flex"
                        flexDirection="column"
                        border="none">
                        {({ isExpanded }) => (
                          <>
                            <Text
                              fontSize="lg"
                              color={menu?.disabled ? '#777' : '#F5F6FA'}>
                              <AccordionButton
                                w="full"
                                p={4}
                                _expanded={{
                                  bg: '#323335',
                                  borderLeftRadius: 'lg',
                                  borderRightRadius: { base: 'lg', md: 'none' }
                                }}>
                                {menu.icon}
                                {opened && menu?.title}
                              </AccordionButton>
                            </Text>
                            {isExpanded && (
                              <AccordionPanel w="full" px={16}>
                                {menu.submenu.map(
                                  (submenu: any, index: number) => (
                                    <Link
                                      as={NavLink}
                                      key={`submenu_item_${index}`}
                                      fontSize="md"
                                      to={submenu?.slug}
                                      color={
                                        currentMenuActive === submenu?.slug
                                          ? 'brand.500'
                                          : 'white'
                                      }
                                      w="full"
                                      alignItems="center"
                                      display="flex"
                                      py={4}>
                                      {submenu?.title}
                                    </Link>
                                  )
                                )}
                              </AccordionPanel>
                            )}
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                  )
                }

                return (
                  <Link
                    as={NavLink}
                    bg={
                      currentMenuActive === menu?.slug
                        ? '#323335'
                        : 'transparent'
                    }
                    key={`menu_item_${idx}`}
                    to={menu?.disabled ? currentMenuActive : menu?.slug ?? ''}
                    color={menu?.disabled ? '#777' : '#F5F6FA'}
                    alignItems="center"
                    display="flex"
                    w="full"
                    fontSize="lg"
                    p={4}
                    pr={10}
                    borderLeftRadius="lg"
                    borderRightRadius={{ base: 'lg', md: 'none' }}
                    _hover={{
                      bg: !menu.disabled && '#323335'
                    }}>
                    {menu.icon}
                    {menu?.disabled ? (
                      <Tooltip label="Em breve">
                        <Box as="span" display={opened ? 'block' : 'none'}>
                          {menu.title}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box as="span" display={opened ? 'block' : 'none'}>
                        {menu.title}
                      </Box>
                    )}
                  </Link>
                )
              })}
            </VStack>
          </nav>
        </Box>
      </Box>
      <Box mb={6}>
        <VStack w="full" spacing={6} alignItems="flex-start">
          <Box w="full" pr={6} display={opened ? 'block' : 'none'}>
            <Divider
              h={`${normalizeNumber(2)}px`}
              style={{
                borderColor: '#4D4D4D'
              }}
            />
          </Box>
          <Box
            as={NavLink}
            to="/perfil/geral"
            w="full"
            display="flex"
            alignItems="center"
            pl={4}>
            <Avatar
              bg={{ base: 'transparent', md: 'white' }}
              color={{ base: 'white', md: '#1B1C1E' }}
              icon={<Icon as={FaUserAlt} w={6} h={5} />}
            />
            <Box ml={4} display={opened ? 'block' : 'none'}>
              <VStack spacing={1} alignItems="flex-start">
                <Text
                  fontWeight="bold"
                  fontSize="md"
                  color="#F5F6FA"
                  w="90%"
                  noOfLines={1}>
                  {user?.name}
                </Text>
                <Text fontSize="md" color="#F5F6FA" w="95%" noOfLines={1}>
                  {user?.email}
                </Text>
              </VStack>
            </Box>
          </Box>
          <Box w="full" pl={4}>
            <nav>
              <Link
                onClick={() => resetUser()}
                href="/"
                color="#F5F6FA"
                alignItems="center"
                display="flex"
                w="full"
                fontSize="sm"
                p={4}
                borderLeftRadius="lg"
                _hover={{
                  bg: '#323335'
                }}>
                <Icon as={FiLogOut} w={4} h={4} mr={4} />
                <Box as="span" display={opened ? 'block' : 'none'}>
                  Sair
                </Box>
              </Link>
            </nav>
          </Box>
        </VStack>
      </Box>
    </Box>
  )
}
