import { useParams } from 'react-router-dom'
import { YellowEnvironment } from './components/YellowEnvironment'

const pages: any = {
  'ambiente-yellow': YellowEnvironment,
  beneficios: () => <></>,
  'contratacao-e-salario': () => <></>,
  genero: () => <></>,
  'hard-skills': () => <></>,
  idiomas: () => <></>,
  mercado: () => <></>,
  vaga: () => <></>
}

export const List = () => {
  const { category }: any = useParams()

  const Page = pages[category]

  return <Page />
}
