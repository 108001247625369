/* eslint-disable react/jsx-indent */
import { Box, Flex, HStack, Spinner, useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'

import { HeaderPage } from 'components/organisms/HeaderPage'
import { Fragment, useCallback, useEffect, useState } from 'react'
import useFetch from 'use-http'
import { Filters } from './components/Filters'
import { TableCandidates } from './components/TableCandidates'

export const Candidates = () => {
  const toast = useToast()

  const [page, setPage] = useState<number>(0)
  const [nameFilter, setNameFilter] = useState('')
  const [filters, setFilters] = useState<{ [key: string]: string }>({})

  const take = 12

  const filterQuery = () => {
    let string = ''
    if (filters.nps) string = string + `&nps=${filters.nps}`
    if (filters.is_placement)
      string = string + `&is_placement=${filters.is_placement}`

    return string
  }

  const {
    loading,
    data: forms,
    get
  } = useFetch(
    Endpoints.forms.candidates({ page, take }) + filterQuery(),
    {
      onError: () =>
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: 'Não foi possível listar os forms.',
          position: 'top-right',
          status: 'warning',
          title: 'Ops!',
          variant: 'subtle'
        })
    },
    [filters]
  )

  const {
    loading: loadingExport,
    post: exportCandidate,
    response: responseExportCandidates
  } = useFetch(Endpoints.forms.exportCandidates, {
    onError: () =>
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: 'Não foi possível exportar o form.',
        position: 'top-right',
        status: 'warning',
        title: 'Ops!',
        variant: 'subtle'
      })
  })

  const onDownload = useCallback(async () => {
    await exportCandidate([
      'candidate.name',
      'candidate.email',
      'nps',
      'is_placement',
      'closing_form_job.job.job_code'
    ])

    const blob = await responseExportCandidates.blob()
    const filename = 'form_candidates.csv'

    const linkElement = document.createElement('a')
    // @ts-ignore
    const file = new Blob([blob], { type: 'text/csv' }, filename)
    const url = URL.createObjectURL(file)

    linkElement.href = url
    linkElement.setAttribute('download', filename)

    document.body.appendChild(linkElement)
    linkElement.click()

    linkElement.remove()
  }, [exportCandidate, responseExportCandidates])

  const handleFilter = (values: {}) => {
    setFilters((currentFilters: any) => ({
      ...currentFilters,
      ...values
    }))
  }

  useEffect(() => {
    get()
  }, [get, filters])

  return (
    <Fragment>
      <Flex
        minH="12%"
        w="100%"
        flexDirection="column"
        justify="space-between"
        pb="5">
        <HStack mb="6">
          <HeaderPage title="Candidatos" />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <HStack>
            <Filters
              loading={loading}
              nameState={nameFilter}
              onChangeName={(name: string) => setNameFilter(name)}
              setFilters={setFilters}
              filters={filters}
              searchTerm="false"
              handleFilter={handleFilter}
              type="candidate"
            />
          </HStack>
          <Button
            variant="solid"
            colorScheme="brand"
            fontSize="xs"
            fontWeight="bold"
            px={8}
            height={30}
            borderRadius="md"
            loading={loadingExport}
            disabled={loading}
            onClick={() => onDownload()}>
            Exportar
          </Button>
        </HStack>
      </Flex>
      <Box
        h="78%"
        width="100%"
        bg="white"
        borderRadius="lg"
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: `rgba(0, 0, 0, 0.15)`
          }
        }}>
        {loading && !forms?.data && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Spinner color="brand.500" />
          </Box>
        )}
        {forms?.data && (
          <TableCandidates
            refresh={get}
            data={forms.data}
            total={Math.round(forms.total / take)}
            onChange={setPage}
            pagination={{
              take,
              total: forms.total
            }}
          />
        )}
      </Box>
    </Fragment>
  )
}
