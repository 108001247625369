import { Heading, Stack, Text } from '@chakra-ui/react'

type HeaderBoxType = {
  title: string
  description?: string
}

export const HeaderLoginForm = ({ title, description }: HeaderBoxType) => (
  <Stack spacing={4}>
    <Heading
      fontSize={30}
      fontWeight="semibold"
      textAlign={{ base: 'center', sm: 'left' }}>
      {title}
    </Heading>
    {description && (
      <Text
        fontSize="md"
        color="#4D4D4D"
        textAlign={{ base: 'center', sm: 'left' }}>
        {description}
      </Text>
    )}
  </Stack>
)
