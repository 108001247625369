import { ReactNode, createContext, useContext, useState } from 'react'

type FormCandidateType = {
  dataForm: any
  setDataForm: (_payload: any) => void
}

type FormCandidateProviderType = {
  children: ReactNode
}

export const FormCandidateContext = createContext<FormCandidateType>(
  {} as FormCandidateType
)

const FormCandidateProvider = ({ children }: FormCandidateProviderType) => {
  const [dataForm, setDataForm] = useState(null)

  return (
    <FormCandidateContext.Provider
      value={{
        dataForm,
        setDataForm
      }}>
      {children}
    </FormCandidateContext.Provider>
  )
}

export const useFormCandidate = (): FormCandidateType => {
  const context = useContext(FormCandidateContext)

  if (!context) {
    throw new Error(
      'useFormCandidate must be used within an FormCandidateProvider'
    )
  }

  return context
}

export default FormCandidateProvider
