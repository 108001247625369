import { Accordion } from '@chakra-ui/react'
import { HardSkillsDetailsAccordion } from './HardSkillsDetailsAccordion'
import { ProfessionalDetailsAccordion } from './ProfessionalDetailsAccordion'
import { ProfessionalExperiencesDetailsAccordion } from './ProfessionalExperiencesDetailsAccordion'
import { ProfileDetailsAccordion } from './ProfileDetailsAccordion'

export const DataInfoCandidate = ({ candidate, isInShortlist }: any) => (
  <Accordion defaultIndex={[0, 1, 2, 3]} allowMultiple w="full">
    <HardSkillsDetailsAccordion hardSkils={candidate.candidate_hard_skills} />
    <ProfileDetailsAccordion
      phone={candidate.phone}
      linkedinProfileUrl={candidate.linkedin_profile_url}
      professionalResume={candidate.professionalResume}
      city={candidate.city}
      state={candidate.state}
      mpcStatus={candidate.mpc_status}
      isWhatsapp={candidate.is_whatsapp}
      email={candidate.email}
    />
    {!isInShortlist && (
      <>
        <ProfessionalDetailsAccordion
          superiorCourses={candidate.superior_courses}
          languages={candidate.languages}
          availableTravel={candidate.available_travel}
          availableChangeCity={candidate.available_change_city}
          availableHomeOffice={candidate.available_home_office}
        />
        <ProfessionalExperiencesDetailsAccordion
          professionalExperiences={candidate?.professional_experiences}
        />
      </>
    )}
  </Accordion>
)
