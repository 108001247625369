import {
  AccordionButton,
  Accordion as AccordionChakra,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps
} from '@chakra-ui/react'
import { ReactNode } from 'react'

interface AccordionType extends AccordionProps {
  children: ReactNode
  secondary?: boolean
  title: string
}

export const Accordion = ({
  title,
  secondary,
  children,
  ...rest
}: AccordionType) => (
  <AccordionChakra {...rest} allowToggle>
    <AccordionItem
      borderTop="none"
      borderColor={secondary ? 'transparent' : '#C8CCD4'}>
      <AccordionButton
        px={0}
        py={secondary ? 0 : 10}
        fontSize={secondary ? 'md' : 'lg'}
        fontWeight="bold"
        color={secondary ? '#4D4D4D' : '#1B1C1E'}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        _hover={{}}>
        {title}
        <AccordionIcon
          color={secondary ? '#C8CCD4' : 'brand.500'}
          fontSize="3xl"
        />
      </AccordionButton>
      <AccordionPanel pl={secondary ? 6 : 0} pr={1}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  </AccordionChakra>
)
