import { CheckCircleIcon } from '@chakra-ui/icons'
import { Box, List, ListIcon, ListItem, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { RefObject, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '../../../components/atoms/Button'
import { Input } from '../../../components/atoms/Input'

import {
  lowerCaseRegex,
  minLength,
  numberRegex,
  passwordComplexityRegex,
  specialCharacterRegex,
  upperCaseRegex
} from '../../../utils/validations'

export type FormDataType = {
  password: string
}

type FormType = {
  formRef: RefObject<HTMLFormElement>
  onSubmit: (_payload: FormDataType) => void
  loading: boolean
}

const formSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .required('Campo obrigatório')
    .oneOf([yup.ref('password'), null], 'As senhas não conferem'),
  password: yup
    .string()
    .matches(passwordComplexityRegex, 'Senha inválida')
    .min(minLength, 'Mínimo de 8 caracteres')
    .required('Campo obrigatório')
})

const generateFieldValidator = (input: string) => {
  const _payload: any = {
    password: []
  }

  if (input.length < minLength) {
    _payload.password.push({ message: 'Mínimo de 8 caracteres', valid: false })
  } else {
    _payload.password.push({ message: 'Mínimo de 8 caracteres', valid: true })
  }

  if (upperCaseRegex.test(input)) {
    _payload.password.push({ message: 'Letra maiúscula', valid: true })
  } else {
    _payload.password.push({ message: 'Letra maiúscula', valid: false })
  }

  if (lowerCaseRegex.test(input)) {
    _payload.password.push({ message: 'Letra minúscula', valid: true })
  } else {
    _payload.password.push({ message: 'Letra minúscula', valid: false })
  }

  if (numberRegex.test(input)) {
    _payload.password.push({ message: 'Número', valid: true })
  } else {
    _payload.password.push({ message: 'Número', valid: false })
  }

  if (specialCharacterRegex.test(input)) {
    _payload.password.push({ message: 'Caractere especial', valid: true })
  } else {
    _payload.password.push({ message: 'Caractere especial', valid: false })
  }

  return _payload
}

export const FormFirstAccess = ({ formRef, onSubmit, loading }: FormType) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch
  } = useForm({
    defaultValues: {
      confirmPassword: '',
      password: ''
    },
    mode: 'onChange',
    resolver: yupResolver(formSchema)
  })

  const handleSubmitForm = useCallback(() => {
    formRef?.current?.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    )
  }, [formRef])

  const [field, setField] = useState({ password: [] })
  const password = watch('password')

  useEffect(() => {
    setField(generateFieldValidator(password))
  }, [password])

  return (
    <>
      <form
        ref={formRef}
        onSubmit={handleSubmit((data: any) => onSubmit(data))}>
        <Stack spacing={9}>
          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field: { ref, value, ...props } }) => (
              <Input
                label="Insira sua nova senha"
                type="password"
                placeholder="Digite aqui"
                variant="filled"
                id="password"
                tabIndex={0}
                value={value ?? ''}
                isInvalid={errors?.password !== undefined}
                errorMessage={errors?.password?.message}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="confirmPassword"
            rules={{ required: true }}
            render={({ field: { ref, value, ...props } }) => (
              <Input
                label="Confirme sua nova senha"
                type="password"
                placeholder="Digite aqui"
                variant="filled"
                id="confirmPassword"
                tabIndex={0}
                value={value ?? ''}
                isInvalid={errors?.confirmPassword !== undefined}
                errorMessage={errors?.confirmPassword?.message}
                {...props}
              />
            )}
          />

          <Box>
            <Text
              fontWeight="bold"
              color="#6A6C72"
              fontSize="sm"
              textAlign={{ base: 'center', sm: 'left' }}>
              Sua senha deve conter as seguintes regras:
            </Text>
            <List marginTop={2} spacing={1}>
              {field.password.map((step: any, idx: number) => (
                <ListItem
                  color={step.valid ? 'positive.500' : '#6A6C72'}
                  fontSize="sm"
                  key={`step_${idx}`}>
                  <ListIcon
                    as={CheckCircleIcon}
                    color={step.valid ? 'positive.500' : 'neutralLight.400'}
                  />
                  {step.message}
                </ListItem>
              ))}
            </List>
          </Box>

          <Button
            disabled={!isValid}
            loading={loading}
            type="button"
            variant="solid"
            colorScheme="brand"
            onClick={() => !loading && handleSubmitForm()}
            width="100%">
            Definir senha e acessar
          </Button>
        </Stack>
      </form>
    </>
  )
}
