/* eslint-disable indent */
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  VStack
} from '@chakra-ui/react'
import currency from 'currency-formatter'
import { format, formatDistance, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { FiBriefcase } from 'react-icons/fi'
import { SenioryTypes } from '../Tabs/enum'

type ProfessionalExperiencesDetailsAccordionProps = {
  professionalExperiences: {
    id: string
    seniority: string
    actual_job: boolean
    company_name: string
    company: {
      company_name: string
    }
    position_in_the_company: string
    salary: number
    salary_currency: string
    description: string
    started_at: Date
    end_at: Date | null
    job_type: string
  }[]
}

export const ProfessionalExperiencesDetailsAccordion = ({
  professionalExperiences
}: ProfessionalExperiencesDetailsAccordionProps) => {
  return (
    <AccordionItem py={5} px={0} mx={4} _last={{ borderBottomWidth: 0 }}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Text fontSize="md" fontWeight="medium" mb="2">
            Experiências
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {professionalExperiences?.map((experiences, i) => {
          return (
            <Box my="8" key={i}>
              <HStack key={experiences.id} mb={4} alignItems="flex-start">
                <Box bg="neutralLight.300" px={3} py={2}>
                  <Icon as={FiBriefcase} h={5} w={5} />
                </Box>
                <VStack alignItems="flex-start" pl={2} spacing={1}>
                  <Text fontSize="md" fontWeight="bold" lineHeight="normal">
                    {experiences?.position_in_the_company ??
                      SenioryTypes?.[experiences.seniority] ??
                      ''}
                  </Text>
                  <Text fontSize="sm" fontWeight="normal" lineHeight="normal">
                    {`${experiences.company_name} . ${
                      experiences?.job_type ?? ''
                    }`}
                  </Text>
                  <Text
                    pb="4"
                    color="neutralDark.200"
                    fontSize="sm"
                    fontWeight="normal"
                    lineHeight="normal"
                    borderBottomWidth="1px"
                    borderBottomColor="gray.200"
                    borderBottomStyle="dashed">
                    {experiences.started_at
                      ? format(
                          // @ts-ignore
                          parseISO(experiences.started_at),
                          "MMMM 'de' yyyy",
                          {
                            locale: ptBR
                          }
                        )
                      : null}{' '}
                    {experiences?.end_at
                      ? ' - ' +
                        format(
                          // @ts-ignore
                          parseISO(experiences?.end_at),
                          "MMMM 'de' yyyy",
                          {
                            locale: ptBR
                          }
                        )
                      : experiences?.actual_job
                      ? '- Até o momento'
                      : null}{' '}
                    {experiences?.started_at
                      ? ' . ' +
                        formatDistance(
                          new Date(experiences?.started_at),
                          experiences?.end_at
                            ? new Date(experiences?.end_at)
                            : Date.now(),
                          {
                            locale: ptBR
                          }
                        )
                      : null}
                  </Text>
                  <Text
                    py={3}
                    fontSize="sm"
                    fontWeight="normal"
                    lineHeight="normal">
                    {experiences?.salary
                      ? `Remuneração: ${currency.format(experiences?.salary, {
                          code: experiences?.salary_currency
                        })}`
                      : ''}
                  </Text>
                  {experiences.description && (
                    <Flex
                      justifyContent="space-between"
                      p={3}
                      bg="grayBackground"
                      borderRadius="lg"
                      mt={6}>
                      <Text
                        fontSize="sm"
                        fontWeight="normal"
                        lineHeight="normal">
                        {experiences.description}
                      </Text>
                    </Flex>
                  )}
                </VStack>
              </HStack>
              {i !== professionalExperiences.length - 1 && (
                <Divider variant="dashed" my={4} />
              )}
            </Box>
          )
        })}
      </AccordionPanel>
    </AccordionItem>
  )
}
