import { Box, Divider, Heading, Text } from '@chakra-ui/react'

type HeaderPageType = {
  title: string
  divider?: boolean
  subTitle?: string
}

export const HeaderPage = ({ title, divider, subTitle }: HeaderPageType) => (
  <Box width="100%">
    <Heading as="h1" color="blackText" fontSize="2xl">
      {title}
    </Heading>
    {subTitle && <Text>{subTitle}</Text>}

    {divider && <Divider mt={5} color="#E9E9E9" />}
  </Box>
)
