import { useToast } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import useUserStore from 'stores/user'
import { CachePolicies, Provider } from 'use-http'

type ChildrenType = {
  children: React.ReactNode
}
const TOAST_ID = 'unauthorized'

export const HttpProvider = ({ children }: ChildrenType) => {
  const { accessToken, resetUser } = useUserStore()
  const toast = useToast()

  const options = useMemo(() => {
    return {
      cachePolicy: CachePolicies.NO_CACHE,
      data: null,
      retries: 1,
      timeout: 10000,
      interceptors: {
        request: async ({ options }: any) => {
          if (!options.headers?.authorization) {
            options.headers = {
              ...options.headers,
              authorization: `Bearer ${accessToken}`
            }
            return options
          }
          return options
        },
        response: ({ response }: any) => {
          if (response.status === 401) {
            if (!toast.isActive(TOAST_ID)) {
              toast({
                id: TOAST_ID,
                title: 'Não foi possível realizar esta ação.',
                description: 'Por favor, faça login novamente.',
                status: 'error',
                duration: 4000,
                position: 'top-right',
                variant: 'subtle'
              })
            }

            resetUser()

            return
          }
          return response
        }
      }
    }
  }, [accessToken, resetUser, toast])

  return (
    <Provider url={process.env.REACT_APP_API_URL} options={options}>
      {children}
    </Provider>
  )
}
