/* eslint-disable camelcase */
/* eslint-disable indent */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  IconButton,
  Select,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { useState } from 'react'
import { FiFileText } from 'react-icons/fi'
import useUserStore from 'stores/user'
import useFetch from 'use-http'
import { formatDate } from 'utils/formatDate'
import { JobProps } from './TabJob'
import { CandidateCardForTheJob } from '../Cards/CandidateCardForTheJob'
import { NoDataMessage } from '../NoDataMessage'

const options: { [key: string]: string } = {
  SELECT: 'Selecione',
  IS_ACTIVE: 'Ativa',
  IS_INNACTIVE: 'Inativa'
}

const selectBgOptions: { [key: string]: string } = {
  IS_INNACTIVE: 'negative.100',
  IS_ACTIVE: 'positive.100'
}

const bgHoverOptions: { [key: string]: string } = {
  IS_INNACTIVE: 'negative.200',
  IS_ACTIVE: 'positive.200'
}

const bgFocusOptions: { [key: string]: string } = {
  IS_INNACTIVE: 'negative.100',
  IS_ACTIVE: 'lightgreen'
}

const selectColorOptions: { [key: string]: string } = {
  IS_INNACTIVE: 'negative.500',
  IS_ACTIVE: 'positive.500'
}

export const TabShortList = ({ canEdit, user_conductor, ...job }: JobProps) => {
  const toast = useToast()
  const { id } = useUserStore()
  const [selectedList, setSelectedList] = useState<any>(null)

  const {
    data,
    loading,
    get: refetch
  } = useFetch(Endpoints.jobs.getApprovedShortlist(job?.jobId), {}, [])

  const { put: onUpdateCandidateStatus, response: resUpdateStatus } = useFetch(
    Endpoints.jobs.candidateStatus(data?.id)
  )

  const { put: onUpdateListStatus, response } = useFetch(
    Endpoints.jobs.listStatus(selectedList?.id)
  )

  const handleChange = async (event: any) => {
    const status = event.target.value
    const value = Object.keys(options).find((item) => item === status)

    await onUpdateListStatus({
      customerStatus: value
    })

    if (response.status >= 400) {
      toast({
        title: 'Não foi possível mudar status da lista.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

      throw new Error(response.data.message)
    }

    toast({
      title: 'Sucesso!',
      description: 'Status da lista atualizado.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })

    refetch()
  }

  const handleUpdateStatus = async (candidate: any, status: any) => {
    await onUpdateCandidateStatus({
      candidateId: candidate?.id,
      status
    })

    if (resUpdateStatus.status >= 400) {
      toast({
        title: 'Não foi possível atualizar o status do candidato.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

      throw new Error(resUpdateStatus.data.message)
    }

    toast({
      title: 'Sucesso!',
      description: 'Status do candidato atualizado.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })

    await refetch()
  }

  if (loading) {
    return (
      <Flex flexDir="column" py={8} alignItems="center">
        <Spinner />
      </Flex>
    )
  }

  if (!loading && (!data || !data.length)) {
    return <NoDataMessage />
  }

  return (
    <Flex flexDir="column" px={4}>
      {!!data &&
        data.map((item: any) => (
          <Accordion
            allowToggle
            key={`shortlist_${item?.id}`}
            mt="9"
            width="full"
            onClick={() =>
              setSelectedList(item.id !== selectedList?.id ? item : null)
            }>
            <AccordionItem>
              <h2>
                <AccordionButton gap={6}>
                  <Box flex="1" textAlign="left">
                    <Text noOfLines={1} fontWeight="500">
                      Shortlist {item?.list_number} -{' '}
                      {formatDate(new Date(item?.created_at), "dd'/'MM'/'yyyy")}{' '}
                      - Aprovador: {item?.approved_by?.user?.name}
                    </Text>
                  </Box>

                  <Select
                    isDisabled={id !== user_conductor?.id}
                    bg={
                      // eslint-disable-next-line
                      // prettier-ignore
                      selectBgOptions[item?.job_list_customer_status] ||
                    'transparent'
                    }
                    size="sm"
                    borderRadius="md"
                    w="fit-content"
                    variant="filled"
                    value={item?.job_list_customer_status || 'Selecione'}
                    fontSize="xs"
                    fontWeight="500"
                    color={
                      // eslint-disable-next-line
                      // prettier-ignore
                      selectColorOptions[item?.job_list_customer_status] ||
                    'BlackText'
                    }
                    cursor="pointer"
                    onChange={(e) => handleChange(e)}
                    _focusVisible={{
                      _placeholder: {
                        color: '#000'
                      },
                      bg:
                        bgFocusOptions[item?.job_list_customer_status] ||
                        'transparent',

                      borderColor: 'transparent'
                    }}
                    _hover={{
                      bg:
                        bgHoverOptions[item?.job_list_customer_status] ||
                        'transparent'
                    }}>
                    {Object.keys(options).map((key, i) => (
                      <option key={i} value={key}>
                        {options[key]}
                      </option>
                    ))}
                  </Select>

                  <IconButton
                    isDisabled={!selectedList}
                    p={2}
                    bg="white"
                    borderRadius={6}
                    aria-label="Get PDF"
                    icon={<Icon as={FiFileText} h={6} w={6} color="black" />}
                    onClick={() =>
                      window.open(`/shortlist/${selectedList.id}`, '_blank')
                    }
                  />

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Accordion
                  allowMultiple
                  w="full"
                  pointerEvents={canEdit ? 'auto' : 'none'}>
                  {item?.job_list_candidate
                    .sort(
                      (a: any, b: any) =>
                        a.candidate_position - b.candidate_position
                    )
                    .map((candidate: any, index: number) => (
                      <CandidateCardForTheJob
                        isInShortlist
                        isApprovedShortlist
                        key={candidate?.candidate_id}
                        listId={item?.[0]?.id}
                        jobId={job?.jobId}
                        candidate={candidate?.candidate}
                        candidateStatus={candidate?.candidate_status}
                        position={++index}
                        onUpdateStatus={handleUpdateStatus}
                        refetch={refetch}
                      />
                    ))}
                </Accordion>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ))}
    </Flex>
  )
}
