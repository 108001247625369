import { Text } from '@chakra-ui/react'
import { Tooltip } from 'components/atoms/Tooltip'
import { useState } from 'react'

export const FormTooltip = ({ error, customMessage }: any) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Tooltip
      label={customMessage || 'Obrigatório'}
      placement="top"
      isOpen={isOpen}>
      <Text
        color={error ? 'negative.500' : 'black'}
        _hover={{
          cursor: 'help'
        }}
        onMouseEnter={() => {
          setIsOpen(true)
        }}
        onMouseLeave={() => {
          setIsOpen(false)
        }}>
        *
      </Text>
    </Tooltip>
  )
}
