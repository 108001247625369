import { Box, Divider, Text, useToast } from '@chakra-ui/react'
import { Auth } from 'aws-amplify'
import { createRef, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormClient, FormDataType } from './components/FormClient'
import { SocialLogin } from './components/SocialLogin'
import { HeaderLoginForm } from '../../components/organisms/HeaderLoginForm'
import { LoginTemplate } from '../../components/templates/Login'

export const LoginClient = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const formRef: any = createRef()

  const [loading, setLoading] = useState<boolean>(false)

  const onAuth = useCallback(
    async (email: string, password: string) => {
      setLoading(true)

      try {
        const user = await Auth.signIn(email, password)

        if (user?.attributes?.['custom:first_access'] === '1') {
          navigate('primeiro-acesso', {
            state: {
              email: user.attributes.email,
              name: user.attributes.name,
              password
            }
          })
        } else {
          navigate('/')
        }
      } catch (err) {
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: `Por favor, caso o e-mail esteja correto e
          você não lembre sua senha, recupere sua senha no link ao lado de “Esqueceu a senha?”.`,
          position: 'top-right',
          status: 'warning',
          title: 'E-mail não encontrado e/ou senha incorreta. ',
          variant: 'subtle'
        })
      } finally {
        setLoading(false)
      }
    },
    [navigate, toast]
  )

  return (
    <LoginTemplate>
      <HeaderLoginForm
        title="Bem-vindo!"
        description="Insira seu e-mail para acessar nossa plataforma"
      />
      <FormClient
        formRef={formRef}
        onSubmit={({ email, password }: FormDataType) =>
          onAuth(email, password)
        }
        loading={loading}
      />
      <Box position="relative">
        <Text
          bg="white"
          width={20}
          textAlign="center"
          fontSize="xl"
          paddingLeft={6}
          paddingRight={6}
          position="relative"
          zIndex={1}
          margin="0 auto">
          OU
        </Text>
        <Divider
          style={{
            borderColor: 'black',
            position: 'absolute',
            top: '50%',
            zIndex: 0
          }}
        />
      </Box>
      <SocialLogin />
    </LoginTemplate>
  )
}
