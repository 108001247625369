import {
  Flex,
  HStack,
  IconButton,
  Table as TableChakra,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { ReactNode, useEffect } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useExpanded, usePagination, useTable } from 'react-table'

type TableType = {
  headers: {
    title: string
  }[]
  data: {
    [key: string]: string | ReactNode
  }[]
}

export const Table = ({ headers, data }: TableType) => (
  <TableContainer overflowX="unset" overflowY="unset">
    <TableChakra variant="unstyled" bg="#F9F9F9" w="100%">
      <Thead
        bg="#F9F9F9"
        border="none"
        position="sticky"
        w="100%"
        top={0}
        zIndex="2"
        borderBottom="1px solid #E7E7ED">
        <Tr w="100%">
          {headers.map((header, idx) => (
            <Th
              key={`header_${idx}`}
              textTransform="none"
              border="none"
              color="grayText"
              fontSize={12}
              fontWeight={400}
              py={15}>
              {header.title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody bg="white">
        {data.map((item, idx) => (
          <Tr key={`item_${idx}`}>
            {Object.keys(item).map((value, i) => (
              <Td key={`item_value_${i}`}>{item[value]}</Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </TableChakra>
  </TableContainer>
)

export const TablePagination = ({
  columns,
  total,
  data,
  onChange,
  pagination
}: any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  }: any = useTable(
    {
      columns,
      data,
      pageCount: total,
      manualPagination: true,
      initialState: { pageIndex: 0, pageSize: 12 } as any
    } as any,
    useExpanded,
    usePagination
  )

  useEffect(() => {
    onChange(pageIndex)
  }, [onChange, pageIndex])

  return (
    <Flex direction="column" height="fit-content" bgColor={'grayBackground'}>
      <TableContainer overflowX="unset" overflowY="unset">
        <TableChakra {...getTableProps()} variant="unstyled" bg="#F9F9F9">
          <Thead
            bg="#F9F9F9"
            border="none"
            position="sticky"
            w="100%"
            top={0}
            zIndex="2"
            borderBottom="1px solid #E7E7ED">
            {headerGroups.map((headerGroup: any, idx: number) => (
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                key={`header_${idx}`}
                borderBottomWidth={1}
                borderColor="#E7E7ED">
                {headerGroup.headers.map((column: any, i: number) => {
                  return (
                    <Th
                      {...column.getHeaderProps()}
                      key={`header_column_${i}`}
                      color="grayText"
                      fontSize={12}
                      fontWeight={400}
                      border="none"
                      textTransform="none"
                      py={15}>
                      {column.render('Header')}
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()} bg="white">
            {page.map((row: any, idx: number) => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()} key={`item_${idx}`}>
                  {row.cells.map((cell: any, i: number) => {
                    if (cell.column.Header === 'Status') {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={`item_cell_${i}`}
                          px={2}>
                          {cell.render('Cell')}
                        </Td>
                      )
                    } else {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={`item_cell_${i}`}
                          px={cell.column.Header === '' ? 0 : 6}>
                          {cell.render('Cell')}
                        </Td>
                      )
                    }
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </TableChakra>
      </TableContainer>

      <HStack
        alignItems="center"
        justifyContent="flex-end"
        pr={4}
        my={4}
        spacing={2}>
        <Flex mr={2}>
          <Text fontSize="sm" color="#1B1C1E">{`${
            pageIndex * pagination.take
          } - ${
            pagination.total < pagination.take
              ? pagination.total
              : pageIndex * pagination.take + data.length
          } de ${pagination.total}`}</Text>
        </Flex>
        <Flex>
          <IconButton
            w={7}
            h={7}
            aria-label="Previous Page"
            onClick={previousPage}
            isDisabled={!canPreviousPage}
            variant="outline"
            size="xs"
            borderRadius={4}
            colorScheme="#1B1C1E"
            icon={<FiChevronLeft fontSize="1rem" />}
          />
        </Flex>
        <Flex>
          <IconButton
            w={7}
            h={7}
            aria-label="Next Page"
            onClick={nextPage}
            isDisabled={!canNextPage}
            variant="outline"
            size="xs"
            borderRadius={4}
            colorScheme="#1B1C1E"
            icon={<FiChevronRight fontSize="1rem" />}
          />
        </Flex>
      </HStack>
    </Flex>
  )
}
