import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  InputAddon,
  Input as InputChakra,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner
} from '@chakra-ui/react'
import { FormTooltip } from 'modules/Jobs/components/FormTooltip/FormTooltip'
import { RefCallback, useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Control, Controller } from 'react-hook-form'
import { FiInfo, FiLock } from 'react-icons/fi'
import InputMask from 'react-input-mask'
import { normalizeNumber } from 'theme/normalization'
import { Button } from '../Button'
import { Tooltip } from '../Tooltip'

interface InputType extends InputProps {
  ref?: RefCallback<any>
  label?: string
  disabled?: boolean
  errorMessage?: string
  required?: boolean
  loading?: boolean
  isInvalid?: any
  leading?: string
  isMoney?: boolean
  mask?: string | RegExp
  infoHelp?: string
  control?: Control<any>
  requiredInfo?: boolean
  customTooltipMessage?: string
}

export const Component = ({
  label,
  errorMessage,
  customTooltipMessage,
  loading,
  leading,
  isMoney,
  mask,
  infoHelp,
  ...rest
}: InputType): JSX.Element => {
  const [state, toggleState] = useState<boolean>(false)

  const isError = useMemo(
    () => rest?.isInvalid && errorMessage,
    [errorMessage, rest.isInvalid]
  ) as boolean

  const fixedDecimalScale = true
  const allowNegative = true

  return (
    <FormControl isInvalid={isError} isRequired={rest.required}>
      {!!label && (
        <HStack spacing={0} alignItems="center">
          <FormLabel
            htmlFor={rest.id}
            color={rest.isInvalid ? 'negative.500' : 'black'}>
            {label}
          </FormLabel>
          {infoHelp && (
            <Tooltip label={infoHelp} placement="top-start">
              <Icon as={FiInfo} w={4} h={4} color="#8F8F8F" />
            </Tooltip>
          )}
          {rest.requiredInfo && (
            <FormTooltip
              error={errorMessage}
              customMessage={customTooltipMessage}
            />
          )}
        </HStack>
      )}
      <InputGroup zIndex="50">
        {leading && (
          <InputAddon
            placement="left"
            height={`${normalizeNumber(54)}px`}
            border="none"
            bg="#F5F6FA"
            fontWeight="bold"
            color="black">
            {leading}
          </InputAddon>
        )}
        <InputChakra
          {...rest}
          as={mask ? InputMask : isMoney ? CurrencyFormat : rest.as}
          value={isMoney ? rest.value ?? 0 : rest.value ?? ''}
          fontSize={`${normalizeNumber(18)}px`}
          mask={mask}
          maskChar={null}
          fixedDecimalScale={fixedDecimalScale.toString()}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          py="0"
          allowNegative={allowNegative.toString()}
          prefix=""
          bg="neutralLight.300"
          type={state ? 'text' : rest.type}
          color="black"
          height={`${normalizeNumber(56)}px`}
          errorBorderColor="transparent"
          borderStartRadius={leading ? 'none' : 'md'}
        />
        {rest.type === 'password' && (
          <InputRightElement width={12} height={12}>
            <Button variant="unstyled" onClick={() => toggleState(!state)}>
              {state ? (
                <ViewOffIcon
                  w={6}
                  color={rest.isInvalid ? 'negative.500' : 'black'}
                />
              ) : (
                <ViewIcon
                  w={6}
                  color={rest.isInvalid ? 'negative.500' : 'black'}
                />
              )}
            </Button>
          </InputRightElement>
        )}
        {rest.type !== 'password' && loading && (
          <InputRightElement width={12} height={12}>
            <Spinner color="black" size="sm" />
          </InputRightElement>
        )}
        {rest.isReadOnly && (
          <InputRightElement width={12} height={12}>
            <Icon as={FiLock} color="#8F8F8F" />
          </InputRightElement>
        )}
      </InputGroup>
      {isError && (
        <FormErrorMessage justifyContent="end" color="negative.500">
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export const Input = ({ control, name, ...props }: InputType): JSX.Element =>
  control && name ? (
    <Controller
      {...props}
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field: { ref, value, ...fieldProps }, fieldState }) => (
        <Component
          value={value}
          {...fieldProps}
          {...props}
          isInvalid={fieldState.error}
          errorMessage={fieldState.error?.message}
          customTooltipMessage={props?.customTooltipMessage}
        />
      )}
    />
  ) : (
    <Component {...props} />
  )
