/* eslint-disable indent */
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import useFilters from 'hooks/useFilters'
import useStorage from 'hooks/useStorage'
import { useEffect, useMemo, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { areArraysEmpty } from 'utils/helper'
import { Customer } from './components/Customer'
import { Forecast } from './components/Forecast'
import { Unity } from './components/Unity'
import { UsersYw } from './components/UsersYw'
import { Vertical } from './components/Vertical'

type FilterType = {
  enableInput: boolean
  placeholder?: string
  options?: Array<string>
}

const Filter = ({ enableInput, placeholder, options }: FilterType) => {
  const { get } = useStorage()

  const storagedFilters = useMemo(
    () => get('@storage::jobFilters') ?? '{}',
    [get]
  )

  const { appliedFilters, filters, updateFilter, clearFilter } = useFilters(
    true,
    JSON.parse(storagedFilters) ?? {},
    true
  )

  const [termInput, setTermInput] = useState<string>(filters.term)
  const [focused, setFocused] = useState<boolean | null>(null)

  useEffect(() => {
    setTermInput(filters.term ?? '')
  }, [filters.term])

  return (
    <>
      <Box w="100%">
        <Flex gap="2">
          {enableInput && (
            <InputGroup
              w={focused ? '15rem' : '10rem'}
              display="inline-block"
              transition="all 0.2s ease-in-out">
              <Input
                placeholder={focused ? placeholder : ''}
                variant="filled"
                bg="white"
                size="lg"
                fontSize="sm"
                value={termInput}
                borderColor="neutralLight"
                _focus={{
                  bg: 'white',
                  borderColor: 'neutralLight'
                }}
                onChange={(e) => setTermInput(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onKeyPress={(event) => {
                  if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                    event.preventDefault()
                    updateFilter('term', termInput, true)
                  }
                }}
              />
              <InputRightElement w="2.5rem" h="100%">
                <FiSearch />
              </InputRightElement>
            </InputGroup>
          )}
          {options?.includes('usersYw') && (
            <UsersYw
              total={appliedFilters.users?.length ?? 0}
              onFilter={(key, value) => updateFilter(key, value, true)}
            />
          )}
          {options?.includes('customer') && (
            <Customer
              total={appliedFilters.customers?.length ?? 0}
              onFilter={(key, value) => updateFilter(key, value, true)}
            />
          )}
          {options?.includes('forecast') && (
            <Forecast
              total={appliedFilters.forecast?.length ?? 0}
              onFilter={(key, value) => updateFilter(key, value, true)}
            />
          )}
          {options?.includes('vertical') && (
            <Vertical
              total={appliedFilters.verticals?.length ?? 0}
              onFilter={(key, value) => updateFilter(key, value, true)}
            />
          )}
          {options?.includes('unity') && (
            <Unity
              total={appliedFilters.unity?.length ?? 0}
              onFilter={(key, value) => updateFilter(key, value, true)}
            />
          )}
          {Object.keys(appliedFilters).length > 0 &&
            !areArraysEmpty(appliedFilters) && (
              <Button
                type="button"
                variant="solid"
                size="lg"
                bg="neutralLight.500"
                onClick={() => clearFilter()}>
                Remover filtros
              </Button>
            )}
        </Flex>
      </Box>
    </>
  )
}

export default Filter
