type EnumObject = { [key: string]: string }

export const YWOffices: EnumObject = {
  BELO_HORIZONTE: 'Belo Horizonte',
  RECIFE: 'Recife',
  SAO_PAULO: 'São Paulo'
}

export const AreaTypes: EnumObject = {
  BACK_OFFICE: 'Backoffice',
  MASTER: 'Master',
  RECRUTAMENTO: 'Recrutamento',
  SUPORTE: 'Suporte'
}

export const SubAreaTypes: EnumObject = {
  CS_EXPRESS: 'CS Express',
  RECRUTADOR: 'Recrutador',
  TECH: 'Tech'
}

export const TeamTypes: EnumObject = {
  BACKOFFICE_COMERCIAL: 'Back Office Comercial',
  BACKOFFICE_FINANCEIRO: 'Back Office Financeiro',
  BACKOFFICE_MKT: 'Back Office Mkt',
  BACKOFFICE_PRODUTO: 'Back Office Produto',
  BACKOFFICE_RH: 'Back Office RH',

  CS_EXPRESS_EXEC_ENG: 'CS Express Engenharia',
  CS_EXPRESS_EXEC_MKT_VENDAS: 'CS Express Mkt & Vendas',
  CS_EXPRESS_FA: 'CS Express F&A',
  CS_EXPRESS_TECH: 'CS Express Tech',

  MASTER_ADMIN: 'Master Admin',

  RECRUTADOR_EXEC_ENG: 'Recrutador Engenharia',
  RECRUTADOR_EXEC_FA: 'Recrutador F&A',
  RECRUTADOR_EXEC_MKT_VENDAS: 'Recrutador Mkt & Vendas',
  RECRUTADOR_TECH: 'Recrutador Tech',

  SUPORTE: 'Suporte'
}

export const VerticalTypes: EnumObject = {
  TECH: 'Tech',
  TECH_1: 'Tech',
  EXEC_MKT_VENDAS: 'Mkt & Vendas',
  EXEC_MKT_e_VENDAS: 'Mkt & Vendas',
  EXEC_ENG: 'Engenharia',
  EXEC_FA: 'F&A',
  EXEC_FeA: 'F&A',
  EXPRESS_TECH: 'Express Tech',
  EXPRESS_MKT_VENDAS: 'Express MKT Vendas',
  EXPRESS_ENG: 'Express Eng',
  EEXPRESS_FA: 'Eexpress Fa',

  PRODUTO: 'Produto',
  MARKETING: 'Marketing',
  COMERCIAL: 'Comercial',
  FINANCEIRO: 'Financeiro',
  RH: 'RH',
  MASTER: 'Master',
  SUPORTE: 'Suporte'
}

export const GenderTypes: EnumObject = {
  MALE: 'Masculino',
  FEMALE: 'Feminino',
  INDIFFERENT: 'Outro'
}

export const CivilStateTypes: EnumObject = {
  CASADO: 'Casado(a)',
  SOLTEIRO: 'Solteiro(a)',
  DIVORCIADO: 'Divorciado(a)',
  SEPARADO: 'Separado(a)',
  VIUVO: 'Viúvo(a)'
}

export const JobHoursType: EnumObject = {
  FULL_TIME_40H: '',
  FULL_TIME_44H: '',
  PART_TIME_6H: '',
  PART_TIME_4H: ''
}

export const JobModeType: EnumObject = {
  REMOTE: 'Remoto',
  PRESENTIAL: 'Presencial'
}

export const SalaryCurrencyType: EnumObject = {
  BRL: 'Real',
  USD: 'Dólar'
}

export const SalaryFrequencyType: EnumObject = {
  MONTHLY: 'Mensal',
  YEARLY: 'Anual'
}

export const LanguageSkillLevelType: EnumObject = {
  BASIC: 'Básico',
  INTERMEDIATE: 'Intermediário',
  ADVANCED: 'Avançado',
  FLUENT: 'Fluente/Nativo'
}

export const SenioryTypes: EnumObject = {
  ASSISTENTE: 'Assistente',
  ANALISTA_JUNIOR: 'Analista Junior',
  ANALISTA_PLENO: 'Analista Pleno',
  ANALISTA_SENIOR: 'Analista Senior',
  ESPECIALISTA: 'Especialista',
  SUPERVISOR: 'Supervisor',
  COORDENADOR: 'Coordernador',
  GERENTE: 'Gerente',
  C_LEVEL: 'C Level'
}

export const SuperiorCoursesTypes: EnumObject = {
  DOUTORADO: 'Doutorado',
  SPECIALIZATION: 'Especialização',
  GRADUACAO: 'Graduação',
  MBA: 'MBA',
  MESTRADO: 'Mestrado',
  POS_GRADUACAO: 'Pós-graduação',
  POS_DOUTORADO: 'Pós-doutorado',
  TECNOLOGO: 'Técnico'
}
