/* eslint-disable indent */
import {
  Box,
  Button,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { TablePagination } from 'components/organisms/Table'
import { format } from 'date-fns'
import { InvoiceStatusTypes } from 'enum/form'
import { JobTypes } from 'enum/jobs'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useMemo } from 'react'
import useFetch from 'use-http'
import { formatCurrency } from 'utils/formatCurrency'
import { formatYellowProducts } from 'utils/formatYellowProduct'

type TableOpeningType = {
  data: any
  total: number
  onChange: (_page: number) => void
  pagination: {
    take: number
    total: number
  }
  refresh: any
}

export const averageRange = (
  min: number,
  max: number,
  hasVariable: boolean,
  value: number
) => {
  if (min && max) {
    return (
      <>
        <Text fontSize="sm">
          De <strong>{formatCurrency(min)} </strong>
          até <strong>{formatCurrency(max)}</strong>
        </Text>
        <br />
        <Text fontSize="sm">
          Média: <strong>{formatCurrency((min + max) / 2)}</strong>
        </Text>
        {hasVariable && (
          <>
            <br />
            <Text fontSize="sm">
              Variável: <strong>{formatCurrency(value)}</strong>
            </Text>
          </>
        )}
      </>
    )
  }

  return '–'
}

export const TableOpening = ({
  data,
  total,
  onChange,
  pagination,
  refresh
}: TableOpeningType) => {
  const onError = useErrorToast()
  const toast = useToast()

  const { put: updateForm, response: responseForm, loading } = useFetch()

  const { data: yellowProducts } = useFetch(
    Endpoints.lists.yellowProduct,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os Produtos Yellow.'
        }),
      interceptors: { response: formatYellowProducts }
    },
    []
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Cód. Vaga',
        accessor: 'job_code'
      },
      {
        Header: 'Vaga',
        accessor: 'job_title'
      },
      {
        Header: 'Cliente',
        accessor: 'customer'
      },
      {
        Header: 'Produto',
        accessor: 'product'
      },
      {
        Header: 'Kickoff',
        accessor: 'kickoff'
      },
      {
        Header: 'Retainer',
        accessor: 'retainer'
      },
      {
        Header: 'Salário de abertura',
        accessor: 'salaryOnOpen'
      },
      {
        Header: 'Contratação',
        accessor: 'hiring_model'
      },
      {
        Header: 'Condutor',
        accessor: 'conductor'
      },
      {
        Header: 'Qualificador',
        accessor: 'qualifier'
      },
      {
        Header: 'Sigilosa',
        accessor: 'secret'
      },
      {
        Header: 'Observação',
        accessor: 'obs'
      },
      {
        Header: 'Link',
        accessor: 'link'
      },
      {
        Header: 'Insumos',
        accessor: 'inputs'
      }
    ],
    []
  )

  const handleStatus = async (status: string, formId: string) => {
    await updateForm(Endpoints.forms.update(formId), {
      status: status === '' ? null : status
    })

    if (responseForm.status >= 400) {
      onError({
        title: 'Não foi possível atualizar o form.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Form atualizado com sucesso.',
      variant: 'subtle'
    })

    refresh()
  }

  return (
    <Fragment>
      <TablePagination
        total={total}
        columns={columns}
        onChange={onChange}
        pagination={pagination}
        data={data?.map((form: any) => ({
          id: form.id,
          status: (
            <Box w="150px">
              <Select
                placeholder="Selecione"
                bg="neutralLight.400"
                p="0"
                ml="4"
                size="sm"
                w="fit-content"
                variant="filled"
                // defaultValue={form.status}
                onChange={(e) => {
                  handleStatus(e.target.value, form.id)
                }}
                value={form?.status}
                fontSize="xs"
                borderRadius="md"
                color="BlackText"
                disabled={loading}
                _focusVisible={{
                  _placeholder: {
                    color: 'black'
                  },
                  bg: 'neutralLight.400',
                  borderColor: 'transparent'
                }}
                _hover={{
                  bg: 'neutralLight.400'
                }}>
                {Object.keys(InvoiceStatusTypes)
                  .filter((s) => s !== 'DECLINED')
                  .map((key) => (
                    <option key={`invoice_${key}`} value={key}>
                      {InvoiceStatusTypes[key]}
                    </option>
                  ))}
              </Select>
            </Box>
          ),
          job_code: <Text fontSize="sm">{form.job.job_code || '–'}</Text>,
          job_title: <Text fontSize="sm">{form.job.title}</Text>,
          customer: <Text fontSize="sm">{form.job.customer?.name ?? '–'}</Text>,
          product: (
            <Text fontSize="sm">
              {yellowProducts
                ? yellowProducts[form.job?.yellow_product_id]
                : '–'}
            </Text>
          ),
          kickoff: (
            <Text fontSize="sm">
              {format(new Date(form.job.kickoff_date), 'dd/MM/yyyy')}
            </Text>
          ),
          retainer: (
            <Text fontSize="sm">{form?.job?.retainer ? 'Sim' : 'Não'}</Text>
          ),
          /* salary: (
            <Text fontSize="sm">
              {averageRange(
                form.job.min_salary_range,
                form.job.max_salary_range,
                form.job.has_salary_variable,
                form.job.has_salary_variable_value
              )}
            </Text>
          ), */
          salaryOnOpen: (
            <Text fontSize="sm">
              {formatCurrency(form?.job?.salaryOnOpen) || '-'}
            </Text>
          ),
          hiring_model: (
            <Text fontSize="sm">
              {form.job.contract_type ? JobTypes[form.job.contract_type] : '–'}
            </Text>
          ),
          conductor: (
            <Text fontSize="sm">
              {form.job.user_conductor?.user?.name ?? '–'}
            </Text>
          ),
          qualifier: (
            <Text fontSize="sm">
              {form.job.users_qualifier?.length
                ? form.job.users_qualifier
                    .map(({ qualifier }: any) => qualifier.user.name)
                    .join(', ')
                : '–'}
            </Text>
          ),
          secret: (
            <Text fontSize="sm">
              {' '}
              {
                <>
                  <Text fontSize="sm">
                    {!form.job.is_secret ? 'Não' : form.job.email_is_secret}
                  </Text>
                </>
              }
            </Text>
          ),
          obs: (
            <Text fontSize="sm">
              {' '}
              {form.observation ? (
                <>
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        variant="unstyled"
                        fontWeight="normal"
                        _hover={{
                          textDecoration: 'underline'
                        }}>
                        Visualizar
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader px="5" py="3">
                        Observação forms de abertura
                      </PopoverHeader>
                      <PopoverBody p="5">
                        <Text whiteSpace="normal" lineHeight="1.2">
                          {form?.observation || '-'}
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </>
              ) : (
                '–'
              )}
            </Text>
          ),
          link: (
            <Text fontSize="sm">
              {' '}
              {form.job.linkToPublish ? (
                <Link
                  _hover={{
                    textDecoration: 'underline'
                  }}
                  target="_blank"
                  href={form.job.linkToPublish}>
                  Abrir link
                </Link>
              ) : (
                '–'
              )}
            </Text>
          ),
          inputs: (
            <Text fontSize="sm">
              {' '}
              {form.job.linkedinInputs ? (
                <>
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        variant="unstyled"
                        fontWeight="normal"
                        _hover={{
                          textDecoration: 'underline'
                        }}>
                        Visualizar
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader px="5" py="3">
                        Insumos para vaga
                      </PopoverHeader>
                      <PopoverBody p="5">
                        <Text whiteSpace="normal" lineHeight="1.2">
                          {form.job.linkedinInputs}
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </>
              ) : (
                '–'
              )}
            </Text>
          )
        }))}
      />
    </Fragment>
  )
}
