/* eslint-disable camelcase */
import { Box, Divider, Flex, HStack, Heading } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Checkbox } from 'components/atoms/Checkbox'
import { Input } from 'components/atoms/Input'
import { Select } from 'components/atoms/Select'
import { BrazilianStates } from 'data/userProfile'
import { useErrorToast } from 'hooks/useErroToast'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useFetch from 'use-http'
import { formatArrayObjectResponse } from 'utils/formatObjectValuesResponse'
import * as yup from 'yup'
import { Menu } from './../../../components/atoms/Menu'
import { ClientAnnualBilling, employes } from './../../../data/yellowInfo'
import { capitalizeNames } from '../../../utils/strings'

import { ClientType } from '../types/clients'

type FormType = {
  onSubmit: (data: any) => void
  clientUuid?: string
  loading?: boolean
  values: any
}

type UserYw = { id: string; user: { name: string } }

const formatUserYWforSelect = async ({ response }: any) => {
  if (response.data)
    response.data = response.data.data.map((u: any) => ({
      label: u.user.name,
      value: u.id
    }))
  return response
}

// const formatResponseStates = async ({ response }: any) => {
//   const res = response

//   res.data = res.data.sort((a: any, b: any) =>
//     Intl.Collator('pt-BR').compare(a.nome, b.nome)
//   )

//   return res
// }

export const Form = ({ onSubmit, loading, values }: FormType) => {
  const [isInternational, setIsInternational] = useState(false)
  const onError = useErrorToast()

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        // cnpj: yup.string().required('Campo obrigatório').default(''),
        // business_name: yup.string().required('Campo obrigatório'),
        name: yup.string().required('Campo obrigatório'),
        /* kams: yup
          .array()
          .min(1, 'Campo obrigatório')
          .required('Campo obrigatório'),
        annual_billing: yup.string().nullable(),
        cadastral_situation: yup.string().nullable(),
        number_employees: yup.string().nullable(), */
        is_active: yup.string().required('Campo obrigatório')
        /* ...(!isInternational && {
          contact_phone: yup
            .string()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório'),
          contact_email: yup
            .string()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório'),
          contact_name: yup
            .string()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório'),
          country: yup
            .string()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório')
            .default('Brasil'),
          state: yup
            .string()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório'),
          city: yup
            .string()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório')
        }) */
      }),
    [isInternational]
  )

  const {
    control,
    formState: { errors, isValid },
    setValue,
    watch,
    handleSubmit
  } = useForm<ClientType>({
    defaultValues: values,
    mode: 'onChange',
    resolver: yupResolver(formSchema)
  })

  const { data: userYW, loading: loadinguserYW } = useFetch(
    Endpoints.users.findAllForKAM,
    {
      interceptors: {
        response: formatUserYWforSelect
      }
    },
    []
  )

  const { data: businessAreas, loading: loadingBusinessAreas } = useFetch(
    Endpoints.customers.businessAreas,
    {
      interceptors: {
        response: formatArrayObjectResponse
      },
      onError: () =>
        onError({
          title: 'Erro ao carregar Mercado de Atuação.',
          description: 'Por favor, tente novamente.'
        })
    },
    []
  )

  const {
    get: getCnpjData,
    loading: cnpjLoading,
    response,
    error
  } = useFetch({
    onError: () =>
      onError({
        title: 'Cnpj não encontrado ou invalido.',
        description: 'Por favor, tente novamente.'
      })
  })

  // const { data: states } = useFetch(
  //   Endpoints.lists.states,
  //   {
  //     onError: () =>
  //       onError({
  //         title: 'Não foi possível carregar a lista de estados.'
  //       }),
  //     interceptors: {
  //       response: formatResponseStates
  //     }
  //   },
  //   []
  // )

  const listStates = BrazilianStates.map((s) => ({
    nome: s.label,
    sigla: s.value
  }))

  const { data: cities, loading: loadingCities } = useFetch(
    Endpoints.lists.citiesByState(watch('state')),
    { retries: 0 },
    [watch('state')]
  )

  const handleCnpj = async (cnpj: string) => {
    setValue('cnpj', cnpj)
    if (cnpj.length < 14) return

    await getCnpjData(Endpoints.external.cpbj(cnpj))
    if (response.ok) {
      setValue('name', response.data.fantasyName)
      setValue('business_name', response.data.corporateName)
      setValue('cadastral_situation', response.data.situation)
      setValue('state', response.data.state)
      setValue('city', response.data.city)
    }
  }

  const userYwFromKam = useMemo(() => {
    if (!userYW) return []

    const kamsUserYW = values?.kams?.map(
      ({ user_yw }: { user_yw: UserYw }) => ({
        id: user_yw.id,
        name: user_yw.user.name
      })
    )

    const findKamsUserYW = ({ value }: { value: string }) =>
      kamsUserYW?.find((userYw: any) => userYw.id === value)

    return userYW.filter((user: any) => !!findKamsUserYW(user) && user)
  }, [values?.kams, userYW])

  return (
    <form
      onSubmit={(e) =>
        handleSubmit(onSubmit)(e).catch((err) => console.error({ err }))
      }>
      <Box bg="white" flex={1} borderRadius="lg" p={6}>
        <Flex
          alignItems="flex-start"
          flexDir="column"
          py="6"
          px="8"
          width="100%"
          gap="8">
          <HStack width="100%" spacing={6} alignItems="flex-start">
            <Box width="60%">
              <Controller
                control={control}
                name="cnpj"
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    {...props}
                    onChange={(e) =>
                      handleCnpj(e.target.value?.replace(/[^\w ]/g, ''))
                    }
                    type="tel"
                    label="CNPJ"
                    placeholder="Digite aqui"
                    variant="filled"
                    id="documentNumber"
                    tabIndex={0}
                    value={value || ''}
                    isInvalid={errors?.cnpj || error}
                    errorMessage={errors?.cnpj?.message}
                    disabled={cnpjLoading || loading}
                    loading={cnpjLoading}
                    mask="**.***.***/****-**"
                  />
                )}
              />
              <Box my={4}>
                <Controller
                  control={control}
                  name="international"
                  render={({ field: { ref, value, ...props } }) => (
                    <Checkbox
                      {...props}
                      label="Empresa internacional"
                      isChecked={value}
                      onChange={(e) => {
                        setValue('international', e.target.checked)
                        setIsInternational((prevState) => !prevState)
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box width="100%">
              <Controller
                control={control}
                name="business_name"
                rules={{ required: true }}
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    {...props}
                    isReadOnly={!watch('international')}
                    type="text"
                    label="Razão Social"
                    placeholder="Digite aqui"
                    variant="filled"
                    id="business_name"
                    value={value}
                    isInvalid={errors?.business_name}
                    errorMessage={errors?.business_name?.message}
                    disabled={loading}
                  />
                )}
              />
            </Box>
            <Box width="80%">
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    {...props}
                    type="text"
                    label="Nome fantasia"
                    placeholder="Digite aqui"
                    variant="filled"
                    requiredInfo={true}
                    id="name"
                    value={value}
                    isInvalid={errors?.name}
                    errorMessage={errors?.name?.message}
                    disabled={loading}
                  />
                )}
              />
            </Box>
          </HStack>

          <HStack
            flex="3"
            width="100%"
            alignItems="initial"
            spacing={6}
            mt={0}
            pt={0}>
            <Controller
              control={control}
              name="cadastral_situation"
              rules={{ required: true }}
              render={({ field: { ref, value, ...props } }) => (
                <Input
                  {...props}
                  isReadOnly
                  type="text"
                  label="Situação cadastral"
                  placeholder="Digite aqui"
                  variant="filled"
                  id="cadastralSituation"
                  value={value}
                  isInvalid={errors?.cadastral_situation}
                  errorMessage={errors?.cadastral_situation?.message}
                  disabled={loading}
                />
              )}
            />

            <Controller
              control={control}
              name="country"
              render={({ field: { ref, ...props } }) => (
                <Menu
                  {...props}
                  label="País"
                  options={[
                    {
                      value: 'Brasil',
                      label: 'Brasil'
                    }
                  ]}
                  defaultValue={!isInternational ? 'Brasil' : undefined}
                  errorMessage={errors?.country?.message}
                />
              )}
            />
            <Select
              label="Estado"
              placeholder="Selecione"
              variant="filled"
              control={control}
              name="state">
              {listStates?.length &&
                listStates?.map((s: { nome: string; sigla: string }) => (
                  <option key={`state_${s?.nome}`} value={s?.sigla}>
                    {capitalizeNames(s?.nome)}
                  </option>
                ))}
            </Select>

            <Select
              label="Cidade"
              placeholder="Selecione"
              variant="filled"
              control={control}
              name="city"
              disabled={!watch('state') || loadingCities}>
              {cities?.length &&
                !loadingCities &&
                cities?.map((city: { nome: string }) => (
                  <option
                    key={`city_${city?.nome}`}
                    value={capitalizeNames(city?.nome)}>
                    {capitalizeNames(city?.nome)}
                  </option>
                ))}
            </Select>
          </HStack>

          <HStack
            flex="3"
            width="100%"
            alignItems="initial"
            spacing={6}
            mt={0}
            pt={0}>
            <Box width="100%" zIndex={200}>
              <Controller
                control={control}
                name="business_area"
                rules={{ required: true }}
                render={({ field: { ref, ...props } }) => (
                  <Menu
                    {...props}
                    options={businessAreas}
                    label="Mercado de atuação"
                    disabled={loadingBusinessAreas || loading}
                  />
                )}
              />
            </Box>
            <Box width="100%" zIndex={200}>
              <Controller
                control={control}
                name="number_employees"
                rules={{ required: true }}
                render={({ field: { ref, ...props } }) => (
                  <Menu
                    {...props}
                    options={employes}
                    label="Número de funcionários"
                  />
                )}
              />
            </Box>
            <Box width="100%" zIndex={200}>
              <Controller
                control={control}
                name="annual_billing"
                rules={{ required: true }}
                render={({ field: { ref, ...props } }) => (
                  <Menu
                    {...props}
                    label="Faturamento último ano"
                    options={ClientAnnualBilling}
                    /*  isInvalid={errors?.annual_billing}
                    errorMessage={errors?.annual_billing?.message} */
                    disabled={loading}
                  />
                )}
              />
            </Box>
            <Box width="100%">
              <Controller
                control={control}
                name="is_active"
                rules={{ required: true }}
                render={({ field: { ref, ...props } }) => (
                  <Menu
                    {...props}
                    options={[
                      { value: true, label: 'Ativa' },
                      { value: false, label: 'Inativa' }
                    ]}
                    label="Status"
                    requiredInfo={true}
                    errorMessage={errors?.is_active?.message}
                    disabled={loading}
                  />
                )}
              />
            </Box>
          </HStack>
          <Divider color="#E9E9E9" my={4} />

          {!loadinguserYW && userYW && (
            <Controller
              control={control}
              name="kams"
              rules={{ required: true }}
              render={({ field: { ref, value, ...props } }) => {
                return (
                  <Select
                    {...props}
                    isMulti
                    id="kams"
                    label="KAM"
                    options={userYW}
                    value={userYwFromKam}
                    onChange={(values: any) => setValue('kams', values)}
                    isInvalid={errors?.kams}
                    errorMessage={errors?.kams?.message}
                  />
                )
              }}
            />
          )}

          <Divider color="#E9E9E9" my={4} />
          <Heading as="h2" fontSize="lg" fontWeight="semibold">
            Contato da Empresa
          </Heading>
          <HStack width="100%" spacing={6}>
            <Box width="100%">
              <Controller
                control={control}
                name="contact_name"
                rules={{ required: true }}
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    {...props}
                    type="text"
                    label="Nome"
                    placeholder="Digite aqui"
                    variant="filled"
                    id="contactName"
                    value={value ?? ''}
                    isInvalid={errors?.contact_name}
                    errorMessage={errors?.contact_name?.message}
                    disabled={loading}
                  />
                )}
              />
            </Box>
            <Box width="100%">
              <Controller
                control={control}
                name="contact_email"
                rules={{ required: true }}
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    {...props}
                    type="email"
                    label="E-mail"
                    placeholder="Digite aqui"
                    variant="filled"
                    id="contactEmail"
                    value={value ?? ''}
                    isInvalid={errors?.contact_email}
                    errorMessage={errors?.contact_email?.message}
                    disabled={loading}
                  />
                )}
              />
            </Box>
            <Box width="100%">
              <Controller
                control={control}
                name="contact_phone"
                rules={{ required: true }}
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    {...props}
                    type="tel"
                    label="Telefone celular"
                    placeholder="Digite aqui"
                    variant="filled"
                    id="contactPhone"
                    value={value ?? ''}
                    isInvalid={errors?.contact_email}
                    errorMessage={errors?.contact_phone?.message}
                    disabled={loading}
                  />
                )}
              />
            </Box>
          </HStack>
        </Flex>
      </Box>
      <Box width="100%" textAlign="right" mt={8}>
        <Button
          type="submit"
          disabled={values ? !values : !isValid || loading}
          loading={loading}
          variant="solid"
          width={230}
          borderRadius="md"
          colorScheme="brand">
          Salvar e fechar
        </Button>
      </Box>
    </form>
  )
}
