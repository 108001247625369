export const formatCurrency = (number: number) => {
  const currencyFormated = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(number)

  return number > 0 ? currencyFormated : '-'
}

export const reverseFormatNumber = (val: string, locale: string) => {
  const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '')
  const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '')
  let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '')
  reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')
  return Number.isNaN(reversedVal) ? 0 : reversedVal
}

export const jobCurrencyFormatter = (value: number | string) => {
  const stringfyValue = value.toString()
  if (stringfyValue?.includes(',')) {
    return parseFloat(
      stringfyValue?.replace('.', '').replace(',', '.')
    ).toFixed(2)
  }
  return parseFloat(stringfyValue)
}
