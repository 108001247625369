/* eslint-disable indent */
/* eslint-disable camelcase */
import { Box, Spinner, VStack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Endpoints } from 'api/endpoints'
import { Breadcrumb } from 'components/atoms/Breadcrumb'
import { Button } from 'components/atoms/Button'
import { Tabs } from 'components/atoms/Tabs'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { VerticalTypes } from 'enum/user'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useFetch } from 'use-http'
import { transformDate, transformDatePayload } from 'utils/formatDate'
import { orderBy } from 'utils/orderBy'
import { Applications } from './components/Applications'
// import { Observations } from './components/Observations'
import { Profile } from './components/Profile'
import { Resume } from './components/Resume'
import { formSchema } from './CreateCandidate'
import { Candidate } from './types'

export const EditCandidate = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [tabIndex, setTabIndex] = useState<number>(0)

  const { uuid }: any = useParams()
  const { state, search }: any = useLocation()
  const query = new URLSearchParams(search)

  const {
    loading,
    data: candidate,
    response,
    get,
    put
  } = useFetch(Endpoints.usersCandidate.userById(uuid))

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(formSchema)
  })

  const onSubmit = useCallback(
    async (dataForm: any) => {
      const {
        id,
        last_qualifier,
        last_qualifier_id,
        candidate_hard_skills,
        ...restDataForm
      } = dataForm
      await put({
        ...restDataForm,
        available_change_city: JSON.parse(dataForm?.available_change_city),
        available_home_office: JSON.parse(dataForm?.available_home_office),
        available_travel: JSON.parse(dataForm?.available_travel),
        birth_date: dataForm?.birth_date ?? null,
        last_qualifier_at: dataForm?.last_qualifier_at ?? null,
        ...(last_qualifier_id && {
          last_qualifier: last_qualifier_id
        }),
        salary_expectation:
          typeof dataForm?.salary_expectation === 'string'
            ? parseFloat(
                dataForm.salary_expectation
                  ?.toString()
                  .replace(/\./g, '')
                  ?.replace(',', '.')
              ).toFixed(2)
            : dataForm.salary_expectation,
        cpf: dataForm?.cpf?.replace(/\D/g, ''),
        phone: dataForm?.phone?.replace(/\D/g, ''),
        ...(dataForm?.verticals && {
          verticals: dataForm.verticals.map(({ value }: { value: string }) =>
            value === 'TECH' ? 'TECH_1' : value
          )
        }),
        ...(dataForm?.professional_experiences && {
          professional_experiences: dataForm.professional_experiences.map(
            ({
              candidate_id,
              professional_experience_id,
              benefits,
              // company,
              ...experiences
            }: Candidate['professional_experiences']) => ({
              ...experiences,
              // company_id: company?.value,
              benefits: benefits?.map((b) => ({
                benefit_id: b.benefit_id,
                value: b.value
              })),
              started_at: experiences?.started_at
                ? new Date(transformDatePayload(experiences.started_at))
                : null,
              end_at: experiences?.end_at
                ? new Date(transformDatePayload(experiences.end_at))
                : null,
              salary: experiences.salary
                ? parseFloat(
                    experiences.salary
                      .toString()
                      .replace(/\./g, '')
                      ?.replace(',', '.')
                  ).toFixed(2)
                : null
            })
          )
        }),
        ...(dataForm?.superior_courses && {
          superior_courses: dataForm.superior_courses.map(
            ({
              candidate_id,
              superior_course,
              university,
              university_ref,
              superiorCourse_ref,
              ...superiorCourse
            }: Candidate['superior_courses']) => ({
              ...superiorCourse,
              university_id: university_ref?.value,
              superior_course_id: superiorCourse_ref?.value,
              start_date: superiorCourse?.start_date
                ? new Date(transformDatePayload(superiorCourse.start_date))
                : null,
              end_date: superiorCourse?.end_date
                ? new Date(transformDatePayload(superiorCourse.end_date))
                : null
            })
          )
        }),
        ...(dataForm?.candidate_technical_or_complementary_courses && {
          candidate_technical_or_complementary_courses:
            dataForm.candidate_technical_or_complementary_courses.map(
              ({
                candidate_id,
                complementary_course_id,
                complementary_course,
                ...complementaryCourse
              }: Candidate['candidate_technical_or_complementary_courses']) => ({
                ...complementaryCourse,
                complementary_course:
                  complementary_course?.title ?? complementary_course ?? ''
              })
            )
        }),
        ...(dataForm?.languages && {
          languages: dataForm.languages.map(
            ({
              language_id,
              level
            }: {
              language_id: string
              level: string
            }) => ({
              language_id,
              level
            })
          )
        }),
        ...(candidate_hard_skills && {
          candidate_hard_skills: candidate_hard_skills.map(
            ({ id, value }: { id: string; value?: string }) => id ?? value
          )
        })
      })

      if (![200, 201].includes(response.status)) {
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description:
            'Não foi possível editar o candidato. Por favor, tente novamente.',
          position: 'top-right',
          status: 'warning',
          title: response.status === 500 ? 'Erro interno' : 'Erro',
          variant: 'subtle'
        })

        return
      }

      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: 'Candidato editado com sucesso.',
        position: 'top-right',
        status: 'success',
        title: 'Sucesso',
        variant: 'subtle'
      })

      if (query.get('returnUrl')) {
        navigate(query.get('returnUrl') as string)
      } else {
        navigate('/candidatos')
      }
    },
    [navigate, put, query, response.status, toast]
  )

  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (response.status === 200 && candidate && !loading) {
      methods.reset({
        ...candidate,
        available_travel: candidate?.available_travel?.toString(),
        available_change_city: candidate?.available_change_city?.toString(),
        available_home_office: candidate?.available_home_office?.toString(),
        verticals: candidate?.verticals?.map(({ vertical }: any) => ({
          label: VerticalTypes[vertical],
          value: vertical
        })),
        last_qualifier_at: candidate?.last_qualifier_at
          ? transformDate(candidate.last_qualifier_at?.split('T')?.[0])
          : null,
        birth_date: candidate?.birth_date
          ? transformDate(candidate.birth_date?.split('T')?.[0])
          : null,
        professional_experiences: orderBy(
          candidate?.professional_experiences?.map(
            ({ company, ...experience }: any) => ({
              ...experience,
              // company: {
              //   label: company.company_name,
              //   value: company.id
              // },
              benefits: experience?.benefits?.map((benefit: any) => ({
                benefit_id: benefit.benefit_id,
                value: benefit.value
              })),
              started_at: experience?.started_at
                ? transformDate(experience.started_at?.split('T')?.[0])
                : null,
              end_at: experience?.end_at
                ? transformDate(experience.end_at?.split('T')?.[0])
                : null
            })
          ),
          'desc'
        ),
        superior_courses: candidate?.superior_courses?.map(
          (superiorCourse: any) => ({
            ...superiorCourse,
            university_ref: {
              label: superiorCourse.university.name,
              value: superiorCourse.university.id
            },
            superiorCourse_ref: {
              label: superiorCourse.superior_course.name,
              value: superiorCourse.superior_course.id
            },
            start_date: superiorCourse?.start_date
              ? transformDate(superiorCourse.start_date?.split('T')?.[0])
              : null,
            end_date: superiorCourse?.end_date
              ? transformDate(superiorCourse.end_date?.split('T')?.[0])
              : null
          })
        ),
        candidate_technical_or_complementary_courses:
          candidate?.candidate_technical_or_complementary_courses?.map(
            (complementaryCourse: any) => ({
              ...complementaryCourse,
              complementary_course:
                complementaryCourse?.complementary_course?.title ?? ''
            })
          ),
        candidate_hard_skills: candidate?.candidate_hard_skills?.map(
          ({
            hardskill: { id, name }
          }: {
            hardskill: { id: string; name: string }
          }) => ({
            label: name,
            value: id
          })
        )
      })
    }
  }, [candidate, methods, loading, response.status])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <VStack spacing={2} alignItems="flex-start">
          <HeaderPage title="Gestão de candidatos" />
          <Breadcrumb
            menus={[
              {
                current: false,
                slug: '/candidatos',
                title: 'Candidatos'
              },
              {
                current: true,
                slug: `/candidatos/editar/${uuid}`,
                title:
                  candidate?.name ??
                  query?.get?.('name') ??
                  state?.candidateProps?.name ??
                  ''
              }
            ]}
          />
        </VStack>
        <Box mt={9}>
          {loading && !candidate ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center">
              <Spinner color="brand.500" />
            </Box>
          ) : (
            <Tabs
              onChange={setTabIndex}
              tabs={[
                {
                  content: <Profile loading={loading} data={candidate} />,
                  title: 'Perfil'
                },
                {
                  content: <Resume />,
                  title: 'Currículo'
                },
                {
                  content: <Applications candidateId={uuid} />,
                  title: 'Candidaturas'
                }
                /* {
                  content: <Observations values={candidate} />,
                  title: 'Observações'
                } */
              ]}
            />
          )}
        </Box>
        {tabIndex !== 2 && (
          <Box my={6}>
            <VStack
              spacing={6}
              pb={6}
              align={{ base: 'center', md: 'flex-end' }}>
              <Button
                loading={loading}
                type="submit"
                width={230}
                variant="solid"
                borderRadius="md"
                colorScheme="brand">
                Salvar e fechar
              </Button>
            </VStack>
          </Box>
        )}
      </form>
    </FormProvider>
  )
}
