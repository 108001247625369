import {
  Flex,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanels,
  TabPanelsProps,
  Tabs as TabsChakra,
  Text
} from '@chakra-ui/react'
import { ReactNode } from 'react'

interface TabsType {
  tabs: {
    title: string
    content: ReactNode
    disabled?: boolean
  }[]
  tabListProps?: TabListProps
  tabPanelsProps?: TabPanelsProps
  index?: number
  defaultIndex?: number
  onChange?: (index: number) => void
  selectType?: string
}

const spreadSelectedStyles = {
  w: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  m: '0',
  whiteSpace: 'nowrap',
  fontSize: 'md'
}

export const Tabs = ({ tabs, selectType, ...rest }: TabsType) => (
  <TabsChakra isLazy {...rest} maxH="100%">
    <TabList {...rest.tabListProps} px="8" h="5%">
      {tabs.map((tab, idx) => (
        <Tab
          key={`tab_${idx}`}
          isDisabled={tab.disabled}
          sx={selectType === 'spread' ? spreadSelectedStyles : {}}>
          {tab.title}
        </Tab>
      ))}
    </TabList>
    <TabPanels {...rest.tabPanelsProps} px="7" maxH="100%">
      {tabs.map((tab, idx) => (
        <TabPanel key={`tab_content_${idx}`} px={4} maxH="100%">
          {tab.content}
        </TabPanel>
      ))}
    </TabPanels>
  </TabsChakra>
)
