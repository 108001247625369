export const getFirstName = (value: string) => value && value.split(' ')[0]

export const queryStringParams = (params: {
  [key: string]: string | number
}): string =>
  `?${Object.keys(params)
    .map(
      (key) => params[key] !== '' && `${key}=${encodeURIComponent(params[key])}`
    )
    .filter((k) => k)
    .join('&')}`

export const additionalQueryParams = (
  propName: string,
  params: any
): string => {
  const queryAddition = `&${propName}=` + encodeURIComponent(params)
  return queryAddition
}

export const capitalizeNames = (string: string): string =>
  string
    .toLowerCase()
    .split(' ')
    .map((word) => {
      if (
        word === 'das' ||
        word === 'dos' ||
        word === 'des' ||
        word.length < 3
      ) {
        return word
      }

      return word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')

export const maskedText = (value: string, mask: string) => {
  let i = 0
  return mask.replace(/#/g, () => value[i++] || '')
}
