import { Endpoints } from '../../api/endpoints'
import { FiltersOptionType } from '../../components/organisms/Filters'

type Props = {
  state?: string
}
export const filterOptions = ({ state = '' }: Props): FiltersOptionType[] => [
  {
    button: 'Status',
    filters: {
      field: 'qualified',
      filterType: 'checkbox',
      disableSearch: true,
      filtersOptions: [
        { label: 'Qualificado', value: 'true' },
        { label: 'Não Qualificado', value: 'false' }
      ]
    }
  },
  {
    button: 'Qualificador',
    filters: {
      field: 'last_qualifier_id',
      filterType: 'checkbox',
      endpoint: `${Endpoints.users.findAll({
        page: 0,
        take: 1000
      })}&accessCategory=RECRUTAMENTO`,
      endpointFields: ['user.name', 'id'],
      searchField: 'name'
    }
  },
  {
    button: 'Vertical',
    filters: {
      field: 'verticals',
      filterType: 'checkbox',
      filtersOptions: [
        {
          label: 'Tech',
          value: 'TECH_1'
        },
        {
          label: 'Engenharia',
          value: 'EXEC_ENG'
        },
        {
          label: 'F&A',
          value: 'EXEC_FeA'
        },
        {
          label: 'Mkt & Vendas',
          value: 'EXEC_MKT_e_VENDAS'
        }
      ]
    }
  },
  {
    button: 'Estado',
    filters: {
      field: 'state',
      filterType: 'checkbox',
      endpoint: Endpoints.lists.states,
      endpointFields: ['nome', 'sigla'],
      searchField: 'nome'
    }
  },
  {
    disabled: !state,
    button: 'Cidade',
    filters: {
      field: 'city',
      filterType: 'checkbox',
      endpoint: state && Endpoints.lists.citiesByState(state),
      endpointFields: ['nome', 'nome'],
      searchField: 'nome'
    }
  },
  {
    button: 'Nome',
    filters: {
      field: 'name',
      filterType: 'input'
    }
  },
  {
    button: 'Empresa',
    filters: {
      field: 'company_name',
      filterType: 'input'
    }
  }
]
