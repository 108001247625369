/* eslint-disable indent */
import { useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useFetch from 'use-http'
import { Form } from './components/Form'

const updateSubArea = (currentSubarea: any, newArea: any, userId: string) => ({
  update: {
    where: {
      subarea_id_user_yw_id: {
        user_yw_id: userId,
        subarea_id: currentSubarea.id
      }
    },
    data: {
      subarea_id: newArea.id
    }
  }
})

const createNewSubarea = (newArea: any, userId: string) => ({
  connectOrCreate: {
    where: {
      subarea_id_user_yw_id: {
        user_yw_id: userId,
        subarea_id: newArea?.id
      }
    },
    create: {
      subarea_id: newArea?.id
    }
  }
})

const handleSubArea = (currentSubarea: any, newArea: any, userId: string) => {
  if (currentSubarea) return updateSubArea(currentSubarea, newArea, userId)

  if (newArea) return createNewSubarea(newArea, userId)

  return undefined
}

export const EditUser = () => {
  const { uuid: userId }: any = useParams()
  const { state: userYW }: any = useLocation()
  const toast = useToast()
  const navigate = useNavigate()

  const {
    get,
    put: onUpdateUser,
    response: updateResponse,
    loading
  } = useFetch(Endpoints.users.userById(userId ?? userYW?.id))

  const handleUpdateUser = async (fields: any) => {
    const body = {
      area: {
        update: {
          where: {
            area_id_user_yw_id: {
              user_yw_id: userId,
              area_id: userYW?.area?.[0]?.area?.id
            }
          },
          data: {
            area_id: fields?.area?.id
          }
        }
      },
      subarea: handleSubArea(
        userYW?.subarea?.[0]?.subarea,
        fields?.subarea,
        userId
      ),
      is_lead: fields?.is_lead || false,
      team: fields?.team,
      user: {
        update: {
          email: fields?.email,
          name: fields?.name,
          user_type: 'YW'
        }
      },
      yw_unity: fields?.unity
    }

    await onUpdateUser(body)

    if (updateResponse.status !== 200) {
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: `Não foi possível editar o usuário. Por favor, tente novamente.`,
        position: 'top-right',
        status: 'warning',
        title: updateResponse.status === 500 ? 'Erro interno' : 'Erro',
        variant: 'subtle'
      })

      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      description: `Usuário editado com sucesso.`,
      position: 'top-right',
      status: 'success',
      title: 'Sucesso',
      variant: 'subtle'
    })

    navigate('/usuarios')
  }

  useEffect(() => {
    if (userId) get()
  }, [userId, get])

  return !loading ? (
    <Form
      userId={userId}
      userYW={userYW}
      onSubmit={handleUpdateUser}
      loading={loading}
    />
  ) : (
    <></>
  )
}
