import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import { Tag } from 'components/atoms/Tag'

type HardSkillsDetailsAccordionProps = {
  hardSkils: any
}

export const HardSkillsDetailsAccordion = ({
  hardSkils
}: HardSkillsDetailsAccordionProps) => (
  <AccordionItem py={5} px={0} mx={4}>
    <AccordionButton>
      <Box flex="1" textAlign="left">
        <Text fontSize="md" fontWeight="medium">
          Hard Skills
        </Text>

        {!hardSkils?.length && (
          <Text color="gray.500" fontSize="xxs" fontWeight="normal">
            Sem hard skill
          </Text>
        )}
      </Box>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel pb={4}>
      <Wrap>
        {hardSkils?.map(({ hardskill }: any, i: number) => (
          <WrapItem key={i}>
            <Tag
              title={hardskill?.name}
              variant="solid"
              px="3"
              py="2"
              fontSize="xs"
            />
          </WrapItem>
        ))}
      </Wrap>
    </AccordionPanel>
  </AccordionItem>
)
