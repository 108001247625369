import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import useFetch from 'use-http'
import 'react-calendar/dist/Calendar.css'

import { Button } from '../../../../../components/atoms/Button'

interface ConfirmModalInterface {
  modalOpen: boolean
  onClose: () => void
  check: any
  refresh: any
}

export const ConfirmModal = ({
  modalOpen,
  onClose,
  check,
  refresh
}: ConfirmModalInterface) => {
  const toast = useToast()

  const { delete: deleteChecklistItem, response: responseChecklistItem } =
    useFetch(Endpoints.jobChecklist.deleteChecklistItem(check.id))

  const handleDeleteChecklistItem = async () => {
    await deleteChecklistItem()

    if (responseChecklistItem.status !== 200) {
      const toastMessageTitle =
        check.check_list_responsible > 0
          ? 'Não foi possível deletar o item da checklist.'
          : 'Não é possível deletar o item quando há um usuário responsável.'

      const toastMessageDescription =
        check.check_list_responsible > 0
          ? 'Tente novamente mais tarde.'
          : 'Remova o usuário e tente novamente.'

      toast({
        title: toastMessageTitle,
        description: toastMessageDescription,
        status: 'error',
        duration: 4200,
        position: 'top-right',
        variant: 'subtle'
      })
    }

    refresh()
  }

  return (
    <Box>
      <Modal
        isOpen={modalOpen}
        onClose={onClose}
        size="lg"
        blockScrollOnMount={false}>
        <ModalOverlay bg="rgba(0, 0, 0, 5%)" />
        <ModalContent
          top="96"
          right="center"
          bg="neutralDark.500"
          color="neutralLight.200">
          <Flex flexDir="row" alignItems="center">
            <ModalHeader textAlign="center" whiteSpace="nowrap">
              Deletar item?
            </ModalHeader>
            <ModalCloseButton />
          </Flex>
          <ModalBody mt="1.5" p="0">
            <Divider></Divider>
            <Flex flexDir="column" alignItems="center" justifyContent="center">
              <Button
                disabled={false}
                type="submit"
                w="32"
                variant="solid"
                colorScheme="brand"
                borderRadius="lg"
                size="md"
                my="9"
                onClick={() => {
                  handleDeleteChecklistItem()
                  onClose()
                }}>
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
