/* eslint-disable sort-keys */
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Switch,
  VStack
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'
import { Select } from 'components/atoms/Select'
import { Textarea } from 'components/atoms/Textarea'
import { UrgencyLevel } from 'components/atoms/UrgencyLevel/UrgencyLevel'
import { Table } from 'components/organisms/Table'
import { BrazilianStates } from 'data/userProfile'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FiPlusCircle, FiTrash } from 'react-icons/fi'
import useFetch from 'use-http'
import { execToSpot } from 'utils/execToSpot'
import {
  JobVertical,
  jobJourney,
  jobModality,
  seniorityTypes
} from '../../../../data/jobInfo'
import { YWOffices as offices } from '../../../../data/yellowInfo'

import { useEditJob } from '../../../../hooks/useEditJob'
import { capitalizeNames } from '../../../../utils/strings'
import { FormTooltip } from '../FormTooltip/FormTooltip'

export const SenioryTypes = {
  ASSISTENTE: 'ASSISTENTE',
  ANALISTA_JUNIOR: 'ANALISTA_JUNIOR',
  ANALISTA_PLENO: 'ANALISTA_PLENO',
  ANALISTA_SENIOR: 'ANALISTA_SENIOR',
  ESPECIALISTA: 'ESPECIALISTA',
  SUPERVISOR: 'SUPERVISOR',
  COORDENADOR: 'COORDENADOR',
  GERENTE: 'GERENTE',
  C_LEVEL: 'C_LEVEL'
}

export const YWOffices = {
  BELO_HORIZONTE: 'Belo Horizonte',
  SAO_PAULO: 'São Paulo',
  RECIFE: 'Recife'
}

const URGENCY = ['LEVEL_1', 'LEVEL_2', 'LEVEL_3', 'LEVEL_4', 'LEVEL_5']

// const formatResponseStates = async ({ response }: any) => {
//   const res = response

//   res.data = res.data.sort((a: any, b: any) =>
//     Intl.Collator('pt-BR').compare(a.nome, b.nome)
//   )

//   return res
// }

type keyValueType = {
  value: string
  label: string
}

const formatYellowProducts = async ({ response }: any) => {
  const res = response
  const newRes: keyValueType[] = []

  res?.data?.forEach((product: any) => {
    const productNewObject = {
      label: product?.name,
      value: product?.id
    }

    product?.name !== 'Exec' && newRes?.push(productNewObject)
  })
  res.data = newRes

  return res
}

const Hiring = ({ control, setValue, watch }: any) => (
  <Fragment>
    <Heading as="h2" fontSize="lg" fontWeight="semibold">
      Cenário da contratação
    </Heading>
    <HStack width="100%" alignItems="flex-start" spacing={4}>
      <Box width="60%">
        <Controller
          control={control}
          name="hiring_reason"
          render={({ field: { ref, ...props } }) => (
            <Menu
              {...props}
              label="Indique o motivo da contratação"
              requiredInfo={true}
              options={[
                {
                  value: 'Substituição',
                  label: 'Substituição'
                },
                {
                  value: 'Expansão',
                  label: 'Expansão'
                }
              ]}
            />
          )}
        />
      </Box>
      <HStack
        width="20%"
        justifyContent="center"
        alignItems="center"
        pt={10}
        spacing={4}>
        <FormLabel
          fontWeight="bold"
          fontSize="md"
          color="black"
          htmlFor=""
          m={0}>
          Retainer
        </FormLabel>
        <Switch
          colorScheme="brand"
          isChecked={watch('retainer')}
          onChange={() => setValue('retainer', !watch('retainer'))}
        />
      </HStack>
      <HStack
        width="20%"
        justifyContent="center"
        alignItems="center"
        pt={10}
        spacing={4}>
        <FormLabel
          fontWeight="bold"
          fontSize="md"
          color="black"
          htmlFor=""
          m={0}>
          Vaga sigilosa?
        </FormLabel>
        <Switch
          colorScheme="brand"
          isChecked={watch('is_secret')}
          onChange={() => setValue('is_secret', !watch('is_secret'))}
        />
      </HStack>
      <Box width="40%">
        <Input
          isReadOnly={!watch('is_secret')}
          disabled={!watch('is_secret')}
          label="Indique o e-mail para vaga sigilosa"
          type="email"
          variant="filled"
          placeholder="Digite aqui"
          control={control}
          name="email_is_secret"
        />
      </Box>
    </HStack>
  </Fragment>
)

const Location = ({ control, watch }: any) => {
  // const onError = useErrorToast()

  // const { data: state } = useFetch(
  //   Endpoints.lists.states,
  //   {
  //     onError: () =>
  //       onError({
  //         title: 'Não foi possível carregar a lista de estados.'
  //       }),
  //     interceptors: {
  //       response: formatResponseStates
  //     }
  //   },
  //   []
  // )

  const listStates = BrazilianStates.map((s) => ({
    nome: s.label,
    sigla: s.value
  }))

  const { data: cities } = useFetch(
    Endpoints.lists.citiesByState(watch('state')),
    {},
    [watch('state')]
  )

  return (
    <Fragment>
      <Heading as="h2" fontSize="lg" fontWeight="semibold" mb="4">
        Localização da vaga
      </Heading>
      <VStack width="100%" alignItems="flex-start" spacing={9}>
        <HStack width="100%" alignItems="flex-start" mt={4} spacing={4}>
          <Controller
            control={control}
            name="work_mode"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="Modalidade da vaga"
                options={jobModality}
              />
            )}
          />

          <Controller
            control={control}
            name="work_hours"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="Jornada de trabalho"
                options={jobJourney}
              />
            )}
          />
        </HStack>

        <HStack width="100%" alignItems="flex-end" spacing={4} mt={0} pt={0}>
          <Controller
            control={control}
            name="country"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="País"
                options={[
                  {
                    value: 'Brasil',
                    label: 'Brasil'
                  }
                ]}
              />
            )}
          />

          <Select
            label="Estado"
            placeholder="Selecione"
            variant="filled"
            control={control}
            name="state"
            zIndex={-1}>
            {listStates?.length &&
              listStates?.map((state: { nome: string; sigla: string }) => (
                <option key={`state_${state?.nome}`} value={state?.sigla}>
                  {capitalizeNames(state?.nome)}
                </option>
              ))}
          </Select>

          <Controller
            control={control}
            name="city"
            render={({ field: { ref, ...props } }) => (
              <Select
                {...props}
                disabled={!watch('state')}
                variant="filled"
                label="Cidade"
                placeholder="Selecione"
                zIndex={-1}>
                {!!cities?.length &&
                  cities?.map((city: { nome: string; codigo_ibge: string }) => (
                    <option
                      key={`city_${city?.codigo_ibge}`}
                      value={city?.nome}>
                      {capitalizeNames(city?.nome)}
                    </option>
                  ))}
              </Select>
            )}
          />
        </HStack>
      </VStack>
    </Fragment>
  )
}

const Mobility = ({ watch, setValue }: any) => (
  <Fragment>
    <Heading as="h2" fontSize="lg" fontWeight="semibold">
      Mobilidade
    </Heading>
    <HStack alignItems="flex-start" mt={14} spacing={4}>
      <HStack alignItems="center" spacing={4}>
        <FormLabel
          fontWeight="bold"
          fontSize="md"
          color="black"
          htmlFor=""
          m={0}>
          Precisa viajar a trabalho?
        </FormLabel>
        <Switch
          colorScheme="brand"
          id=""
          isChecked={watch('need_to_travel')}
          onChange={() => setValue('need_to_travel', !watch('need_to_travel'))}
        />
      </HStack>
      <HStack alignItems="center" spacing={4}>
        <FormLabel
          fontWeight="bold"
          fontSize="md"
          color="black"
          htmlFor=""
          m={0}>
          Precisa mudar de cidade?
        </FormLabel>
        <Switch
          colorScheme="brand"
          id=""
          isChecked={watch('need_to_city_move')}
          onChange={() =>
            setValue('need_to_city_move', !watch('need_to_city_move'))
          }
        />
      </HStack>
    </HStack>
  </Fragment>
)

const Contacts = ({ control }: any) => {
  const { fields, append, remove } = useFieldArray({
    name: 'contacts',
    control
  })

  return (
    <Fragment>
      <Heading as="h2" fontSize="lg" fontWeight="semibold">
        Contatos na empresa
      </Heading>
      <Box width="100%">
        <Table
          headers={[
            { title: 'Nome' },
            { title: 'E-mail' },
            { title: 'Telefone' },
            { title: 'Área de atuação' }
          ]}
          data={fields.map((contact: any, index) => ({
            name: (
              <Input
                required
                // eslint-disable-next-line jsx-a11y/no-autofocus
                //          autoFocus
                placeholder="Nome"
                variant="filled"
                tabIndex={index}
                name={`contacts.${index}.name`}
                control={control}
              />
            ),
            email: (
              <Input
                required
                placeholder="E-mail"
                type="email"
                variant="filled"
                tabIndex={index}
                name={`contacts.${index}.email`}
                control={control}
              />
            ),
            phone: (
              <Input
                required
                placeholder="Telefone"
                type="tel"
                variant="filled"
                tabIndex={index}
                name={`contacts.${index}.phone`}
                control={control}
              />
            ),
            area: (
              <Input
                required
                placeholder="Área de atuação"
                variant="filled"
                tabIndex={index}
                name={`contacts.${index}.area`}
                control={control}
              />
            ),
            action: (
              <IconButton
                onClick={() => remove(index)}
                variant="ghost"
                size="xs"
                colorScheme="black"
                icon={<FiTrash fontSize="1.5rem" />}
                aria-label="Delete"
              />
            )
          }))}
        />

        <Box width="100%" mt={8}>
          <Button
            type="button"
            width="100%"
            variant="solid"
            borderRadius="md"
            onClick={() =>
              append({
                name: '',
                email: '',
                phone: '',
                area: ''
              })
            }>
            <Icon as={FiPlusCircle} mr={2} /> Adicionar novo usuário
          </Button>
        </Box>
      </Box>
    </Fragment>
  )
}

export const Info = ({ errors, tabAcess, setTabAcess }: any) => {
  const onError = useErrorToast()
  const { control, setValue, watch, getValues } = useFormContext()
  const job = getValues()
  const canEdit = useEditJob(job)

  const { data: customers } = useFetch(
    Endpoints.customers.allCustomers,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os Clientes.'
        })
    },
    []
  )

  const { data: yellowProducts } = useFetch(
    Endpoints.lists.yellowProduct,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os Produtos Yellow.'
        }),
      interceptors: { response: formatYellowProducts }
    },
    []
  )

  useEffect(() => {
    if (
      job.title &&
      job.title !== '' &&
      job.level &&
      job.urgency &&
      job.job_code &&
      job.job_code !== '' &&
      job.yellow_product &&
      job.vertical &&
      job.yw_unity &&
      job.card_model &&
      job.customer_code
    ) {
      setTabAcess(true)
    } else {
      tabAcess === true && setTabAcess(false)
    }
  }, [job])

  return (
    <Box
      bg="white"
      h="2xl"
      overflowY="scroll"
      borderRadius="lg"
      sx={{
        '&::-webkit-scrollbar': {
          height: '9px',
          width: '10px',
          backgroundColor: `rgba(0, 0, 0, 0.05)`
        },
        '&::-webkit-scrollbar-thumb': {
          height: '9px',
          borderRadius: '6px',
          backgroundColor: `rgba(0, 0, 0, 0.15)`
        }
      }}
      p={6}
      pointerEvents={canEdit ? 'auto' : 'none'}>
      <Flex flexDirection="column" py="6" px="2" width="100%" gap="8">
        <HStack width="100%" alignItems="flex-start" spacing={4} mb="3">
          <Input
            label="Título da vaga"
            variant="filled"
            name="title"
            requiredInfo={true}
            control={control}
          />

          <Controller
            control={control}
            name="level"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                requiredInfo={true}
                errorMessage={errors?.level?.message}
                label="Nível do cargo"
                options={seniorityTypes}
              />
            )}
          />

          <Box px="2.5">
            <Flex>
              <FormLabel
                whiteSpace="nowrap"
                color={errors?.urgency ? 'negative.500' : 'blackText'}>
                Nível de urgência
              </FormLabel>
              <FormTooltip error={errors?.urgency} />
            </Flex>
            <Flex justifyContent="center" mt={5}>
              <UrgencyLevel
                size="lg"
                urgencyLevel={watch('urgency')}
                onChange={(value) => setValue('urgency', URGENCY[value - 1])}
              />
            </Flex>
          </Box>
          <Input
            label="Código da vaga"
            variant="filled"
            placeholder="YW0000"
            name="job_code"
            requiredInfo={true}
            control={control}
          />
        </HStack>

        <HStack width="100%" alignItems="flex-start" spacing={4}>
          <Select
            label="Cliente"
            placeholder="Selecione"
            variant="filled"
            name="customer_id"
            requiredInfo={true}
            control={control}>
            {customers?.data?.map((customer: { id: string; name: string }) => (
              <option key={customer.id} value={customer.id}>
                {customer.name}
              </option>
            ))}
          </Select>

          <Controller
            control={control}
            name="yellow_product_id"
            render={({ field: { ref, value, ...props } }) => (
              <Menu
                {...props}
                errorMessage={errors?.yellow_product_id?.message}
                label="Produto Yellow"
                requiredInfo={true}
                value={execToSpot(value, yellowProducts)}
                options={yellowProducts}
              />
            )}
          />

          <Controller
            control={control}
            name="vertical"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                requiredInfo={true}
                label="Vertical"
                errorMessage={errors?.vertical?.message}
                options={JobVertical}
              />
            )}
          />

          <Controller
            control={control}
            name="yw_unity"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="Unidade Yellow"
                requiredInfo={true}
                errorMessage={errors?.yw_unity?.message}
                options={offices}
              />
            )}
          />

          <Controller
            control={control}
            name="card_model"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="Modelo de card"
                requiredInfo={true}
                errorMessage={errors?.card_model?.message}
                options={[
                  {
                    value: 'TECH',
                    label: 'Yellow'
                  }
                ]}
              />
            )}
          />
        </HStack>

        <Divider color="#E9E9E9" my={4} />

        <Hiring control={control} watch={watch} setValue={setValue} />

        <Divider color="#E9E9E9" my={4} />

        <HStack width="100%" alignItems="flex-start" spacing={4}>
          <Box width="100%">
            <Location control={control} watch={watch} />
          </Box>
          <Box width="100%">
            <Mobility watch={watch} setValue={setValue} />
          </Box>
        </HStack>

        <Divider color="#E9E9E9" my={4} />

        <Contacts control={control} />

        <Divider color="#E9E9E9" my={4} />

        <Textarea
          label="Momento da empresa"
          cols={6}
          rows={7}
          variant="filled"
          placeholder="Digite aqui"
          onChange={(e) =>
            setValue('company_moment_description', e.target.value)
          }
          value={watch('company_moment_description')}
        />

        <Divider color="#E9E9E9" my={4} />

        <Input
          label="Link de publicação da vaga"
          variant="filled"
          placeholder="Digite aqui"
          name="linkToPublish"
          control={control}
          mb="3"
        />

        <Textarea
          label="Insumos para publicação Linkedin"
          cols={6}
          rows={7}
          variant="filled"
          placeholder="Digite aqui"
          onChange={({ target: { value } }) =>
            setValue('linkedinInputs', value)
          }
          value={watch('linkedinInputs')}
        />
      </Flex>
    </Box>
  )
}
