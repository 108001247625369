type EnumObject = { [key: string]: string }

export const SenioryTypes: EnumObject = {
  ASSISTENTE: 'Assistente',
  ANALISTA_JUNIOR: 'Analista Junior',
  ANALISTA_PLENO: 'Analista Pleno',
  ANALISTA_SENIOR: 'Analista Senior',
  ESPECIALISTA: 'Especialista',
  SUPERVISOR: 'Supervisor',
  COORDENADOR: 'Coordernador',
  GERENTE: 'Gerente',
  C_LEVEL: 'C Level'
}
