import { FiltersOptionType } from '../../components/organisms/Filters'

export const filterOptions = (): FiltersOptionType[] => [
  {
    button: 'Status',
    filters: {
      field: 'status',
      filterType: 'checkbox',
      filtersOptions: [
        { label: 'Selecione', value: '' },
        { label: 'Aberta', value: 'OPEN' },
        { label: 'Cancelada', value: 'CANCELED' },
        { label: 'Cdd. declinou', value: 'DECLINED' },
        { label: 'Crédito', value: 'CREDIT' },
        { label: 'Emitida', value: 'ISSUED' },
        { label: 'Fatura', value: 'INVOICE' },
        { label: 'Permuta', value: 'EXCHANGE' }
      ]
    }
  }
]
