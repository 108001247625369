/* eslint-disable indent */
import {
  Box,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useToast
} from '@chakra-ui/react'

import { useMemo } from 'react'
import { FiX } from 'react-icons/fi'
import useFetch from 'use-http'
import { Endpoints } from '../../../../../api/endpoints'

import { UserType } from '../../../../Users/types/users'

interface ChecklistModalUserAssignInterface {
  jobId: string
  modalOpen: boolean
  onClose: () => void
  refresh: () => void
  check: any
  editorUsers: any
}

export const ChecklistModalUserAssign = ({
  jobId,
  modalOpen,
  onClose,
  refresh,
  check,
  editorUsers
}: ChecklistModalUserAssignInterface) => {
  const { loading } = useFetch(`/jobs/${jobId}`)

  const { data: usersYw } = useFetch(Endpoints.users.findAllForKAM, [loading])
  const completeUsersYw = usersYw?.data

  const toast = useToast()

  const { put: updateChecklistItem, response } = useFetch(
    Endpoints.jobChecklist.updateItem(check.id)
  )

  const selectableUser = useMemo(() => {
    const selectable: UserType[] = []

    completeUsersYw?.forEach((userYw: UserType) => {
      editorUsers.forEach((editorUser: string) => {
        if (editorUser === userYw.id) {
          selectable.push(userYw)
        }
      })
    })

    return selectable
  }, [completeUsersYw, editorUsers])

  const handleUpdateResponsable = async (
    user?: UserType,
    ignoreError?: boolean
  ) => {
    let body

    if (!user) {
      body = {
        check_list_responsible: {
          deleteMany: { id: check?.check_list_responsible[0]?.id }
        }
      }
    }

    if (user) {
      if (check.check_list_responsible.length === 0) {
        body = {
          check_list_responsible: {
            create: [
              {
                user_yw_id: user.id
              }
            ]
          }
        }
      } else {
        body = {
          check_list_responsible: {
            update: [
              {
                where: {
                  id: check?.check_list_responsible[0]?.id
                },
                data: {
                  user_yw_id: user.id
                }
              }
            ]
          }
        }
      }
    }

    await updateChecklistItem(body)

    if (response.status !== 200 && !!ignoreError) {
      toast({
        title: 'Não foi possível alterar o checklist.',
        description: 'Por favor, tente mais tarde.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })
    }

    refresh()
  }

  return (
    <Box>
      <Modal
        isOpen={modalOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="md">
        <ModalOverlay bg="rgba(0, 0, 0, 5%)" />
        <ModalContent
          top="200"
          left={{
            sm: '50px',
            base: '150px',
            md: '200px',
            lg: '300px'
          }}
          bg="neutralDark.500"
          borderRadius="md"
          overflowX="hidden"
          boxShadow="none"
          color="neutralLight.200">
          <Flex flexDir="row" alignItems="center">
            <ModalHeader textAlign="center">Atribuir</ModalHeader>
            <ModalCloseButton />
          </Flex>
          <Divider />
          <ModalBody
            p="0"
            maxH="md"
            bg="neutralLight.200"
            color="neutralDark.500"
            sx={{
              '&::-webkit-scrollbar': {
                width: '10px',
                backgroundColor: `rgba(0, 0, 0, 0.05)`
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '8px',
                backgroundColor: `rgba(0, 0, 0, 0.15)`
              }
            }}>
            <Flex flexDirection="column">
              {selectableUser?.map((user: UserType, i: number) => (
                <Box key={`${i} + teste`} _hover={{ cursor: 'pointer' }}>
                  <Flex
                    onClick={() => {
                      handleUpdateResponsable(user)
                      onClose()
                    }}
                    flexDirection="row"
                    py="4"
                    pl="4"
                    _hover={{ bg: 'neutralLight.400' }}>
                    <Text>{user.user.name}</Text>
                    <Spacer />
                    {user.id ===
                    check?.check_list_responsible[0]?.user_yw_id ? (
                      <Box
                        w={8}
                        h={8}
                        mr="3"
                        borderRadius="md"
                        onClick={() => {
                          handleUpdateResponsable(undefined, true)
                        }}>
                        <Icon
                          as={FiX}
                          w={7}
                          h={7}
                          _hover={{ color: 'negative.400' }}
                          color="neutralDark.300"
                        />
                      </Box>
                    ) : null}
                  </Flex>
                  <Divider />
                </Box>
              ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
