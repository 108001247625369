export const Tabs = {
  baseStyle: {
    tab: {
      fontSize: 'lg',
      fontWeight: 'bold'
    }
  },
  sizes: {
    md: {
      tab: {
        py: 3
      }
    }
  },
  variants: {
    line: {
      tab: {
        _active: {
          bg: '#F5F6FA'
        },
        _selected: {
          borderBottomWidth: '4px',
          borderColor: 'brand.500',
          color: '#1B1C1E'
        }
      },
      tablist: {
        borderBottom: '1px solid',
        borderColor: 'currentColor',
        color: '#C8CCD4'
      }
    }
  }
}
