/* eslint-disable camelcase */
import { format } from 'date-fns'
import { omit } from 'radash'
import { jobCurrencyFormatter } from 'utils/formatCurrency'
import { formatTimeline } from './formatTimeline'

export type OfflimitsTypes = {
  id: string
  company_id: string
  job_id: string
  company: {
    id: string
    company_name: string
  }
}

export const formatResponse = ({ response, methods }: any) => {
  if (response.data) {
    Object.entries(response.data).forEach(([key, value]: [string, any]) => {
      if (key === 'job_superior_courses') {
        methods.setValue(
          key,
          // @ts-ignore
          value?.map((sjc: any) => ({
            label: sjc.superior_course.name,
            value: sjc.superior_course.id
          }))
        )
        return
      }

      if (key === 'job_hard_skills') {
        methods.setValue(
          key,
          // @ts-ignore
          value?.map((sjc: any) => ({
            label: sjc.hard_skill.name,
            value: sjc.hard_skill.id
          }))
        )
        return
      }

      if (key === 'job_languages') {
        methods.setValue(
          key,
          // @ts-ignore
          value?.map((jl: any) => ({
            level: jl.level,
            language_id: jl.language.id
          }))
        )
        return
      }

      if (key === 'benefits') {
        methods.setValue(
          key,
          // @ts-ignore
          value?.map((benefit: any) => ({
            value:
              typeof benefit.value === 'string'
                ? String(benefit?.value)
                : Number(benefit.value),
            benefits_id: benefit?.benefits_id
          }))
        )
        return
      }

      if (key === 'time_line') {
        const { timelineYellow, timelineClient } = formatTimeline(value)

        methods.setValue('timelineYellow', timelineYellow)
        methods.setValue('timelineClient', timelineClient)

        return
      }

      if (key === 'kickoff_date') {
        methods.setValue(
          'kickoff_date',
          value ? format(new Date(value), 'dd/MM/yyyy') : null
        )
        return
      }

      if (key === 'success_email_date') {
        methods.setValue(
          'success_email_date',
          value ? format(new Date(value), 'dd/MM/yyyy') : null
        )

        return
      }
      if (key === 'end_date') {
        methods.setValue(
          'end_date',
          value ? format(new Date(value), 'dd/MM/yyyy') : null
        )

        return
      }

      if (key === 'contacts') {
        methods.setValue(
          'contacts',
          value.map((c: any) => omit(c, ['job_id']))
        )
        return
      }

      if (key === 'customer') {
        methods.setValue('customer_code', value.customer_code)
        return
      }

      if (key === 'offlimits') {
        methods.setValue(
          'offlimits',
          value?.map((offlimit: OfflimitsTypes) => ({
            value: offlimit.company_id,
            label: offlimit.company.company_name
          }))
        )
        return
      }

      if (key === 'reference_companies') {
        methods.setValue(
          key,
          value?.map((company_name: string) => ({
            value: company_name,
            label: company_name
          }))
        )
        return
      }

      methods.setValue(key, value)
    })
  }
  return response
}

export const formatPayload = (payload: any) => {
  const returnData = {
    ...payload,
    salaryOnOpen: !!payload.salaryOnOpen
      ? jobCurrencyFormatter(payload.salaryOnOpen)
      : null,
    max_salary_range: !!payload.max_salary_range
      ? jobCurrencyFormatter(payload.max_salary_range)
      : null,
    min_salary_range: !!payload.min_salary_range
      ? jobCurrencyFormatter(payload.min_salary_range)
      : null,
    users_qualifier: payload?.users_qualifier?.map?.(
      ({ qualifier_id }: { qualifier_id: string }) => qualifier_id
    ),
    job_languages: payload.job_languages.map((jl: any) => ({
      level: jl.level,
      language_id: jl?.language_id ?? jl?.language?.id
    }))
  }

  !payload.salaryOnOpen && delete returnData.salaryOnOpen
  !payload.max_salary_range && delete returnData.max_salary_range
  !payload.min_salary_range && delete returnData.min_salary_range

  return returnData
}
