import { useMemo } from 'react'
import useUserStore from 'stores/user'

export const useEditJob = (job: any) => {
  const user = useUserStore()

  return useMemo(() => {
    const currentUserIsConductorOfJob = job?.user_conductor_id === user?.id

    const currentUserIsQualifierOfJob = !!job?.users_qualifier?.find(
      (qualifier: { qualifier_id: string }) =>
        qualifier?.qualifier_id === user?.id
    )

    const currentUserIsLead = user?.is_lead === true

    if (!job?.user_conductor_id || !job?.users_qualifier?.length) return true

    return (
      currentUserIsConductorOfJob ||
      currentUserIsQualifierOfJob ||
      currentUserIsLead
    )
  }, [job, user])
}
