import './reset.css'
import { Box, ChakraProvider } from '@chakra-ui/react'
import Hotjar from '@hotjar/browser'
import { HttpProvider } from 'api/HttpProvider'
import Amplify from 'aws-amplify'
import React from 'react'
import { RoutesContainer } from './routes'
import { theme } from './theme/Theme'

const hotjarVersion = 6

Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_identity_pools_id: process.env.REACT_APP_AWS_IDENTIFY_POOLS_ID,
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_ID
})

process.env.REACT_APP_HOTJAR_CODE &&
  Hotjar.init(Number(process.env.REACT_APP_HOTJAR_CODE), hotjarVersion)

export const App: React.FC = ({ ...props }) => (
  <Box height="100%" {...props}>
    <HttpProvider>
      <ChakraProvider theme={theme}>
        <RoutesContainer />
      </ChakraProvider>
    </HttpProvider>
  </Box>
)
