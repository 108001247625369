import { Button as ButtonChakra, ButtonProps, Spinner } from '@chakra-ui/react'
import { MutableRefObject } from 'react'
import { normalizeNumber } from 'theme/normalization'

interface ButtonType extends ButtonProps {
  children: string | React.ReactNode
  loading?: boolean
  ref?: MutableRefObject<any>
}

export const Button = ({
  children,
  loading,
  height,
  ...rest
}: ButtonType): JSX.Element => (
  <ButtonChakra {...rest} height={height ?? `${normalizeNumber(48)}px`}>
    {!loading ? (
      children
    ) : (
      <Spinner
        color={
          rest.variant === 'outline'
            ? `${rest.colorScheme ?? 'brand'}.500`
            : 'black'
        }
        size="sm"
      />
    )}
  </ButtonChakra>
)
