type keyValueType = {
  value: string
  label: string
}

export const jobModality: keyValueType[] = [
  {
    value: 'REMOTE',
    label: 'Remoto'
  },
  {
    value: 'PRESENTIAL',
    label: 'Presencial'
  },
  {
    value: 'HIBRIDO',
    label: 'Híbrido'
  }
]

export const jobJourney: keyValueType[] = [
  {
    value: 'FULL_TIME_40H',
    label: 'Full time 40 horas'
  },
  {
    value: 'FULL_TIME_44H',
    label: 'Full time 44 horas'
  },
  {
    value: 'PART_TIME_6H',
    label: 'Full time 6 horas'
  },
  {
    value: 'PART_TIME_4H',
    label: 'Full time 4 horas'
  }
]

export const seniorityTypes: keyValueType[] = [
  {
    value: 'ASSISTENTE',
    label: 'Assistente'
  },
  {
    value: 'ANALISTA_JUNIOR',
    label: 'Analista Júnior'
  },
  {
    value: 'ANALISTA_PLENO',
    label: 'Analista Pleno'
  },
  {
    value: 'ANALISTA_SENIOR',
    label: 'Analista Sênior'
  },
  {
    value: 'ESPECIALISTA',
    label: 'Especialista'
  },
  {
    value: 'SUPERVISOR',
    label: 'Supervisor'
  },
  {
    value: 'COORDENADOR',
    label: 'Coordenador'
  },
  {
    value: 'GERENTE',
    label: 'Gerente'
  },
  {
    value: 'C_LEVEL',
    label: 'C Level'
  },
  {
    value: 'TECHNICIAN',
    label: 'Técnico'
  }
]

export const JobType: keyValueType[] = [
  { value: 'CLT', label: 'CLT' },
  { value: 'PJ', label: 'PJ' },
  { value: 'COOPERADO', label: 'Cooperador' },
  { value: 'ASSOCIADO', label: 'Associado' },
  { value: 'BOLSISTA', label: 'Bolsista' }
]

export const JobFormat: keyValueType[] = [
  { value: 'LOCAL', label: 'Local' },
  { value: 'REMOTE', label: 'Remoto' }
]

export const JobVertical: keyValueType[] = [
  { value: 'TECH_1', label: 'Tech' },
  { value: 'EXEC_ENG', label: 'Engenharia' },
  { value: 'EXEC_FeA', label: 'F&A' },
  { value: 'EXEC_MKT_e_VENDAS', label: 'Mkt & Vendas' }
]

export const enum JobProductsType {
  exec = 'Exec',
  express = 'Express',
  tech = 'Tech'
}
