import { Box, Flex, HStack, Spinner, useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Filters } from 'components/organisms/Filters'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFetch } from 'use-http'
import { queryStringParams } from 'utils/strings'
import { TableCandidates } from './components/TableCandidates'
import { filterOptions } from './filterOptions'

export const Candidates = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const [page, setPage] = useState<number>(0)
  const [filters, setFilters] = useState<{ [key: string]: string }>({})

  const take = 20

  const defaultParams = queryStringParams({
    ...filters,
    skip: page * take,
    take
  })

  const {
    loading,
    data: candidates,
    get
  } = useFetch(`${Endpoints.usersCandidate.root}${defaultParams}`, {
    onError: () =>
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: 'Não foi possível listar os candidatos.',
        position: 'top-right',
        status: 'warning',
        title: 'Ops!',
        variant: 'subtle'
      })
  })

  const handleFilter = (values: {}) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      ...values
    }))
  }

  useEffect(() => {
    get()
  }, [get])

  return (
    <Fragment>
      <Flex
        minH="14%"
        w="100%"
        flexDirection="column"
        justify="space-between"
        pb="5">
        <HStack mb="6">
          <HeaderPage title="Gestão de candidatos" />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <HStack alignItems="flex-start" justifyContent="flex-start">
            <Filters
              options={filterOptions({ state: filters?.state?.toLowerCase() })}
              filters={filters}
              onClearFilters={() => setFilters({})}
              onFilter={handleFilter}
            />
          </HStack>

          <HStack>
            {/* 
              {false && (
               <Button
                 variant="outline"
                  colorScheme="brand"
                  fontSize="xs"
                  fontWeight="bold"
                  width="135px"
                  height="30px">
                  Cadastro múltiplo
                </Button>
              )} 
            */}
            <Button
              variant="solid"
              colorScheme="brand"
              fontSize="xs"
              fontWeight="bold"
              px={8}
              height={30}
              borderRadius="md"
              onClick={() => navigate('criar')}>
              Cadastrar candidato
            </Button>
          </HStack>
        </HStack>
      </Flex>
      <Box
        width="100%"
        h="86%"
        borderRadius="lg"
        bg="white"
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: `rgba(0, 0, 0, 0.15)`
          }
        }}>
        {loading && !candidates?.data && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Spinner color="brand.500" />
          </Box>
        )}
        {candidates?.data && (
          <TableCandidates
            data={candidates.data}
            get={get}
            total={Math.round(candidates.total / take)}
            onChange={setPage}
            pagination={{
              take,
              total: candidates.total
            }}
          />
        )}
      </Box>
    </Fragment>
  )
}
