/* eslint-disable camelcase */
/* eslint-disable indent */
import { Box, Select, Text, useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { TablePagination } from 'components/organisms/Table'
import { InvoiceStatusTypes } from 'enum/form'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useMemo } from 'react'
import useFetch from 'use-http'
import { formatCurrency } from 'utils/formatCurrency'

type TableDeliveryType = {
  data: any
  total: number
  onChange: (_page: number) => void
  pagination: {
    take: number
    total: number
  }
  refresh: any
}

export const TableDelivery = ({
  data,
  total,
  onChange,
  pagination,
  refresh
}: TableDeliveryType) => {
  const onError = useErrorToast()
  const toast = useToast()

  const { put: updateForm, response: responseForm, loading } = useFetch()

  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Cód. Vaga',
        accessor: 'job_code'
      },
      {
        Header: 'Vaga',
        accessor: 'job_title'
      },
      {
        Header: 'Cliente',
        accessor: 'customer'
      },
      /* {
        Header: 'Salário',
        accessor: 'salary'
      } */
      {
        Header: 'Salário de abertura',
        accessor: 'salaryOnOpen'
      }
    ],
    []
  )

  const handleStatus = async (status: string, formId: string) => {
    await updateForm(Endpoints.forms.update(formId), {
      status: status === '' ? null : status
    })

    if (responseForm.status >= 400) {
      onError({
        title: 'Não foi possível atualizar o form.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Form atualizado com sucesso.',
      variant: 'subtle'
    })

    refresh()
  }

  return (
    <Fragment>
      <TablePagination
        total={total}
        columns={columns}
        onChange={onChange}
        pagination={pagination}
        data={data?.map((form: any) => ({
          id: form.id,
          status: (
            <Box w="150px">
              <Select
                placeholder="Selecione"
                bg="neutralLight.400"
                p="0"
                ml="4"
                size="sm"
                w="fit-content"
                variant="filled"
                defaultValue={form.status}
                value={form?.status}
                onChange={(e) => handleStatus(e.target.value, form.id)}
                fontSize="xs"
                borderRadius="md"
                color="BlackText"
                disabled={loading}
                _focusVisible={{
                  _placeholder: {
                    color: 'black'
                  },
                  bg: 'neutralLight.400',
                  borderColor: 'transparent'
                }}
                _hover={{
                  bg: 'neutralLight.400'
                }}>
                {Object.keys(InvoiceStatusTypes)
                  .filter(
                    (s) =>
                      !['CANCELED', 'DECLINED', 'INVOICE', 'EXCHANGE'].includes(
                        s
                      )
                  )
                  .map((key) => (
                    <option key={`invoice_${key}`} value={key}>
                      {InvoiceStatusTypes[key]}
                    </option>
                  ))}
              </Select>
            </Box>
          ),
          job_code: <Text fontSize="sm">{form.job.job_code || '–'}</Text>,
          job_title: (
            <Text fontSize="sm" textDecoration="bold">
              {form.job.title}
            </Text>
          ),
          customer: (
            <Text fontSize="sm">{form?.job?.customer?.name ?? '–'}</Text>
          ),
          /* salary: (
            <Text fontSize="sm">
              {averageRange(
                form.job.min_salary_range,
                form.job.max_salary_range,
                form.job.has_salary_variable,
                form.job.has_salary_variable_value
              )}
            </Text>
          ) */
          salaryOnOpen: (
            <Text fontSize="sm">
              {formatCurrency(form?.job?.salaryOnOpen) || '-'}
            </Text>
          )
        }))}
      />
    </Fragment>
  )
}
