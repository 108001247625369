/* eslint-disable indent */
/* eslint-disable camelcase */
import {
  Box,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import Filter from 'components/organisms/Filter'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useFetch from 'use-http'
import { removeAccents } from 'utils/helper'
import * as yup from 'yup'
import { CreateNewButton } from './components/CreateNewButton'
import { Draft } from './components/Form/Draft'
import { Kanban } from './components/Kanban'
import { ModalJob } from './components/ModalJob'
import { dateIsNotValid, parseDate } from './validationAndFormat'

const formSchema = yup.object().shape({
  title: yup.string().required('Campo obrigatório'),
  customer_code: yup.string().required('Campo obrigatório'),
  level: yup.string().required('Campo obrigatório'),
  vertical: yup.string().required('Campo obrigatório'),
  kickoff_date: yup.string().required('Campo obrigatório'),
  yw_unity: yup.string().required('Campo obrigatório'),
  card_model: yup.string().required('Campo obrigatório'),
  yellow_product_id: yup.string().required('Campo obrigatório')
})

export const Jobs = () => {
  const navigate = useNavigate()
  const { jobId } = useParams()
  const [params] = useSearchParams()
  const toast = useToast()
  const onError = useErrorToast()

  const [filteredJobs, setFilteredJobs] = useState(null)

  const { handleSubmit, control, reset, formState, getValues } = useForm({
    resolver: yupResolver(formSchema)
  })
  const { errors } = formState

  const [jobIdSelected, setJobIdSelected] = useState<string | null>(
    jobId ?? null
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenDraft,
    onOpen: onOpenDraft,
    onClose: onCloseDraft
  } = useDisclosure()

  const {
    post: createJob,
    response,
    loading: loadingCreate
  } = useFetch(Endpoints.jobs.root)

  const {
    get: onGetJobs,
    loading,
    data: jobs
  } = useFetch(
    '/jobs',
    {
      retries: 2,
      retryDelay: 5000
    },
    []
  )

  const handleClickJob = (id: string) => {
    setJobIdSelected(id)
    onOpen()
  }

  const onSubmit = async (data: any) => {
    const errorKickoffDate = dateIsNotValid(data.kickoff_date)
    if (errorKickoffDate) {
      onError({
        title: 'Data de kickoff inválida.',
        description: 'Verifique e tente novamente.'
      })
      return
    }

    data.kickoff_date = parseDate(data.kickoff_date)
    await createJob({
      ...data,
      board_status: 'START'
    })

    if (response.status >= 400) {
      onError({
        title: 'Não foi possível cria uma Vaga.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Vaga criada com sucesso',
      variant: 'subtle'
    })

    reset()
    onCloseDraft()
    onGetJobs()
  }

  const isSearchParams = useMemo(
    () =>
      params.get('term') ||
      params.getAll('forecast').length ||
      params.getAll('verticals').length ||
      params.getAll('users').length ||
      params.getAll('customers').length ||
      params.getAll('unity').length,
    [params]
  )

  const onFilter = useCallback(
    () =>
      !isSearchParams
        ? jobs
        : jobs?.filter(
            (job: {
              title: string
              job_code: string
              vertical: string
              forecast: string
              yw_unity: string
              customer: { id: string }
              user_conductor_id: string
              users_qualifier: { qualifier_id: string }[]
            }) => {
              const termFormated = removeAccents(
                params.get('term')?.toLowerCase() ?? ''
              )
              const regex = new RegExp(removeAccents(termFormated), 'i')

              const isTermSearched =
                job.job_code === params.get('term') ||
                regex.test(
                  removeAccents(job.title.replaceAll('+', ' ').toLowerCase())
                )
              const isVerticalsSearched =
                params.getAll('verticals').length === 0 ||
                params.getAll('verticals').includes(job.vertical)
              const isCustomersSearched =
                params.getAll('customers').length === 0 ||
                params.getAll('customers').includes(job.customer.id)
              const isForecastSearched =
                params.getAll('forecast').length === 0 ||
                params.getAll('forecast').includes(job.forecast)
              const isUnitySearched =
                params.getAll('unity').length === 0 ||
                params.getAll('unity').includes(job.yw_unity)
              const isUserConductorSearched =
                params.getAll('users').length === 0 ||
                params.getAll('users').includes(job.user_conductor_id) ||
                params
                  .getAll('users')
                  .every(
                    (user) =>
                      job.users_qualifier
                        .map(({ qualifier_id }) => qualifier_id)
                        .indexOf(user) !== -1
                  )

              return (
                isTermSearched &&
                isVerticalsSearched &&
                isForecastSearched &&
                isUnitySearched &&
                isUserConductorSearched &&
                isCustomersSearched
              )
            }
          ),
    [isSearchParams, jobs, params]
  )

  useEffect(() => {
    if (jobs?.length) {
      setFilteredJobs(jobs)
    }
  }, [jobs])

  useEffect(() => {
    if (jobId) {
      onOpen()
    }
  }, [onOpen, jobId])

  useEffect(() => {
    setFilteredJobs(onFilter())
  }, [onFilter, params])

  return (
    <Fragment>
      <Flex
        minH="14%"
        w="100%"
        flexDirection="column"
        justify="space-between"
        pb="5">
        <HStack mb="6">
          <HeaderPage title="Pipeline de vagas" />
        </HStack>

        <Flex alignItems="center" justifyContent="space-between" gap="8">
          <Filter
            enableInput
            placeholder="Nome ou Código da vaga"
            options={['forecast', 'vertical', 'usersYw', 'customer', 'unity']}
          />
          <HStack>
            <CreateNewButton navigate={navigate} onOpen={onOpenDraft} />
          </HStack>
        </Flex>
      </Flex>

      <Box width="100%" height="86%">
        {loading && !filteredJobs && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Spinner color="brand.500" />
          </Box>
        )}
        {filteredJobs && (
          <Kanban
            data={filteredJobs}
            onOpen={handleClickJob}
            onAdd={() => onOpenDraft()}
            simple
          />
        )}
      </Box>

      {jobIdSelected && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            reset()
            onClose()
            if (jobId) {
              setJobIdSelected(null)
              navigate('/pipeline-de-vagas', { replace: true })
            }
          }}
          isCentered
          size="6xl"
          scrollBehavior="inside">
          <ModalOverlay />
          <ModalJob
            jobId={jobIdSelected}
            onClose={() => {
              reset()
              onClose()
            }}
          />
        </Modal>
      )}

      <Modal
        isOpen={isOpenDraft}
        onClose={() => {
          reset()
          onCloseDraft()
        }}
        isCentered
        size="4xl"
        scrollBehavior="inside">
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius="md" overflow="hidden">
            <ModalHeader fontSize="xl" px={8} py={6} fontWeight="bold">
              Cadastrar nova vaga
              <Divider mt={6} borderBottom="2px" borderBottomColor="#E9E9E9" />
            </ModalHeader>

            <Draft control={control} errors={errors} getValues={getValues} />

            <ModalFooter px={8} pb={8} mt={18}>
              {!loadingCreate && (
                <Button
                  px={20}
                  borderRadius="md"
                  height={12}
                  minW="xxs"
                  variant="outline"
                  colorScheme="brand"
                  onClick={() => {
                    reset()
                    onCloseDraft()
                  }}>
                  Cancelar
                </Button>
              )}

              <Button
                isDisabled={loadingCreate}
                loading={loadingCreate}
                px={20}
                borderRadius="md"
                height={12}
                minW="xxs"
                colorScheme="brand"
                ml={6}
                type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Fragment>
  )
}
