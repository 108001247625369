/* eslint-disable indent */
/* eslint-disable no-unreachable */

/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { parallel, tryit } from 'radash'
import { useNavigate } from 'react-router-dom'
import useFetch from 'use-http'
import { Form } from './components/Form'
import { UserType } from './types/users'

export const CreateUser = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { post: onCreateUsers, loading } = useFetch(Endpoints.users.create)

  const handleSaveNewUserYW = async (tempUsers: UserType[]) => {
    const [err] = await tryit(parallel)(
      tempUsers.length,
      tempUsers,
      async (user: any) => {
        const {
          accessCategory,
          accessSubcategory,
          is_lead,
          team,
          email,
          name,
          unity
        } = user
        const body = {
          area: accessCategory
            ? {
                create: {
                  area_id: accessCategory.id
                }
              }
            : undefined,
          subarea: accessSubcategory
            ? {
                create: {
                  subarea_id: accessSubcategory.id
                }
              }
            : undefined,
          is_lead: is_lead || false,
          team,
          user: {
            create: {
              email,
              name,
              user_type: 'YW'
            }
          },
          yw_unity: unity
        }

        const res = await onCreateUsers(body)
        if (res?.status > 400 || res?.statusCode) {
          if (res?.meta?.target.find((m: string) => m === 'email'))
            throw new Error('Email já cadastrado')

          throw new Error(res?.status)
        }
      }
    )

    if (err) {
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: `Por favor, tente novamente.`,
        position: 'top-right',
        status: 'warning',
        title:
          // @ts-ignore
          err?.errors[0]?.message || 'Não foi possível criar o(s) usuário(s).',
        variant: 'subtle'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      description: `Usuário(s) criado(s) com sucesso.`,
      position: 'top-right',
      status: 'success',
      title: 'Sucesso',
      variant: 'subtle'
    })

    navigate('/usuarios')
  }

  return <Form onSubmit={handleSaveNewUserYW} loading={loading} />
}
