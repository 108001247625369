import { HStack, VStack, useBoolean } from '@chakra-ui/react'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { Tooltip } from 'components/atoms/Tooltip'

export const Filters = ({ nameState, onChangeName, loading }: any) => {
  const [filterName, toggleFilterName] = useBoolean()

  return (
    <VStack>
      <HStack>
        <Button
          onClick={() => {
            onChangeName('')
            toggleFilterName.toggle()
          }}
          variant="solid"
          bg={filterName ? 'brand.500' : 'white'}
          size="xs"
          px={5}
          borderRadius="md"
          fontWeight="400"
          height={30}>
          Nome do usuário
        </Button>
        {/*  <Tooltip label="Em breve">
          <Button
            disabled
            variant="solid"
            bg="white"
            size="xs"
            height={30}
            fontWeight="400"
            px={5}
            borderRadius="md">
            Área
          </Button>
        </Tooltip>
        <Tooltip label="Em breve">
          {' '}
          <Button
            disabled
            variant="solid"
            bg="white"
            size="xs"
            height={30}
            fontWeight="400"
            px={5}
            borderRadius="md">
            Vertical
          </Button>
        </Tooltip>

        <Tooltip label="Em breve">
          <Button
            disabled
            variant="solid"
            bg="white"
            size="xs"
            height={30}
            fontWeight="400"
            px={5}
            borderRadius="md">
            Unidade
          </Button>
        </Tooltip>

        <Tooltip label="Em breve">
          <Button
            disabled
            variant="solid"
            bg="white"
            size="xs"
            height={30}
            fontWeight="400"
            px={5}
            borderRadius="md">
            Status
          </Button>
        </Tooltip>

        <Tooltip label="Em breve">
          <Button
            disabled
            variant="solid"
            bg="white"
            size="xs"
            height={30}
            fontWeight="400"
            px={5}
            borderRadius="md">
            Líder
          </Button>
        </Tooltip> */}
      </HStack>

      {filterName && (
        <HStack alignItems="flex-start" w="100%">
          <Input
            loading={loading}
            type="text"
            variant="filled"
            id="name"
            placeholder="Nome"
            bg="white"
            borderColor="white"
            size="xs"
            height={30}
            value={nameState}
            onChange={(e) => onChangeName(e.target.value)}
            _hover={{ background: 'white' }}
            _focus={{ background: 'white' }}
          />
          <Button p="6" variant="link" onClick={() => onChangeName('')}>
            limpar
          </Button>
        </HStack>
      )}
    </VStack>
  )
}
