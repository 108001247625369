import { HStack } from '@chakra-ui/react'
import { Input } from 'components/atoms/Input'
import { JobModeType } from 'enum/user'
import { transformDate } from 'utils/formatDate'

export const InterviewTimeline = ({ data }: any) => (
  <HStack width="100%" alignItems="flex-start" spacing={4} mb={6}>
    <Input
      variant="filled"
      label="Descrição"
      value={data?.description ?? ''}
      isReadOnly
    />
    <Input
      variant="filled"
      label="Shortlist"
      value={`Shortlist ${data?.shortlist_number ?? 1}`}
      isReadOnly
    />
    <Input
      variant="filled"
      label="Data"
      type="text"
      mask="99/99/9999"
      placeholder="--/--/----"
      value={
        data?.date
          ? transformDate(new Date(data.date)?.toISOString()?.split('T')?.[0])
          : ''
      }
      isReadOnly
    />
    <Input
      label="Duração"
      type="time"
      variant="filled"
      value={data?.duration}
      isReadOnly
    />
    <Input
      variant="filled"
      label="Formato"
      value={JobModeType[data?.format]}
      isReadOnly
    />
    <Input
      label="Responsável"
      variant="filled"
      value={data?.responsible}
      isReadOnly
    />
  </HStack>
)
