import { Flex, Text } from '@chakra-ui/react'
import { FiMeh } from 'react-icons/fi'

export const NoDataMessage = () => {
  return (
    <Flex
      alignItems="center"
      px={5}
      py={17}
      my={6}
      mt={10}
      mx={38}
      justifyContent="center"
      borderRadius={8}
      shadow={'0px 1px 5px 0px #10182862'}>
      <FiMeh color="#8F8F8F" fontSize={24} />
      <Text fontWeight={700} color="grayText" ml={3}>
        Você ainda não tem candidatos cadastrados nessa lista
      </Text>
    </Flex>
  )
}
