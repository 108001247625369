import {
  Tag as TagChakra,
  TagCloseButton as TagCloseButtonChakra,
  TagLabel as TagLabelChakra,
  TagLabelProps,
  TagProps
} from '@chakra-ui/react'

interface TagType extends TagProps {
  title?: string
  disabled?: boolean
  onClick?: () => void
}

export const TagLabel = ({ ...rest }: TagLabelProps) => (
  <TagLabelChakra {...rest} fontSize="xs" />
)

export const TagCloseButton = ({ ...rest }) => (
  <TagCloseButtonChakra
    ml={2}
    fontSize={12}
    width="20px"
    height="20px"
    bg="#6A6C72"
    color="white"
    opacity={1}
    rounded="full"
    {...rest}
  />
)

export const Tag = ({
  title,
  onClick,
  children,
  disabled,
  ...rest
}: TagType): JSX.Element => (
  <TagChakra px={3} py={1} {...rest} opacity={disabled ? 0.2 : 1}>
    {title && <TagLabel>{title}</TagLabel>}
    {onClick && (
      <TagCloseButton
        isDisabled={disabled}
        onClick={disabled ? null : () => onClick()}
      />
    )}
    {children}
  </TagChakra>
)
