import create from 'zustand'

type SidebarStateType = {
  opened: boolean
  changeState: (_state: boolean) => void
}

const useSidebarStore = create<SidebarStateType>((set) => ({
  changeState: (opened) => set(() => ({ opened })),
  opened: true
}))

export default useSidebarStore
