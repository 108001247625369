import { Box, Flex, HStack, useBoolean } from '@chakra-ui/react'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'

import {
  Filters as FilterCheckbox,
  FiltersOptionType
} from 'components/organisms/Filters'

const checboxFilterOptions: FiltersOptionType[] = [
  {
    button: 'NPS',
    filters: {
      disableSearch: true,
      field: 'nps',
      filterType: 'checkbox',
      filtersOptions: [
        { value: 'false', label: 'Não enviado' },
        { value: 'true', label: 'Enviado' }
      ]
    }
  },
  {
    button: 'Placement',
    filters: {
      disableSearch: true,
      field: 'is_placement',
      filterType: 'checkbox',
      filtersOptions: [
        { value: 'false', label: 'Não' },
        { value: 'true', label: 'Sim' }
      ]
    }
  }
]

export const Filters = ({
  nameState,
  type,
  onChangeName,
  loading,
  setFilters,
  filters
}: any) => {
  const [filterName, toggleFilterName] = useBoolean()

  const handleFilter = (values: {}) => {
    setFilters((currentFilters: any) => ({
      ...currentFilters,
      ...values
    }))
  }

  return (
    <Box>
      <Flex flexDir="row" gap="3">
        {type !== 'candidate' && (
          <HStack>
            <Button
              onClick={() => {
                onChangeName('')
                toggleFilterName.toggle()
              }}
              variant="solid"
              bg={filterName ? 'brand.500' : 'white'}
              size="xs"
              px={5}
              borderRadius="md"
              fontWeight="400"
              height={30}>
              Código da vaga
            </Button>
          </HStack>
        )}
        {type === 'candidate' && (
          <HStack>
            <FilterCheckbox
              options={checboxFilterOptions}
              filters={filters}
              onClearFilters={() => setFilters({})}
              onFilter={handleFilter}
            />
          </HStack>
        )}
      </Flex>
      {filterName && (
        <HStack alignItems="flex-start" w="100%" mt="3">
          <Input
            loading={loading}
            type="text"
            variant="filled"
            id="name"
            placeholder="Código"
            bg="white"
            borderColor="white"
            size="xs"
            height={30}
            value={nameState}
            onChange={(e) => onChangeName(e.target.value)}
            _hover={{ background: 'white' }}
            _focus={{ background: 'white' }}
          />
          <Button p="6" variant="link" onClick={() => onChangeName('')}>
            limpar
          </Button>
        </HStack>
      )}
    </Box>
  )
}
