import { Box, Flex, Text } from '@chakra-ui/react'

import { useState } from 'react'

import { Button } from '../../../../components/atoms/Button'
import { Input } from '../../../../components/atoms/Input'

interface ListInputInterface {
  onClose: () => void
  onAddItem: (term: string) => void
}

export const ListInput = ({ onClose, onAddItem }: ListInputInterface) => {
  const [inputState, setInputState] = useState('')
  const [error, setError] = useState(false)

  const onConfirmAddItem = () => {
    onAddItem(inputState)
    setInputState('')
    onClose()
  }

  const handleClickButton = (type?: string) => {
    if (type === 'confirm') {
      inputState !== '' ? onConfirmAddItem() : setError(true)
      return
    }

    onClose()
    setInputState('')
  }

  return (
    <Box>
      <Flex flexDir="column" justifyContent="center">
        <Input
          placeholder={
            error ? 'Preencha o campo para confirmar' : 'Insira novo item aqui'
          }
          _placeholder={{ fontWeight: '400' }}
          _focus={{
            borderColor: 'neutralDark.200 '
          }}
          type="search"
          variant="filled"
          mt="6"
          borderWidth="1px"
          borderColor={error ? 'negative.500' : 'neutralDark.200 '}
          borderRadius="md"
          backgroundColor="none"
          fontWeight="400 !important"
          value={inputState}
          onChange={(e) => setInputState(e.target.value)}
          onFocus={() => setError(false)}
          w="100%"
        />
        <Flex>
          <Button
            h="28"
            borderRadius="base"
            bg="neutralLight.400"
            mt="6"
            ml="10"
            onClick={() => handleClickButton('confirm')}>
            <Text fontSize="sm" fontWeight="400" px="3" color="neutralDark">
              Confirmar
            </Text>
          </Button>
          <Button
            h="28"
            borderRadius="base"
            bg="none"
            mt="6"
            ml="9"
            onClick={() => handleClickButton()}>
            <Text fontSize="sm" fontWeight="400" px="3" color="neutralDark">
              Cancelar
            </Text>
          </Button>
        </Flex>

        {/* <Button
          disabled={false}
          type="submit"
          w="28"
          variant="solid"
          colorScheme="brand"
          borderRadius="lg"
          size="md"
          marginY="8"
          onClick={() => {
            inputState !== '' && onAddItem(inputState)
            onClose()
            setInputState('')
          }}>
          Salvar
        </Button> */}
      </Flex>
    </Box>
  )
}
