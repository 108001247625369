/* eslint-disable camelcase */
/* eslint-disable indent */
import {
  Box,
  ButtonGroup,
  Divider,
  FormLabel,
  Heading,
  IconButton,
  Spinner,
  VStack,
  useToast
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import Underline from '@tiptap/extension-underline'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Tabs } from 'components/atoms/Tabs'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { VerticalTypes } from 'enum/user'
import { useEditJob } from 'hooks/useEditJob'
import { useErrorToast } from 'hooks/useErroToast'
import { formSchema } from 'modules/Candidates'
import type { Candidate as CandidateType } from 'modules/Candidates/types'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FiBold, FiItalic, FiList, FiUnderline } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'
import useUserStore from 'stores/user'
import { useFetch } from 'use-http'
import { transformDate, transformDatePayload } from 'utils/formatDate'
import { Candidate } from './components/Interview/Candidate'
import { Form } from './components/Interview/Form'
import { InterviewHeader } from './components/Interview/Header'

const EditorBar = ({ editor }: any) => {
  if (!editor) {
    return null
  }

  return (
    <ButtonGroup width="100%" spacing="1">
      <IconButton
        variant={!editor?.isActive('bold') ? 'outline' : 'solid'}
        isDisabled={!editor?.can().chain().focus().toggleBold().run()}
        aria-label="bold"
        onClick={() => editor?.chain().focus().toggleBold().run()}
        icon={<FiBold />}
        borderRadius="lg"
      />
      <IconButton
        variant={!editor?.isActive('italic') ? 'outline' : 'solid'}
        isDisabled={!editor?.can().chain().focus().toggleItalic().run()}
        aria-label="bold"
        onClick={() => editor?.chain().focus().toggleItalic().run()}
        icon={<FiItalic />}
        borderRadius="lg"
      />
      <IconButton
        variant={!editor?.isActive('underline') ? 'outline' : 'solid'}
        isDisabled={!editor?.can().chain().focus().toggleUnderline().run()}
        aria-label="bold"
        onClick={() => editor?.chain().focus().toggleUnderline().run()}
        icon={<FiUnderline />}
        borderRadius="lg"
      />
      <IconButton
        variant={!editor?.isActive('bulletList') ? 'outline' : 'solid'}
        aria-label="bold"
        onClick={() => editor?.chain().focus().toggleBulletList().run()}
        icon={<FiList />}
        borderRadius="lg"
      />
    </ButtonGroup>
  )
}

export const InterviewJob = () => {
  const navigate = useNavigate()
  const onError = useErrorToast()
  const toast = useToast()
  const { id } = useUserStore()

  const { uuid, jobId }: any = useParams()

  const [updateDataCandidate, setUpdateDataCandidate] = useState<boolean>(false)

  const { get, loading, data: job } = useFetch(Endpoints.jobs.findOne(jobId))
  const {
    put: updateJob,
    loading: loadingJob,
    response: responseJob
  } = useFetch(Endpoints.jobs.update(jobId))

  const {
    put: updateCandidate,
    get: getCandidate,
    loading: loadingCandidate,
    data: candidate
  } = useFetch(Endpoints.usersCandidate.userById(uuid))

  const {
    get: getInterview,
    loading: loadingInterview,
    data: interview
  } = useFetch(Endpoints.interviewForm.root(uuid, jobId))

  const {
    loading: loadingCreateInterview,
    post,
    put
  } = useFetch(Endpoints.interviewForm.create(jobId, uuid))

  useEffect(() => {
    get()
    getCandidate()
    getInterview()
  }, [get, getCandidate, getInterview])

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(formSchema)
  })

  const canEdit = useEditJob(job)

  const handleForecast = async (forecast: string) => {
    await updateJob({
      forecast
    })

    if (responseJob.status >= 400) {
      onError({
        title: 'Não foi possível atualizar o forecast.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Forecast atualizado com sucesso.',
      variant: 'subtle'
    })
  }

  const onSubmit = useCallback(
    async (data: any) => {
      const {
        additional_observations,
        specific_question,
        interest_level,
        // desired_benefits,
        is_particapting_other_process,
        interview_form_job_agenda,
        start_date_availability,
        candidate_is_in_other_job_process,
        objections_and_motivations,
        // candidate
        linkedin_profile_url,
        gender,
        civil_state,
        name,
        country,
        state,
        city,
        email,
        professional_resume,
        available_change_city,
        available_home_office,
        available_travel,
        last_qualifier_at,
        last_qualifier_id,
        birth_date,
        salary_expectation,
        salary_currency,
        cpf,
        phone,
        verticals,
        professional_experiences,
        superior_courses,
        candidate_technical_or_complementary_courses,
        languages,
        candidate_hard_skills,
        notes
      } = data

      if (!interview && !interview.id && !updateDataCandidate) {
        await post({
          start_date_availability: start_date_availability
            ? new Date(transformDatePayload(start_date_availability))
            : null,
          additional_observations,
          specific_question,
          interest_level,
          candidate_is_in_other_job_process,
          notes,
          // desired_benefits: desired_benefits.map(
          //   ({ value }: { value: string }) => value
          // ),
          ...(objections_and_motivations && {
            objections_and_motivations: objections_and_motivations ?? null
          }),
          is_particapting_other_process,
          interview_form_job_agenda: interview_form_job_agenda.map(
            (agenda: any) => ({
              ...agenda,
              date: agenda?.date
                ? new Date(transformDatePayload(agenda.date))
                : null
            })
          )
        })
      } else {
        await put({
          start_date_availability: start_date_availability
            ? new Date(transformDatePayload(start_date_availability))
            : null,
          additional_observations,
          specific_question,
          interest_level,
          notes,
          is_particapting_other_process,
          ...(objections_and_motivations?.filter(
            (history: { comment: string }) => history?.comment !== ''
          )?.length > 0 && {
            objections_and_motivations:
              [
                ...objections_and_motivations,
                ...interview.interview_form_job[0].objections_and_motivations.map(
                  (history: {
                    comment: string
                    type: string
                    user_yw_id: string
                  }) => ({
                    comment: history.comment,
                    type: history.type,
                    user_yw_id: history.user_yw_id
                  })
                )
              ] ?? null
          }),
          // desired_benefits: desired_benefits.map(
          //   ({ value }: { value: string }) => value
          // ),
          interview_form_job_agenda: interview_form_job_agenda.map(
            ({ interview_form_job_id, ...agenda }: any) => ({
              ...agenda,
              date: agenda?.date
                ? new Date(transformDatePayload(agenda.date))
                : null
            })
          ),
          candidate_is_in_other_job_process:
            candidate_is_in_other_job_process.map(
              ({ interview_form_job_id, id, ...process }: any) => ({
                ...process
              })
            )
        })
      }

      if (updateDataCandidate) {
        await updateCandidate({
          linkedin_profile_url,
          gender,
          civil_state: civil_state !== '' ? civil_state : null,
          name,
          country: country !== '' ? country : null,
          state: state !== '' ? state : null,
          city,
          email,
          professional_resume,
          available_change_city: JSON.parse(available_change_city),
          available_home_office: JSON.parse(available_home_office),
          available_travel: JSON.parse(available_travel),
          birth_date: birth_date ?? null,
          last_qualifier_at: last_qualifier_at ?? null,
          ...(last_qualifier_id && {
            last_qualifier: last_qualifier_id
          }),
          salary_expectation:
            typeof salary_expectation === 'string'
              ? Number(salary_expectation.replace(/\D/g, ''))
              : salary_expectation,
          salary_currency: salary_currency !== '' ? salary_currency : null,
          cpf: cpf?.replace(/\D/g, ''),
          phone: phone?.replace(/\D/g, ''),
          ...(verticals && {
            verticals: verticals.map(({ value }: { value: string }) =>
              value === 'TECH' ? 'TECH_1' : value
            )
          }),
          ...(professional_experiences && {
            professional_experiences: professional_experiences.map(
              ({
                candidate_id,
                professional_experience_id,
                benefits,
                company,
                ...experiences
              }: CandidateType['professional_experiences']) => ({
                ...experiences,
                benefits: benefits.map((b) => ({
                  benefit_id: b.benefit_id,
                  value: b.value
                })),
                started_at: experiences?.started_at
                  ? new Date(transformDatePayload(experiences.started_at))
                  : null,
                end_at: experiences?.end_at
                  ? new Date(transformDatePayload(experiences.end_at))
                  : null,
                salary:
                  typeof experiences?.salary === 'string'
                    ? Number(experiences.salary.replace(/\D/g, ''))
                    : experiences.salary
              })
            )
          }),
          ...(superior_courses && {
            superior_courses: superior_courses.map(
              ({
                candidate_id,
                superior_course,
                university,
                ...superiorCourse
              }: CandidateType['superior_courses']) => ({
                ...superiorCourse,
                start_date: superiorCourse?.start_date
                  ? new Date(transformDatePayload(superiorCourse.start_date))
                  : null,
                end_date: superiorCourse.end_date
                  ? new Date(transformDatePayload(superiorCourse.end_date))
                  : null
              })
            )
          }),
          ...(candidate_technical_or_complementary_courses && {
            candidate_technical_or_complementary_courses:
              candidate_technical_or_complementary_courses.map(
                ({
                  candidate_id,
                  complementary_course_id,
                  complementary_course,
                  ...complementaryCourse
                }: CandidateType['candidate_technical_or_complementary_courses']) => ({
                  ...complementaryCourse,
                  complementary_course:
                    complementary_course?.title ?? complementary_course ?? '',
                  start_date: complementaryCourse?.start_date
                    ? new Date(
                        transformDatePayload(complementaryCourse.start_date)
                      )
                    : null,
                  end_date: complementaryCourse.end_date
                    ? new Date(
                        transformDatePayload(complementaryCourse.end_date)
                      )
                    : null
                })
              )
          }),
          ...(languages && {
            languages: languages.map(
              ({
                language_id,
                level
              }: {
                language_id: string
                level: string
              }) => ({
                language_id,
                level
              })
            )
          }),
          ...(candidate_hard_skills.length && {
            candidate_hard_skills: candidate_hard_skills.map(
              ({ id, value }: { id: string; value?: string }) => id ?? value
            )
          })
        })

        setUpdateDataCandidate(false)
      }

      await toast({
        containerStyle: {
          maxWidth: '312px'
        },
        position: 'top-right',
        status: 'success',
        title: 'Formulário atualizado com sucesso',
        variant: 'subtle'
      })

      navigate(0)
    },
    [
      interview,
      navigate,
      post,
      put,
      toast,
      updateCandidate,
      updateDataCandidate
    ]
  )

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content:
      interview?.interview_form_job?.[0]?.notes ?? methods?.watch?.('notes'),
    onUpdate: ({ editor: e }) => methods.setValue('notes', e.getHTML())
  })

  useEffect(() => {
    candidate &&
      methods.reset({
        ...candidate,
        salary_expectation: candidate?.salary_expectation,
        available_travel: candidate?.available_travel?.toString(),
        available_change_city: candidate?.available_change_city?.toString(),
        available_home_office: candidate?.available_home_office?.toString(),
        verticals: candidate?.verticals?.map(({ vertical }: any) => ({
          label: VerticalTypes[vertical],
          value: vertical
        })),
        last_qualifier_at: candidate?.last_qualifier_at
          ? transformDate(candidate.last_qualifier_at?.split('T')?.[0])
          : null,
        birth_date: candidate?.birth_date
          ? transformDate(candidate.birth_date?.split('T')?.[0])
          : null,
        professional_experiences: candidate?.professional_experiences?.map(
          ({ company, ...experience }: any) => ({
            ...experience,
            company: {
              label: company?.company_name,
              value: company?.id
            },
            benefits: experience?.benefits?.map((benefit: any) => ({
              benefit_id: benefit.benefit_id,
              value: benefit.value
            })),
            salary: experience.salary,
            started_at: experience?.started_at
              ? transformDate(experience.started_at?.split('T')?.[0])
              : null,
            end_at: experience?.end_at
              ? transformDate(experience.end_at?.split('T')?.[0])
              : null
          })
        ),
        superior_courses: candidate?.superior_courses?.map(
          (superiorCourse: any) => ({
            ...superiorCourse,
            start_date: superiorCourse?.start_date
              ? transformDate(superiorCourse.start_date?.split('T')?.[0])
              : null,
            end_date: superiorCourse?.end_date
              ? transformDate(superiorCourse.end_date?.split('T')?.[0])
              : null
          })
        ),
        candidate_technical_or_complementary_courses:
          candidate?.candidate_technical_or_complementary_courses?.map(
            (complementaryCourse: any) => ({
              ...complementaryCourse,
              complementary_course:
                complementaryCourse?.complementary_course?.title ?? '',
              start_date: complementaryCourse?.start_date
                ? transformDate(complementaryCourse.start_date?.split('T')?.[0])
                : null,
              end_date: complementaryCourse?.end_date
                ? transformDate(complementaryCourse.end_date?.split('T')?.[0])
                : null
            })
          ),
        candidate_hard_skills: candidate?.candidate_hard_skills?.map(
          ({
            hardskill: { id, name }
          }: {
            hardskill: { id: string; name: string }
          }) => ({
            label: name,
            value: id
          })
        ),
        objections_and_motivations: [
          {
            comment: '',
            user_yw_id: id,
            type: 'OBJECTION'
          },
          {
            comment: '',
            user_yw_id: id,
            type: 'MOTIVATION'
          }
        ],
        notes: interview?.interview_form_job?.[0]?.notes ?? '',
        start_date_availability: interview?.interview_form_job?.[0]
          ?.start_date_availability
          ? transformDate(
              interview.interview_form_job?.[0]?.start_date_availability?.split(
                'T'
              )?.[0]
            )
          : null,
        candidate_is_in_other_job_process:
          interview?.interview_form_job?.[0]
            ?.candidate_is_in_other_job_process ?? [],
        additional_observations:
          interview?.interview_form_job?.[0]?.additional_observations ?? '',
        specific_question:
          interview?.interview_form_job?.[0]?.specific_question ?? '',
        interest_level: interview?.interview_form_job?.[0]?.interest_level ?? 0,
        // desired_benefits:
        //   interview?.interview_form_job?.[0]?.desired_benefits.map(
        //     (b: any) => ({
        //       label: b.benefit.name,
        //       value: b.benefit_id
        //     })
        //   ) ?? [],
        is_particapting_other_process:
          interview?.interview_form_job?.[0]?.is_particapting_other_process ??
          false,
        interview_form_job_agenda:
          interview?.interview_form_job?.[0]?.interview_form_job_agenda.map(
            (agenda: any) => ({
              ...agenda,
              date: agenda?.date
                ? transformDate(agenda?.date?.split('T')?.[0])
                : null
            })
          ) ?? [{ date: '', format: null, observations: '' }]
      })

    editor?.commands.setContent(interview?.interview_form_job?.[0]?.notes)
  }, [candidate, interview, methods, id, editor])

  return (
    <FormProvider {...methods}>
      <VStack spacing={2} alignItems="flex-start">
        <HeaderPage title="Forms de entrevista" />
        <Heading as="h2" color="blackText" fontSize={14}>
          {[job?.title, candidate?.name].filter((s) => s).join(' • ')}
        </Heading>
      </VStack>
      {loading && loadingCandidate && loadingInterview ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Box>
      ) : (
        <Fragment>
          <Box width="100%" bg="white" flex={1} borderRadius="lg" p={6} my={6}>
            <InterviewHeader
              data={job}
              onChangeForecast={handleForecast}
              loading={loadingJob}
            />
          </Box>
          <Tabs
            tabs={[
              {
                content: (
                  <Form
                    canEdit={canEdit}
                    data={interview}
                    onSubmit={methods.handleSubmit(onSubmit)}
                    loading={loadingCreateInterview}
                  />
                ),
                title: 'Form de entrevista'
              },
              {
                content: (
                  <Candidate
                    canEdit={canEdit}
                    onSubmit={methods.handleSubmit(onSubmit)}
                    loading={loadingCandidate}
                    onUpdate={setUpdateDataCandidate}
                  />
                ),
                title: 'Candidato'
              },
              {
                content: (
                  <>
                    <Box
                      bg="white"
                      flex={1}
                      borderRadius="lg"
                      height="100%"
                      py={12}
                      px={6}
                      pointerEvents={canEdit ? 'auto' : 'none'}>
                      <FormLabel>Observações</FormLabel>
                      <VStack width="100%" spacing={4} alignItems="flex-start">
                        <EditorBar editor={editor} />
                        <Box width="100%">
                          <EditorContent editor={editor} />
                        </Box>
                      </VStack>
                    </Box>
                    <VStack
                      spacing={6}
                      pb={6}
                      align={{ base: 'center', md: 'flex-end' }}>
                      <Divider />
                      <Button
                        loading={loadingCandidate}
                        type="button"
                        width={230}
                        variant="solid"
                        px={5}
                        colorScheme="brand"
                        onClick={() => onSubmit(methods.getValues())}>
                        Salvar
                      </Button>
                    </VStack>
                  </>
                ),
                title: 'Notas'
              }
            ]}
          />
        </Fragment>
      )}
    </FormProvider>
  )
}
