import { useToast } from '@chakra-ui/react'

export const useErrorToast = () => {
  const toast = useToast()

  return ({ title, description }: { title: string; description?: string }) =>
    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      description,
      position: 'top-right',
      status: 'warning',
      title,
      variant: 'subtle'
    })
}
