import {
  Box,
  Divider,
  FormLabel,
  HStack,
  Heading,
  Switch,
  VStack
} from '@chakra-ui/react'
import { Breadcrumb } from 'components/atoms/Breadcrumb'
import { Button } from 'components/atoms/Button'
import { Select } from 'components/atoms/Select'
import { Fragment } from 'react'
import { Tooltip } from '../../components/atoms/Tooltip'

export const PermissionsProfile = () => (
  <Fragment>
    <VStack spacing={10} align="flex-start">
      <Box width="100%">
        <HStack spacing={6}>
          <Breadcrumb
            menus={[
              {
                current: false,
                slug: '/',
                title: 'Home'
              },
              {
                current: true,
                slug: '/perfil/permissioes',
                title: 'Meu Perfil'
              }
            ]}
          />

          <Tooltip label="Em breve" placement="top-start">
            <Button
              isDisabled
              variant="solid"
              colorScheme="brand"
              onClick={() => {}}
              height={9}
              size="sm">
              solicitar acesso
            </Button>
          </Tooltip>
        </HStack>
      </Box>

      <Heading as="h2" fontSize="xl">
        Em breve
      </Heading>

      <Box width="100%" opacity={0.7}>
        <VStack spacing={6} align="flex-start">
          <Heading as="h2" fontSize="lg">
            Verticais/time
          </Heading>

          <HStack width="100%" spacing={3}>
            <HStack spacing={3} w={215} mr={6}>
              <Switch isDisabled colorScheme="brand" id="leader" />

              <FormLabel
                fontWeight="bold"
                fontSize="md"
                color="black"
                htmlFor="leader">
                Líder de time?
              </FormLabel>
            </HStack>

            <Select isDisabled variant="filled" placeholder="Localidade">
              <option value="">São Paulo</option>
            </Select>
          </HStack>

          <Divider style={{ borderColor: '#E9E9E9', borderWidth: 1 }} />
        </VStack>
      </Box>

      <Box width="100%" opacity={0.7}>
        <VStack spacing={6} align="flex-start">
          <Heading as="h2" fontSize="lg">
            Área
          </Heading>

          <Select isDisabled variant="filled" placeholder="Selecione">
            <option value="">Backoffice</option>
          </Select>

          <Divider style={{ borderColor: '#E9E9E9', borderWidth: 1 }} />
        </VStack>
      </Box>

      <Box width="100%" opacity={0.7}>
        <VStack spacing={6} align="flex-start">
          <Heading as="h2" fontSize="lg">
            Time/Vertical YW
          </Heading>

          <Select isDisabled variant="filled" placeholder="Selecione">
            <option value="">Backoffice</option>
          </Select>
        </VStack>
      </Box>
    </VStack>
  </Fragment>
)
