import { Box, Divider, Flex, Heading, VStack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Endpoints } from 'api/endpoints'
import { Auth } from 'aws-amplify'
import { Breadcrumb } from 'components/atoms/Breadcrumb'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useUserStore from 'stores/user'
import useFetch from 'use-http'
import { getFirstName } from 'utils/strings'
import * as yup from 'yup'
import { Photo } from './components/Photo'

const formSchema = yup.object().shape({
  oldPassword: yup.string().required('Campo obrigatório'),
  newPassword: yup.string().required('Campo obrigatório'),
  newPasswordConfirmation: yup.string().required('Campo obrigatório')
})

export const GeneralProfile = () => {
  const { post: login } = useFetch(Endpoints.auth.login)

  const [loading, setLoading] = useState(false)
  const onError = useErrorToast()
  const { user, setUser } = useUserStore()
  const toast = useToast()

  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(formSchema)
  })
  const { errors } = formState

  const onSubmit = async (data: any) => {
    setLoading(true)

    const { oldPassword, newPassword, newPasswordConfirmation } = data
    if (newPassword !== newPasswordConfirmation) return

    if (!user) return
    const { email, password } = user
    await Auth.signIn(email, password)
    const authenticatedUser = await Auth.currentAuthenticatedUser()

    try {
      const userLogged = await login({ email, password })
      await Auth.changePassword(authenticatedUser, oldPassword, newPassword)
      const userToSet = {
        ...userLogged,
        user: {
          ...userLogged.user,
          password: newPassword
        }
      }
      if (userLogged) setUser(userToSet)
      setLoading(false)
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        position: 'top-right',
        status: 'success',
        title: 'Senha alterada. Faça login utilizando a nova senha.',
        variant: 'subtle'
      })
    } catch (err) {
      setLoading(false)
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: 'Tente novamente.',
        position: 'top-right',
        status: 'warning',
        title: 'Não foi possível alterar!',
        variant: 'subtle'
      })
    }
  }

  useEffect(() => {
    Object.keys(errors).length > 0 &&
      onError({
        title: 'Não foi possível salvar.',
        description: 'Preencha os campos obrigatórios, tente novamente.'
      })
  }, [onError, errors])

  return (
    <Fragment>
      <VStack spacing={10} align="flex-start" height="100%">
        <Box width="100%">
          <Breadcrumb
            menus={[
              {
                current: false,
                slug: '/',
                title: 'Home'
              },
              {
                current: true,
                slug: '/perfil/geral',
                title: 'Meu Perfil'
              }
            ]}
          />
        </Box>
        <Box w={350}>
          <Heading>Olá, {getFirstName(user?.name ?? '')}</Heading>
        </Box>

        <Heading as="h2" fontSize="xl">
          Em breve
        </Heading>

        <Box w={350} opacity={0.7}>
          <VStack spacing={6} align="flex-start">
            <Heading as="h2" fontSize="lg">
              O que eu faço
            </Heading>

            <Input
              isDisabled
              variant="filled"
              label="Deixa a empresa saber o que você faz"
              name="position"
              type="text"
              placeholder="Digite aqui"
              tabIndex={0}
            />
            <Divider style={{ borderColor: '#E9E9E9', borderWidth: 1 }} />
          </VStack>
        </Box>

        <Flex alignItems="center" width="100%" wrap="wrap" opacity={0.7}>
          <Box width={350}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack h={300} spacing={6} align="flex-start">
                <Heading as="h2" fontSize="lg">
                  Senha de acesso
                </Heading>

                <Controller
                  name="oldPassword"
                  control={control}
                  render={({ field: { ref, value, ...props } }) => (
                    <Input
                      {...props}
                      errorMessage={errors?.oldPassword?.message}
                      variant="filled"
                      label="Digite sua senha antiga"
                      type="password"
                      placeholder="Digite aqui"
                      value={value ?? ''}
                      tabIndex={0}
                    />
                  )}
                />

                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field: { ref, value, ...props } }) => (
                    <Input
                      {...props}
                      errorMessage={errors?.newPassword?.message}
                      variant="filled"
                      label="Insira sua nova senha"
                      type="password"
                      placeholder="Digite aqui"
                      value={value ?? ''}
                      tabIndex={0}
                    />
                  )}
                />

                <Controller
                  name="newPasswordConfirmation"
                  control={control}
                  render={({ field: { ref, value, ...props } }) => (
                    <Input
                      {...props}
                      errorMessage={errors?.newPasswordConfirmation?.message}
                      variant="filled"
                      label="Confirme sua nova senha"
                      type="password"
                      placeholder="Digite aqui"
                      value={value ?? ''}
                      tabIndex={0}
                    />
                  )}
                />
              </VStack>

              <Button
                loading={loading}
                type="submit"
                variant="solid"
                colorScheme="brand"
                width="100%">
                Salvar
              </Button>
            </form>
          </Box>

          <Photo />
        </Flex>
      </VStack>
    </Fragment>
  )
}
