import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Divider,
  HStack,
  Heading,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import { Button } from 'components/atoms/Button'
import { Select } from 'components/atoms/Select'
import { Tabs } from 'components/atoms/Tabs'
import { Tag } from 'components/atoms/Tag'
import { Fragment, useRef } from 'react'

const Area = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const cancelRef: any = useRef()

  return (
    <Fragment>
      <Box width="100%" p={4}>
        <VStack width="100%" spacing={8}>
          <Select isMulti options={[]} placeholder="Digite aqui" />
          <Divider color="#E9E9E9" />
          <HStack width="100%" spacing={4}>
            <Tag disabled title="Recrutamento" size="lg" variant="solid" />
            <Tag disabled title="Backoffice" size="lg" variant="solid" />
            <Tag disabled title="Master" size="lg" variant="solid" />
            <Tag
              disabled
              title="Suporte plataforma"
              size="lg"
              variant="solid"
            />
            <Tag
              title="Area"
              size="lg"
              variant="solid"
              onClick={() => onOpen()}
            />
          </HStack>
          <Divider color="#E9E9E9" />
          <Box width="100%">
            <Heading as="h2" color="#1B1C1E" fontSize="lg" mb={4}>
              Sub-área
            </Heading>
            <Select isMulti options={[]} placeholder="Digite aqui" />
          </Box>
          <HStack width="100%" spacing={4}>
            <Tag
              disabled
              title="Recrutamento/Recrutador"
              size="lg"
              variant="solid"
            />
            <Tag
              disabled
              title="Recrutamento/CS Express"
              size="lg"
              variant="solid"
            />
          </HStack>
        </VStack>
      </Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        size="3xl"
        isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader fontSize="xl" px={8} py={6}>
            Excluir lista
            <Divider mt={6} borderBottom="2px" borderBottomColor="#E9E9E9" />
          </AlertDialogHeader>
          <AlertDialogCloseButton
            top={6}
            right={6}
            color="#C8CCD4"
            fontSize="md"
          />
          <AlertDialogBody pt={0} px={8} fontSize="md" color="#8F8F8F">
            Tem certeza que deseja excluir este item da lista? <br />
            Todas as informações serão perdidas e será necessário adicionar
            novamente.
          </AlertDialogBody>
          <AlertDialogFooter px={8} pb={8} mt={16}>
            <Button
              width={57.5}
              variant="outline"
              colorScheme="brand"
              ref={cancelRef}
              onClick={onClose}>
              Cancelar
            </Button>
            <Button width={57.5} colorScheme="brand" ml={6}>
              Excluir e fechar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  )
}

export const YellowEnvironment = () => (
  <Fragment>
    <Box bg="white" width="full" height="full" borderRadius="lg" pb={4} px={4}>
      <Tabs
        tabs={[
          {
            content: <Area />,
            title: 'Área'
          }
          // {
          //   content: <></>,
          //   title: 'Vertical'
          // },
          // {
          //   content: <></>,
          //   title: 'Unidade Yellow'
          // },
          // {
          //   content: <></>,
          //   title: 'Produto Yellow'
          // }
        ]}
      />
    </Box>
  </Fragment>
)
