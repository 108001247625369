import {
  FormControl,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
  Stack,
  Text
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { RefObject, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '../../../components/atoms/Button'
import { Input } from '../../../components/atoms/Input'

import { minLength, passwordComplexityRegex } from '../../../utils/validations'

export type FormDataType = {
  code: string
  password: string
}

type FormType = {
  formRef: RefObject<HTMLFormElement>
  onSubmit: (_payload: FormDataType) => void
  loading: boolean
}

const formSchema = yup.object().shape({
  code: yup.string().min(6, 'Código inválido').required('Campo obrigatório'),
  confirmPassword: yup
    .string()
    .required('Campo obrigatório')
    .oneOf([yup.ref('password'), null], 'As senhas não conferem'),
  password: yup
    .string()
    .matches(
      passwordComplexityRegex,
      `A senha precisa conter mínimo de 8 caracteres, uma letra
      maiúscula, uma letra minúscula, um número e um caractere especial`
    )
    .min(minLength, 'min')
    .required('Campo obrigatório')
})

export const Form = ({ formRef, onSubmit, loading }: FormType) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema)
  })

  const handleSubmitForm = useCallback(() => {
    formRef?.current?.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    )
  }, [formRef])

  return (
    <>
      <form
        ref={formRef}
        onSubmit={handleSubmit((data: any) => onSubmit(data))}>
        <Stack spacing={9}>
          <Controller
            control={control}
            name="code"
            rules={{ required: true }}
            render={({ field: { ref, value, ...props } }) => (
              <FormControl>
                <FormLabel>Código de verificação</FormLabel>
                <HStack justifyContent="space-between">
                  <PinInput
                    variant="filled"
                    size="lg"
                    placeholder=""
                    focusBorderColor="transparent"
                    value={value ?? ''}
                    isInvalid={errors?.code !== undefined}
                    {...props}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field: { ref, value, ...props } }) => (
              <Input
                label="Insira sua nova senha"
                type="password"
                placeholder="Digite aqui"
                variant="filled"
                id="password"
                tabIndex={0}
                value={value ?? ''}
                isInvalid={errors?.password !== undefined}
                errorMessage={errors?.password?.message}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="confirmPassword"
            rules={{ required: true }}
            render={({ field: { ref, value, ...props } }) => (
              <Input
                label="Confirme sua nova senha"
                type="password"
                placeholder="Digite aqui"
                variant="filled"
                id="confirmPassword"
                tabIndex={0}
                value={value ?? ''}
                isInvalid={errors?.confirmPassword !== undefined}
                errorMessage={errors?.confirmPassword?.message}
                {...props}
              />
            )}
          />
          <Button
            disabled={!isValid}
            loading={loading}
            type="button"
            variant="solid"
            colorScheme="brand"
            onClick={() => !loading && handleSubmitForm()}
            width="100%">
            Resetar senha
          </Button>
        </Stack>
      </form>
      <Text textAlign="center" fontSize="sm" color="#4D4D4D">
        Precisa de ajuda?{' '}
        <Text as="span" fontWeight="bold" color="brand.500">
          Entre em contato
        </Text>
        .
      </Text>
    </>
  )
}
