/* eslint-disable indent */
/* eslint-disable camelcase */
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'
import { Select } from 'components/atoms/Select'
import { Textarea } from 'components/atoms/Textarea'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FiPlus, FiPlusCircle, FiTrash } from 'react-icons/fi'
import useFetch from 'use-http'
import { formatCurrency, reverseFormatNumber } from 'utils/formatCurrency'
import { JobType } from './../../../../data/jobInfo'
import { useEditJob } from '../../../../hooks/useEditJob'

type keyValueType = {
  value: string
  label: string
}

const formatBenefits = async ({ response }: any) => {
  const res = response
  const newRes: keyValueType[] = []

  res?.data?.forEach((benefits: any) => {
    const benefitsNewObject = {
      label: benefits?.name,
      value: benefits?.id
    }

    newRes?.push(benefitsNewObject)
  })
  res.data = newRes

  return res
}

const Salary = ({ control, setValue, watch }: any) => {
  const errorToast = useErrorToast()
  const [showMessage, setShowMessage] = useState(false)

  const isSalaryRangeValid = useMemo(
    () =>
      parseFloat(watch('min_salary_range')) <=
      parseFloat(watch('max_salary_range')),
    [watch('min_salary_range'), watch('max_salary_range')]
  )

  const sliderValue = useMemo(
    () => [watch('min_salary_range') || 0, watch('max_salary_range') || 0],
    [watch('min_salary_range'), watch('max_salary_range')]
  )

  const handleChange = ([min_salary_range, max_salary_range]: number[]) => {
    setValue(
      'min_salary_range',
      reverseFormatNumber(`${min_salary_range}`, 'pt-BR')
    )
    setValue(
      'max_salary_range',
      reverseFormatNumber(`${max_salary_range}`, 'pt-BR')
    )
  }

  /*  useEffect(() => {
    if (!!watch('max_salary_range') && !isSalaryRangeValid) {
      errorToast({
        title: 'Valor mínimo do salário maior que valor máximo.',
        description: 'Por favor, insira um valor menor.'
      })

      setShowMessage(true)

      setValue('min_salary_range', 0)

      setInterval(() => {
        setShowMessage(false)
      }, 3000)
    }
  }, [isSalaryRangeValid]) */

  return (
    <Fragment>
      <Heading as="h2" fontSize="lg" fontWeight="bold">
        Informações salariais
      </Heading>
      <HStack width="100%" alignItems="flex-start" spacing={8}>
        <Box flex="1">
          <Input
            label="Salário de abertura"
            variant="filled"
            placeholder="Digite aqui"
            isMoney
            name="salaryOnOpen"
            control={control}
          />
        </Box>
        <Box flex="1">
          <Controller
            control={control}
            name="contract_type"
            render={({ field: { ref, ...props } }) => (
              <Menu {...props} label="Tipo de contratação" options={JobType} />
            )}
          />
        </Box>
      </HStack>
      <HStack width="100%" alignItems="flex-start" spacing={8} mt="3">
        <Box flex="1">
          <FormLabel
            fontWeight="bold"
            fontSize="md"
            color="black"
            htmlFor=""
            m={0}>
            Tem bonificação?
          </FormLabel>
          <Switch
            colorScheme="brand"
            id=""
            isChecked={watch('has_bonus')}
            onChange={() => setValue('has_bonus', !watch('has_bonus'))}
            mt="2"
          />
          <HStack width="100%" alignItems="flex-start" spacing={4} mt={4}>
            <Input
              isMoney={watch('has_bonus')}
              isDisabled={!watch('has_bonus')}
              label="Bonificação"
              variant="filled"
              placeholder="Digite aqui"
              name="has_bonus_value"
              defaultValue={0}
              control={control}
              leading={watch('has_bonus') ? 'R$' : undefined}
              type="tel"
            />
            <Input
              isDisabled={!watch('has_bonus')}
              label="Observação"
              variant="filled"
              placeholder="Digite aqui"
              name="has_bonus_description"
              control={control}
            />
          </HStack>
        </Box>
        <Box flex="1">
          <FormLabel
            fontWeight="bold"
            fontSize="md"
            color="black"
            htmlFor=""
            m={0}>
            Tem variável?
          </FormLabel>
          <Switch
            colorScheme="brand"
            isChecked={watch('has_salary_variable')}
            onChange={() =>
              setValue('has_salary_variable', !watch('has_salary_variable'))
            }
            mt="2"
          />
          <HStack width="100%" alignItems="flex-start" spacing={4} mt={4}>
            <Input
              isDisabled={!watch('has_salary_variable')}
              label="Variável"
              variant="filled"
              placeholder="Digite aqui"
              name="has_salary_variable_value"
              control={control}
              isMoney
              leading={watch('has_salary_variable') ? 'R$' : undefined}
            />
            <Input
              isDisabled={!watch('has_salary_variable')}
              label="Observação"
              variant="filled"
              placeholder="Digite aqui"
              name="has_salary_variable_description"
              control={control}
            />
          </HStack>
        </Box>
      </HStack>
      <HStack width="100%" alignItems="flex-start" spacing={8} mt="3">
        <Box width="100%">
          <Box mt={4} height="80px">
            <FormLabel mb={4}>Defina a faixa salarial</FormLabel>
            <Box pr={4}>
              <RangeSlider
                position="relative"
                min={0}
                max={60000}
                value={sliderValue}
                onChange={handleChange}>
                <Flex
                  width="100%"
                  justifyContent="center"
                  position="absolute"
                  gap="4"
                  top={8}>
                  <Text
                    color={showMessage ? 'red' : 'black'}
                    fontWeight="600"
                    fontSize="sm"
                    textAlign="center">{`De: ${formatCurrency(
                    sliderValue[0]
                  )}`}</Text>
                  <Text
                    color={showMessage ? 'red' : 'black'}
                    fontWeight="600"
                    fontSize="sm"
                    textAlign="center">{`Até: ${formatCurrency(
                    sliderValue[1]
                  )}`}</Text>
                </Flex>
                <RangeSliderTrack h="3">
                  <RangeSliderFilledTrack bg="brand.500" />
                </RangeSliderTrack>
                <RangeSliderThumb boxSize={6} index={0} />
                <RangeSliderThumb boxSize={6} index={1} />
              </RangeSlider>
            </Box>
          </Box>
          <HStack width="100%" alignItems="flex-start" spacing={4} mt="2">
            <Input
              leading="De:"
              isMoney
              name="min_salary_range"
              control={control}
              variant="filled"
              height={120}
            />
            <Input
              leading="Até:"
              isMoney
              variant="filled"
              name="max_salary_range"
              control={control}
            />
          </HStack>
          {showMessage && (
            <Text fontSize={12} color="red" mt={2}>
              Valor mínimo do salário maior que o valor máximo.
            </Text>
          )}
        </Box>
      </HStack>
    </Fragment>
  )
}

const Benefits = ({ control }: any) => {
  const onError = useErrorToast()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'benefits'
  })

  const { data: benefits } = useFetch(
    Endpoints.lists.benefits,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os benefícios.'
        }),
      interceptors: {
        response: formatBenefits
      }
    },
    []
  )

  return (
    <Fragment>
      <VStack width="100%" spacing={4}>
        {fields.length === 0 && (
          <Box width="100%" mt={8}>
            <Button
              type="button"
              width="100%"
              variant="solid"
              onClick={() => append({ benefits_id: '', value: '' })}>
              <Icon as={FiPlusCircle} mr={2} /> Adicionar benefício
            </Button>
          </Box>
        )}
        {fields?.map((_: any, idx: number) => (
          <HStack
            width="100%"
            alignItems="flex-start"
            spacing={4}
            key={`benefit_${idx}`}>
            <Controller
              control={control}
              name={`benefits.${idx}.benefits_id`}
              render={({ field: { ref, ...props } }) => (
                <Menu {...props} label="Benefício" options={benefits} />
              )}
            />

            <Input
              type="text"
              variant="filled"
              label="Valor/Variável"
              name={`benefits.${idx}.value`}
              control={control}
            />
            <HStack width={36} pt={10} alignItems="center" spacing={4}>
              {fields.length - 1 === idx && (
                <>
                  <IconButton
                    onClick={() => remove(idx)}
                    variant="ghost"
                    size="xs"
                    colorScheme="black"
                    icon={<FiTrash fontSize="1.5rem" />}
                    aria-label="Delete"
                  />

                  <IconButton
                    isRound
                    onClick={() => append({ benefits_id: '', value: '' })}
                    variant="solid"
                    size="sm"
                    colorScheme="brand"
                    icon={<FiPlus fontSize="1.2rem" color="white" />}
                    aria-label="Add"
                  />
                </>
              )}
            </HStack>
          </HStack>
        ))}
      </VStack>
    </Fragment>
  )
}

type CompanyTypes = {
  value: string
  label: string
}

export const Detailing = ({ invoiceObs, setInvoiceObs }: any) => {
  const onError = useErrorToast()
  const { control, setValue, watch, getValues } = useFormContext()
  const canEdit = useEditJob(getValues())

  const {
    loading: loadingCreate,
    post: createCompany,
    response: resCreateCompany
  } = useFetch(Endpoints.companies['/companies'])

  const { data: companies, loading } = useFetch(
    Endpoints.companies['/companies'],
    {
      interceptors: {
        response: async ({ response }) => {
          if (response.data)
            response.data = response.data.map((company: any) => ({
              label: company.company_name,
              value: company.id
            }))
          return response
        }
      }
    },
    [resCreateCompany, loadingCreate]
  )

  const handleCreateCompanie = async (key: string, company_name: string) => {
    await createCompany({ company_name })

    if (!resCreateCompany.ok && resCreateCompany.status >= 400) {
      onError({
        title: 'Não foi possível criar empresa.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    const value: any = {
      reference_companies: {
        label: company_name,
        value: company_name
      },
      offlimits: {
        label: resCreateCompany.data.company_name,
        value: resCreateCompany.data.id
      }
    }

    setValue(key, [...getValues(key), value[key]])
  }

  return (
    <Box
      bg="white"
      flex={1}
      maxH="2xl"
      overflowY="scroll"
      borderRadius="lg"
      sx={{
        '&::-webkit-scrollbar': {
          height: '9px',
          width: '10px',
          backgroundColor: `rgba(0, 0, 0, 0.05)`
        },
        '&::-webkit-scrollbar-thumb': {
          height: '9px',
          borderRadius: '6px',
          backgroundColor: `rgba(0, 0, 0, 0.15)`
        }
      }}
      p={6}
      pointerEvents={canEdit ? 'auto' : 'none'}>
      <Flex flexDirection="column" py="6" px="2" width="100%" gap="8">
        <Heading as="h2" fontSize="lg" fontWeight="bold">
          Organograma
        </Heading>
        <HStack width="100%" spacing={4}>
          <Box flex="1">
            <FormLabel mb="3">Reporte</FormLabel>
            <Input
              variant="filled"
              placeholder="Digite aqui"
              name="organogram_report"
              control={control}
            />
          </Box>

          <Box flex="1">
            <FormLabel mb="3">Pares</FormLabel>
            <Input
              variant="filled"
              placeholder="Digite aqui"
              name="organogram_pairs"
              control={control}
            />
          </Box>

          <Box flex="1">
            <FormLabel mb="3">Equipe</FormLabel>
            <Input
              variant="filled"
              placeholder="Digite aqui"
              name="organogram_team"
              control={control}
            />
          </Box>
        </HStack>
        <Divider color="#E9E9E9" my="4" />
        <HStack width="100%" alignItems="flex-start" spacing={8}>
          <Box flex="1">
            <FormLabel mb="3">Empresa de referências</FormLabel>
            <Select
              options={
                companies?.map((company: CompanyTypes) => ({
                  value: company.label,
                  label: company.label
                })) || []
              }
              onCreateOption={(value: string) =>
                handleCreateCompanie('reference_companies', value)
              }
              loading={loading || loadingCreate}
              isDisabled={loading}
              creatable
              isMulti
              variant="filled"
              value={watch('reference_companies')}
              onChange={(value) => setValue('reference_companies', value)}
            />
          </Box>

          <Box flex="1">
            <FormLabel mb="3">Offlimits</FormLabel>

            <Select
              options={companies}
              onCreateOption={(value: string) =>
                handleCreateCompanie('offlimits', value)
              }
              loading={loading || loadingCreate}
              isDisabled={loading}
              creatable
              isMulti
              variant="filled"
              value={watch('offlimits')}
              onChange={(value) => setValue('offlimits', value)}
            />
          </Box>
        </HStack>

        <Divider color="#E9E9E9" my="4" />
        <Salary control={control} setValue={setValue} watch={watch} />
        <Benefits control={control} />

        <Textarea
          label="Observações forms de abertura"
          cols={5}
          rows={7}
          mb="2"
          variant="filled"
          placeholder="Digite aqui"
          value={invoiceObs}
          onChange={(e: any) => {
            setInvoiceObs(e.target.value)
          }}
        />
      </Flex>
    </Box>
  )
}
