export const formatYellowProducts = async ({ response }: any) => {
  const res = response
  const newRes: any = {}

  res?.data?.forEach((product: any) => {
    newRes[`${product.id}`] = product?.name
  })
  res.data = newRes

  return res
}
