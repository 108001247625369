import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  HStack,
  Icon,
  Text,
  VStack
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { FiBriefcase } from 'react-icons/fi'

const LanguageSkillLevel: { [key: string]: string } = {
  BASIC: 'Basico',
  INTERMEDIATE: 'Intermediario',
  ADVANCED: 'Avamçado',
  FLUENT: 'Fluente'
}

type ProfessionalDetailsAccordionProps = {
  superiorCourses: {
    start_date: Date
    end_date: Date
    id: string
    superior_course: {
      name: string
    }
    university: {
      name: string
    }
  }[]
  languages: {
    level: string
    language: {
      name: string
    }
  }[]
  availableTravel: boolean
  availableChangeCity: boolean
  availableHomeOffice: boolean
}

const getYesOrNo = (answer: boolean) => (answer ? 'Sim' : 'Não')

export const ProfessionalDetailsAccordion = ({
  superiorCourses,
  languages,
  availableTravel,
  availableChangeCity,
  availableHomeOffice
}: ProfessionalDetailsAccordionProps) => (
  <AccordionItem py={5} px={0} mx={4}>
    <AccordionButton>
      <Box flex="1" textAlign="left">
        <Text fontSize="md" fontWeight="medium">
          Detalhes profissionais
        </Text>
      </Box>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel pt={4}>
      {!!superiorCourses?.length && (
        <>
          {superiorCourses?.map((superiorCourse, key) => (
            <HStack key={key} mb={4}>
              <Box bg="neutralLight.300" px={3} py={2}>
                <Icon as={FiBriefcase} h={4} w={4} />
              </Box>
              <VStack alignItems="flex-start" pl={2} spacing={1}>
                <Text fontSize="sm" fontWeight="bold" lineHeight="normal">
                  {superiorCourse.superior_course.name} -{' '}
                  {superiorCourse.university.name}
                </Text>
                <Text fontSize="xxs" fontWeight="normal" lineHeight="normal">
                  {format(
                    new Date(superiorCourse.start_date),
                    "MMMM 'de' yyyy",
                    {
                      locale: ptBR
                    }
                  )}{' '}
                  -{' '}
                  {format(new Date(superiorCourse.end_date), "MMMM 'de' yyyy", {
                    locale: ptBR
                  })}
                </Text>
              </VStack>
            </HStack>
          ))}
        </>
      )}
      {!!languages?.length && (
        <>
          <Divider variant="dashed" />
          <Box my="5">
            <Text mb={4} fontSize="xs" fontWeight="600" lineHeight="normal">
              Idiomas
            </Text>
            <HStack spacing={8}>
              {languages?.map((language, index) => (
                <VStack key={index} alignItems="flex-start" spacing={1}>
                  <Text
                    fontWeight="bold"
                    fontSize="md"
                    lineHeight="normal"
                    noOfLines={2}>
                    {language.language.name}
                  </Text>
                  <Text color="grayPrimary" fontSize="xs" lineHeight="normal">
                    Nível {LanguageSkillLevel[language.level]}
                  </Text>
                </VStack>
              ))}
            </HStack>
          </Box>
        </>
      )}

      <Divider variant="dashed" />
      <HStack spacing={8} mt={5}>
        <VStack alignItems="flex-start" spacing={3}>
          <Text color="BlackText" fontSize="sm" lineHeight="normal">
            Aceita viajar a trabalho?
          </Text>
          <Text
            fontSize="sm"
            fontWeight="bold"
            lineHeight="normal"
            noOfLines={1}>
            {getYesOrNo(availableTravel)}
          </Text>
        </VStack>
        <VStack alignItems="flex-start" spacing={2}>
          <Text color="BlackText" fontSize="sm" lineHeight="normal">
            Aceita mudar de cidade?
          </Text>
          <Text
            fontSize="sm"
            fontWeight="bold"
            lineHeight="normal"
            noOfLines={1}>
            {getYesOrNo(availableChangeCity)}
          </Text>
        </VStack>
        <VStack alignItems="flex-start" spacing={2}>
          <Text color="BlackText" fontSize="sm" lineHeight="normal">
            Home office?
          </Text>
          <Text
            fontSize="sm"
            fontWeight="bold"
            lineHeight="normal"
            noOfLines={1}>
            {getYesOrNo(availableHomeOffice)}
          </Text>
        </VStack>
      </HStack>
    </AccordionPanel>
  </AccordionItem>
)
