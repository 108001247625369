import { useToast } from '@chakra-ui/react'
import { Auth } from 'aws-amplify'
import { createRef, useCallback, useState } from 'react'
import { Form, FormDataType } from './components/Form'
import { HeaderLoginForm } from '../../components/organisms/HeaderLoginForm'
import { LoginTemplate } from '../../components/templates/Login'

export const RecoveryPassword = (props: any) => {
  const toast = useToast()
  const formRef: any = createRef()

  const [showForm, setShowForm] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string | null>(null)

  const onRecovery = useCallback(
    async (email: string) => {
      setLoading(true)

      try {
        const { CodeDeliveryDetails } = await Auth.forgotPassword(email)
        setShowForm(false)
        setEmail(CodeDeliveryDetails.Destination)
        window.open(`/resetar-senha/${email}`, '_blank')
      } catch (err) {
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: `Aparentemente o seu endereço não
          consta em nossa base de dados. Entre em contato com
          alguém do time de pessoas da Yellow para maiores informações`,
          position: 'top-right',
          status: 'warning',
          title: 'E-mail não encontrado!',
          variant: 'subtle'
        })
      } finally {
        setLoading(false)
      }
    },
    [toast]
  )

  return (
    <LoginTemplate {...props}>
      <HeaderLoginForm
        title={showForm ? 'Vamos te ajudar' : 'Feito!'}
        description={
          showForm
            ? 'Enviaremos um link para o e-mail informado'
            : `Enviamos um link para o e-mail ${email}, confira sua caixa de entrada.
            Agora é só seguir as instruções e redefinir sua senha.`
        }
      />
      {showForm && (
        <Form
          formRef={formRef}
          onSubmit={({ email }: FormDataType) => onRecovery(email)}
          loading={loading}
        />
      )}
    </LoginTemplate>
  )
}
