/* eslint-disable indent */
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Tag,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import { Checkbox } from 'components/atoms/Checkbox'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

type Props = {
  total: number
  onFilter: (key: string, value: any) => void
}

export const Unity = ({ total, onFilter }: Props) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  const [selectedOptions, setSelectedOptions] = useState<boolean[]>([])

  const { control, handleSubmit } = useForm()

  const results = useMemo(
    () => [
      { id: 'BELO_HORIZONTE', title: 'Belo Horizonte' },
      { id: 'SAO_PAULO', title: 'São Paulo' },
      { id: 'RECIFE', title: 'Recife' }
    ],
    []
  )
  const allChecked = useMemo(
    () => !!selectedOptions.length && selectedOptions.every(Boolean),
    [selectedOptions]
  )
  const isIndeterminate = useMemo(
    () => !allChecked && selectedOptions.some(Boolean),
    [allChecked, selectedOptions]
  )

  const handleCheck = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setSelectedOptions((prevOptions) => {
      const newOptions = [...prevOptions]
      newOptions[index] = checked
      return newOptions
    })
  }

  const onSubmit = useCallback(() => {
    onFilter(
      'unity',
      selectedOptions
        .map((key, idx) => key && results[idx].id)
        .filter((values) => values)
    )
    onToggle()
  }, [onFilter, onToggle, results, selectedOptions])

  useEffect(() => {
    if (total === 0) {
      setSelectedOptions(results?.map(() => false))
    }
  }, [results, total])

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start">
      <PopoverTrigger>
        <Button
          type="button"
          size="lg"
          variant="ghost"
          rightIcon={<ChevronDownIcon />}
          onClick={onToggle}>
          Unidade
          {total > 0 && (
            <Tag
              bg="neutralDark"
              ml="2"
              color="white"
              borderRadius="full"
              fontSize="xs"
              textAlign="center"
              justifyContent="center">
              {total}
            </Tag>
          )}
        </Button>
      </PopoverTrigger>
      <Portal>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PopoverContent>
            <PopoverBody py={0}>
              {results?.length > 0 && (
                <VStack
                  width="100%"
                  py={2}
                  pl={2}
                  maxHeight="155px"
                  overflowX="hidden"
                  overflowY="auto">
                  <Checkbox
                    label="Selecionar todos"
                    value="all"
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={(e) =>
                      setSelectedOptions(results?.map(() => e.target.checked))
                    }
                  />
                  {results
                    .sort((a: any, b: any) =>
                      Intl.Collator('pt-BR').compare(a.title, b.title)
                    )
                    .map((option: any, idx: number) => (
                      <Controller
                        key={`customer_${idx}`}
                        control={control}
                        name={`customers[${idx}]`}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Checkbox
                            {...rest}
                            label={option.title}
                            value={option.id}
                            isChecked={selectedOptions[idx]}
                            onChange={(event) => handleCheck(event, idx)}
                          />
                        )}
                      />
                    ))}
                </VStack>
              )}
            </PopoverBody>
            <PopoverFooter>
              <Button
                type="submit"
                width="100%"
                height="32px"
                variant="solid"
                colorScheme="brand"
                borderRadius="lg"
                size="sm">
                Filtrar
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </form>
      </Portal>
    </Popover>
  )
}
