/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable camelcase */
/* eslint-disable indent */
// @ts-nocheck
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tag,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Filters } from 'components/organisms/Filters'
import currency from 'currency-formatter'
import { differenceInYears } from 'date-fns'
import { useErrorToast } from 'hooks/useErroToast'
import { useMemo, useState } from 'react'
import {
  FiChevronLeft,
  FiChevronRight,
  FiEdit,
  FiMoreVertical,
  FiRepeat
} from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import useFetch from 'use-http'
import { currentJob } from 'utils/currentJob'
import { filterOptions } from './filterOptions'
import { formatDate } from '../../../../utils/formatDate'
import { DataInfoCandidate } from '../InfoCandidate/DataInfo'

export const TabCandidateMatrix = ({ canEdit, ...job }: any) => {
  const onError = useErrorToast()
  const [checked, setCheck] = useState<string[]>([])
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<{ [key: string]: string }>({})

  const navigate = useNavigate()
  const toast = useToast()

  const {
    data,
    loading,
    get: refresh
  } = useFetch(
    Endpoints.jobs.candidatesMatrix(page, job.id) +
      `&where=${JSON.stringify(filters)}`,
    {},
    [page, filters]
  )

  const { data: longlist } = useFetch(
    Endpoints.jobs.getLonglist(job.id),
    {},
    []
  )

  const { post: onCreateLonglist, response: resCreateLonglist } = useFetch(
    Endpoints.jobs.list
  )

  const { put: onSendToLonglist, response: resSendToLonglist } = useFetch(
    Endpoints.jobs.addCandidatesToList(longlist?.data?.[0]?.id)
  )

  const canSendToLongList = useMemo(() => {
    return !!longlist && longlist.data.length > 0
  }, [longlist])

  const totalPages = useMemo(() => {
    const pages = []
    const total = data?.pageSize

    for (let i = 1; i <= total; i++) {
      pages.push({
        number: i,
        value: i
      })
    }

    return pages
  }, [data])

  const handleCreateLongList = async () => {
    await onCreateLonglist({
      job_id: job?.id,
      candidates_id: checked,
      is_long_list: true
    })

    if (!resCreateLonglist.ok && resCreateLonglist.status >= 400) {
      onError({
        title: 'Não foi possível enviar candidato(s) para longlist.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      title: 'Sucesso!',
      description: 'Candidato(s) enviado(s) para longlist.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })
  }

  const handleSendToLongList = async () => {
    await onSendToLonglist({ candidatesId: checked })

    if (!resSendToLonglist.ok && resSendToLonglist.status >= 400) {
      onError({
        title: 'Não foi possível enviar candidato(s) para longlist.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      title: 'Sucesso!',
      description: 'Candidato(s) enviado(s) para longlist.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })
  }

  const onAddToLonglist = async () => {
    if (canSendToLongList) {
      await handleSendToLongList()
      setCheck([])
      await refresh()
      return
    }

    await handleCreateLongList()
    setCheck([])
    await refresh()
  }

  const handleEditCandidate = (candidate) =>
    navigate(`/candidatos/editar/${candidate?.id}`, {
      state: {
        candidateProps: { name: candidate?.name }
      }
    })

  const handleFilter = (values: {}) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      ...values
    }))
  }

  if (!loading && filters && (data?.data?.length === 0 || !data?.data)) {
    setTimeout(() => {
      setFilters({})
      refresh()
    }, 4000)
  }

  return (
    <Flex
      flexDir="column"
      px={4}
      mt="4"
      pointerEvents={canEdit ? 'auto' : 'none'}>
      <Box
        width="100%"
        // overflowX="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            height: '9px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`
          },
          '&::-webkit-scrollbar-thumb': {
            height: '9px',
            borderRadius: '6px',
            backgroundColor: `rgba(0, 0, 0, 0.15)`
          }
        }}>
        <HStack
          alignItems="flex-start"
          width="fit-content"
          overflow="hidden"
          mb="4">
          <Filters
            bg="grayBackground"
            options={filterOptions({ state: filters?.state?.toLowerCase() })}
            filters={filters}
            onClearFilters={() => setFilters({})}
            onFilter={handleFilter}
          />
        </HStack>
      </Box>

      <Divider my={5} color="neutralLight.600" />

      <Flex justifyContent="flex-end" gap={3} mb="1.5" width="100%">
        <Button
          variant="outline"
          colorScheme="brand"
          fontSize={11}
          px="6"
          py="4"
          borderRadius="md"
          rightIcon={<FiRepeat size={14} transform="rotate(90)" />}
          onClick={() => refresh()}>
          Recarregar
        </Button>
        <Button
          variant="solid"
          bg="brand.500"
          px="6"
          py="4"
          borderRadius="md"
          fontSize={10}
          disabled={!checked.length}
          onClick={onAddToLonglist}>
          Enviar para longlist
        </Button>
      </Flex>

      <Text fontSize="sm" fontWeight="500">
        {`Lista possui ${data?.total ?? 0} candidatos.`}
      </Text>

      <Accordion allowMultiple w="full">
        {loading && (
          <Flex flexDir="column" py={8} alignItems="center">
            <Spinner />
          </Flex>
        )}

        {!loading && filters && (data?.data?.length === 0 || !data?.data) && (
          <Text width="100%" textAlign="center" py={8}>
            Não foram encontrados candidatos que se encaixam nos filtros
            aplicados. Retirando filtros...
          </Text>
        )}

        {!loading && data
          ? data?.data?.map((candidate: any, i) => {
              const actualJob = currentJob(candidate?.professional_experiences)

              const candidateAge = (c) => {
                if (c?.birth_date) {
                  return differenceInYears(
                    Date.now(),
                    new Date(c.birth_date)
                  ).toString()
                }
                return ' - '
              }

              return (
                <AccordionItem
                  mt={5}
                  key={i}
                  padding={1.25}
                  py="0.5"
                  pl="2"
                  shadow={`0px 3px 6px 0px ${
                    checked.find((c) => c === candidate?.id)
                      ? 'rgba(16, 16, 40, 0.5)'
                      : 'rgba(16, 24, 40, 0.2)'
                  } `}
                  borderRadius="lg">
                  <HStack flex="1" justifyContent="space-between" px={3} py={4}>
                    <HStack w={200}>
                      <Checkbox
                        size="xl"
                        iconSize="xxs"
                        colorScheme="brand"
                        onChange={(e) => {
                          return e.target.checked
                            ? setCheck([...checked, candidate?.id])
                            : setCheck(
                                checked.filter(
                                  (c: string) => c !== candidate?.id
                                )
                              )
                        }}
                      />

                      <VStack pl={1} alignItems="flex-start" spacing={1}>
                        <Text
                          fontWeight="bold"
                          fontSize="md"
                          lineHeight="normal"
                          noOfLines={2}>
                          {candidate?.name}
                        </Text>
                        <Text
                          color="neutralDark.300"
                          fontWeight="500"
                          fontSize="xs"
                          lineHeight="normal">
                          {`${candidateAge(candidate)} anos, `}
                          {candidate?.gender === 'MALE'
                            ? 'masculino'
                            : candidate?.gender === 'INDIFFERENT'
                            ? 'indiferente'
                            : 'feminino'}
                        </Text>
                      </VStack>
                    </HStack>
                    <VStack alignItems="flex-start" w="18%" spacing={1}>
                      <Text
                        color="neutralDark.300"
                        fontWeight="500"
                        fontSize="xs"
                        lineHeight="normal">
                        Pretensão
                      </Text>
                      <Text fontSize="sm" fontWeight="bold" lineHeight="normal">
                        {candidate?.salary_expectation
                          ? currency.format(candidate?.salary_expectation, {
                              code: candidate?.salary_currency
                            })
                          : ''}
                      </Text>
                    </VStack>
                    <VStack alignItems="flex-start" w="22%" spacing={1}>
                      <Text
                        color="neutralDark.300"
                        fontWeight="500"
                        fontSize="xs"
                        lineHeight="normal">
                        Empresa
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        lineHeight="normal"
                        noOfLines={1}>
                        {actualJob?.company_name ?? '-'}
                      </Text>
                    </VStack>

                    <VStack alignItems="flex-start" w="15%" spacing={1}>
                      <Text
                        color="neutralDark.300"
                        fontWeight="500"
                        fontSize="xs"
                        lineHeight="normal">
                        Cargo
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        lineHeight="normal"
                        noOfLines={1}>
                        {actualJob?.position_in_the_company ?? '–'}
                      </Text>
                    </VStack>
                    <HStack w="11%">
                      <AccordionButton as="div" w="-moz-fit-content">
                        <AccordionIcon />
                      </AccordionButton>
                      <Box py={4}>
                        <Menu placement="start" autoSelect={false}>
                          <MenuButton
                            as={IconButton}
                            aria-label="More"
                            icon={
                              <Icon as={FiMoreVertical} w={5} h={5} mr={6} />
                            }
                            variant="unstyled"
                          />
                          <MenuList
                            bg="#F9F9F9"
                            border="0"
                            borderRadius={8}
                            minWidth={31.75}>
                            <MenuItem
                              onClick={() => handleEditCandidate(candidate)}
                              p={3}
                              icon={<Icon as={FiEdit} w={4} h={4} />}
                              _hover={{ bg: 'neutralLight.300' }}>
                              Editar candidato
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Box>
                    </HStack>
                  </HStack>
                  <AccordionPanel py={4}>
                    <Divider />
                    <HStack pt={5} pb="7">
                      {candidate.qualified ? (
                        <Tag
                          variant="subtle"
                          colorScheme="positive"
                          borderRadius="md"
                          px="3"
                          fontSize="xs">
                          Qualificado
                        </Tag>
                      ) : (
                        <Tag
                          variant="subtle"
                          colorScheme="negative"
                          borderRadius="md"
                          px="3"
                          fontSize="xs">
                          Não qualificado
                        </Tag>
                      )}
                      <Text pl={6} fontWeight="bold" fontSize="md">
                        Qualificador:{' '}
                      </Text>
                      <Text fontWeight="bold" fontSize="md">
                        {candidate?.last_qualifier?.user?.name ?? '-'}
                      </Text>
                      <Text pl={6} fontWeight="bold" fontSize="md">
                        Ultima qualificação
                      </Text>
                      <Text fontWeight="bold" fontSize="md">
                        {formatDate(
                          new Date(candidate?.last_qualifier_at),
                          "dd'/'MM'/'yyyy"
                        ) ?? '-'}
                      </Text>
                    </HStack>
                    <DataInfoCandidate candidate={candidate} />
                  </AccordionPanel>
                </AccordionItem>
              )
            })
          : !loading &&
            !data &&
            !filters && (
              <Text width="100%" textAlign="center" py={8}>
                Não foram encontrados candidatos para essa vaga.
              </Text>
            )}
      </Accordion>

      {!loading && data && (
        <Flex justifyContent="center" mt={3} w="100%">
          <Flex alignItems="center" gap={4}>
            <Button
              variant="outline"
              disabled={page <= 1}
              size="xs"
              height={7}
              borderRadius={4}
              onClick={() => setPage((page) => --page)}>
              <Icon as={FiChevronLeft} />
            </Button>

            {totalPages.length && (
              <>
                <Text
                  fontSize={14}
                  fontWeight={700}
                  color={'#9a9a9a'}
                  cursor="pointer"
                  _hover={{ color: 'black' }}
                  onClick={() => setPage(1)}>
                  1
                </Text>

                <Text
                  fontSize={14}
                  fontWeight={700}
                  color="black"
                  cursor="pointer"
                  mx={3}>
                  {page}
                </Text>

                <Text
                  fontSize={14}
                  fontWeight={700}
                  color={'#9a9a9a'}
                  cursor="pointer"
                  _hover={{ color: 'black' }}
                  onClick={() => setPage(data?.pageSize)}>
                  {data?.pageSize}
                </Text>
              </>
            )}

            <Button
              variant="outline"
              disabled={page === data?.pageSize}
              size="xs"
              height={7}
              borderRadius={4}
              onClick={() => setPage((page) => ++page)}>
              <Icon as={FiChevronRight} />
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
