/* eslint-disable camelcase */
import { Box, Divider, useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { CheckList } from 'components/molecules/CheckList'
import produce from 'immer'
import { useState } from 'react'
import useFetch from 'use-http'

export type CheckListGroupProps = {
  jobId: string
  editorUsers: any
}

export type CheckListItem = {
  id: string
  label: string
  checklist_id: string
  checked: boolean
  type: boolean | null
  due_date: string | null
  order: number
  check_list_responsible: any[]
}

export type CheckListProps = {
  id: string
  title: string
  order: number
  checklist_items: CheckListItem[]
}

export const CheckListGroup = ({ jobId, editorUsers }: CheckListGroupProps) => {
  const toast = useToast()

  const [checkedItems, setCheckedItems] = useState<CheckListProps[]>([])

  const { put, response } = useFetch(Endpoints.jobChecklist.root)
  const { post: createChecklistItem, response: responseChecklistItem } =
    useFetch(Endpoints.jobChecklist.createItems)

  const { get: refresh } = useFetch(
    Endpoints.jobChecklist.checklistByJobId(jobId),
    {
      interceptors: {
        response: async ({ response }) => {
          if (response.data) {
            setCheckedItems(
              response.data.map(
                (c: { checklist: CheckListProps }) => c.checklist
              )
            )
          }
          return response
        }
      }
    },
    []
  )

  const handleCheckItem = async (
    checkListId: string,
    checkId: string,
    checked?: boolean,
    dueDate?: Date,
    checkListResponsible?: any
  ) => {
    setCheckedItems(
      produce((draft: CheckListProps[]) => {
        const currentCheckList = draft.find((cl) => cl.id === checkListId)
        const currentCheckListItem = currentCheckList?.checklist_items.find(
          (c: any) => c.id === checkId
        )

        if (!currentCheckListItem) return

        if (checked) currentCheckListItem.checked = checked
        if (checkListResponsible)
          currentCheckListItem.check_list_responsible = [checkListResponsible]
      })
    )

    await put(Endpoints.jobChecklist.checkListItemByCheckItemId(checkId), {
      checked,
      due_date: dueDate
    })

    if (response.status !== 200) {
      toast({
        title: 'Não foi possível alterar o check.',
        description: 'Por favor, tente mais tarde.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })
    }

    refresh()
  }

  const handleUpdateJobChecklists = async (newItems: any) => {
    await createChecklistItem(newItems)

    if (responseChecklistItem.status !== 201) {
      toast({
        title: 'Não foi possível alterar o checklist.',
        description: 'Por favor, tente mais tarde.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })
    }

    refresh()
  }

  if (!checkedItems) return null

  return (
    <>
      {checkedItems.map((c: any, index: number) => (
        <Box key={c.id}>
          <CheckList
            id={c.id}
            title={c.title}
            checklistItems={c.checklist_items}
            onChangeCheck={handleCheckItem}
            handleUpdateJobChecklists={handleUpdateJobChecklists}
            jobId={jobId}
            editorUsers={editorUsers}
            refresh={refresh}
          />
          {index < checkedItems.length - 1 && <Divider mt={4} />}
        </Box>
      ))}
    </>
  )
}
