import {
  Box,
  Flex,
  HStack,
  Icon,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Filters } from 'components/organisms/Filters'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFetch } from 'use-http'
import { TableClients } from './components/TableClients'
import { filterOptions } from './filterOptions'

export const Clients = () => {
  const toast = useToast()
  const onError = useErrorToast()
  const location = useLocation()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState<{ [key: string]: string }>({})

  const {
    del,
    loading: loadingDelete,
    response: resDelete
  } = useFetch(Endpoints.customers.list(page, filters?.customerName), {
    onError: () =>
      onError({
        title: 'Erro',
        description: 'Não foi possível apagar o cliente.'
      })
  })

  const { put, response: responseUpdate } = useFetch({
    onError: () =>
      onError({
        title: 'Erro',
        description: 'Não foi possível atualizar o cliente.'
      })
  })

  const {
    loading,
    data: customers,
    get: refetch
  } = useFetch(
    Endpoints.customers.list(page, filters?.customerName),
    {
      onError: () =>
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: 'Não foi possível carregar a lista de clientes.',
          position: 'top-right',
          status: 'warning',
          title: 'Erro',
          variant: 'subtle'
        })
    },
    [location.key, filters, page, loadingDelete]
  )

  const handleFilter = (values: {}) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      ...values
    }))
  }

  const handleDeleteClient = async (idClient: string, onClose: () => void) => {
    await del(`/${idClient}`)

    if (resDelete.ok) {
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: 'Cliente deletado!',
        position: 'top-right',
        status: 'success',
        title: 'Sucesso',
        variant: 'subtle'
      })
      onClose()
    }
  }

  const handleToggleStatusClient = async (client: any, onClose: () => void) => {
    await put(Endpoints.customers.update(client.id), {
      is_active: !client.is_active
    })

    if (responseUpdate.data) {
      await refetch()
      onClose()
    }
  }

  return (
    <Fragment>
      <Flex
        minH="14%"
        w="100%"
        flexDirection="column"
        justify="space-between"
        pb="5">
        <HStack mb="6">
          <HeaderPage title="Empresas e Usuários" />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <HStack>
            <Filters
              options={filterOptions({
                state: filters?.state?.toLowerCase()
              })}
              filters={filters}
              onClearFilters={() => setFilters({})}
              onFilter={handleFilter}
            />
          </HStack>
          <HStack>
            <Button
              variant="solid"
              colorScheme="brand"
              fontSize="xs"
              fontWeight="bold"
              px={8}
              height={30}
              borderRadius="md"
              onClick={() => navigate('criar')}>
              Adicionar empresa
            </Button>
          </HStack>
        </HStack>
      </Flex>

      <Box
        h="78%"
        width="100%"
        bg="white"
        borderRadius="lg"
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: `rgba(0, 0, 0, 0.15)`
          }
        }}>
        {loading && !customers?.data && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Spinner color="brand.500" />
          </Box>
        )}
        {customers?.data && (
          <TableClients
            data={customers.data}
            refetch={refetch}
            onDeleteClient={handleDeleteClient}
            onToggleClient={handleToggleStatusClient}
          />
        )}
      </Box>

      <Flex h="8%" justifyContent="flex-end" w="100%" alignItems="center">
        <Text fontSize={14} mr={5}>{`${page * 20} - ${page * 20 + 20} de ${
          customers?.total
        }`}</Text>

        <Button
          variant="outline"
          disabled={page <= 0}
          size="xs"
          height={7}
          borderRadius={4}
          mr={5}
          onClick={() => setPage((page) => --page)}>
          <Icon as={FiChevronLeft} />
        </Button>

        <Button
          variant="outline"
          disabled={customers?.total / ((page + 1) * 20) < 1} // check if there is another page
          size="xs"
          height={7}
          borderRadius={4}
          onClick={() => setPage((page) => ++page)}>
          <Icon as={FiChevronRight} />
        </Button>
      </Flex>
    </Fragment>
  )
}
