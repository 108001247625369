import { Endpoints } from 'api/endpoints'
import { FiltersOptionType } from 'components/organisms/Filters'

type Props = {
  state: string
}

export const filterOptions = ({ state = '' }: Props): FiltersOptionType[] => [
  {
    button: 'Nome da empresa',
    filters: {
      field: 'customerName',
      filterType: 'checkbox',
      endpoint: Endpoints.customers.allCustomers,
      endpointFields: ['name', 'name'],
      searchField: 'name'
    }
  }
  /* {
    disabled: true,
    button: 'Nome do usuário',
    filters: {
      field: 'user',
      filterType: 'input'
    }
  },
  {
    disabled: true,
    button: 'CNPJ',
    filters: {
      field: 'cnpj',
      filterType: 'input'
    }
  },
  {
    disabled: true,
    button: 'Mercado',
    filters: {
      field: 'mercado',
      filterType: 'input'
    }
  },
  {
    disabled: true,
    button: 'Estado',
    filters: {
      field: 'state',
      filterType: 'checkbox',
      endpoint: Endpoints.lists.states,
      endpointFields: ['nome', 'sigla'],
      searchField: 'nome'
    }
  }, */
  /* {
    disabled: !state,
    button: 'Cidade',
    filters: {
      field: 'city',
      filterType: 'checkbox',
      endpoint: state && Endpoints.lists.citiesByState(state),
      endpointFields: ['nome', 'nome'],
      searchField: 'nome'
    }
  } */
]
