import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Breadcrumb as BreadcrumbChakra,
  BreadcrumbItem,
  BreadcrumbLink
} from '@chakra-ui/react'

interface BreadcrumbType {
  menus: {
    title: string
    slug: string
    current: boolean
  }[]
}

export const Breadcrumb = ({ menus, ...rest }: BreadcrumbType) => (
  <BreadcrumbChakra {...rest} separator={<ChevronRightIcon color="black" />}>
    {menus.map((menu, idx) => (
      <BreadcrumbItem key={`menu_${idx}`} isCurrentPage={menu.current}>
        <BreadcrumbLink
          color="blackText"
          fontSize="lg"
          fontWeight={menu.current ? 'bold' : 'normal'}
          href={menu.slug}>
          {menu.title}
        </BreadcrumbLink>
      </BreadcrumbItem>
    ))}
  </BreadcrumbChakra>
)
