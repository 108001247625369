import { Box, Switch } from '@chakra-ui/react'
import { Accordion } from 'components/atoms/Accordion'
import { Button } from 'components/atoms/Button'
import { Fragment } from 'react'

export const Recruitment = () => (
  <Fragment>
    <Box bg="white" width="full" height="full" borderRadius="lg" pb={8} px={8}>
      <Accordion title="SUPORTE | LÍDER">
        <Accordion secondary title="BD candidato">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            Support
            <Switch colorScheme="brand" id="leader" />
          </Box>
        </Accordion>
      </Accordion>
    </Box>
    <Box w="full" textAlign="right" mt={10}>
      <Button disabled variant="solid" colorScheme="brand" px={5}>
        Salvar e fechar
      </Button>
    </Box>
  </Fragment>
)
