export const currentJob = (experiences: Array<any>) => {
  const actualJob = experiences.find(
    (experience: { actual_job: boolean }) => experience.actual_job
  )

  const mostRecentDate = new Date(
    Math.max.apply(
      null,
      // @ts-ignore
      experiences.map(
        (experience: { end_at: string }) => new Date(experience.end_at)
      )
    )
  )

  const mostRecentJob =
    experiences.filter((experience: { end_at: string }) => {
      const d = new Date(experience.end_at)
      return d.getTime() === mostRecentDate.getTime()
    })?.[0] ?? null

  return actualJob ?? mostRecentJob
}
