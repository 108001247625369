import { Box, Divider, VStack } from '@chakra-ui/react'
import { Button } from 'components/atoms/Button'
import { Vacancy } from '../Form/Vacancy'

export const Form = ({ data, loading, canEdit, onSubmit }: any) => (
  <form
    onSubmit={onSubmit}
    style={{ pointerEvents: canEdit ? 'auto' : 'none' }}>
    <Box width="100%" bg="white" flex={1} borderRadius="lg" p={6} mt={3} mb={6}>
      <VStack width="100%" alignItems="flex-start" spacing={6} mt={4}>
        <Vacancy data={data} />
      </VStack>
    </Box>
    <VStack spacing={6} pb={6} align={{ base: 'center', md: 'flex-end' }}>
      <Divider />
      <Button
        loading={loading}
        type="submit"
        width={230}
        variant="solid"
        px={5}
        colorScheme="brand">
        Salvar
      </Button>
    </VStack>
  </form>
)
