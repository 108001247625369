import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Progress,
  Text
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { parseISO } from 'date-fns'
import { useMemo, useReducer } from 'react'
import { /* FiTrash */ FiClock, FiUserCheck, FiX } from 'react-icons/fi'
import useFetch from 'use-http'
import { formatDate } from 'utils/formatDate'
import { Button } from '../../components/atoms/Button/index'
import { Tooltip } from '../../components/atoms/Tooltip'
import { ListInput } from '../../modules/Jobs/components/ListInput'
import { ChecklistModalDate } from '../../modules/Jobs/components/Modals/ChecklistModalDate'
import { ChecklistModalUserAssign } from '../../modules/Jobs/components/Modals/ChecklistModalUserAssign'
import { ConfirmModal } from '../../modules/Jobs/components/Modals/ConfirmModal'
import {
  formatResponseUserYw,
  userYwFormated
} from '../../utils/formatResponseUserYw'

import { CheckListItem } from '../organisms/CheckListGroup'

interface CheckListComponentProps {
  id: string
  title: string
  checklistItems: CheckListItem[]
  onChangeCheck: any
  editorUsers: any
  jobId: string
  handleUpdateJobChecklists: any
  refresh: () => void
}

interface modalState {
  modalDate: boolean
  modalAssign: boolean
  isAddingItem: boolean
  modalConfirm: boolean
  modalCheckRef: CheckListItem | null
}

interface modalStateAction {
  type: string
  payload?: any
}

const initialState = {
  modalDate: false,
  modalAssign: false,
  isAddingItem: false,
  modalConfirm: false,
  modalCheckRef: null
}

const ACTIONS = {
  TOGGLE_MODAL_DATE: 'toggleModalDate',
  TOGGLE_MODAL_ASSIGN: 'toggleModalAssign',
  TOGGLE_MODAL_CONFIRM: 'toggleModalConfirm',
  TOGGLE_ADD_ITEM: 'toggleAddItem'
}

const reducer = (state: modalState, action: modalStateAction) => {
  // eslint-disable-next-line
  // prettier-ignore
  switch (action.type) {
  case 'toggleModalDate':
    return { ...state, modalDate: !state.modalDate, modalCheckRef: action.payload  }
  case 'toggleModalAssign':
    return { ...state, modalAssign: !state.modalAssign, modalCheckRef: action.payload  }
  case 'toggleModalConfirm':
    return { ...state, modalConfirm: !state.modalConfirm, modalCheckRef: action.payload  }
  case 'toggleAddItem':
    return { ...state, isAddingItem: !state.isAddingItem }
  default:
    return state
  }
}

const hoverStyle = {
  borderRadius: 'md',
  backgroundColor: 'neutralLight.400',
  p: '0',
  m: '0',
  height: '10'
}

export const CheckList = ({
  id,
  title,
  checklistItems,
  onChangeCheck,
  jobId,
  handleUpdateJobChecklists,
  editorUsers,
  refresh
}: CheckListComponentProps) => {
  const { loading } = useFetch(`/jobs/${jobId}`)
  const { data: userSimplify } = useFetch(
    Endpoints.users.findAllForKAM,
    {
      interceptors: {
        response: formatResponseUserYw
      }
    },
    [loading]
  )

  const [state, dispatch] = useReducer(reducer, initialState)

  const findUserName = (check: CheckListItem) => {
    const filter = userSimplify?.filter((userSimple: userYwFormated) => {
      return userSimple.value === check?.check_list_responsible[0]?.user_yw_id
    })

    return filter?.length === 1 && filter[0]?.label
  }

  const progress = useMemo(() => {
    const checkeds = checklistItems.filter(
      (check: CheckListItem) => check.checked
    ).length
    const total = checklistItems.length

    return Number(((checkeds / total) * 100).toFixed(0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistItems])

  const progressOnNotation = useMemo(() => `${progress}%`, [progress])

  return (
    <>
      <Flex flexDir="column" py={7}>
        <HStack justifyContent="space-between">
          <Text fontSize="lg" fontWeight="bold">
            {title}
          </Text>
          {/* <Button onClick={() => {}}>
            <Icon as={FiTrash} h="18px" w="18px" />
          </Button> */}
        </HStack>
        <HStack mt={4}>
          <Text fontSize="sm" fontWeight="normal">
            {progressOnNotation}
          </Text>
          <Progress
            w="100%"
            colorScheme="brand"
            size="sm"
            value={progress}
            borderRadius="md"
          />
        </HStack>

        {checklistItems.map((check: CheckListItem) => (
          <Box key={check.id}>
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center">
              <Checkbox
                onChange={(e) =>
                  onChangeCheck(check.checklist_id, check.id, e.target.checked)
                }
                isChecked={check.checked}
                variant="checklist"
                mt={4}
                colorScheme="brand"
                spacing="3.5"
                defaultChecked>
                <Text fontSize="md">{check.label}</Text>
              </Checkbox>

              <Box>
                <Flex flexDir="row" gap="2" alignItems="center">
                  {check.due_date ? (
                    <Box>
                      <Flex flexDir="row" alignItems="center" gap="1.5">
                        <Box
                          textAlign="center"
                          style={{ borderCollapse: 'separate' }}>
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            color="neutralDark.300"
                            bg="positive.100"
                            px="2"
                            py="0.5"
                            borderRadius="base">
                            {formatDate(parseISO(check.due_date), 'dd/MM/yyyy')}
                          </Text>
                        </Box>
                        <Button
                          borderRadius="4px"
                          bg="neutralLight.400"
                          p="0"
                          m="0"
                          height="10"
                          onClick={() => {
                            dispatch({
                              type: ACTIONS.TOGGLE_MODAL_DATE,
                              payload: check
                            })
                          }}>
                          <Icon as={FiClock} w={5} h={5} color="blackText" />
                        </Button>
                      </Flex>
                    </Box>
                  ) : (
                    <Button
                      bg="none"
                      m="0"
                      p="0"
                      _hover={hoverStyle}
                      onClick={() => {
                        dispatch({
                          type: ACTIONS.TOGGLE_MODAL_DATE,
                          payload: check
                        })
                      }}>
                      <Icon as={FiClock} w={5} h={5} color="blackText" />
                    </Button>
                  )}

                  {check.check_list_responsible.length === 1 ? (
                    <Box>
                      <Flex flexDir="row" gap="1.5" alignItems="center">
                        <Box textAlign="center">
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            color="neutralDark.300"
                            bg="neutralLight.400"
                            px="2"
                            py="0.5"
                            borderRadius="base">
                            {findUserName(check)}
                          </Text>
                        </Box>
                        <Button
                          borderRadius="4px"
                          bg="neutralLight.400"
                          p="0"
                          m="0"
                          height="10"
                          onClick={() => {
                            dispatch({
                              type: ACTIONS.TOGGLE_MODAL_ASSIGN,
                              payload: check
                            })
                          }}>
                          <Icon
                            as={FiUserCheck}
                            w={5}
                            h={5}
                            color="blackText"
                          />
                        </Button>
                      </Flex>
                    </Box>
                  ) : (
                    <Tooltip
                      label="Não possuí condutores e/ou qualificadores"
                      isDisabled={editorUsers.length > 0}
                      placement="top">
                      <Button
                        bg="none"
                        m="0"
                        p="0"
                        isDisabled={!(editorUsers.length > 0)}
                        _disabled={{ backgroundColor: 'none' }}
                        _hover={editorUsers.length > 0 ? hoverStyle : {}}
                        onClick={() => {
                          dispatch({
                            type: ACTIONS.TOGGLE_MODAL_ASSIGN,
                            payload: check
                          })
                        }}>
                        <Icon
                          as={FiUserCheck}
                          w={5}
                          h={5}
                          color={
                            editorUsers.length > 0
                              ? 'blackText'
                              : 'neutralLight.600'
                          }
                        />
                      </Button>
                    </Tooltip>
                  )}

                  <Button
                    bg="none"
                    m="0"
                    p="0"
                    _hover={hoverStyle}
                    onClick={() => {
                      dispatch({
                        type: ACTIONS.TOGGLE_MODAL_CONFIRM,
                        payload: check
                      })
                    }}>
                    <Icon as={FiX} w={6} h={6} color="blackText" />
                  </Button>
                </Flex>
              </Box>
            </Flex>

            <ChecklistModalDate
              modalOpen={state.modalDate || false}
              onClose={() => {
                dispatch({
                  type: ACTIONS.TOGGLE_MODAL_DATE,
                  payload: null
                })
              }}
              check={state.modalCheckRef}
              onChangeCheck={onChangeCheck}
            />

            {state.modalCheckRef && (
              <ChecklistModalUserAssign
                editorUsers={editorUsers}
                jobId={jobId}
                modalOpen={state.modalAssign || false}
                onClose={() => {
                  dispatch({
                    type: ACTIONS.TOGGLE_MODAL_ASSIGN,
                    payload: null
                  })
                }}
                check={state.modalCheckRef}
                refresh={refresh}
              />
            )}

            {state.modalCheckRef && (
              <ConfirmModal
                modalOpen={state.modalConfirm || false}
                onClose={() => {
                  dispatch({
                    type: ACTIONS.TOGGLE_MODAL_CONFIRM,
                    payload: null
                  })
                }}
                check={state.modalCheckRef}
                refresh={refresh}
              />
            )}
          </Box>
        ))}
        {state.isAddingItem && (
          <ListInput
            onClose={() => {
              dispatch({
                type: ACTIONS.TOGGLE_ADD_ITEM
              })
            }}
            onAddItem={(itemName) =>
              handleUpdateJobChecklists({
                label: itemName,
                order: checklistItems.length,
                checked: false,
                checklist_id: id
              })
            }
          />
        )}

        {!state.isAddingItem && (
          <Button
            w="48"
            h="28"
            borderRadius="base"
            bg="neutralLight.400"
            mt="6"
            ml="10"
            onClick={() => {
              dispatch({
                type: ACTIONS.TOGGLE_ADD_ITEM
              })
            }}>
            <Text fontSize="sm" fontWeight="400" px="2" color="neutralDark">
              Adicionar novo item
            </Text>
          </Button>
        )}
      </Flex>
    </>
  )
}
