import { Box, Icon, Link, VStack } from '@chakra-ui/react'
import { Fragment } from 'react'
import { FiSettings, FiShield } from 'react-icons/fi'
import { NavLink, Outlet, useLocation } from 'react-router-dom'

export const Profile = () => {
  const location = useLocation()

  return (
    <Fragment>
      <Box display="flex">
        <Box width={265}>
          <nav>
            <VStack w="full" alignItems="flex-start" spacing={4}>
              <Link
                as={NavLink}
                to="geral"
                display="flex"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                w="full"
                h={9}
                px={4}
                bg={
                  location.pathname === '/perfil/geral'
                    ? 'brand.500'
                    : 'transparent'
                }
                color={
                  location.pathname === '/perfil/geral' ? 'white' : '#8F8F8F'
                }
                fontWeight="semibold"
                fontSize="md"
                borderRadius="lg"
                _hover={{
                  bg: 'brand.500',
                  color: 'white'
                }}>
                <Icon as={FiSettings} w={4} h={4} mr={2} /> Geral
              </Link>
              <Link
                as={NavLink}
                to="permissoes"
                display="flex"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                w="full"
                h={9}
                px={4}
                bg={
                  location.pathname === '/perfil/permissoes'
                    ? 'brand.500'
                    : 'transparent'
                }
                color={
                  location.pathname === '/perfil/permissoes'
                    ? 'white'
                    : '#8F8F8F'
                }
                fontWeight="semibold"
                fontSize="md"
                borderRadius="lg"
                _hover={{
                  bg: 'brand.500',
                  color: 'white'
                }}>
                <Icon as={FiShield} w={4} h={4} mr={2} /> Permissões
              </Link>
            </VStack>
          </nav>
        </Box>
        <Box flex={1} ml={8}>
          <Outlet />
        </Box>
      </Box>
    </Fragment>
  )
}
