/* eslint-disable indent */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable camelcase */
import { Box, Divider, HStack, VStack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Endpoints } from 'api/endpoints'
import { Breadcrumb } from 'components/atoms/Breadcrumb'
import { Button } from 'components/atoms/Button'
import { parse } from 'date-fns'
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFetch } from 'use-http'
import {
  addToLongListErr,
  addToLongListSucc,
  candidateDuplicate,
  creatCandidateErr,
  creatCandidateSucc
} from 'utils/commonToastMessages'
import { transformDatePayload } from 'utils/formatDate'
import * as yup from 'yup'
import { Profile } from './components/Create/Profile'
import { Resume } from './components/Create/Resume'
import { Steps } from './components/Steps'
import { Candidate } from './types'

export const formSchema = yup.object().shape(
  {
    name: yup.string().required('Campo obrigatório'),
    gender: yup.string(),
    email: yup.string().nullable().email('E-mail inválido'),
    linkedin_profile_url: yup.string().when(['phone'], {
      is: (phone: string) => !phone,
      then: yup.string().required('Campo obrigatório')
    }),
    phone: yup.string().when(['linkedin_profile_url'], {
      is: (linkedin_profile_url: string) => !linkedin_profile_url,
      then: yup.string().required('Campo obrigatório')
    }),
    birth_date: yup
      .date()
      .optional()
      .nullable()
      .typeError('Data inválida')
      .transform(
        (value, originalValue) =>
          originalValue &&
          originalValue !== '' &&
          parse(originalValue, 'dd/MM/yyyy', new Date())
      ),
    last_qualifier_at: yup
      .date()
      .optional()
      .nullable()
      .typeError('Data inválida')
      .transform(
        (value, originalValue) =>
          originalValue &&
          originalValue !== '' &&
          parse(originalValue, 'dd/MM/yyyy', new Date())
      )
  },
  [
    ['phone', 'linkedin_profile_url'],
    ['linkedin_profile_url', 'phone']
  ]
)

export const CreateCandidate = () => {
  const { search }: any = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const toast = useToast()
  const navigate = useNavigate()

  const [step, setStep] = useState<number>(0)

  const { loading, post, response } = useFetch(Endpoints.usersCandidate.root)

  const { put: onSendToLonglist, response: sendToLonglistRes } = useFetch(
    Endpoints.jobs.addCandidatesToList(query?.get('longlistId') || '')
  )

  const { post: onCreateLonglist, response: createLonglistRes } = useFetch(
    Endpoints.jobs.list
  )

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: query.get('name') || ''
    }
  })

  const onAddToLonglist = useCallback(
    async (candidateId: string) => {
      if (query.get('createLonglist') === 'true') {
        onCreateLonglist({
          job_id: query.get('jobId'),
          candidates_id: [candidateId],
          is_long_list: true
        })
      } else onSendToLonglist({ candidatesId: [candidateId] })

      if (sendToLonglistRes.status >= 400 || createLonglistRes.status >= 400) {
        addToLongListErr(toast)
        return
      }

      addToLongListSucc(toast)
    },
    [
      createLonglistRes.status,
      onCreateLonglist,
      onSendToLonglist,
      query,
      sendToLonglistRes.status,
      toast
    ]
  )

  const onSubmit = useCallback(
    async (dataForm: any) => {
      const {
        last_qualifier_id,
        linkedin_profile_url,
        phone,
        ...restDataForm
      } = dataForm

      if ((!linkedin_profile_url || '') && (!phone || '')) {
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: 'Preencha email ou linkedin para criar usuário.',
          position: 'top-right',
          status: 'warning',
          title: response.status === 500 ? 'Erro interno' : 'Erro',
          variant: 'subtle'
        })

        return
      }

      await post({
        ...restDataForm,
        available_change_city: dataForm?.available_change_city === 'true',
        available_home_office: dataForm?.available_home_office === 'true',
        available_travel: dataForm?.available_travel === 'true',
        birth_date: dataForm?.birth_date ?? null,
        gender: dataForm?.gender || 'INDIFFERENT',
        last_qualifier_at: dataForm?.last_qualifier_at ?? null,
        linkedin_profile_url: dataForm?.linkedin_profile_url,
        ...(last_qualifier_id && {
          last_qualifier: last_qualifier_id
        }),
        salary_expectation: Number(
          dataForm?.salary_expectation?.replace('.', '').replace(',', '.')
        ),
        cpf: dataForm?.cpf?.replace(/\D/g, ''),
        phone: dataForm?.phone?.replace(/\D/g, ''),
        ...(dataForm?.verticals && {
          verticals: dataForm.verticals.map(({ value }: { value: string }) =>
            value === 'TECH' ? 'TECH_1' : value
          )
        }),
        ...(dataForm?.professional_experiences && {
          professional_experiences: dataForm.professional_experiences.map(
            ({
              // company,
              benefits,
              ...experiences
            }: Candidate['professional_experiences']) => ({
              ...experiences,
              ...(benefits?.filter(({ value }) => value)?.length && {
                benefits
              }),
              // company_id: company?.value,
              started_at: experiences?.started_at
                ? new Date(transformDatePayload(experiences?.started_at))
                : null,
              end_at: experiences?.end_at
                ? new Date(transformDatePayload(experiences?.end_at))
                : null,
              salary:
                experiences?.salary &&
                parseFloat(
                  experiences?.salary.replace('.', '').replace(',', '.')
                )
            })
          )
        }),
        ...(dataForm?.superior_courses && {
          superior_courses: dataForm.superior_courses
            .filter(
              (course: Candidate['superior_courses']) =>
                course?.university_ref?.value &&
                course?.superiorCourse_ref?.value
            )
            .map(
              ({
                university_ref,
                superiorCourse_ref,
                ...superiorCourse
              }: Candidate['superior_courses']) => ({
                ...superiorCourse,
                university_id: university_ref?.value,
                superior_course_id: superiorCourse_ref?.value,
                start_date: superiorCourse?.start_date
                  ? new Date(transformDatePayload(superiorCourse.start_date))
                  : null,
                end_date: superiorCourse?.end_date
                  ? new Date(transformDatePayload(superiorCourse.end_date))
                  : null
              })
            )
        }),
        ...(dataForm?.candidate_technical_or_complementary_courses && {
          candidate_technical_or_complementary_courses:
            dataForm.candidate_technical_or_complementary_courses.map(
              (
                complementaryCourse: Candidate['candidate_technical_or_complementary_courses']
              ) => ({
                ...complementaryCourse
              })
            )
        }),
        ...(dataForm?.languages && {
          languages: dataForm.languages
            .filter?.(
              ({
                language_id,
                level
              }: {
                language_id: string
                level: string
              }) => language_id && level
            )
            .map(
              ({
                language_id,
                level
              }: {
                language_id: string
                level: string
              }) => ({
                language_id,
                level
              })
            )
        }),
        ...(dataForm?.candidate_hard_skills && {
          candidate_hard_skills: dataForm.candidate_hard_skills.map(
            ({ id, value }: { id?: string; value?: string }) => id ?? value
          )
        })
      })

      if (response.status === 409) {
        candidateDuplicate(response?.data?.response?.id, toast)
        return
      }

      if (![200, 201].includes(response.status)) {
        creatCandidateErr(response.status, toast)
        return
      }

      creatCandidateSucc(toast)

      const { id } = response.data

      if (query.get('jobId')) {
        await onAddToLonglist(id)

        if (query.get('returnUrl')) {
          navigate(query.get('returnUrl') as string)
        } else {
          navigate('/candidatos')
        }
        return
      }

      navigate('/candidatos')
    },
    [
      post,
      response.status,
      response.data,
      toast,
      query,
      navigate,
      onAddToLonglist
    ]
  )

  return (
    <FormProvider {...methods}>
      <form
        style={{ height: '100%', width: '100%' }}
        onSubmit={methods.handleSubmit(onSubmit)}>
        <HStack
          spacing={6}
          width="100%"
          display="flex"
          alignItems="stretch"
          flexDirection={{ base: 'column', md: 'row' }}>
          <Box bg="white" height="100%" flex={1.5} borderRadius="lg" p={6}>
            <VStack width="100%" spacing={6}>
              <Box width="100%" mb="10">
                <Breadcrumb
                  menus={[
                    {
                      current: false,
                      slug: '/candidatos',
                      title: 'Gestão de Candidatos'
                    },
                    {
                      current: true,
                      slug: '/candidatos/criar',
                      title: 'Cadastrar candidato'
                    }
                  ]}
                />
              </Box>
              <Steps
                steps={[{ label: 'Perfil' }, { label: 'Currículo' }]}
                current={step}
              />

              {step === 0 && <Profile />}
              {step === 1 && <Resume />}

              {step === 0 && (
                <Fragment>
                  <Divider />
                  <Button
                    onClick={() => setStep(step + 1)}
                    width="100%"
                    py="0.5"
                    borderRadius="md"
                    fontSize="sm"
                    fontWeight="500"
                    variant="outline"
                    colorScheme="brand">
                    Avançar
                  </Button>
                </Fragment>
              )}

              {step === 1 && (
                <Fragment>
                  <Divider />
                  <Button
                    onClick={() => setStep(0)}
                    width="100%"
                    py="0.5"
                    borderRadius="md"
                    fontSize="sm"
                    fontWeight="500"
                    variant="outline"
                    colorScheme="brand">
                    Voltar
                  </Button>
                </Fragment>
              )}
            </VStack>
          </Box>

          {/* <Box flex={1} p={6} bg="white" borderRadius="lg" /> */}
        </HStack>

        <Box my={6}>
          <VStack spacing={6} pb={6} align={{ base: 'center', md: 'flex-end' }}>
            <Divider />
            <Button
              loading={loading}
              type="submit"
              width={230}
              variant="solid"
              px={5}
              colorScheme="brand">
              Salvar e fechar
            </Button>
          </VStack>
        </Box>
      </form>
    </FormProvider>
  )
}
