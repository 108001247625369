export const forescastFormatter = (forecast: string | null) => {
  if (!forecast) return ''

  forecast.toString()

  const forecastNotDefined =
    forecast.charAt(0).toUpperCase() + forecast.slice(1).toLowerCase()

  return forecast === 'INDEFINIDO' ? forecastNotDefined : forecast
}
