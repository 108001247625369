import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { useNotifications } from 'hooks/useNotifications'
import { BsFillExclamationCircleFill, BsXCircleFill } from 'react-icons/bs'
import { FiX } from 'react-icons/fi'
import { normalizeNumber } from 'theme/normalization'
import { formatDateHumanized } from 'utils/formatDate'

const Item = ({
  title,
  description,
  type = 'default',
  category,
  date,
  onRemove
}: {
  title: string
  description: string
  type?: 'default' | 'error'
  category: string
  date: any
  onRemove: () => void
}) => (
  <Box
    w="100%"
    position="relative"
    pl={2}
    pt={7}
    pb={6}
    _before={{
      bg: `${type === 'error' ? 'negative' : 'brand'}.200`,
      content: '""',
      display: 'inline-block',
      h: '100%',
      left: -6,
      top: 0,
      position: 'absolute',
      w: 3,
      opacity: 0.4
    }}>
    <VStack alignItems="flex-start" spacing={4}>
      <HStack alignItems="center" spacing={4}>
        <Icon
          as={type === 'error' ? BsXCircleFill : BsFillExclamationCircleFill}
          w={7}
          h={7}
          color={type === 'error' ? 'negative.500' : 'brand.500'}
        />
        <Text fontSize="xs" textTransform="uppercase" color="#8F8F8F">
          {[category, formatDateHumanized(new Date(date))].join(' • ')}
        </Text>
      </HStack>
      <VStack alignItems="flex-start" spacing={4} pl={10} ml={1}>
        <Text fontSize="lg" color="#1B1C1E">
          {title}
        </Text>
        <Text fontSize="md" color="rgba(27, 28, 30, 0.56);">
          {description}
        </Text>
      </VStack>
    </VStack>
    <IconButton
      rounded="full"
      onClick={onRemove}
      size="lg"
      aria-label="Remover notificação"
      icon={<Icon as={FiX} w={6} h={6} />}
      position="absolute"
      right={0}
      top={0}
      colorScheme="white"
      color="#8F8F8F"
      p={0}
    />
  </Box>
)

export const NotificationsDrawer = ({ isOpen, onClose, btnRef }: any) => {
  const { notifications, loading } = useNotifications()

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="sm"
      finalFocusRef={btnRef}>
      <DrawerOverlay mt={`${normalizeNumber(72)}px`} />
      <DrawerContent mt={`${normalizeNumber(72)}px`}>
        <DrawerHeader px={6} py={8} fontWeight="bold" fontSize="md">
          Notificações
        </DrawerHeader>
        <DrawerBody>
          <>
            {loading ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center">
                <Spinner color="brand.500" />
              </Box>
            ) : (
              notifications.map((n, idx) => (
                <Item key={`notification_${idx}`} {...n} onRemove={() => {}} />
              ))
            )}
          </>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
