import { Box, Flex, HStack, Spinner, useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Filters as StatusFilters } from 'components/organisms/Filters'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { Fragment, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import useFetch from 'use-http'
import { additionalQueryParams } from 'utils/strings'
import { Filters } from './components/Filters'
import { TableOpening } from './components/TableOpening'
import { filterOptions } from './filterOptions'

export const Opening = () => {
  const toast = useToast()

  const [page, setPage] = useState<number>(0)
  const [nameFilter, setNameFilter] = useState('')
  const [filters, setFilters] = useState<{ [key: string]: string }>({})
  const [filter] = useDebounce(nameFilter, 500)
  const [checkBoxFilters, setCheckBoxFilters] = useState<{
    [key: string]: string
  }>({})

  const take = 12

  const defaultParams = checkBoxFilters.status
    ? additionalQueryParams('status', checkBoxFilters.status)
    : ''

  const {
    loading,
    data: forms,
    get
  } = useFetch(
    Endpoints.forms.open({ page, take }) +
      `&job_code=${filter}${defaultParams}`,
    {
      onError: () =>
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: 'Não foi possível listar os forms.',
          position: 'top-right',
          status: 'warning',
          title: 'Ops!',
          variant: 'subtle'
        })
    },
    [filters, checkBoxFilters]
  )

  const handleFilter = (values: {}) => {
    setFilters((currentFilters: any) => ({
      ...currentFilters,
      ...values
    }))
  }

  const handleStatusFilter = (values: any) => {
    if (values?.status === '') {
      setCheckBoxFilters((currentFilters) => ({
        ...currentFilters,
        status: 'null'
      }))
      return
    }

    setCheckBoxFilters((currentFilters) => ({
      ...currentFilters,
      ...values
    }))
  }

  useEffect(() => {
    get()
  }, [get])

  return (
    <Fragment>
      <Flex
        minH="12%"
        w="100%"
        flexDirection="column"
        justify="space-between"
        pb="5">
        <HStack mb="6">
          <HeaderPage title="Abertura" />
        </HStack>
        <HStack alignItems="center">
          <HStack>
            <Filters
              loading={loading}
              nameState={nameFilter}
              onChangeName={(name: string) => setNameFilter(name)}
              setFilters={setFilters}
              filters={filters}
              handleFilter={handleFilter}
            />
          </HStack>
          <HStack alignItems="flex-start" justifyContent="flex-start">
            <StatusFilters
              options={filterOptions()}
              filters={checkBoxFilters}
              onClearFilters={() => setCheckBoxFilters({})}
              onFilter={handleStatusFilter}
            />
          </HStack>
        </HStack>
      </Flex>
      <Box
        h="78%"
        width="100%"
        bg="white"
        borderRadius="lg"
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: `rgba(0, 0, 0, 0.15)`
          }
        }}>
        {loading && !forms?.data && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Spinner color="brand.500" />
          </Box>
        )}
        {forms?.data && (
          <TableOpening
            refresh={get}
            data={forms.data}
            total={Math.round(Math.ceil(forms.total / take))}
            onChange={setPage}
            pagination={{
              take,
              total: forms.total
            }}
          />
        )}
      </Box>
    </Fragment>
  )
}
