import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { Fragment } from 'react'

type StepsType = {
  steps: {
    label: string
  }[]
  current: number
}

export const Steps = ({ steps, current }: StepsType) => (
  <Flex
    my={2}
    mx="auto"
    width="80%"
    maxW="2xl"
    alignItems="flex-start"
    justifyContent="space-between">
    {steps?.map((step, idx: number) => (
      <Fragment key={`step_${idx}`}>
        <Flex w={20} direction="column" alignItems="center">
          <Box
            rounded="full"
            w={3}
            h={3}
            bg="#C8CCD4"
            border={8}
            position="relative"
            zIndex={0}
            _before={{
              bg: `${current === idx ? 'white' : 'transparent'}`,
              borderColor: `${current === idx ? 'brand.500' : 'white'}`,
              borderWidth: 6,
              w: 7,
              h: 7,
              borderRadius: 'full',
              content: '""',
              display: 'inline-block',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              zIndex: -1
            }}
          />
          <Text fontSize="xs" mt={2}>
            {step.label}
          </Text>
        </Flex>
        {idx % 2 === 0 && <Divider my={1.3} borderWidth={2} color="#C8CCD4" />}
      </Fragment>
    ))}
  </Flex>
)
