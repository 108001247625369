/* eslint-disable indent */
import { dateIsNotValid, parseDate, validateTimelineAndFormat } from '.'

/* eslint-disable camelcase */
export const validateData = (
  data: any,
  onError: ({
    title,
    description
  }: {
    title: string
    description?: string
  }) => void
) => {
  const newData = data

  newData.job_superior_courses = newData.job_superior_courses.map(
    (jsc: { value: string }) => jsc.value
  )
  newData.job_hard_skills = newData.job_hard_skills.map(
    (jhs: { value: string }) => jhs.value
  )

  // JOB TIMELINE
  if (data.kickoff_date) {
    const errorKickoffDate = dateIsNotValid(data.kickoff_date)
    if (errorKickoffDate) {
      onError({
        title: 'Data de kickoff inválida.',
        description: 'Verifique e tente novamente.'
      })
      return [errorKickoffDate]
    }
    newData.kickoff_date = parseDate(data.kickoff_date)
  }

  if (data.success_email_date) {
    const errorSuccessDate = dateIsNotValid(data.success_email_date)
    if (errorSuccessDate) {
      onError({
        title: 'Data de email de sucesso inválida.',
        description: 'Verifique e tente novamente.'
      })
      return [errorSuccessDate]
    }
    newData.success_email_date = parseDate(data.success_email_date)
  }

  if (data.end_date) {
    const errorEndDate = dateIsNotValid(data.end_date)
    if (errorEndDate) {
      onError({
        title: 'Data de fechamento prevista inválida.',
        description: 'Verifique e tente novamente.'
      })
      return [errorEndDate]
    }

    newData.end_date = parseDate(data.end_date)
  }
  newData.end_date_shortlist_number =
    !data.end_date_shortlist_number || data.end_date_shortlist_number === 0
      ? 1
      : Number(data.end_date_shortlist_number)

  const [errorTimeline, time_line] = validateTimelineAndFormat(
    newData.timelineYellow,
    newData.timelineClient
  )

  if (errorTimeline) {
    onError({
      title: 'Datas inválidas',
      description: 'Verifique e tente novamente.'
    })
    return [errorTimeline]
  }

  newData.time_line = time_line

  // JOB LANGUAGE
  if (
    newData.job_languages?.length === 1 &&
    !newData.job_languages[0].language_id &&
    !newData.job_languages[0].level
  ) {
    delete newData.job_languages
  }

  if (
    newData.job_languages?.length > 0 &&
    newData.job_languages?.find((jl: any) => !jl.language_id || !jl.level)
  ) {
    onError({
      title: 'Língua e nível são obrigatórios.',
      description: 'Selecione e tente novamente.'
    })
    return [true]
  }

  // offlimits and references
  newData.offlimits = data.offlimits.map(
    (company: { value: string }) => company.value
  )

  newData.reference_companies = data.reference_companies.map(
    (company: { value: string }) => company.value
  )

  // bonus and salary variable
  if (data.has_bonus_value)
    newData.has_bonus_value =
      typeof data.has_bonus_value === 'string'
        ? parseFloat(data.has_bonus_value.replace('.', '').replace(',', '.'))
        : data.has_bonus_value

  if (data.has_salary_variable_value)
    newData.has_salary_variable_value =
      typeof data.has_salary_variable_value === 'string'
        ? parseFloat(
            data.has_salary_variable_value.replace('.', '').replace(',', '.')
          )
        : data.has_salary_variable_value

  if (data.benefits) {
    if (
      data.benefits.some(
        (item: any) => item?.benefits_id === '' || item?.value === ''
      )
    ) {
      onError({
        title: 'Descrição e valor de benefício obrigatórios.',
        description: 'Por favor, tente novamente.'
      })
      return [true]
    }

    newData.benefits = data.benefits.map(
      (benefit: { benefits_id: string; value: number | string | null }) => ({
        benefits_id: benefit.benefits_id,
        value: String(benefit.value)
      })
    )
  }

  delete newData.customer
  delete newData.timelineClient
  delete newData.timelineYellow

  return [null, newData]
}
