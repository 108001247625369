import { Button, HStack, Text, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

export const EditCandidateToast = ({ id }: { id: string }) => {
  const navigate = useNavigate()
  const { close } = useToast()

  const handleClick = () => {
    navigate(`/candidatos/editar/${id}`)
    close(7)
  }

  return (
    <HStack
      px={6}
      py={2}
      width="100%"
      bgColor="brand.200"
      borderRadius={8}
      shadow="xl">
      <Text fontSize={12} fontWeight={500} mr={4}>
        Esse candidato já existe. Clique no botão para editá-lo.
      </Text>
      <Button
        px={6}
        bgColor="brand.700"
        color="white"
        fontWeight={700}
        fontSize={12}
        onClick={handleClick}>
        Editar
      </Button>
    </HStack>
  )
}
