/* eslint-disable react/jsx-indent */
/* eslint-disable camelcase */
/* eslint-disable indent */
import {
  AccordionButton,
  AccordionIcon,
  Checkbox,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Tag,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import currency from 'currency-formatter'
import { differenceInYears } from 'date-fns'
import { useMemo, useState } from 'react'
import {
  FiEdit,
  FiFile,
  FiFileText,
  FiMoreVertical,
  FiXCircle
} from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { currentJob } from 'utils/currentJob'

export const OptionsStatusCandidateJob: { [key: string]: string } = {
  NO_STATUS: 'Sem status',
  DOES_NOT_EVALUATE_MOVEMENT: 'Não Avalia movimentação',
  LOWER_THAN_EXPECTED_SALARY: 'Salário da vaga abaixo do esperado',
  CANDIDATE_SALARY_LOWER_THAN_PAID_FOR_THE_VACANCY:
    'Salário do candidato abaixo do pago pela vaga',
  INSUFFICIENT_TECHNICAL_REQUIREMENTS: 'Requisitos técnicos insuficientes',
  CANDIDATE_WITH_LOW_CULTURAL_FIT: 'Candidato com baixo fit cultural',
  INAPPROPRIATE_CANDIDATE_MOTIVATION_VECTOR:
    'Vetor de motivação do candidato inadequado',
  WAITING_FOR_THE_CANDIDATE_TO_RETURN: 'Aguardando retorno do candidato',
  INCOMMUNICADO_CANDIDATE: 'Candidato incomunicável',
  SUITABLE_CANDIDATE: 'Candidato apto'
}

/**
 * figma @see https://www.figma.com/file/qSvt5eEU0XjXAQjJdc5hw9/Pipeline-de-Vagas?node-id=2096%3A11702
 */
export const HeaderStatus = ({
  candidate,
  position,
  jobId,
  onClick,
  isInShortlist = false,
  isApprovedShortlist = false,
  candidateStatus,
  onRemove,
  onUpdateStatus,
  checked
}: {
  candidate: any
  position: number
  jobId: string
  onClick: any
  isInShortlist?: boolean
  isApprovedShortlist?: boolean
  candidateStatus: string
  onRemove: any
  onUpdateStatus: any
  checked?: boolean
}) => {
  const navigate = useNavigate()
  const [, setStatus] = useState<string>('')

  const gender = useMemo(() => {
    if (candidate?.gender === 'MALE') return 'masculino'
    if (candidate?.gender === 'INDIFFERENT') return 'indiferente'
    return 'feminino'
  }, [candidate])

  const actualJob = currentJob(candidate?.professional_experiences)

  const candidateAge = useMemo(() => {
    if (candidate?.birth_date) {
      return differenceInYears(
        Date.now(),
        new Date(candidate.birth_date)
      ).toString()
    }

    return ' - '
  }, [candidate])

  const handleSelectStatus = (e: any) => {
    setStatus(e.target.value)
    onUpdateStatus(candidate, e.target.value)
  }

  const MENU_ITEMS = [
    {
      label: 'Editar candidato',
      onClick: () =>
        navigate(
          `/candidatos/editar/${candidate.id}?returnUrl=/pipeline-de-vagas/v/${jobId}`,
          {
            state: {
              candidateProps: {
                name: candidate?.name
              }
            }
          }
        ),
      icon: FiEdit,
      show: true
    },
    {
      label: 'Ver currículo completo',
      to: `/curriculum/${candidate.id}`,
      icon: FiFile,
      show: !isInShortlist
    },
    {
      label: 'Remover candidato',
      onClick: () => onRemove(candidate),
      icon: FiXCircle,
      show: true
    }
  ]

  return (
    <Flex gap="4" px={6} py={6} alignItems="stretch">
      <Flex justifyContent="center" flex="1" alignItems="center">
        {!isInShortlist && (
          <Checkbox
            size="xl"
            iconSize="xs"
            colorScheme="brand"
            onChange={onClick}
            isChecked={checked}
          />
        )}
      </Flex>
      <Text fontWeight="bold" fontSize="md" lineHeight="7">
        {position}º
      </Text>
      <VStack justifyContent="space-between" w="100%" spacing={5}>
        <HStack w="100%" flex="1" justifyContent="space-between">
          <HStack spacing={6}>
            <VStack alignItems="flex-start" spacing={0}>
              <Text
                fontWeight="bold"
                fontSize="md"
                lineHeight="normal"
                noOfLines={2}>
                {candidate?.name}
              </Text>
              <Text
                fontSize="xs"
                lineHeight="normal"
                color="neutralDark.300"
                fontWeight="500">
                {candidateAge}
                anos, {gender}
              </Text>
            </VStack>
            <Tag
              size="lg"
              variant="subtle"
              px="5"
              py="2"
              borderRadius="md"
              color={candidate?.qualified ? 'positive.500' : 'negative.500'}
              bg={candidate?.qualified ? 'positive.100' : 'negative.100'}
              fontSize="xs">
              {candidate?.qualified ? 'Qualificado' : 'Não qualificado'}
            </Tag>
          </HStack>

          <HStack spacing={0}>
            {isInShortlist && (
              <Tooltip label="Ver currículo completo">
                <IconButton
                  aria-label="Currículo"
                  colorScheme="white"
                  icon={<FiFileText />}
                  size="lg"
                  onClick={() =>
                    window.open(`/curriculum/${candidate.id}`, '_blank')
                  }
                />
              </Tooltip>
            )}

            <AccordionButton p={2.5}>
              <AccordionIcon w={9} h={9} />
            </AccordionButton>
            {!isApprovedShortlist && (
              <Menu placement="start" autoSelect={false}>
                <MenuButton
                  as={IconButton}
                  aria-label="More"
                  icon={<Icon as={FiMoreVertical} w={6} h={6} />}
                  variant="unstyled"
                />
                <MenuList
                  bg="#F9F9F9"
                  border="0"
                  borderRadius={8}
                  minWidth={31.75}>
                  {MENU_ITEMS.filter(({ show }) => show).map((item, index) => {
                    const menuProps: any = {}

                    if (item?.to) {
                      menuProps.to = item.to
                      menuProps.as = Link
                      menuProps.target = '_blank'
                    }

                    return (
                      <MenuItem
                        key={`menuItem_${index}`}
                        onClick={item.onClick}
                        p={3}
                        icon={<Icon as={item.icon} w={4} h={4} />}
                        _hover={{ bg: 'neutralLight.300' }}
                        {...menuProps}>
                        {item.label}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            )}
          </HStack>
        </HStack>

        <HStack alignItems="flex-start" w="100%" spacing={8}>
          <VStack alignItems="flex-start" w="18%" spacing={1}>
            <Text
              fontSize="xs"
              lineHeight="normal"
              color="neutralDark.300"
              fontWeight="500">
              Pretensão
            </Text>
            <Text fontSize="sm" fontWeight="bold" lineHeight="normal">
              {candidate?.salary_expectation
                ? currency.format(candidate?.salary_expectation, {
                    code: candidate?.salary_currency
                  })
                : '–'}
            </Text>
          </VStack>

          <VStack alignItems="flex-start" w="18%" spacing={1}>
            <Text
              fontSize="xs"
              lineHeight="normal"
              color="neutralDark.300"
              fontWeight="500">
              Salário atual
            </Text>
            <Text fontSize="sm" fontWeight="bold" lineHeight="normal">
              {actualJob?.salary
                ? currency.format(actualJob?.salary, {
                    code: actualJob?.salary_currency
                  })
                : ''}
            </Text>
          </VStack>

          <VStack alignItems="flex-start" w="22%" spacing={1}>
            <Text
              fontSize="xs"
              lineHeight="normal"
              color="neutralDark.300"
              fontWeight="500">
              Empresa
            </Text>
            <Text
              fontSize="sm"
              fontWeight="bold"
              lineHeight="normal"
              noOfLines={1}>
              {actualJob?.company_name ?? '-'}
            </Text>
          </VStack>

          <VStack alignItems="flex-start" width="20%" spacing={1}>
            <Text
              fontSize="xs"
              lineHeight="normal"
              color="neutralDark.300"
              fontWeight="500">
              Cargo
            </Text>
            <Text
              fontSize="sm"
              fontWeight="bold"
              lineHeight="normal"
              noOfLines={1}>
              {actualJob?.position_in_the_company ?? '–'}
            </Text>
          </VStack>
        </HStack>

        <HStack w="100%" justifyContent="space-between">
          {!isInShortlist && (
            <Select
              isDisabled={isApprovedShortlist}
              bg={
                candidateStatus === 'SUITABLE_CANDIDATE'
                  ? 'assistant.500'
                  : 'grayText'
              }
              p="0"
              size="md"
              onChange={handleSelectStatus}
              value={candidateStatus}
              borderRadius="md"
              w="fit-content"
              variant="filled"
              fontSize="xs"
              fontWeight="500"
              color="white"
              _focusVisible={{
                _placeholder: {
                  color: '#000'
                },
                bg:
                  candidateStatus === 'SUITABLE_CANDIDATE'
                    ? 'assistant.500'
                    : 'grayText',
                borderColor: 'transparent'
              }}
              _hover={{
                bg: 'neutralDark.100'
              }}
              _disabled={{
                bg:
                  candidateStatus === 'SUITABLE_CANDIDATE'
                    ? 'assistant.500'
                    : 'grayText'
              }}>
              {Object.keys(OptionsStatusCandidateJob).map((key: string) => (
                <option
                  key={key}
                  value={key}
                  style={{ backgroundColor: '#fff', color: '#000' }}>
                  {OptionsStatusCandidateJob[key]}
                </option>
              ))}
            </Select>
          )}
          {!isInShortlist && (
            <IconButton
              p="1"
              bg="#042E4B"
              borderRadius={6}
              aria-label="Search database"
              icon={<Icon as={FiFileText} h={5} w={5} color="white" />}
              onClick={() =>
                window.open(
                  `/pipeline-de-vagas/${candidate.id}/interview/${jobId}`,
                  '_blank'
                )
              }
            />
          )}
        </HStack>
      </VStack>
    </Flex>
  )
}
