type keyValueType = {
  value: string
  label: string
}

export const YWOffices: keyValueType[] = [
  { value: 'BELO_HORIZONTE', label: 'Belo Horizonte' },
  { value: 'SAO_PAULO', label: 'São Paulo' },
  { value: 'RECIFE', label: 'Recife' }
]

export const employes: keyValueType[] = [
  { value: 'FROM_0_TO_20', label: '0 a 20' },
  { value: 'FROM_20_TO_100', label: '20 a 100' },
  { value: 'FROM_100_TO_500', label: '100 a 500' },
  { value: 'FROM_500_TO_1000', label: '500 a 1000' },
  { value: 'ABOVE_1000', label: 'mais de 1000' }
]

export const ClientAnnualBilling: keyValueType[] = [
  { value: '100_MIL', label: 'Até 100 mil' },
  { value: '100_MIL_1_MILHAO', label: 'De 100 mil a 1 milhão' },
  { value: '1_MILHAO_10_MILHOES', label: 'De 1 milhão a 10 milhões' },

  { value: '10_MILHOES_50_MILHOES', label: 'De 10 milhões a 50 milhões' },
  { value: '50_MILHOES_500_MILHOES', label: 'De 50 milhões a 500 milhões' },
  { value: 'ACIMA_500_MILHOES', label: 'Acima de 500 milhões' }
]
