export default {
  assistant: {
    100: '#CFFAF0',
    200: '#A1F5E8',
    300: '#6EE1D9',
    400: '#46C3C4',
    500: '#17929E',
    600: '#107387',
    700: '#0B5871',
    800: '#073F5B',
    900: '#042E4B'
  },
  blackText: '#1B1C1E',
  blackBackground: '#161616',
  brand: {
    100: '#FFF4D1',
    200: '#FFECA8',
    300: '#FFE380',
    400: '#FFDD50',
    500: '#FDD600',
    600: '#D9B400',
    700: '#B69400',
    800: '#927500',
    900: '#795E00'
  },

  grayBackground: '#F5F6FA',
  grayText: '#8F8F8F',
  grayPrimary: '#4D4D4D',

  negative: {
    100: '#FDE1D4',
    200: '#FBBCAA',
    300: '#F58E7E',
    400: '#EB645D',
    500: '#DF2B32',
    600: '#BF1F33',
    700: '#A01533',
    800: '#810D30',
    900: '#6B082F'
  },
  neutralDark: {
    100: '#81929F',
    200: '#556476',
    300: '#425063',
    400: '#374052',
    500: '#272E3B'
  },
  neutralLight: {
    200: '#F6F8FA',
    300: '#F0F2F5',
    400: '#E1E6E9',
    500: '#C1CAD0',
    600: '#B3BDC4'
  },
  positive: {
    100: '#C7F9C7',
    200: '#91F39A',
    300: '#57DB70',
    400: '#2EB855',
    500: '#008935',
    600: '#007539',
    700: '#006239',
    800: '#004F36',
    900: '#004133'
  },
  warning: {
    100: '#FDF1DC',
    200: '#FFD6A6',
    300: '#FFBD6F',
    400: '#FFA63D',
    500: '#FF8A00',
    600: '#E97000',
    700: '#D65600',
    800: '#C44800',
    900: '#9C2F00'
  }
}
