/* eslint-disable camelcase */
import {
  Avatar,
  Box,
  Center,
  Divider,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { Select } from 'components/atoms/Select'
import { Textarea } from 'components/atoms/Textarea'
import { UrgencyLevel } from 'components/atoms/UrgencyLevel/UrgencyLevel'
import { JobModeType } from 'enum/user'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FiPlus, FiTrash, FiUser } from 'react-icons/fi'
import { useFetch } from 'use-http'
import { formatDate, transformDate } from 'utils/formatDate'

const Processes = () => {
  const { control } = useFormContext()

  const {
    fields: fieldsJob,
    append: appendJob,
    remove: removeJob
  } = useFieldArray({
    name: 'candidate_is_in_other_job_process',
    control
  })

  return (
    <>
      {fieldsJob?.map((field, idx) => (
        <HStack key={field.id} width="100%" spacing={4}>
          <Controller
            control={control}
            name={`candidate_is_in_other_job_process.${idx}.company_name`}
            render={({ field: { ref, ...props } }) => (
              <Input
                {...props}
                variant="filled"
                label="Nome da empresa"
                placeholder="Digite aqui"
              />
            )}
          />
          <Controller
            control={control}
            name={`candidate_is_in_other_job_process.${idx}.step`}
            render={({ field: { ref, ...props } }) => (
              <Input
                {...props}
                variant="filled"
                label="Etapa"
                placeholder="Digite aqui"
              />
            )}
          />
          <HStack pt={10} alignItems="center" spacing={4}>
            {fieldsJob.length > 1 && (
              <IconButton
                onClick={() => removeJob(idx)}
                variant="ghost"
                size="xs"
                colorScheme="black"
                icon={<FiTrash fontSize="1.5rem" />}
                aria-label="Delete"
              />
            )}
            <IconButton
              isRound
              onClick={() => appendJob({})}
              variant="solid"
              size="xs"
              colorScheme="brand"
              icon={<FiPlus fontSize="1.2rem" color="white" />}
              aria-label="Add"
            />
          </HStack>
        </HStack>
      ))}
      {fieldsJob.length === 0 && (
        <Button
          width="100%"
          height={8}
          size="xs"
          variant="outline"
          colorScheme="neutralLight"
          onClick={() => appendJob({})}>
          Adicionar processo
        </Button>
      )}
    </>
  )
}

type HistoryType = {
  comment: string
  user_yw: {
    id: string
    user: {
      name: string
    }
  }
  user_yw_id: string
  job: {
    title: string
  }
  interview_form_job_id: string
  type: 'MOTIVATION' | 'OBJECTION'
  created_at: string
}

const HistoryItem = ({ comment, user_yw, job, created_at }: HistoryType) => {
  if (comment === '') return <></>

  return (
    <HStack alignItems="flex-start">
      <Box mr={1}>
        <Avatar
          size="sm"
          bg="#D9D9D9"
          color="#1B1C1E"
          icon={<FiUser fontSize="1.2rem" />}
        />
      </Box>
      <Box width="100%" pt={2}>
        <Heading as="h3" fontSize="sm" display="flex" alignItems="center">
          {user_yw.user.name}
          <Text as="span" fontWeight="light" ml={2}>
            adicionou comentário
          </Text>
        </Heading>
        <Text
          display="table"
          as="p"
          py={2}
          px={4}
          bg="#F5F6FA"
          borderRadius="lg"
          fontSize="sm"
          mt={2}>
          {comment}
        </Text>
        <HStack alignItems="center">
          <Text as="span" fontWeight="light" fontSize="sm">
            {formatDate(
              new Date(created_at),
              "dd 'de' MMM 'de' yyyy 'às' HH:mm."
            )}
          </Text>
          <Text as="span" fontWeight="light" fontSize="sm">
            Vaga: {job?.title ?? '–'}
          </Text>
        </HStack>
      </Box>
    </HStack>
  )
}

const History = ({
  data,
  job
}: {
  data: HistoryType[]
  job: {
    title: string
  }
}) => {
  const { control } = useFormContext()

  const types: any = {
    MOTIVATION: 'Motivações',
    OBJECTION: 'Objeções'
  }

  const { fields: fieldsHistory } = useFieldArray({
    name: 'objections_and_motivations',
    control
  })

  return (
    <>
      {fieldsHistory?.map((field: any, idx) => (
        <VStack key={field.id} width="100%" alignItems="flex-start" spacing={4}>
          <Controller
            control={control}
            name={`objections_and_motivations.${idx}.comment`}
            render={({ field: { ref, ...props } }) => (
              <Textarea
                {...props}
                label={types[field.type]}
                cols={5}
                rows={5}
                variant="filled"
                placeholder="Digite aqui"
                maxLength={2600}
              />
            )}
          />
          <VStack width="100%" alignItems="flex-start" spacing={4}>
            {data
              .filter(({ type }) => type === field.type)
              .map((history, idx) => (
                <HistoryItem key={`history_${idx}`} {...history} job={job} />
              ))}
          </VStack>
        </VStack>
      ))}
    </>
  )
}

const Calendar = ({ index, data }: { index: number; data: any }) => {
  const { control, setValue } = useFormContext()

  useEffect(() => {
    data?.interview_form_job_agenda?.map(
      // eslint-disable-next-line array-callback-return
      ({ date, format, observations }: any, i: number) => {
        setValue(
          `interview_form_job_agenda.${i}.date`,
          date ? transformDate(date?.split?.('T')?.[0]) : null
        )
        setValue(`interview_form_job_agenda.${i}.format`, format)
        setValue(`interview_form_job_agenda.${i}.observations`, observations)
      }
    )
  }, [data.interview_form_job_agenda, setValue])

  return (
    <>
      <VStack width="100%" alignItems="flex-start" spacing={6}>
        <HStack width="100%" alignItems="flex-start" spacing={6}>
          <Controller
            control={control}
            name={`interview_form_job_agenda.${index}.date`}
            render={({ field: { ref, value, ...props } }) => (
              <Input
                {...props}
                type="text"
                label="Data"
                variant="filled"
                value={value ?? ''}
                mask="99/99/9999"
                placeholder="--/--/----"
              />
            )}
          />
          <Controller
            control={control}
            name={`interview_form_job_agenda.${index}.format`}
            render={({ field: { ref, ...props } }) => (
              <Select
                {...props}
                label="Formato"
                placeholder="Selecione"
                variant="filled">
                {Object.keys(JobModeType)?.map((key, idx) => (
                  <option key={`job_type_${idx}`} value={key}>
                    {JobModeType[key]}
                  </option>
                ))}
              </Select>
            )}
          />
        </HStack>
        <Box width="100%">
          <Controller
            control={control}
            name={`interview_form_job_agenda.${index}.observations`}
            render={({ field: { ref, value, ...props } }) => (
              <Textarea
                {...props}
                label="Observações"
                cols={5}
                rows={3}
                variant="filled"
                placeholder="Digite aqui"
                maxLength={2600}
                value={value ?? ''}
              />
            )}
          />
        </Box>
      </VStack>
    </>
  )
}

export const Benefits = () => {
  const onError = useErrorToast()

  const { control } = useFormContext()
  const { loading, data: benefits } = useFetch(
    Endpoints.lists.benefits,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os benefícios.'
        }),
      interceptors: {
        response: async ({ response }) => {
          response.data = response.data.map(
            ({ id, name }: { id: string; name: string }) => ({
              label: name,
              value: id
            })
          )
          return response
        }
      }
    },
    []
  )

  return !loading ? (
    <Controller
      control={control}
      name="desired_benefits"
      render={({ field: { ref, ...props } }) => (
        <Select
          {...props}
          isMulti
          loading={loading}
          width="100%"
          label="Benefícios desejáveis"
          variant="filled"
          placeholder="Selecione"
          options={benefits}
        />
      )}
    />
  ) : (
    <Input
      label="Benefícios desejáveis"
      placeholder="Carregando..."
      variant="filled"
    />
  )
}

export const Vacancy = ({ data }: any) => {
  const { control, register, setValue, watch } = useFormContext()

  const fields = watch()

  const isParticaptingOtherProcess = watch('is_particapting_other_process')

  const {
    fields: fieldsCalendar,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'interview_form_job_agenda'
  })

  useEffect(() => {
    register('interest_level', {
      value: data?.interview_form_job?.[0]?.interest_level
    })
  }, [data?.interview_form_job, register])

  return (
    <Fragment>
      <VStack width="100%" alignItems="flex-start" spacing={4}>
        {false && <Benefits />}
        <HStack
          width="100%"
          alignItems="flex-start"
          justifyContent="stretch"
          py={4}
          spacing={8}>
          <VStack alignItems="flex-start" spacing={6}>
            <Box>
              <FormLabel>Nível de interesse na vaga</FormLabel>
              <UrgencyLevel
                reverse
                size="lg"
                urgencyLevel={fields.interest_level}
                onChange={(level) => setValue('interest_level', level)}
                mode="solid"
              />
            </Box>
            <Controller
              control={control}
              name="start_date_availability"
              render={({ field: { ref, value, ...props } }) => (
                <Input
                  {...props}
                  type="text"
                  label="Disponibilidade de início"
                  variant="filled"
                  value={value ?? ''}
                  mask="99/99/9999"
                  placeholder="--/--/----"
                />
              )}
            />
          </VStack>
          <Center height="150px">
            <Divider orientation="vertical" />
          </Center>
          <Box width="100%">
            <HStack alignItems="center" mb="4">
              <FormLabel
                fontWeight="bold"
                fontSize="md"
                color="black"
                htmlFor="process"
                m={0}>
                Está participando de outros processos seletivos?
              </FormLabel>
              <Switch
                colorScheme="brand"
                id="process"
                {...register('is_particapting_other_process')}
              />
            </HStack>
            {isParticaptingOtherProcess && <Processes />}
          </Box>
        </HStack>
        <History
          data={data?.interview_form_job?.[0]?.objections_and_motivations ?? []}
          job={data?.interview_form_job?.[0]?.job ?? {}}
        />
        <Divider />
        <Controller
          control={control}
          name="specific_question"
          render={({ field: { ref, value, ...props } }) => (
            <Textarea
              {...props}
              label="Pergunta Específica"
              cols={5}
              rows={5}
              variant="filled"
              placeholder="Digite aqui"
              maxLength={2600}
              value={value ?? ''}
            />
          )}
        />
        <Divider />
        <Heading
          width="100%"
          as="h3"
          fontSize="lg"
          fontWeight="semibold"
          textAlign="left">
          Agenda
        </Heading>
        {fieldsCalendar.map((field: any, idx) => (
          <Fragment key={field.id}>
            <Calendar index={idx} data={data?.interview_form_job?.[0] ?? {}} />
            <Button
              width="100%"
              py="0.5"
              fontSize="sm"
              fontWeight="500"
              variant="outline"
              borderRadius="md"
              color="negative"
              colorScheme="negative"
              _hover={{}}
              onClick={() => remove(idx)}>
              Remover agenda
            </Button>
          </Fragment>
        ))}
        <Box width="100%" mt={8}>
          <Button
            type="button"
            width="100%"
            variant="outline"
            py="0.5"
            fontSize="sm"
            fontWeight="500"
            borderRadius="md"
            colorScheme="neutralLight"
            onClick={() => append({})}>
            Adicionar Agenda
          </Button>
        </Box>
      </VStack>
    </Fragment>
  )
}
