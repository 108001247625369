import {
  HStack,
  PinInput,
  PinInputField,
  Text,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Auth } from 'aws-amplify'
import { Button } from 'components/atoms/Button'
import { useErrorToast } from 'hooks/useErroToast'
import { createRef, useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useFetch from 'use-http'
import { FormDataType, FormFirstAccess } from './components/FormFirstAccess'
import { HeaderLoginForm } from '../../components/organisms/HeaderLoginForm'
import { LoginTemplate } from '../../components/templates/Login'

export const LoginFirstAccess = () => {
  const onError = useErrorToast()
  const {
    state: { name, password, email }
  }: any = useLocation()

  const navigate = useNavigate()
  const toast = useToast()
  const [step, setStep] = useState<string>('CONFIRME_CODE')
  const [code, setCode] = useState<string>('')

  const formRef: any = createRef()

  const {
    post: confirmCode,
    loading: loadingConfirmCode,
    response: resConfirmCode
  } = useFetch(Endpoints.auth.confirm)

  const { post: resendCode } = useFetch(Endpoints.auth.resendCode, {
    onError: () => onError({ title: 'Erro ao enviar código', description: '' })
  })

  const [loading, setLoading] = useState<boolean>(false)

  const onConfirmCode = async () => {
    await confirmCode({ email, code })
    if (resConfirmCode.data?.response?.code === 'ExpiredCodeException') {
      await resendCode({ email })
      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: 'Verifique e tente novamente.',
        position: 'top-right',
        status: 'warning',
        title:
          'O seu código expirou. Reenviamos um novo código para seu e-mail.',
        variant: 'subtle',
        duration: 5000
      })
      return
    }

    if (resConfirmCode.data?.response?.code === 'CodeMismatchException') {
      onError({
        title: 'Código incorreto.',
        description: 'Verifique e tente novamente.'
      })
      return
    }

    if (resConfirmCode?.status >= 400) {
      onError({
        title: 'Algo deu errado.',
        description: 'Verifique e tente novamente.'
      })
      return
    }

    setStep('RESET_PASSWORD')
  }

  const onPassword = useCallback(
    async (newPassword: string) => {
      setLoading(true)

      try {
        await Auth.signIn(email, 'yellow.REC0')
        const user = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(user, password, newPassword)
        await Auth.updateUserAttributes(user, {
          'custom:first_access': '0'
        })

        navigate('../../')
      } catch (err) {
        console.error({ err })
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: 'Não foi possível alterar sua senha. Tente novamente.',
          position: 'top-right',
          status: 'warning',
          title: 'Erro',
          variant: 'subtle'
        })
      } finally {
        setLoading(false)
      }
    },
    [navigate, password, toast]
  )

  const STEPS: any = {
    CONFIRME_CODE: {
      title: name ? `Olá, ${name}!` : 'Olá',
      description: 'Digite o codigo enviado para o email ' + email,
      content: (
        <>
          <HStack>
            {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
            <PinInput onChange={setCode} autoFocus focusBorderColor="brand.500">
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <Text> - </Text>

              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          <Button
            disabled={loadingConfirmCode || code.length < 6}
            loading={loadingConfirmCode}
            type="button"
            variant="solid"
            colorScheme="brand"
            onClick={() => !loading && onConfirmCode()}
            width="100%">
            Confirmar E-mail
          </Button>
        </>
      )
    },
    RESET_PASSWORD: {
      title: 'Definir Senha',
      description: 'Precisamos que defina uma senha de acesso.',
      content: (
        <FormFirstAccess
          formRef={formRef}
          onSubmit={({ password: newPassword }: FormDataType) =>
            onPassword(newPassword)
          }
          loading={loading}
        />
      )
    }
  }

  return (
    <LoginTemplate>
      <HeaderLoginForm
        title={STEPS[step].title}
        description={STEPS[step].description}
      />
      {STEPS[step].content}
    </LoginTemplate>
  )
}
