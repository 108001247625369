/* eslint-disable camelcase,react/jsx-indent,indent */
import {
  Box,
  Divider,
  HStack,
  Heading,
  Spinner,
  VStack,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { Select } from 'components/atoms/Select'
import { Textarea } from 'components/atoms/Textarea'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { useEditJob } from 'hooks/useEditJob'
import { useErrorToast } from 'hooks/useErroToast'
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useFetch } from 'use-http'
import { jobCurrencyFormatter } from 'utils/formatCurrency'
import {
  formatDate,
  transformDate,
  transformDatePayload
} from 'utils/formatDate'
import { Candidate } from './components/Close/Candidate'
import { InterviewHeader } from './components/Interview/Header'

const JOB_OPTIONS: { [key: string]: string } = {
  CANCELED: 'Cancelada',
  NORMAL: 'Normal',
  ADITIONAL_POSITION: 'Posição adicional',
  WARRANTY: 'Garantia',
  MPC: 'MPC'
}

export const CloseJob = () => {
  const { jobId }: any = useParams()
  const toast = useToast()
  const onError = useErrorToast()
  const [invoiceObs, setInvoiceObs] = useState()

  const methods = useForm()

  const { loading, data: job } = useFetch(
    Endpoints.jobs.findOne(jobId),
    {
      onError: () =>
        onError({
          title: 'Não foi possivel encontrar a vaga.',
          description: 'Por favor tente novamente'
        })
    },
    []
  )
  const {
    loading: loadingClosingForm,
    data: closingForm,
    get: refetch
  } = useFetch(
    Endpoints.closeForm.root(jobId),
    {
      onError: () =>
        onError({
          title: 'Não foi possivel encontrar o form de fechamento.',
          description: 'Por favor tente novamente'
        })
    },
    []
  )

  const { data: cancelOptions, loading: loadingCanceledOptions } = useFetch(
    Endpoints.lists.cancelJobOptions,
    {},
    []
  )

  const {
    loading: loadingCreateClosingForm,
    post,
    response: resCreate
  } = useFetch(Endpoints.closeForm.root(jobId))

  const {
    loading: loadingUpdateClosingForm,
    put,
    response: resUpdate
  } = useFetch(Endpoints.closeForm.root(jobId))

  const { put: updateJob, response: responseJob } = useFetch(
    Endpoints.jobs.update(jobId)
  )

  const { data: invoices } = useFetch(
    Endpoints.forms.findOne(jobId, 'closure'),
    {
      onError: () =>
        onError({
          title: 'Não foi possivel encontrar o invoice.',
          description: 'Por favor tente novamente'
        })
    },
    []
  )
  const invoiceId = invoices ? invoices[0]?.id : null

  const { put: updateInvoice, response: responseForm } = useFetch(
    Endpoints.forms.update(invoiceId)
  )

  const putObservation = useCallback(
    async (invoiceObs: string) => {
      await updateInvoice({
        observation: invoiceObs
      })

      if (responseForm.status >= 400) {
        onError({
          title: 'Não foi possível atualizar a observação.',
          description: 'Por favor, tente novamente.'
        })
        return
      }

      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        position: 'top-right',
        status: 'success',
        title: 'Form atualizado com sucesso.',
        variant: 'subtle'
      })
    },
    [onError, toast, updateInvoice, responseForm.status]
  )

  const canEdit = useEditJob(job)

  const handleForecast = useCallback(
    async (forecast: string) => {
      await updateJob({
        forecast
      })

      if (responseJob.status >= 400) {
        onError({
          title: 'Não foi possivel atualizar o forecast.',
          description: 'Por favor tente novamente'
        })
        return
      }

      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        position: 'top-right',
        status: 'success',
        title: 'Forecast atualizado com sucesso',
        variant: 'subtle'
      })
    },
    [onError, responseJob.status, toast, updateJob]
  )

  const isUpdate = useMemo(() => !!closingForm?.id, [closingForm])

  const onSubmit = useCallback(
    async (dataForm: any) => {
      const { candidates } = dataForm

      if (invoiceObs && invoiceId) {
        await putObservation(invoiceObs)
      }

      const payload: any = {
        canceled_date: dataForm?.canceled_date
          ? new Date(transformDatePayload(dataForm.canceled_date))
          : null,
        comment: dataForm?.comment,
        job_options: dataForm?.job_options,
        job_id: jobId,
        closing_form_job_candidates: candidates.map(
          ({
            candidate,
            abandoned,
            counter_proposal,
            interviewed_by_client,
            is_placement,
            placement_by_email,
            admission_date,
            closing_date,
            final_salary,
            hiring_model,
            ...restCandidate
          }: any) => {
            isUpdate && delete restCandidate?.closing_form_job_id

            const finalSalaryConverted =
              jobCurrencyFormatter(final_salary).toString()

            return {
              candidate_id: candidate?.id,
              placement_by_email:
                placement_by_email && JSON.parse(placement_by_email),
              abandoned: JSON.parse(abandoned),
              counter_proposal: JSON.parse(counter_proposal),
              interviewed_by_client: JSON.parse(interviewed_by_client),
              is_placement: JSON.parse(is_placement),
              admission_date: admission_date
                ? new Date(transformDatePayload(admission_date))
                : undefined,
              closing_date: closing_date
                ? new Date(transformDatePayload(closing_date))
                : undefined,
              final_salary: parseFloat(finalSalaryConverted),
              hiring_model:
                hiring_model || hiring_model !== 'Selecione'
                  ? hiring_model
                  : null,
              ...restCandidate
            }
          }
        )
      }

      if (isUpdate) {
        delete payload.job_id
        await put(payload)
      } else {
        await post({ closeFormData: payload, invoiceData: invoiceObs })
      }

      if ((resCreate || resUpdate).status >= 400) {
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: `Não foi possível ${
            isUpdate ? 'editar' : 'criar'
          } o form de fechamento. Por favor, tente novamente.`,
          position: 'top-right',
          status: 'warning',
          title: resUpdate.status === 500 ? 'Erro interno' : 'Erro',
          variant: 'subtle'
        })

        return
      }

      toast({
        containerStyle: {
          maxWidth: '312px'
        },
        description: `Form de fechamento ${
          isUpdate ? 'editado' : 'criado'
        } com sucesso.`,
        position: 'top-right',
        status: 'success',
        title: 'Sucesso',
        variant: 'subtle'
      })

      await refetch()
    },
    [
      closingForm?.final_salary,
      isUpdate,
      jobId,
      post,
      put,
      refetch,
      resCreate,
      resUpdate,
      toast,
      putObservation,
      invoiceObs,
      invoiceId
    ]
  )

  const { fields: fieldsCandidates } = useFieldArray({
    name: 'candidates',
    control: methods.control
  })

  const candidatesMapping = useCallback(
    () =>
      closingForm &&
      Object.keys(closingForm?.closing_form_job_candidates ?? {})?.length
        ? closingForm?.closing_form_job_candidates
            .filter(
              (value: any, index: any, self: any) =>
                index ===
                self.findIndex(
                  (c: any) => c.candidate.id === value.candidate.id
                )
            )
            ?.map(
              ({
                closing_date,
                admission_date,
                ...candidate
              }: {
                closing_date: string
                admission_date: string
              }) => ({
                ...candidate,
                closing_date: closing_date
                  ? transformDate(
                      new Date(closing_date)?.toISOString()?.split('T')?.[0]
                    )
                  : undefined,
                admission_date: admission_date
                  ? transformDate(
                      new Date(admission_date)?.toISOString()?.split('T')?.[0]
                    )
                  : undefined
              })
            )
        : [],
    [closingForm]
  )

  useEffect(() => {
    if (closingForm && !loadingClosingForm) {
      methods.reset({
        ...closingForm,
        candidates: candidatesMapping()
      })
    }
  }, [closingForm, methods, loadingClosingForm, candidatesMapping])

  useEffect(() => {
    invoices && setInvoiceObs(invoices[0]?.observation)
  }, [invoices])

  return (
    <Suspense>
      <VStack spacing={2} alignItems="flex-start">
        <HeaderPage title="Forms de fechamento" />
        <Heading as="h2" color="blackText" fontSize={14}>
          {job?.title ?? ''}
        </Heading>
      </VStack>
      {loading && loadingClosingForm && loadingCanceledOptions && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Box>
      )}
      {job && closingForm && cancelOptions && (
        <Fragment>
          <VStack
            pointerEvents={canEdit ? 'auto' : 'none'}
            alignItems="flex-start"
            width="100%"
            bg="white"
            flex={1}
            borderRadius="lg"
            p={6}
            my={6}
            spacing={6}>
            <InterviewHeader
              data={job}
              onChangeForecast={handleForecast}
              loading={loading}
              showShortlist={false}
            />
            <Divider />
            <FormProvider {...methods}>
              <form
                style={{
                  width: '100%'
                }}
                onSubmit={methods.handleSubmit(onSubmit)}>
                <VStack alignItems="flex-start" width="100%" spacing={12}>
                  <VStack width="65%" alignItems="flex-start">
                    <Controller
                      control={methods.control}
                      defaultValue={closingForm?.job_options}
                      name="job_options"
                      render={({ field: { ref, value, ...props } }) => (
                        <Select
                          {...props}
                          value={value}
                          variant="filled"
                          label="Situação da vaga"
                          borderRadius="md"
                          placeholder="Selecione">
                          {Object.keys(JOB_OPTIONS).map((key) => (
                            <option key={key} value={key}>
                              {JOB_OPTIONS[key]}
                            </option>
                          ))}
                        </Select>
                      )}
                    />

                    {methods.watch('job_options') === 'CANCELED' && (
                      <HStack alignItems="center" spacing={8}>
                        <Controller
                          control={methods.control}
                          defaultValue={closingForm?.comment}
                          name="comment"
                          render={({ field: { ref, value, ...props } }) => (
                            <Select
                              {...props}
                              value={value}
                              variant="filled"
                              label="Motivo de cancelamento"
                              placeholder="Selecione">
                              {cancelOptions?.map((item: any) => (
                                <option key={item?.id} value={item?.reason}>
                                  {item?.reason.replace(
                                    item?.reason[0],
                                    item?.reason[0].toUpperCase()
                                  )}
                                </option>
                              ))}
                            </Select>
                          )}
                        />

                        <Controller
                          control={methods.control}
                          name="canceled_date"
                          render={({ field: { ref, value, ...props } }) => (
                            <Input
                              {...props}
                              type="text"
                              label="Data de cancelamento"
                              variant="filled"
                              value={
                                value
                                  ? transformDate(value.split('T')[0])
                                  : formatDate(new Date(), "dd'/'MM'/'yyyy")
                              }
                              mask="99/99/9999"
                              placeholder="--/--/----"
                            />
                          )}
                        />
                      </HStack>
                    )}
                  </VStack>
                  {fieldsCandidates?.length && (
                    <>
                      <Divider />
                      {fieldsCandidates?.map((field, idx) => (
                        <Fragment key={field.id}>
                          <Candidate index={idx} methods={methods} />
                        </Fragment>
                      ))}
                    </>
                  )}

                  <Textarea
                    label="Observação forms de fechamento"
                    cols={5}
                    rows={7}
                    mb="2"
                    variant="filled"
                    placeholder="Digite aqui"
                    value={invoiceObs}
                    onChange={(e: any) => {
                      setInvoiceObs(e.target.value)
                    }}
                  />
                </VStack>

                <VStack
                  spacing={6}
                  pb={6}
                  mt={16}
                  align={{ base: 'center', md: 'flex-end' }}>
                  <Divider mb="4" />
                  <Button
                    loading={
                      loadingUpdateClosingForm || loadingCreateClosingForm
                    }
                    type="submit"
                    width={230}
                    variant="solid"
                    borderRadius="md"
                    px={5}
                    colorScheme="brand">
                    Salvar
                  </Button>
                </VStack>
              </form>
            </FormProvider>
          </VStack>
        </Fragment>
      )}
    </Suspense>
  )
}
