const HEIGHT =
  // window.innerHeight ||
  document.documentElement.clientHeight || document.body.clientHeight
const WIDTH =
  // window.innerWidth ||
  document.documentElement.clientWidth || document.body.clientWidth

export const ratio = WIDTH / HEIGHT

// const ratio = 1440 / 1024
export const factorFromResolution = (1 / ratio) * 1.3

const removeRemAndConvertToNumber = (str: string) => Number(str.split('r')[0])

const applyFactor = (value: string, optionalFactor: number = 1) => {
  const numberOfRem = removeRemAndConvertToNumber(value)
  return `${(numberOfRem * factorFromResolution * optionalFactor).toFixed(
    3
  )}rem`
}

export const normalize = (
  space: { [key: string]: string },
  optionalFactor?: number
) => {
  const tuples = Object.entries(space).map(([key, value]) => [
    key,
    applyFactor(value, optionalFactor)
  ])

  return Object.fromEntries(tuples)
}

export const normalizeNumber = (num: number) =>
  Number((num * factorFromResolution).toFixed(3))

export const selectNumberToRatio = (array: number[]) => {
  if (ratio < 1.9) return array[0]
  return array[1] || array[0]
}
