import { Box, Flex, VStack } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'
import { Select } from 'components/atoms/Select'
import { useErrorToast } from 'hooks/useErroToast'
import { Controller } from 'react-hook-form'
import useFetch from 'use-http'
import { execToSpot } from 'utils/execToSpot'
import { JobVertical, seniorityTypes } from './../../../../data/jobInfo'
import { YWOffices } from './../../../../data/yellowInfo'

type keyValueType = {
  value: string
  label: string
}

const formatYellowProducts = async ({ response }: any) => {
  const res = response
  const newRes: keyValueType[] = []

  res?.data?.forEach((product: any) => {
    const productNewObject = {
      label: product?.name,
      value: product?.id
    }

    product?.name !== 'Exec' && newRes.push(productNewObject)
  })
  res.data = newRes

  return res
}

export const Draft = ({ control, errors, getValues }: any) => {
  const onError = useErrorToast()

  const { data: customers } = useFetch(
    Endpoints.customers.allCustomers,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os Clientes.'
        })
    },
    []
  )

  const { data: yellowProducts } = useFetch(
    Endpoints.lists.yellowProduct,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os Produtos Yellows.'
        }),
      interceptors: { response: formatYellowProducts }
    },
    []
  )

  return (
    <VStack as="form" width="100%" alignItems="flex-start" spacing={14} px={8}>
      <Flex width="100%" alignItems="flex-start" gap={4}>
        <Box flex="1">
          <Input
            variant="filled"
            label="Nome da vaga"
            name="title"
            errorMessage={errors?.title?.message}
            control={control}
            requiredInfo={true}
          />
        </Box>
        <Box flex="1">
          <Select
            placeholder="Selecione"
            variant="filled"
            label="Cliente"
            name="customer_code"
            control={control}
            requiredInfo={true}
            errorMessage={errors?.customer_code?.message}>
            {customers?.data?.map(
              (customer: { customer_code: string; name: string }) => (
                <option
                  key={customer.customer_code}
                  value={customer.customer_code}>
                  {customer.name}
                </option>
              )
            )}
          </Select>
        </Box>
      </Flex>
      <Flex width="100%" alignItems="flex-start" gap={4}>
        <Box flex="1" zIndex={1400}>
          <Controller
            control={control}
            name="level"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="Nível do cargo"
                options={seniorityTypes}
                height="2xs"
                requiredInfo={true}
                errorMessage={errors?.level?.message}
                rest={{ zIndex: 1400 }}
              />
            )}
          />
        </Box>

        <Box flex="1">
          <Controller
            control={control}
            name="yellow_product_id"
            render={({ field: { ref, value, ...props } }) => (
              <Menu
                {...props}
                label="Produto Yellow"
                errorMessage={errors?.yellow_product_id?.message}
                options={yellowProducts}
                requiredInfo={true}
                value={execToSpot(value, yellowProducts)}
                rest={{ zIndex: 1400 }}
              />
            )}
          />
        </Box>

        <Box flex="1">
          <Controller
            control={control}
            name="vertical"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="Vertical"
                options={JobVertical}
                requiredInfo={true}
                errorMessage={errors?.vertical?.message}
                rest={{ zIndex: 1400 }}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex width="100%" alignItems="flex-start" gap={4}>
        <Box flex="1">
          <Input
            variant="filled"
            type="text"
            mask="99/99/9999"
            label="Data do kickoff"
            placeholder="--/--/----"
            errorMessage={errors?.kickoff_date?.message}
            name={'kickoff_date'}
            requiredInfo={true}
            control={control}
          />
        </Box>
        <Box flex="1">
          <Controller
            control={control}
            name="yw_unity"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="Unidade Yellow"
                options={YWOffices}
                errorMessage={errors?.yw_unity?.message}
                requiredInfo={true}
                rest={{ zIndex: 1400 }}
              />
            )}
          />
        </Box>
        <Box flex="1">
          <Controller
            control={control}
            name="card_model"
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                label="Modelo de card"
                errorMessage={errors?.card_model?.message}
                requiredInfo={true}
                options={[
                  {
                    value: 'TECH',
                    label: 'Yellow'
                  }
                ]}
                rest={{ zIndex: 1400 }}
              />
            )}
          />
        </Box>
      </Flex>
    </VStack>
  )
}
