/* eslint-disable camelcase */
/* eslint-disable indent */
import { Box, Select, Text, useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { TablePagination } from 'components/organisms/Table'
import { format } from 'date-fns'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useMemo } from 'react'
import useFetch from 'use-http'

type TableCandidatesType = {
  data: any
  total: number
  onChange: (_page: number) => void
  pagination: {
    take: number
    total: number
  }
  refresh: any
}

export const TableCandidates = ({
  data,
  total,
  onChange,
  pagination,
  refresh
}: TableCandidatesType) => {
  const onError = useErrorToast()
  const toast = useToast()

  const { put: updateForm, response: responseForm, loading } = useFetch()

  const columns = useMemo(
    () => [
      {
        Header: 'NPS',
        accessor: 'nps'
      },
      {
        Header: 'Candidato',
        accessor: 'name'
      },
      {
        Header: 'E-mail',
        accessor: 'email'
      },
      {
        Header: 'Cód. Vaga',
        accessor: 'job_code'
      },
      {
        Header: 'Vaga',
        accessor: 'job_title'
      },
      {
        Header: 'Cliente',
        accessor: 'customer'
      },
      {
        Header: 'Data de fechamento da vaga',
        accessor: 'closeDate'
      },
      {
        Header: 'Placement',
        accessor: 'placement'
      }
    ],
    []
  )

  const handleStatus = async (nps: string, formId: string) => {
    await updateForm(Endpoints.forms.updateCandidate(formId), {
      nps: JSON.parse(nps)
    })

    if (responseForm.status >= 400) {
      onError({
        title: 'Não foi possível atualizar o form.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Form atualizado com sucesso.',
      variant: 'subtle'
    })

    refresh()
  }

  return (
    <Fragment>
      <TablePagination
        total={total}
        columns={columns}
        onChange={onChange}
        pagination={pagination}
        data={data?.map((form: any) => ({
          id: form.id,
          nps: (
            <Box w="130px">
              <Select
                bg="neutralLight.400"
                p="0"
                ml="4"
                size="sm"
                w="fit-content"
                variant="filled"
                defaultValue={form.nps.toString()}
                value={form?.nps?.toString()}
                onChange={(e) => handleStatus(e.target.value, form.id)}
                fontSize="xs"
                borderRadius="md"
                color="BlackText"
                disabled={loading}
                _focusVisible={{
                  _placeholder: {
                    color: 'black'
                  },
                  bg: 'neutralLight.400',
                  borderColor: 'transparent'
                }}
                _hover={{
                  bg: 'neutralLight.400'
                }}>
                {[
                  { label: 'Enviado', value: true },
                  { label: 'Não Enviado', value: false }
                ].map(({ label, value }, idx: number) => (
                  <option key={`invoice_${idx}`} value={value.toString()}>
                    {label}
                  </option>
                ))}
              </Select>
            </Box>
          ),
          name: <Text fontSize="sm">{form.candidate.name || '–'}</Text>,
          email: <Text fontSize="sm">{form.candidate.email || '–'}</Text>,
          job_code: (
            <Text fontSize="sm">
              {form.closing_form_job?.job?.job_code || '–'}
            </Text>
          ),
          job_title: (
            <Text fontSize="sm">{form.closing_form_job?.job?.title}</Text>
          ),
          customer: (
            <Text fontSize="sm">
              {form.closing_form_job?.job?.customer?.name ?? '–'}
            </Text>
          ),
          closeDate: (
            <Text fontSize="sm">
              {form.closing_date
                ? format(new Date(form.closing_date), 'dd/MM/yyyy')
                : '–'}
            </Text>
          ),
          placement: (
            <Text fontSize="sm">{form.is_placement ? 'Sim' : 'Não'}</Text>
          )
        }))}
      />
    </Fragment>
  )
}
