/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable camelcase */
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { format } from 'date-fns'
import { YWOffices } from 'enum/user'
import { useEffect, useMemo } from 'react'
import { FiFileText } from 'react-icons/fi'
import { useFetch } from 'use-http'

export const Applications = ({ candidateId }: { candidateId: string }) => {
  const { get, loading, data } = useFetch(
    Endpoints.usersCandidate.applications(candidateId)
  )

  const dataApplications = useMemo(
    () =>
      Object.values(
        data?.reduce((result: any, current: any) => {
          if (
            !result[current.job_list.job_id] ||
            new Date(result[current.job_list.job_id].job_list.updated_at) <
              new Date(current.job_list.updated_at)
          ) {
            result[current.job_list.job_id] = current
          }
          return result
        }, {}) ?? []
      ),
    [data]
  )

  useEffect(() => {
    get()
  }, [get])

  return (
    <>
      {loading && !data && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Box>
      )}
      {data && (
        <Box bg="white" flex={1} borderRadius="lg" p={6}>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            {dataApplications.map(
              ({ job_list: { job_list_type, job } }: any, idx: number) => {
                const closing_form_job = job?.ClosingFormJob
                const candidatePlacement =
                  closing_form_job?.closing_form_job_candidates?.find(
                    ({ candidate_id }: { candidate_id: string }) =>
                      candidate_id === candidateId
                  )

                return (
                  <GridItem w="full" key={`application_${idx}`}>
                    <HStack
                      w="full"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      p={4}
                      h="100%">
                      <VStack alignItems="flex-start" spacing={6}>
                        <Box>
                          <Text fontSize="xl" fontWeight="bold">
                            {job.title}
                          </Text>
                          <HStack alignItems="center" mt={2}>
                            <Box
                              bg="assistant.500"
                              borderRadius="md"
                              py="1.5"
                              px="3"
                              w="fit-content">
                              <Text
                                color="neutralLight.200"
                                fontWeight="500"
                                fontSize="xs">
                                {job.customer.name}
                              </Text>
                            </Box>
                            {job?.ClosingFormJob ? (
                              <Box
                                bg="negative.500"
                                borderRadius="md"
                                py="1.5"
                                px="3"
                                w="fit-content">
                                <Text
                                  color="neutralLight.200"
                                  fontWeight="500"
                                  fontSize="xs">
                                  Vaga fechada
                                </Text>
                              </Box>
                            ) : (
                              <Box
                                bg="positive.500"
                                borderRadius="md"
                                py="1.5"
                                px="3"
                                w="fit-content">
                                <Text
                                  color="neutralLight.200"
                                  fontWeight="500"
                                  fontSize="xs">
                                  Vaga aberta
                                </Text>
                              </Box>
                            )}
                          </HStack>
                        </Box>
                        <VStack alignItems="flex-start" spacing={2}>
                          {closing_form_job?.created_at && (
                            <Text fontSize="lg" fontWeight="normal">
                              data de candidatura:{' '}
                              <strong>
                                {format(
                                  new Date(closing_form_job?.created_at),
                                  'dd/MM/yyyy'
                                )}
                              </strong>
                            </Text>
                          )}
                          <Text
                            fontSize="sm"
                            color="#8F8F8F"
                            fontWeight="normal">
                            {[
                              job?.job_code,
                              job?.yellow_product?.name,
                              YWOffices[job?.yw_unity]
                            ]
                              .filter((item) => item)
                              .join(` \u2022 `)}
                          </Text>
                        </VStack>
                      </VStack>
                      <VStack
                        alignItems="flex-end"
                        justifyContent="space-between"
                        spacing={2}
                        h="100%">
                        <Box>
                          <Box
                            display="flex"
                            borderWidth={1}
                            borderColor="#042E4B"
                            borderRadius="md"
                            py={4}
                            px={5}
                            mb={2}>
                            <Text
                              fontSize="sm"
                              fontWeight="semibold"
                              color="#042E4B"
                              textTransform="capitalize">
                              {candidatePlacement?.is_placement
                                ? 'Placement'
                                : job_list_type.toLowerCase()}
                            </Text>
                          </Box>
                          {candidatePlacement?.is_placement &&
                            candidatePlacement?.admission_date && (
                              <Text
                                fontSize="sm"
                                color="#8F8F8F"
                                fontWeight="normal">
                                {format(
                                  new Date(candidatePlacement?.admission_date),
                                  'dd/MM/yyyy'
                                )}
                              </Text>
                            )}
                        </Box>
                        <IconButton
                          p="1"
                          bg="#042E4B"
                          borderRadius={6}
                          aria-label="Interview candidate"
                          icon={
                            <Icon as={FiFileText} h={5} w={5} color="white" />
                          }
                          onClick={() =>
                            window.open(
                              `/pipeline-de-vagas/${candidateId}/interview/${job.id}`,
                              '_blank'
                            )
                          }
                        />
                        {/* <Button
                          variant="link"
                          as={Link}
                          target="_blank"
                          to={`/pipeline-de-vagas/${candidateId}/interview/${job.id}`}>
                          Forms de entrevista
                        </Button> */}
                      </VStack>
                    </HStack>
                  </GridItem>
                )
              }
            )}
          </Grid>
        </Box>
      )}
    </>
  )
}
