import { useToast } from '@chakra-ui/react'
import { Auth } from 'aws-amplify'
import { createRef, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, FormDataType } from './components/Form'
import { HeaderLoginForm } from '../../components/organisms/HeaderLoginForm'
import { LoginTemplate } from '../../components/templates/Login'

export const ResetPassword = (props: any) => {
  const { email }: any = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const formRef: any = createRef()

  const [loading, setLoading] = useState<boolean>(false)

  const onReset = useCallback(
    async (code: string, newPassword: string) => {
      setLoading(true)

      try {
        await Auth.forgotPasswordSubmit(email, code, newPassword)
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          position: 'top-right',
          status: 'success',
          title: 'Senha alterada. Faça login utilizando a nova senha.',
          variant: 'subtle'
        })
        navigate('/acesso')
      } catch (err) {
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: 'Tente novamente.',
          position: 'top-right',
          status: 'warning',
          title: 'Não foi possível alterar!',
          variant: 'subtle'
        })
      } finally {
        setLoading(false)
      }
    },
    [email, navigate, toast]
  )

  return (
    <LoginTemplate {...props}>
      <HeaderLoginForm
        title="Resetar senha"
        description="Precisamos que insira o código e defina uma senha."
      />
      <Form
        formRef={formRef}
        onSubmit={({ code, password }: FormDataType) => onReset(code, password)}
        loading={loading}
      />
    </LoginTemplate>
  )
}
