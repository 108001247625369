export const formatResponseUserYw = async ({ response }: any) => {
  const res = response
  if (res.data)
    res.data = res.data.data.map((u: any) => ({
      label: u.user.name,
      value: u.id
    }))
  return res
}

export type userYwFormated = {
  value: string
  label: string
}
