import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import 'react-calendar/dist/Calendar.css'

import Calendar from 'react-calendar'

import './Calendar.css'

interface ChecklistModalDateInterface {
  modalOpen: boolean
  onClose: () => void
  onChangeCheck: any
  check: any
}

export const ChecklistModalDate = ({
  modalOpen,
  onClose,
  onChangeCheck,
  check
}: ChecklistModalDateInterface) => {
  const checklistId = check?.checklist_id
  const checkId = check?.id
  const date = check?.due_date ? new Date(check?.due_date) : new Date()

  return (
    <Box>
      <Modal
        isOpen={modalOpen}
        onClose={onClose}
        size="lg"
        blockScrollOnMount={false}>
        <ModalOverlay bg="rgba(0, 0, 0, 5%)" />
        <ModalContent
          top="60"
          right="-24"
          bg="neutralDark.500"
          color="neutralLight.200">
          <Flex flexDir="row" alignItems="center">
            <ModalHeader textAlign="center">Data</ModalHeader>
            <ModalCloseButton />
          </Flex>
          <ModalBody p="0">
            <Box>
              <Box className="calendar-container">
                <Calendar
                  onChange={(value: Date) => {
                    onChangeCheck(checklistId, checkId, undefined, value)
                    onClose()
                  }}
                  value={new Date(date)}
                  selectRange={false}
                />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
