export const Switch = {
  baseStyle: {
    container: {
      h: '20px'
    },
    track: {
      w: '36px',
      h: '20px',
      alignItems: 'center'
    },
    thumb: {
      w: '14px',
      h: '14px',
      m: '3px',
      _checked: {
        m: 0
      }
    }
  }
}
