import { EditCandidateToast } from '../modules/Candidates/components/EditCandidateToast'

export const candidateDuplicate = (
  candidateId: string,
  toastInvocation: any
) => {
  toastInvocation({
    duration: 10000,
    position: 'top-right',
    variant: 'subtle',
    id: 7,
    render(): React.ReactNode {
      return <EditCandidateToast id={candidateId} />
    }
  })
}
export const creatCandidateErr = (resStatus: number, toastInvocation: any) => {
  toastInvocation({
    containerStyle: {
      maxWidth: '312px'
    },
    description:
      'Não foi possível criar o candidato. Por favor, tente novamente.',
    position: 'top-right',
    status: 'warning',
    title: resStatus === 500 ? 'Erro interno' : 'Erro',
    variant: 'subtle'
  })
}

export const creatCandidateSucc = (toastInvocation: any) => {
  toastInvocation({
    containerStyle: {
      maxWidth: '312px'
    },
    description: 'Candidato criado com sucesso.',
    position: 'top-right',
    status: 'success',
    title: 'Sucesso',
    variant: 'subtle'
  })
}

export const addToLongListErr = (toastInvocation: any) => {
  toastInvocation({
    title: 'Não foi possível enviar candidato(s) para longlist.',
    description: 'Por favor, tente novamente.',
    status: 'error',
    duration: 3000,
    position: 'top-right',
    variant: 'subtle'
  })
}
export const addToLongListSucc = (toastInvocation: any) => {
  toastInvocation({
    title: 'Sucesso!',
    description: 'Candidato(s) enviado(s) para longlist.',
    status: 'success',
    duration: 3000,
    position: 'top-right',
    variant: 'subtle'
  })
}

export const updateCandidateSucc = (toastInvocation: any) => {
  toastInvocation({
    containerStyle: {
      maxWidth: '312px'
    },
    description: 'Candidato atualizado com sucesso.',
    position: 'top-right',
    status: 'success',
    title: 'Sucesso',
    variant: 'subtle'
  })
}

export const updateCandidateErr = (toastInvocation: any /*  error: any */) => {
  toastInvocation({
    containerStyle: {
      maxWidth: '312px'
    },
    description: 'Não foi possível atualizar o candidato.',
    position: 'top-right',
    status: 'error',
    title: 'Error',
    variant: 'subtle'
  })
}

export const updateInterviewErr = (toastInvocation: any /*  error: any */) => {
  toastInvocation({
    containerStyle: {
      maxWidth: '312px'
    },
    description: 'Não foi possível atualizar o fomulário job interview.',
    position: 'top-right',
    status: 'error',
    title: 'Error',
    variant: 'subtle'
  })
}

export const sendFormError = (toastInvocation: any) => {
  toastInvocation({
    title: 'Não foi possível enviar o formulário.',
    description: 'Por favor, verifique os campos preenchidos.',
    status: 'error',
    duration: 3000,
    position: 'top-right',
    variant: 'subtle'
  })
}
