import { differenceInDays, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const formatDate = (date: Date | number, maskDate: string) => {
  if (!date) return ''

  return format(date, maskDate, { locale: ptBR })
}

export const transformDate = (date: any) => {
  if (!date) return ''

  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}

export const transformDatePayload = (date: any) => {
  if (!date) return ''

  const [day, month, year] = date.split('/')
  return `${year}-${month}-${day}`
}

export const formatDateHumanized = (stringDate: Date) => {
  if (!stringDate) return ''

  const now = new Date()
  const diff = differenceInDays(stringDate, now)
  const samePeriod =
    now.getMonth() === stringDate.getMonth() &&
    stringDate.getFullYear() === now.getFullYear()

  if (diff === 0 && samePeriod) {
    return `Hoje às ${formatDate(stringDate, 'HH:mm')}`
  }

  if (diff === 1 && samePeriod) {
    return `Ontem às ${formatDate(stringDate, 'HH:mm')}`
  }

  return formatDate(stringDate, 'dd/MM/yyyy')
}

export const transformDateArray = (date: any) => {
  if (!date) return ''

  return date.split('-')
}
