import { Box, Icon, IconButton, useDisclosure } from '@chakra-ui/react'
import Logo from 'assets/images/logo-black.svg'
import { useRef } from 'react'
import { FiBell } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { normalizeNumber } from 'theme/normalization'
import { NotificationsDrawer } from './Notifications'

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const navigate = useNavigate()

  return (
    <>
      <Box
        px={10}
        bg="white"
        width="100%"
        height={`${normalizeNumber(72)}px`}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        boxShadow="inset 0px -1px 1px rgba(0, 0, 0, 0.1)"
        position="fixed"
        zIndex="1">
        <Box cursor="pointer" onClick={() => navigate('/')}>
          <img
            src={Logo}
            width={normalizeNumber(134)}
            height={normalizeNumber(25)}
            alt="Logo Yellow.rec"
          />
        </Box>
        <Box>
          <IconButton
            ref={btnRef as any}
            rounded="full"
            onClick={onOpen}
            size="lg"
            aria-label="Abrir notificações"
            icon={<Icon as={FiBell} w={6} h={6} />}
            colorScheme={isOpen ? 'neutralDark' : 'white'}
            color={isOpen ? 'white' : 'neutralDark'}
          />
        </Box>
      </Box>
      <NotificationsDrawer isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
    </>
  )
}
