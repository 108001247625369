import { PageTemplate } from 'components/templates/Page'

import {
  Candidates,
  CreateCandidate,
  Curriculum,
  EditCandidate
} from 'modules/Candidates'
import FormCandidateProvider from 'modules/Candidates/context'
import { Clients, CreateClient, EditClient } from 'modules/Clients'
import {
  Candidates as CandidatesForm,
  Closure,
  Delivery,
  Opening
} from 'modules/Forms'
import { Hit, Hits } from 'modules/Hits'
import {
  CloseJob,
  CreateJob,
  InterviewJob,
  Jobs,
  Shortlist
} from 'modules/Jobs'
import { UpdateJob } from 'modules/Jobs/UpdateJob'
import { List, Lists } from 'modules/Lists'
import { Login, LoginClient, LoginFirstAccess } from 'modules/Login'
import { GeneralProfile, PermissionsProfile, Profile } from 'modules/Profile'
import { RecoveryPassword } from 'modules/RecoveryPassword'
import { ResetPassword } from 'modules/ResetPassword'
import { CreateUser, EditUser, Users } from 'modules/Users'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import useUserStore from 'stores/user'

export const ProtectedRoute = ({ children }: any) => {
  const { accessToken } = useUserStore()
  const { pathname } = useLocation()

  if (!accessToken) {
    return <Navigate to={`/acesso?returnUrl=${pathname}`} />
  }

  return children
}

export const RoutesContainer = () => {
  const location = useLocation()

  return (
    <Routes>
      <Route path="acesso">
        <Route path="" element={<Login />} />
        <Route path="cliente" element={<LoginClient />} />
        <Route path="primeiro-acesso" element={<LoginFirstAccess />} />
      </Route>

      <Route path="recuperar-senha" element={<RecoveryPassword />} />
      <Route path="resetar-senha/:email" element={<ResetPassword />} />
      <Route path="curriculum">
        <Route path=":uuid">
          <Route path="" element={<Curriculum />} />
          <Route path=":jobId" element={<Curriculum />} />
        </Route>
      </Route>
      <Route path="shortlist/:uuid" element={<Shortlist />} />
      <Route path="*" element={<p>not found</p>} />

      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PageTemplate />
          </ProtectedRoute>
        }>
        <Route path="" element={<Navigate to="/pipeline-de-vagas" replace />} />

        {/*  <Route path="" element={<Dashboard />} /> */}

        <Route path="perfil" element={<Profile />}>
          <Route path="geral" element={<GeneralProfile />} />
          <Route path="permissoes" element={<PermissionsProfile />} />
        </Route>

        <Route path="usuarios">
          <Route path="" element={<Users />} />
          <Route path="editar/:uuid/:clientUuid" element={<EditUser />} />
          <Route path="editar/:uuid" element={<EditUser />} />
          <Route path="criar/:clientUuid" element={<CreateUser />} />
          <Route path="criar" element={<CreateUser />} />
        </Route>

        <Route path="clientes">
          <Route path="" element={<Clients />} />
          <Route path="criar" element={<CreateClient />} />
          <Route
            path="editar/:uuid"
            element={<EditClient />}
            key={location.key}
          />
        </Route>

        <Route path="candidatos">
          <Route path="" element={<Candidates />} />
          <Route
            path="criar"
            element={
              <FormCandidateProvider>
                <CreateCandidate />
              </FormCandidateProvider>
            }
          />
          <Route
            path="editar/:uuid"
            element={
              <FormCandidateProvider>
                <EditCandidate />
              </FormCandidateProvider>
            }
          />
        </Route>

        <Route path="pipeline-de-vagas">
          <Route path="v/:jobId" element={<Jobs />} />
          <Route path="" element={<Jobs />} />
          <Route path="criar" element={<CreateJob />} />
          <Route path="editar/:uuid" element={<UpdateJob />} />
          <Route path=":uuid/interview/:jobId" element={<InterviewJob />} />
          <Route path="close/:jobId" element={<CloseJob />} />
        </Route>

        <Route path="listas">
          <Route path="" element={<Lists />}>
            <Route path=":category" element={<List />} />
          </Route>
        </Route>

        <Route path="acessos">
          <Route path="" element={<Hits />}>
            <Route path=":category" element={<Hit />} />
          </Route>
        </Route>

        <Route path="forms">
          <Route path="abertura" element={<Opening />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="fechamento" element={<Closure />} />
          <Route path="candidatos" element={<CandidatesForm />} />
        </Route>
      </Route>
    </Routes>
  )
}
