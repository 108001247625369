/* eslint-disable indent */
/* eslint-disable camelcase */
// @ts-ignore
import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'

import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  VStack
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Tag } from 'components/atoms/Tag'
import { UrgencyLevel } from 'components/atoms/UrgencyLevel/UrgencyLevel'
import { TimeLine } from 'components/molecules/Timeline'
import { VerticalTypes } from 'enum/user'
import { useErrorToast } from 'hooks/useErroToast'
import { unique } from 'radash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FiMessageSquare } from 'react-icons/fi'
import { foreCastColors, forecastFontColors } from 'styles/forecast'
import { normalizeNumber } from 'theme/normalization'
import { useFetch } from 'use-http'
import { forescastFormatter } from 'utils/forecast'
import { formatCapitalizeUndeline } from 'utils/formatObjectValuesResponse'

type KanbanTypes = {
  data: any
  onOpen: (_id: string) => void
  onAdd: () => void
  simple: boolean
}

type KanbanColumnsTypes = {
  boards: {
    columns: {
      id: string
      status: string
      title: string
    }[]
  }
  card: {
    id: string
    title: string
  }
  from: {
    fromPosition: number
    fromColumnId: string
  }
  to: {
    toPosition: number
    toColumnId: string
  }
}

export const Kanban = ({ data, onOpen, onAdd, simple }: KanbanTypes) => {
  const [cards, setCards] = useState<any>(data ?? [])

  const BOARDS = useMemo(() => {
    const columns = [
      'Start',
      'Kickoff',
      'Hunting',
      'Delivery',
      'Client',
      'Placement',
      'Done',
      'On hold',
      'Cancelada'
    ]

    return {
      columns: columns.map((c) => {
        const columnName = c.replace(/ /g, '_').toUpperCase()
        return {
          id: `column_${columnName}`,
          status: columnName,
          title: c,
          cards: cards?.length
            ? cards
                ?.filter((card: any) => card.board_status === columnName)
                ?.slice(
                  0,
                  ['DONE', 'ON_HOLD', 'CANCELADA'].includes(columnName)
                    ? 20
                    : cards.filter(
                        (card: any) => card.board_status === columnName
                      ).length
                )
            : []
        }
      })
    }
  }, [cards])

  const onError = useErrorToast()

  const { put, loading } = useFetch({
    onError: () =>
      onError({
        title: 'Não foi possível atualizar o status da vaga.',
        description: 'Por favor, tente novamente.'
      })
  })

  const handleUpdateJob = useCallback(
    async (id: string, status: string) => {
      const card = await put(Endpoints.jobs.update(id), {
        board_status: status
      })
      setCards((oldCards: any) => [
        card,
        ...oldCards.filter((c: any) => c.id !== card.id)
      ])
    },
    [put]
  )

  useEffect(() => {
    setCards(data)
  }, [data])

  return (
    <Board
      disableColumnDrag
      disableCardDrag={false}
      onCardDragEnd={(
        card: KanbanColumnsTypes['card'],
        { fromColumnId }: KanbanColumnsTypes['from'],
        { toColumnId }: KanbanColumnsTypes['to']
      ) => {
        if (fromColumnId !== toColumnId && !loading) {
          const board = BOARDS.columns.find(({ id }: any) => id === toColumnId)
          handleUpdateJob(card.id, board?.status ?? '')
        }
      }}
      renderColumnHeader={({ title, cards, status, id }: any) => (
        <Flex
          key={`column_${id}`}
          bg="white"
          borderTopRadius="lg"
          p={3.5}
          justifyContent="space-between"
          alignItems="center">
          <Flex alignItems="center">
            <Heading as="h2" color="#1B1C1E" fontSize="xl">
              {title}
            </Heading>
            <Text
              bg="neutralDark.500"
              color="white"
              borderRadius="full"
              w={normalizeNumber(32)}
              h={normalizeNumber(32)}
              ml={2}
              display="flex"
              alignItems="center"
              fontSize="sm"
              justifyContent="center"
              textAlign="center"
              fontWeight="500"
              verticalAlign="middle">
              {cards.length}
            </Text>
          </Flex>
          {/* {status === 'START' && (
            <Button
              onClick={onAdd}
              size="xs"
              variant="ghost"
              colorScheme="black"
              leftIcon={<FiPlusCircle fontSize="1.2rem" />}>
              Adicionar nova vaga
            </Button>
          )} */}
        </Flex>
      )}
      renderCard={({
        title,
        time_line,
        customer,
        forecast,
        status,
        yw_unity,
        vertical,
        urgency,
        id,
        job_code,
        end_date,
        kickoff_date,
        success_email_date,
        comments
      }: any) => {
        return (
          <Box
            key={`card_${id}`}
            onClick={() => onOpen(id)}
            _hover={{ cursor: 'pointer !important' }}
            // _pressed={{ cursor: 'grab' }}
            userSelect="none">
            <VStack
              pt={5}
              pb={3}
              px={5}
              mb={5}
              width="100%"
              borderRadius="lg"
              boxShadow="lg"
              alignItems="flex-start"
              bg="#FCFCFD">
              <Heading as="h3" color="#1B1C1E" fontSize="xl">
                {title}
              </Heading>
              <HStack justifyContent="space-between" w="100%" py={2}>
                {customer && (
                  <Tag
                    // lineHeight={6}
                    size="md"
                    h="19"
                    fontSize="sm"
                    variant="solid"
                    borderRadius="md"
                    bg="#17929E">
                    <Text fontSize="xs" noOfLines={1}>
                      {customer.name}
                    </Text>
                  </Tag>
                )}
                {status !== 'draft' && <UrgencyLevel urgencyLevel={urgency} />}
              </HStack>
              <Box width="100%">
                <Text
                  color="#8F8F8F"
                  fontSize="xs"
                  fontWeight="semibold"
                  noOfLines={1}>
                  {job_code ? `${job_code} • ` : ''}
                  {vertical
                    ? VerticalTypes[vertical]
                    : vertical || 'Sem vertical'}{' '}
                  • YW {formatCapitalizeUndeline(yw_unity)}
                </Text>
              </Box>

              {status !== 'draft' && (
                <Box width="100%">
                  <TimeLine
                    kickoffDate={kickoff_date}
                    successEmailDate={success_email_date}
                    endDate={end_date}
                    timeline={time_line}
                    simple={simple}
                  />
                </Box>
              )}
              {status !== 'draft' && (
                <Tag
                  size="md"
                  fontSize="xs"
                  variant="solid"
                  borderRadius="md"
                  bg={foreCastColors[forecast] || foreCastColors.Indefinido}
                  color={
                    forecastFontColors[forecast] ||
                    forecastFontColors.Indefinido
                  }>
                  Forecast: {forescastFormatter(forecast)}
                </Tag>
              )}

              <Divider bg="#E9E9E9" my={3} />

              <Box
                width="100%"
                my={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                {status === 'draft' ? (
                  <Tag
                    size="sm"
                    width="100%"
                    fontSize="x-small"
                    display="flex"
                    variant="solid"
                    bg="negative.100"
                    textAlign="center"
                    color="negative.500"
                    borderRadius="md"
                    justifyContent="center">
                    Este é um card de registro. É preciso completá-lo
                  </Tag>
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between">
                    <HStack>
                      <Icon as={FiMessageSquare} fontSize="xl" />
                      <Text fontWeight="semibold" fontSize="xl">
                        {comments?.length || ''}
                      </Text>
                    </HStack>
                    {!!comments?.length && (
                      <AvatarGroup size="sm" ml={2}>
                        {unique(comments, (c: any) => c.user_yw.id).map(
                          (comment: any) => (
                            <Avatar
                              key={comment.id}
                              name={comment?.user_yw?.user?.name}
                            />
                          )
                        )}
                      </AvatarGroup>
                    )}
                  </Box>
                )}
              </Box>
            </VStack>
          </Box>
        )
      }}>
      {BOARDS}
    </Board>
  )
}
