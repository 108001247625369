import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useStorage from './useStorage'

type Filter = Record<string, any>

export const formatUrl = (filters: Filter) =>
  Object.keys(filters)
    .map((key) => {
      if (typeof filters[key] === 'object') {
        return filters[key]
          .map((values: string) => `${key}:${values}`)
          .join(',')
      } else {
        const value = `${filters[key]}`.replace(/\s/g, '+')
        return `${key}:${value}`
      }
    })
    .join(',')

const useFilters = (
  reflectUrl?: boolean,
  defaultFilters?: Record<string, any>,
  saveFilters?: boolean
) => {
  const { set, remove } = useStorage()
  const [, setParams] = useSearchParams()

  const [onTime, setOnTime] = useState<boolean>(false)
  const [filters, setFilters] = useState<Filter>(defaultFilters ?? {})
  const [appliedFilters, setAppliedFilters] = useState<Filter>(
    defaultFilters ?? {}
  )

  const clearFilter = useCallback(() => {
    setFilters({})
    setAppliedFilters({})
    setParams({}, { replace: true })
    if (saveFilters) remove('@storage::jobFilters')
  }, [remove, saveFilters, setParams])

  const updateFilter = useCallback(
    (key: string, value: any, reflect: boolean = false) => {
      setFilters((oldFilters) => ({ ...oldFilters, [key]: value }))

      if (reflect) setOnTime(true)
    },
    [setFilters]
  )

  useEffect(() => {
    if (reflectUrl) {
      setParams({ ...appliedFilters, ...filters }, { replace: true })

      if (saveFilters) {
        set('@storage::jobFilters', { ...appliedFilters, ...filters })
      }
    }
  }, [appliedFilters, filters, reflectUrl, saveFilters, set, setParams])

  useEffect(() => {
    if (filters && onTime) {
      setAppliedFilters((oldFilters) => ({ ...oldFilters, ...filters }))
    }
  }, [filters, onTime])

  return {
    appliedFilters,
    filters,
    updateFilter,
    clearFilter
  } as const
}

export default useFilters
