/* eslint-disable camelcase */
import { Box, Flex, HStack, Spinner, Text, useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Textarea } from 'components/atoms/Textarea'
import { Comment, CommentProps } from 'components/molecules/Comment'
import { useErrorToast } from 'hooks/useErroToast'
import { useState } from 'react'
import useUserStore from 'stores/user'
import useFetch from 'use-http'

export type CommentComponentProps = {
  jobId: string
}

export const Comments = ({ jobId }: CommentComponentProps) => {
  const toast = useToast()
  const onError = useErrorToast()
  const { id } = useUserStore()
  const [comment, setComment] = useState('')

  const {
    post: addComment,
    response,
    loading: loadingAddComment
  } = useFetch(Endpoints.jobs.comments(jobId))

  const { data, error, loading, get } = useFetch(
    Endpoints.jobs.comments(jobId),
    {},
    [loadingAddComment || response.ok]
  )

  const handleAddComment = async () => {
    await addComment({
      user_yw_id: id,
      comment
    })

    if (response.status !== 201) {
      onError({
        title: 'Erro.',
        description: 'Não foi possível adicionar comentário.'
      })
      return
    }

    toast({
      title: 'Sucesso.',
      description: 'Novo comentário adicionado com sucesso.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })
    setComment('')
    await get()
  }

  if (!data && loading)
    return (
      <Flex>
        <Spinner />
      </Flex>
    )

  if (error) return <Text> Não foi possível carregar comentários.</Text>

  return (
    <Box mt="20">
      <Text fontWeight="medium" fontSize="lg" mt={2}>
        Observações
      </Text>
      <Text fontWeight="bold" fontSize="sm" mt={5}>
        Escrever comentário
      </Text>
      <Textarea
        id="description"
        variant="filled"
        placeholder="Digite aqui"
        maxLength={500}
        rows={7}
        onChange={(e) => setComment(e.target.value)}
        value={comment}
      />
      <HStack w="100%" justifyContent="flex-end">
        <Button
          isLoading={loadingAddComment}
          disabled={loadingAddComment}
          onClick={handleAddComment}
          px={10}
          colorScheme="brand"
          mt={6}
          borderRadius="md"
          size="sm">
          Enviar
        </Button>
      </HStack>

      {!!data.length &&
        data.map((comment: CommentProps) => (
          <Comment key={comment.id} {...comment} />
        ))}
    </Box>
  )
}
