export const Checkbox = {
  baseStyle: {
    size: 'md',
    control: {
      borderColor: '#8F8F8F',
      borderRadius: '5px',
      borderWidth: '2px',
      width: '16px',
      height: '16px'
    },
    label: {
      color: '#8F8F8F',
      fontSize: '14px',
      fontWeight: 'bold'
    }
  },
  variants: {
    checklist: {
      control: {
        borderColor: '#8F8F8F',
        borderRadius: '4px',
        borderWidth: '1.5px',
        h: '20px',
        width: '20px'
      },
      label: {
        color: 'black',
        fontSize: '16px',
        fontWeight: 'normal'
      }
    }
  }
}
