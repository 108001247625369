import { Box, Link, VStack } from '@chakra-ui/react'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { Fragment } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'

const menus = [
  {
    slug: 'recrutamento',
    title: 'Recrutamento'
  },
  {
    slug: 'backoffice',
    title: 'Backoffice'
  },
  {
    slug: 'suporte',
    title: 'Suporte'
  },
  {
    slug: 'master',
    title: 'Master'
  }
]

export const Hits = () => {
  const location = useLocation()

  return (
    <Fragment>
      <HeaderPage divider title="Permissão de acessos" />
      <Box display="flex" mt={6}>
        <Box width={245}>
          <nav>
            <VStack w="full" alignItems="flex-start" spacing={6}>
              {menus.map((menu, idx) => (
                <Link
                  key={`menu_${idx}`}
                  as={NavLink}
                  to={menu.slug}
                  color={
                    location.pathname === `/acessos/${menu.slug}`
                      ? '#1B1C1E'
                      : '#4D4D4D'
                  }
                  fontSize="lg"
                  fontWeight="bold"
                  position="relative"
                  pl={location.pathname === `/acessos/${menu.slug}` ? 3 : 0}
                  _before={{
                    bg: 'brand.500',
                    borderRadius: 'full',
                    content: `${
                      location.pathname === `/acessos/${menu.slug}`
                        ? '""'
                        : 'unset'
                    }`,
                    display: 'inline-block',
                    h: 'full',
                    left: '0',
                    position: 'absolute',
                    width: '2px'
                  }}
                  _hover={{ color: '#1B1C1E' }}>
                  {menu.title}
                </Link>
              ))}
            </VStack>
          </nav>
        </Box>
        <Box flex={1} ml={8}>
          <Outlet />
        </Box>
      </Box>
    </Fragment>
  )
}
