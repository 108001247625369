import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import 'react-calendar/dist/Calendar.css'

import { useState } from 'react'

import { FiPlusCircle, FiTrash } from 'react-icons/fi'

import { Button } from '../../../../../components/atoms/Button/index'
import { Input } from '../../../../../components/atoms/Input/index'

interface ChecklistModalAddInterface {
  modalOpen: boolean
  onClose: () => void
  onChangeCheck?: () => void
}

export const ChecklistModalAdd = ({
  modalOpen,
  onClose,
  onChangeCheck
}: ChecklistModalAddInterface) => {
  const [inputState, setInputState] = useState([{ value: '' }])

  const addInput = () => {
    const newIputs = inputState.concat({ value: '' })
    setInputState(newIputs)
  }

  return (
    <Box>
      <Modal isOpen={modalOpen} onClose={onClose} size="xlg">
        <ModalOverlay
          bg="rgba(0, 0, 0, 15%)"
          backdropFilter="blur(0.5px)"
          backdropBlur="2px"
        />
        <ModalContent
          top="40px"
          right="300px"
          bg="rgba(32, 31, 31, 0.941)"
          color="white"
          w="800px">
          <Flex flexDir="row" alignItems="center">
            <ModalHeader textAlign={'center'}>Checklist</ModalHeader>
            <ModalCloseButton />
          </Flex>
          <ModalBody p="0px" mt="5px">
            <Divider></Divider>
            <Flex flexDir="column" alignItems="center" justifyContent="center">
              <Box w="80%" p="0px 0px" mt="35px">
                <Box mb="30px">
                  <FormLabel>
                    <Text>Digite novo item</Text>
                  </FormLabel>
                  <Flex
                    flexDir="row"
                    alignItems="center"
                    justifyContent="center">
                    {inputState.map((val, i) => {
                      return (
                        <Input
                          key={`${i}-checklist-item`}
                          w="90%"
                          backgroundColor="whitesmoke"
                          placeholder="Pesquisar"
                          type="search"
                          variant="filled"
                          /* loading={isSearching} */
                          /* disabled={loading} */
                          value={inputState[i].value}
                          onChange={(e) => {
                            const newState = [...inputState]
                            newState[i].value = e.target.value
                            setInputState(newState)
                          }}
                        />
                      )
                    })}

                    <Box>
                      <Icon as={FiTrash} w={7} h={7} color="white" />
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box m="10px 0px 35px 0px" onClick={addInput}>
                <Icon as={FiPlusCircle} w={12} h={12} color="yellow" />
              </Box>
              <Divider></Divider>
              <Button
                disabled={false}
                type="submit"
                width="100px"
                height="32px"
                variant="solid"
                colorScheme="brand"
                borderRadius="lg"
                size="md"
                margin="20px 0px 20px 0px">
                Salvar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
