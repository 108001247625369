import { capitalize, mapValues } from 'radash'

export const formatResponse = (text: string) => {
  if (!text) return ''

  const formated = capitalize(
    text.replaceAll('_', ' ')?.replaceAll('e', '&')
  ).replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())

  const arr = formated.split(' ')

  if (
    arr[arr.length - 1] === 'Bh' ||
    arr[arr.length - 1] === 'F&a' ||
    arr[arr.length - 1] === 'Sp'
  ) {
    arr[arr.length - 1] = arr[arr.length - 1].toUpperCase()

    return arr.join().replace(',', ' ')
  }

  return formated
}

export const formatObjectValuesResponse = async ({ response }: any) => {
  const res = response
  if (res.data) {
    res.data = mapValues(res.data, formatResponse)
  }
  return res
}

export const formatArrayObjectResponse = async ({ response }: any) => {
  const res = response
  if (res.data) {
    res.data = mapValues(res.data, formatResponse)
  }

  const newResponese: { value: string; label: string }[] = []

  Object.keys(res?.data).forEach((key) => {
    newResponese?.push({
      value: key,
      label: res?.data[key]
    })
  })
  res.data = newResponese

  res.data = res.data.sort((a: any, b: any) =>
    Intl.Collator('pt-BR').compare(a.label, b.label)
  )

  return res
}

export const formatCapitalizeUndeline = (value: string) => {
  if (!value) return

  return capitalize(value.replaceAll('_', ' ')).replace(/(^\w|\s\w)/g, (m) =>
    m.toUpperCase()
  )
}
