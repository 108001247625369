export const Input = {
  baseStyle: {
    borderRadius: 'lg',
    field: {
      _placeholder: {
        color: '#8F8F8F',
        fontWeight: 'bold'
      }
    }
  },
  variants: {
    filled: {
      field: {
        _disabled: {
          _placeholder: {
            color: '#C8CCD4'
          },
          bg: '#F5F6FA',
          color: '#C8CCD4'
        },
        _focusVisible: {
          _placeholder: {
            color: 'black'
          },
          bg: '#F5F6FA',
          borderColor: 'transparent'
        },
        _hover: {
          bg: '#F5F6FA'
        },
        _invalid: {
          _placeholder: {
            color: 'negative.500'
          },
          bg: 'negative.100',
          color: 'negative.500'
        },
        bg: '#F5F6FA',
        borderRadius: 'md',
        fontSize: 'md',
        fontWeight: 'bold'
      }
    }
  }
}
