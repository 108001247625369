/* eslint-disable camelcase */
/* eslint-disable react/jsx-indent */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Radio,
  RadioGroup,
  Spinner,
  Switch,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'
import { Select } from 'components/atoms/Select'
import { Textarea } from 'components/atoms/Textarea'
import {
  BrazilianStates,
  Genders,
  JobModels,
  RelationshipStatus,
  SalaryCurrency,
  SenioryTypes
} from 'data/userProfile'
import { VerticalTypes } from 'enum/user'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment, useEffect, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { FiTrash } from 'react-icons/fi'
import useFetch from 'use-http'
import { orderBy } from 'utils/orderBy'
import { Benefits } from './Profile/Benefits'
import { normalizeNumber } from '../../../../theme/normalization'
import { transformDate } from '../../../../utils/formatDate'
import { capitalizeNames } from '../../../../utils/strings'

// const formatResponseStates = async ({ response }: any) => {
//   const res = response

//   res.data = res.data.sort((a: any, b: any) =>
//     Intl.Collator('pt-BR').compare(a.nome, b.nome)
//   )

//   return res
// }

const buildExperienceData = () => {
  return {
    actual_job: false,
    started_at: null,
    end_at: null,
    role: null,
    seniority: null,
    job_type: null,
    salary: null,
    description: null,
    salary_currency: null,
    company_id: null,
    position_in_the_company: null,
    company_name: '',
    benefits: []
  }
}

export const Profile = ({ data }: { loading?: boolean; data?: any }): any => {
  const [indexingCompanies, setIndexingCompanies] = useState<boolean>(false)
  const onError = useErrorToast()
  const toast = useToast()
  const errors = data?.errors

  const { register, control, watch, setValue, getValues } = useFormContext()

  const { loading: loadingUsersYW, data: users } = useFetch(
    `${Endpoints.users.findAll({
      page: 0,
      take: 200
    })}&accessCategory=RECRUTAMENTO`,
    {
      onError: () =>
        onError({
          title: 'Não foi possível carregar os usuários.'
        })
    },
    []
  )

  const listStates = BrazilianStates.map((s) => ({
    nome: s.label,
    sigla: s.value
  }))

  const {
    get: getCities,
    data: cities,
    loading: loadingCities
  } = useFetch(Endpoints.lists.citiesByState(watch('state')))

  const {
    get: onGetLinkedinData,
    response: resUserLinkedin,
    loading: loadingLinkedinData
  } = useFetch(Endpoints.usersCandidate.linkedin)

  const { fields, insert, append, remove } = useFieldArray({
    name: 'professional_experiences',
    control
  })

  const professionalExperiences = watch('professional_experiences')

  const handleGetLinkedinData = async () => {
    setIndexingCompanies(true)

    const linkedinUrl = getValues('linkedin_profile_url')

    if (linkedinUrl.includes('?')) {
      const validUrl = linkedinUrl.split('?')[0]

      setValue('linkedin_profile_url', validUrl)

      toast({
        title: 'URL do LinkedIn do candidato formatada.',
        status: 'success',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })
    }

    try {
      await onGetLinkedinData(
        `?linkedinUrl=${getValues('linkedin_profile_url')}`
      )

      if (resUserLinkedin.ok && resUserLinkedin.data) {
        setValue('name', resUserLinkedin.data?.name)
        setValue('email', resUserLinkedin.data?.email || '')
        setValue(
          'professional_linkedin_resume',
          resUserLinkedin.data?.resume || ''
        )
        setValue('gender', resUserLinkedin.data?.gender?.toUpperCase())

        remove()

        const professional_experiences = orderBy(
          resUserLinkedin.data.candidateExperience.map((exp: any) => ({
            ...exp,
            end_at: exp.end_at === 'Ainda ativo' ? null : exp.end_at
          })),
          'desc'
        )

        for (const experience of professional_experiences) {
          append({
            company_name: experience.company,
            description: experience.description,
            position_in_the_company: experience.title,
            started_at: experience.started_at,
            end_at: experience.end_at ?? null,
            actual_job: experience?.end_at === null
          })
        }
      }
    } catch (err) {
      onError({
        title: 'Erro',
        description: 'Não foi possível carregar informações do LinkedIn.'
      })
    } finally {
      setIndexingCompanies(false)
    }
  }

  useEffect(() => {
    if (watch('state')) {
      getCities()
    }
  }, [getCities, watch])

  return (
    <Flex flexDirection="column" py="6" px="8" width="100%" gap="8">
      <HStack alignItems="flex-end" width="100%">
        <Input
          control={control}
          name="linkedin_profile_url"
          label="URL perfil LinkedIn"
          variant="filled"
          requiredInfo={true}
          customTooltipMessage="Preencha Linkedin ou Telefone"
          errorMessage={errors?.linkedin_profile_url?.message}
          isInvalid={errors?.linkedin_profile_url}
          placeholder="Digite aqui"
        />

        <Button
          px={6}
          width={120}
          borderRadius="md"
          height={`${normalizeNumber(54)}px`}
          loading={indexingCompanies || loadingLinkedinData}
          onClick={handleGetLinkedinData}>
          Pesquisar
        </Button>
      </HStack>

      <Divider my={4} />

      {indexingCompanies ? (
        <Spinner color="brand.500" size="sm" />
      ) : (
        <>
          <Input
            control={control}
            name="name"
            label="Nome completo"
            variant="filled"
            placeholder="Digite aqui"
            requiredInfo={true}
            isInvalid={errors?.name}
            errorMessage={errors?.name?.message}
          />

          <HStack width="100%" alignItems="center" spacing={4}>
            <Controller
              control={control}
              name="cpf"
              render={({ field: { ref, value, ...props } }) => (
                <Input
                  {...props}
                  label="CPF"
                  mask="999.999.999-99"
                  variant="filled"
                  placeholder="Digite aqui"
                  value={value ?? ''}
                />
              )}
            />
            <Controller
              control={control}
              name="birth_date"
              render={({ field: { ref, value, ...props } }) => (
                <Input
                  {...props}
                  type="text"
                  label="Data de nascimento"
                  variant="filled"
                  value={value ?? ''}
                  mask="99/99/9999"
                  placeholder="--/--/----"
                  isInvalid={errors?.birth_date}
                  errorMessage={errors?.birth_date?.message}
                />
              )}
            />
          </HStack>

          <Flex flexDirection="column">
            <FormLabel alignSelf="start" fontWeight={500} color="black">
              País, estado e cidade onde mora
            </FormLabel>
            <HStack
              width="100%"
              alignItems="flex-end"
              spacing={4}
              mt={0}
              pt={0}>
              <Controller
                control={control}
                name="country"
                render={({ field: { ref, ...props } }) => (
                  <Menu
                    {...props}
                    options={[
                      {
                        value: 'Brasil',
                        label: 'Brasil'
                      }
                    ]}
                  />
                )}
              />

              <Select
                placeholder="Selecione"
                variant="filled"
                control={control}
                name="state"
                disabled={!watch('country')}>
                {listStates?.length &&
                  listStates?.map((state: { nome: string; sigla: string }) => {
                    return (
                      <option key={`state_${state?.nome}`} value={state?.sigla}>
                        {capitalizeNames(state?.nome)}
                      </option>
                    )
                  })}
              </Select>

              <Select
                label="Cidade"
                placeholder="Selecione"
                variant="filled"
                control={control}
                name="city"
                disabled={!watch('state') || loadingCities}
                loading={loadingCities}>
                {!!cities?.length &&
                  cities?.map((city: { nome: string; codigo_ibge: string }) => {
                    return (
                      <option key={`city_${city?.nome}`} value={city?.nome}>
                        {capitalizeNames(city?.nome)}
                      </option>
                    )
                  })}
              </Select>
            </HStack>
          </Flex>

          <HStack width="100%" alignItems="flex-end" spacing={4}>
            <Controller
              control={control}
              name="gender"
              render={({ field: { ref, ...props } }) => (
                <Menu {...props} label="Gênero" options={Genders} />
              )}
            />
            <Controller
              control={control}
              name="civil_state"
              render={({ field: { ref, ...props } }) => (
                <Menu
                  {...props}
                  label="Estado Civil"
                  options={RelationshipStatus}
                />
              )}
            />
          </HStack>

          <HStack width="100%" alignItems="flex-start" spacing={4}>
            <Controller
              control={control}
              name="email"
              render={({ field: { ref, value, ...props } }) => (
                <Input
                  {...props}
                  type="email"
                  label="E-mail"
                  placeholder="Digite aqui"
                  variant="filled"
                  tabIndex={0}
                  value={value ?? ''}
                  isInvalid={errors?.email}
                  errorMessage={errors?.email?.message}
                />
              )}
            />

            <Input
              control={control}
              name="phone"
              type="tel"
              mask="(99) 99999-9999"
              label="Telefone"
              requiredInfo={true}
              customTooltipMessage="Preencha Linkedin ou Telefone"
              placeholder="Digite aqui"
              variant="filled"
              isInvalid={errors?.phone}
              errorMessage={errors?.phone?.message}
            />
          </HStack>
          <Divider my={4} />
          <HStack width="100%" alignItems="center" spacing={4}>
            <Box width="100%" zIndex={200}>
              <Controller
                control={control}
                name="verticals"
                render={({ field: { ref, ...props } }) => (
                  <Select
                    {...props}
                    value={
                      data?.verticals?.map(({ vertical }: any) => ({
                        label: VerticalTypes[vertical],
                        value: vertical
                      })) ??
                      props?.value ??
                      []
                    }
                    isMulti
                    width="100%"
                    label="Verticais"
                    variant="filled"
                    placeholder="Selecione"
                    options={[
                      'TECH_1',
                      'EXEC_ENG',
                      'EXEC_FeA',
                      'EXEC_MKT_e_VENDAS'
                    ]?.map((key) => ({
                      label: VerticalTypes[key],
                      value: key
                    }))}
                  />
                )}
              />
            </Box>
            <HStack alignItems="center" paddingTop="30px">
              <FormLabel
                fontWeight="bold"
                fontSize="md"
                color="black"
                htmlFor="mpcStatus"
                m={0}>
                MPC
              </FormLabel>
              <Switch
                colorScheme="brand"
                id="mpcStatus"
                {...register('mpc_status')}
              />
            </HStack>
          </HStack>
          <HStack width="100%" alignItems="center" spacing={4}>
            <Controller
              control={control}
              name="last_qualifier_id"
              render={({ field: { ref, ...props } }) => (
                <Select
                  {...props}
                  disabled={loadingUsersYW}
                  label="Último qualificador"
                  placeholder="Selecione"
                  variant="filled">
                  {users?.data?.map(({ id, user }: any, idx: number) => (
                    <option key={`user_${idx}`} value={id}>
                      {user?.name}
                    </option>
                  )) ?? []}
                </Select>
              )}
            />
            <Controller
              control={control}
              name="last_qualifier_at"
              render={({ field: { ref, value, ...props } }) => (
                <Input
                  {...props}
                  type="text"
                  label="Última qualificação"
                  variant="filled"
                  tabIndex={0}
                  value={value ?? ''}
                  mask="99/99/9999"
                  placeholder="--/--/----"
                  isInvalid={errors?.last_qualifier_at}
                  errorMessage={errors?.last_qualifier_at?.message}
                />
              )}
            />
          </HStack>
          <Divider my={4} />
          <Controller
            control={control}
            name="professional_linkedin_resume"
            render={({ field: { ref, value, ...props } }) => (
              <Textarea
                {...props}
                variant="filled"
                label="Sobre candidato - LinkedIn"
                placeholder="Digite aqui"
                maxLength={2600}
                value={value ?? ''}
                rows={7}
              />
            )}
          />
          <HStack width="100%" alignItems="flex-end" spacing={4}>
            <RadioGroup flex={1}>
              <Flex flexDirection="column">
                <FormLabel>Possui disponibilidade para viagens?</FormLabel>
                <Controller
                  control={control}
                  name="available_travel"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup onChange={onChange} value={value}>
                      <Box
                        display="flex"
                        alignItems="center"
                        h={16}
                        width="100%"
                        border="1px solid"
                        borderColor={value === 'true' ? 'brand.500' : '#DEDFE4'}
                        borderRadius="md"
                        px={4}>
                        <Radio colorScheme="brand" value="true">
                          Sim
                        </Radio>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        h={16}
                        width="100%"
                        border="1px solid"
                        borderColor={
                          value === 'false' ? 'brand.500' : '#DEDFE4'
                        }
                        borderRadius="md"
                        mt={2}
                        px={4}>
                        <Radio colorScheme="brand" value="false">
                          Não
                        </Radio>
                      </Box>
                    </RadioGroup>
                  )}
                />
              </Flex>
            </RadioGroup>
            <RadioGroup flex={1}>
              <Flex flexDirection="column">
                <FormLabel>Pode mudar de cidade?</FormLabel>
                <Controller
                  control={control}
                  name="available_change_city"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup onChange={onChange} value={value}>
                      <Box
                        display="flex"
                        alignItems="center"
                        h={16}
                        width="100%"
                        border="1px solid"
                        borderColor={value === 'true' ? 'brand.500' : '#DEDFE4'}
                        borderRadius="lg"
                        px={4}>
                        <Radio colorScheme="brand" value="true">
                          Sim
                        </Radio>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        h={16}
                        width="100%"
                        border="1px solid"
                        borderColor={
                          value === 'false' ? 'brand.500' : '#DEDFE4'
                        }
                        borderRadius="lg"
                        mt={2}
                        px={4}>
                        <Radio colorScheme="brand" value="false">
                          Não
                        </Radio>
                      </Box>
                    </RadioGroup>
                  )}
                />
              </Flex>
            </RadioGroup>
            <RadioGroup flex={1}>
              <Flex flexDirection="column">
                <FormLabel>Aceita trabalhar de home office?</FormLabel>
                <Controller
                  control={control}
                  name="available_home_office"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup onChange={onChange} value={value}>
                      <Box
                        display="flex"
                        alignItems="center"
                        h={16}
                        width="100%"
                        border="1px solid"
                        borderColor={value === 'true' ? 'brand.500' : '#DEDFE4'}
                        borderRadius="lg"
                        px={4}>
                        <Radio colorScheme="brand" value="true">
                          Sim
                        </Radio>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        h={16}
                        width="100%"
                        border="1px solid"
                        borderColor={
                          value === 'false' ? 'brand.500' : '#DEDFE4'
                        }
                        borderRadius="lg"
                        mt={2}
                        px={4}>
                        <Radio colorScheme="brand" value="false">
                          Não
                        </Radio>
                      </Box>
                    </RadioGroup>
                  )}
                />
              </Flex>
            </RadioGroup>
          </HStack>
          <HStack width="100%" alignItems="flex-start" spacing={4}>
            <Controller
              control={control}
              name="salary_expectation"
              render={({ field: { ref, value, ...props } }) => (
                <Input
                  {...props}
                  isMoney
                  label="Pretensão salarial mensal"
                  variant="filled"
                  placeholder="Digite aqui"
                  tabIndex={0}
                  value={value ?? ''}
                />
              )}
            />
            <Controller
              control={control}
              name="job_type_expectation"
              render={({ field: { ref, ...props } }) => (
                <Menu
                  {...props}
                  label="Modelo de contratação"
                  options={JobModels}
                />
              )}
            />
            <Controller
              control={control}
              name="salary_currency"
              render={({ field: { ref, ...props } }) => (
                <Menu {...props} label="Moeda" options={SalaryCurrency} />
              )}
            />
          </HStack>
          <Divider my={4} colorScheme="brand" />
          {fields?.length && (
            <>
              <Button
                width="100%"
                py="0.5"
                borderRadius="md"
                fontSize="sm"
                fontWeight="500"
                variant="outline"
                colorScheme="neutralLight"
                onClick={() => insert(0, buildExperienceData())}>
                Adicionar mais experiências profissionais
              </Button>
            </>
          )}
          {fields?.map((field: any, idx: number, items) => (
            <Fragment key={field.id}>
              {getValues(`professional_experiences.${idx}.company_name`) ===
                '' && (
                <Alert
                  status="warning"
                  alignContent="center"
                  borderRadius="md"
                  p="0">
                  <Flex
                    h="100%"
                    w="100%"
                    alignItems="center"
                    px="5"
                    py="3.5"
                    gap="2">
                    <AlertIcon />
                    <AlertTitle>Campos obrigatórios!</AlertTitle>
                    <AlertDescription mt={0} fontWeight="500">
                      Por favor, preencha os dados do emprego ou ocupação
                      atual/mais recente ou remova-a.
                    </AlertDescription>
                  </Flex>
                </Alert>
              )}
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="space-between">
                <Heading as="h2" fontSize="lg" fontWeight="semibold">
                  {idx === 0
                    ? 'Referências Profissionais'
                    : 'Experiência profissional'}
                </Heading>
                <HStack alignItems="center">
                  <FormLabel
                    fontWeight="bold"
                    fontSize="md"
                    color="black"
                    htmlFor="currentJob"
                    m={0}>
                    Emprego atual?
                  </FormLabel>
                  <Switch
                    colorScheme="brand"
                    id="currentJob"
                    {...register(`professional_experiences.${idx}.actual_job`)}
                  />
                </HStack>
              </Flex>
              <HStack width="100%" alignItems="center" spacing={4}>
                <Box width="70%">
                  <Controller
                    control={control}
                    name={`professional_experiences.${idx}.company_name`}
                    render={({ field: { ref, value, ...props } }) => (
                      <Input
                        {...props}
                        label="Empresa"
                        variant="filled"
                        placeholder="Digite aqui"
                        value={value ?? ''}
                      />
                    )}
                  />
                </Box>
                <Box width="30%">
                  <Controller
                    control={control}
                    name={`professional_experiences.${idx}.role`}
                    render={({ field: { ref, value, ...props } }) => (
                      <Input
                        {...props}
                        label="Setor"
                        variant="filled"
                        placeholder="Digite aqui"
                        value={value ?? ''}
                      />
                    )}
                  />
                </Box>
              </HStack>
              <HStack width="100%" alignItems="center" spacing={4}>
                <Controller
                  control={control}
                  name={`professional_experiences.${idx}.started_at`}
                  render={({ field: { ref, value, ...props } }) => (
                    <Input
                      {...props}
                      type="text"
                      label="Data de início"
                      variant="filled"
                      value={value ? transformDate(value) : value}
                      mask="99/99/9999"
                      placeholder="--/--/----"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`professional_experiences.${idx}.end_at`}
                  render={({ field: { ref, value, ...props } }) => (
                    <Input
                      {...props}
                      disabled={professionalExperiences[idx].actual_job}
                      type="text"
                      label="Data de término"
                      variant="filled"
                      value={value ? transformDate(value) : value}
                      mask="99/99/9999"
                      placeholder="--/--/----"
                    />
                  )}
                />
              </HStack>
              <HStack width="100%" alignItems="center" spacing={4}>
                <Controller
                  control={control}
                  name={`professional_experiences.${idx}.position_in_the_company`}
                  render={({ field: { ref, value, ...props } }) => (
                    <Input
                      {...props}
                      label="Cargo"
                      variant="filled"
                      placeholder="Digite aqui"
                      value={value ?? ''}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`professional_experiences.${idx}.seniority`}
                  render={({ field: { ref, ...props } }) => (
                    <Menu
                      {...props}
                      label="Nível de senioridade do cargo"
                      options={SenioryTypes}
                    />
                  )}
                />
              </HStack>
              <HStack width="100%" alignItems="center" spacing={4}>
                <Controller
                  control={control}
                  name={`professional_experiences.${idx}.job_type`}
                  render={({ field: { ref, ...props } }) => (
                    <Menu
                      {...props}
                      label="Regime de contratação"
                      options={JobModels}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`professional_experiences.${idx}.salary`}
                  render={({ field: { ref, value, ...props } }) => (
                    <Input
                      {...props}
                      isMoney
                      label="Salário mensal atual"
                      variant="filled"
                      placeholder="Digite aqui"
                      value={value ?? ''}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`professional_experiences.${idx}.salary_currency`}
                  render={({ field: { ref, ...props } }) => (
                    <Menu {...props} label="Moeda" options={SalaryCurrency} />
                  )}
                />
              </HStack>
              <Benefits
                control={control}
                setValue={setValue}
                getValues={getValues}
                values={professionalExperiences[idx]}
                index={idx}
              />
              <Controller
                control={control}
                name={`professional_experiences.${idx}.description`}
                render={({ field: { ref, value, ...props } }) => (
                  <Textarea
                    {...props}
                    variant="filled"
                    label="Descrição"
                    placeholder="Digite aqui"
                    maxLength={2600}
                    value={value ?? ''}
                    rows={7}
                  />
                )}
              />
              <HStack>
                <Button
                  width="100%"
                  py="0.5"
                  borderRadius="md"
                  fontSize="sm"
                  fontWeight="500"
                  variant="outline"
                  colorScheme="neutralLight"
                  onClick={() => insert(idx + 1, buildExperienceData())}>
                  Adicionar experiência profissional
                </Button>
                <Button
                  py="0.5"
                  fontSize="sm"
                  fontWeight="500"
                  variant="outline"
                  borderRadius="md"
                  color="negative"
                  colorScheme="negative"
                  _hover={{}}
                  onClick={() => remove(idx)}>
                  <Icon as={FiTrash} />
                </Button>
              </HStack>
            </Fragment>
          ))}
          {!fields?.length && (
            <>
              <Heading
                as="h2"
                fontSize="lg"
                fontWeight="semibold"
                textAlign="left"
                w="100%">
                Experiências profissionais
              </Heading>
              <Button
                width="100%"
                py="0.5"
                borderRadius="md"
                fontSize="sm"
                fontWeight="500"
                variant="outline"
                colorScheme="neutralLight"
                onClick={() => append({})}>
                Adicionar mais experiências profissionais
              </Button>
            </>
          )}
        </>
      )}
    </Flex>
  )
}
