import { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Alert = {
  variants: {
    subtle: (props: StyleFunctionProps) => {
      const { colorScheme: c } = props
      return {
        container: {
          bg: `${c === 'orange' ? 'negative' : c}.100`,
          pb: 5
        },
        description: {
          fontSize: 'xs',
          lineHeight: 'sm',
          mt: 2
        },
        icon: {
          color: `${c === 'orange' ? 'negative' : c}.500`,
          marginEnd: 2,
          w: 4
        },
        title: {
          fontSize: '12px',
          lineHeight: '14px'
        }
      }
    }
  }
}
