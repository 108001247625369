/* eslint-disable camelcase */
import { isValid, parse } from 'date-fns'

export const parseDate = (date: string) => parse(date, 'dd/MM/yyyy', new Date())

export const dateIsNotValid = (date: string) => !isValid(parseDate(date))

export const validateTimelineAndFormat = (
  timelineYellow: any,
  timelineClient: any
) => {
  let error: boolean | null = null

  const tl = timelineYellow
    .filter(({ date }: any) => !!date)
    .map((time: any) => {
      error = dateIsNotValid(time.date)
      return {
        ...time,
        date: parseDate(time.date),
        shortlist_number:
          !time.shortlist_number || time.shortlist_number === 0
            ? 1
            : Number(time.shortlist_number),
        key: 'YELLOW'
      }
    })

  const tl_steps = timelineClient.map((time: any) => {
    error = time.date && dateIsNotValid(time.date)

    const returnData = {
      ...time,
      date: time.date ? parseDate(time.date) : undefined,
      shortlist_number:
        !time.shortlist_number || time.shortlist_number === 0
          ? 1
          : Number(time.shortlist_number),
      key: 'CLIENT'
    }

    if (time.date) {
      return returnData
    } else {
      delete returnData.date
      return returnData
    }
  })

  const time_line = [...tl, ...tl_steps]
  return [error, time_line]
}
