/* eslint-disable indent */

const subMenuOptions: any = {
  open: {
    title: 'Abertura',
    slug: '/forms/abertura'
  },
  delivery: {
    title: 'Delivery',
    slug: '/forms/delivery'
  },
  close: {
    title: 'Fechamento',
    slug: '/forms/fechamento'
  },
  candidates: {
    title: 'Candidatos',
    slug: '/forms/candidatos'
  }
}

const masterAcess = ['open', 'delivery', 'close', 'candidates']
const financialAcess = ['open', 'delivery', 'close']
const marketingAcess = ['open', 'close', 'candidates']

export const formsMenu = (userArea: string) => {
  const finalFormSubmenu: any = []

  switch (userArea) {
    case 'MASTER':
      masterAcess.forEach((submenu) => {
        finalFormSubmenu.push(subMenuOptions[submenu])
      })
      break
    case 'FINANCEIRO':
      financialAcess.forEach((submenu) => {
        finalFormSubmenu.push(subMenuOptions[submenu])
      })

      break
    case 'MARKETING':
      marketingAcess.forEach((submenu) => {
        finalFormSubmenu.push(subMenuOptions[submenu])
      })

      break
    default:
      masterAcess.forEach((submenu) => {
        finalFormSubmenu.push(subMenuOptions[submenu])
      })
  }

  return finalFormSubmenu
}
