/* eslint-disable camelcase */
/* eslint-disable indent */
import {
  Badge,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text as TextChakra
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { TablePagination } from 'components/organisms/Table'
import { VerticalTypes } from 'enum/user'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { FiEdit, FiFileText, FiMoreVertical } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useFetch } from 'use-http'
import { Delete } from './Delete'
import { Freezer } from './Freezer'
import { Tooltip } from '../../../components/atoms/Tooltip'
import { capitalizeNames } from '../../../utils/strings'
import { CandidateType } from '../hooks/candidates'

type TableCandidatesType = {
  data: CandidateType[]
  total: number
  onChange: (_page: number) => void
  get: () => void
  pagination: {
    take: number
    total: number
  }
}

const Text = ({ bold, children }: any) => (
  <TextChakra fontSize="sm" color="black" fontWeight={bold ? 'bold' : 'normal'}>
    {children}
  </TextChakra>
)

export const TableCandidates = ({
  data,
  total,
  get,
  onChange,
  pagination
}: TableCandidatesType) => {
  const navigate = useNavigate()

  const [showDeleteUser, toggleDeleteUser] = useState<boolean>(false)
  const [showFreezerUser, toggleFreezerUser] = useState<boolean>(false)

  const { loading, del } = useFetch()

  const onDelete = useCallback(
    async (uuid: string) => {
      await del(Endpoints.usersCandidate.userById(uuid))
      get()
    },
    [del, get]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name'
      },
      {
        Header: 'Localidade',
        accessor: 'location'
      },
      {
        Header: 'Cargo',
        accessor: 'position_in_the_company'
      },
      // {
      //   Header: 'Empresa',
      //   accessor: 'company_name'
      // },
      {
        Header: 'Vertical',
        accessor: 'vertical'
      },
      {
        Header: 'Qualificador',
        accessor: 'last_qualifier'
      },
      {
        Header: 'Status',
        accessor: 'qualified'
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row: { values, original } }: any) => {
          return (
            <Menu placement="start" autoSelect={false}>
              <MenuButton
                as={IconButton}
                aria-label="More"
                icon={<Icon as={FiMoreVertical} w={6} h={6} />}
                variant="unstyled"
              />
              <MenuList
                bg="#F9F9F9"
                border="0"
                zIndex="3"
                borderRadius={8}
                minWidth={31.75}>
                <MenuItem
                  onClick={() => {
                    navigate(`editar/${original.id}`, {
                      state: {
                        candidateProps: {
                          name: values?.name?.props?.children ?? ''
                        }
                      }
                    })
                  }}
                  px={3}
                  py="4"
                  fontSize="md"
                  fontWeight="500"
                  icon={<Icon as={FiEdit} w={4} h={4} />}
                  _hover={{ bg: 'neutralLight.300' }}>
                  Editar candidato
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.open(`/curriculum/${original.id}`, '_blank')
                  }}
                  px={3}
                  py="4"
                  fontSize="md"
                  fontWeight="500"
                  icon={<Icon as={FiFileText} w={4} h={4} />}
                  _hover={{ bg: 'neutralLight.300' }}>
                  Currículo
                </MenuItem>
              </MenuList>
            </Menu>
          )
        }
      }
    ],
    [navigate]
  )

  return (
    <Fragment>
      <TablePagination
        total={total}
        columns={columns}
        onChange={onChange}
        pagination={pagination}
        data={data?.map((candidate) => {
          const actualJob = candidate?.professional_experiences?.find(
            ({ actual_job }) => actual_job
          )
          const vertical =
            candidate?.verticals
              ?.map(({ vertical }) => VerticalTypes[vertical])
              ?.join('/') ?? '–'

          return {
            id: candidate.id,
            name: <Text bold>{candidate.name}</Text>,
            location: (
              <Text>
                {candidate.city && candidate.state
                  ? `${capitalizeNames(candidate.city)} /  ${candidate.state}`
                  : '- '}
              </Text>
            ),
            position_in_the_company:
              actualJob?.position_in_the_company &&
              actualJob?.position_in_the_company?.length > 10 ? (
                <Tooltip label={actualJob?.position_in_the_company}>
                  <Text>
                    {actualJob?.position_in_the_company.slice(0, -10)}...
                  </Text>
                </Tooltip>
              ) : (
                <Text>{actualJob?.position_in_the_company ?? '–'}</Text>
              ),
            company_name: actualJob?.company_name ? (
              <Text bold>{actualJob.company_name}</Text>
            ) : (
              '–'
            ),
            vertical: (
              <Tooltip label={vertical}>
                <Text>
                  {vertical.split('/')[0]}
                  {vertical.split('/').length > 1 && '...'}
                </Text>
              </Tooltip>
            ),
            last_qualifier: (
              <Text>{candidate?.last_qualifier?.user?.name ?? '–'}</Text>
            ),
            qualified: (
              <Badge
                borderRadius="md"
                py="1.5"
                fontWeight="500"
                bg={candidate.qualified ? 'positive.100' : 'negative.100'}
                colorScheme={candidate.qualified ? 'positive' : 'negative'}>
                {candidate.qualified ? 'Qualificado' : 'Não Qualificado'}
              </Badge>
            )
          }
        })}
      />
      {false && <Freezer state={showFreezerUser} toggle={toggleFreezerUser} />}
      {false && (
        <Delete
          state={showDeleteUser}
          loading={loading}
          toggle={toggleDeleteUser}
          onDelete={onDelete}
        />
      )}
    </Fragment>
  )
}
