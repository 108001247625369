import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { FiFile, FiFileText } from 'react-icons/fi'
import { NavigateFunction } from 'react-router-dom'

export const CreateNewButton = ({
  navigate,
  onOpen
}: {
  navigate: NavigateFunction
  onOpen: () => void
}) => (
  <Menu autoSelect={false}>
    <MenuButton
      as={Button}
      variant="solid"
      colorScheme="brand"
      fontSize="xs"
      fontWeight="bold"
      px={8}
      height={30}
      borderRadius="md"
      position="relative">
      Cadastrar nova Vaga
    </MenuButton>
    <MenuList
      bg="#F9F9F9"
      border="0"
      borderRadius={8}
      minWidth={31.75}
      margin="0">
      <MenuItem
        fontSize="md"
        fontWeight="500"
        onClick={() => onOpen()}
        px={3}
        py="4"
        icon={<Icon as={FiFile} w={4} h={4} />}
        _hover={{ bg: 'neutralLight.300' }}>
        Vaga rápida
      </MenuItem>
      <MenuDivider color="#E9E9E9" my="0" mx={3} />
      <MenuItem
        fontSize="md"
        fontWeight="500"
        onClick={() => navigate('criar')}
        px={3}
        py="4"
        icon={<Icon as={FiFileText} w={4} h={4} />}
        _hover={{ bg: 'neutralLight.300' }}>
        Vaga completa
      </MenuItem>
    </MenuList>
  </Menu>
)
