import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Spinner,
  Text
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Textarea } from 'components/atoms/Textarea'
import { debounce } from 'radash'
import { useCallback, useState } from 'react'
import useFetch from 'use-http'
import { HeaderStatus } from './HeaderCardStatus'
import { DataInfoCandidate } from '../InfoCandidate/DataInfo'

/**
 * figma @see https://www.figma.com/file/qSvt5eEU0XjXAQjJdc5hw9/Pipeline-de-Vagas?node-id=2096%3A11696
 */
export const CandidateCardForTheJob = ({
  candidate,
  checked,
  candidateStatus,
  position,
  isInShortlist = false,
  isApprovedShortlist,
  onClick,
  onRemove,
  onUpdateStatus,
  jobId,
  handler,
  listId,
  candidateObs
}: any) => {
  const [value, setValue] = useState(candidate?.professional_resume)
  const [obs, setObs] = useState(candidateObs)

  const {
    put: onUpdate,
    loading,
    response
  } = useFetch(Endpoints.usersCandidate.update(candidate?.id))

  const {
    put: updateJoblistCandidate,
    loading: loadingUpdate,
    response: joblistCandidate
  } = useFetch(Endpoints.jobs.observations(listId))

  const updateResume = useCallback(
    debounce({ delay: 3000 }, (text: string) => {
      onUpdate({ professional_resume: text })
    }),
    []
  )

  const updateObservation = useCallback(
    debounce({ delay: 3000 }, (text: string) => {
      updateJoblistCandidate({ candidateId: candidate?.id, observation: text })
    }),
    []
  )

  const handleChange = (e: any) => {
    updateResume(e.target.value)
    setValue(e.target.value)
  }

  const handleObsChange = (e: any) => {
    updateObservation(e.target.value)
    setObs(e.target.value)
  }

  return (
    <AccordionItem
      role="group"
      mt={5}
      key={candidate?.id}
      padding={1.25}
      shadow={`0px 3px 6px 0px ${
        checked ? 'rgba(16, 16, 40, 0.5)' : 'rgba(16, 24, 40, 0.2)'
      } `}
      borderRadius="lg"
      bg="white"
      position="relative"
      pl={4}
      zIndex={9999}>
      {handler}
      <HeaderStatus
        isInShortlist={isInShortlist}
        isApprovedShortlist={isApprovedShortlist}
        candidate={candidate}
        position={position}
        checked={checked}
        candidateStatus={candidateStatus}
        onClick={onClick}
        onRemove={onRemove}
        onUpdateStatus={onUpdateStatus}
        jobId={jobId}
      />
      <AccordionPanel py={4}>
        <AccordionItem py={5} px={0} mx={4}>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text fontSize="md" fontWeight="medium">
                Observações do candidato
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Textarea
              fontSize="md"
              fontWeight="medium"
              id="obs"
              variant="filled"
              placeholder="Digite aqui"
              maxLength={650}
              rows={4}
              defaultValue={candidateObs}
              value={obs}
              onChange={handleObsChange}
            />
            <Box padding={2}>
              {loadingUpdate ? (
                <Spinner size="sm" />
              ) : (
                joblistCandidate.ok && (
                  <Text fontSize={12} color="positive.300">
                    Oservação atualizada!
                  </Text>
                )
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <DataInfoCandidate
          isInShortlist={isInShortlist}
          candidate={candidate}
        />
        <AccordionItem py={5} px={0} mx={4}>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text fontSize="md" fontWeight="medium">
                Resumo do candidato
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Textarea
              id="description"
              variant="filled"
              placeholder="Digite aqui"
              maxLength={650}
              rows={4}
              defaultValue={candidate?.professional_resume}
              value={value}
              onChange={handleChange}
            />
            <Box padding={2}>
              {loading ? (
                <Spinner size="sm" />
              ) : (
                response.ok && (
                  <Text fontSize={12} color="positive.300">
                    Resumo atualizado. Atualize a lista.
                  </Text>
                )
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </AccordionPanel>
    </AccordionItem>
  )
}
