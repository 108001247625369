/* eslint-disable camelcase */
import {
  Box,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Tabs } from 'components/atoms/Tabs'
import { InvoiceTypes } from 'enum/form'
import { useEditJob } from 'hooks/useEditJob'
import { useErrorToast } from 'hooks/useErroToast'
import { useNavigate } from 'react-router-dom'
import useFetch from 'use-http'
import { HeaderModalJob } from './HeaderModalJob'
import {
  TabCandidateMatrix,
  TabJob,
  TabLongList,
  TabPreShortList,
  TabShortList
} from './Tabs'

export const ModalJob = ({
  jobId,
  onClose
}: {
  jobId: string
  onClose: () => void
}) => {
  const onError = useErrorToast()
  const toast = useToast()
  const navigate = useNavigate()
  const { data: job, get: refetch } = useFetch(
    `/jobs/${jobId}`,
    {
      interceptors: {
        response: async ({ response }) => {
          const res = response
          if (res.data && res.data.time_line) {
            res.data.time_line = res.data.time_line.map((tl: any) => ({
              ...tl,
              date: new Date(tl.date)
            }))
          }
          return response
        }
      }
    },
    [jobId]
  )

  const { put: updateJob, response } = useFetch(
    Endpoints.jobs.update(jobId || '')
  )

  const { data: approvedShortlist } = useFetch(
    Endpoints.jobs.getApprovedShortlist(jobId),
    {},
    [jobId]
  )

  const canEdit = useEditJob(job)

  const JOB_PIPELINE = [
    {
      content: (
        <TabJob
          jobId={jobId}
          data={job}
          {...job}
          onCloseModal={onClose}
          refetch={refetch}
          canEdit={canEdit}
        />
      ),
      title: 'Vaga'
    },
    {
      content: <TabCandidateMatrix canEdit={canEdit} {...job} />,
      title: 'Banco de candidatos'
    },
    {
      content: <TabLongList jobId={jobId} canEdit={canEdit} {...job} />,
      title: 'Longlist'
    },
    {
      content: <TabPreShortList jobId={jobId} canEdit={canEdit} {...job} />,
      title: 'Pré-shortlist'
    },
    {
      content: <TabShortList jobId={jobId} canEdit={canEdit} {...job} />,
      title: 'Shortlist'
    }
  ]

  const handleJobDescription = () => {
    window.open(`/pipeline-de-vagas/editar/${jobId}`, '_blank')
  }

  const handleFormClose = () => {
    onClose()
    navigate(`/pipeline-de-vagas/close/${jobId}`)
  }

  const handleForecast = async (forecast: string) => {
    await updateJob({
      forecast
    })

    if (response.status === 200) {
      refetch()
    }

    if (response.status >= 400) {
      onError({
        title: 'Não foi possível atualizar a vaga.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Vaga atualizada com sucesso',
      variant: 'subtle'
    })
  }

  const { post: createJobInvoice, response: jobInvoiceResponse } = useFetch(
    Endpoints.forms.invoices()
  )

  const onFormDelivery = async () => {
    await createJobInvoice({
      job_id: jobId,
      type: InvoiceTypes.DELIVERY
    })

    if (jobInvoiceResponse.status >= 400) {
      toast({
        title: 'Erro ao tentar enviar forms de delivery!',
        description: 'Por favor, tente novamente mais tarde.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

      return
    }

    toast({
      title: 'Sucesso!',
      description: 'Forms de delivery enviado com sucesso.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })
  }

  return (
    <ModalContent h="90%">
      <ModalHeader p="0" borderRadius="2xl" />
      {!job && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Box>
      )}

      {job && (
        <ModalBody
          w="100%"
          h="100%"
          bg="white"
          px="7"
          css={{
            '&::-webkit-scrollbar': {
              width: '6px',
              backgroundColor: `rgba(0, 0, 0, 0.05)`
            },
            '&::-webkit-scrollbar-track': {
              width: '0px'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '6px',
              backgroundColor: `rgba(0, 0, 0, 0.15)`
            }
          }}>
          <HeaderModalJob
            title={job?.title}
            urgencyLevel={job?.urgency}
            forecast={job?.forecast}
            onFormDelivery={onFormDelivery}
            onChangeForecast={handleForecast}
            clientName={job?.customer?.name}
            onJobDescription={handleJobDescription}
            onFormClose={handleFormClose}
            vertical={job?.vertical}
            yw_unity={job?.yw_unity}
            job_code={job?.job_code}
            jobProductType={job?.yellow_product?.name}
            isCloseFormActive={
              !!approvedShortlist?.length || job?.board_status === 'CANCELADA'
            }
          />

          <Tabs
            // @ts-ignore
            tabs={JOB_PIPELINE}
            selectType="spread"
          />
        </ModalBody>
      )}
      <ModalFooter />
    </ModalContent>
  )
}
