/* eslint-disable react/jsx-indent */
/* eslint-disable no-console */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable camelcase */
/* eslint-disable indent */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { FiAlertTriangle, FiFileText, FiMove } from 'react-icons/fi'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from 'react-sortable-hoc'
import useUserStore from 'stores/user'
import useFetch from 'use-http'
import { formatDate } from 'utils/formatDate'
import { JobProps } from './TabJob'
import { CandidateCardForTheJob } from '../Cards/CandidateCardForTheJob'
import { NoDataMessage } from '../NoDataMessage'

const UserYwTypesForJob = {
  CONDUCTOR: 'CONDUCTOR',
  QUALIFIER: 'QUALIFIER',
  QUALIFIER_CONDUCTOR: 'QUALIFIER_CONDUCTOR',
  NOT_ASSIGNED: 'NOT_ASSIGNED'
}

export const TabPreShortList = ({
  canEdit,
  users_qualifier,
  jobId,
  user_conductor,
  ...job
}: JobProps) => {
  const { id } = useUserStore()

  const toast = useToast()

  const [selectedList, setSelectedList] = useState<any>(null)
  const [confirm, setConfirm] = useState(false)
  const [approve, setApprove] = useState<boolean>()
  // const [observation, setObservation] = useState<string>('')

  const {
    data: reqData,
    loading,
    get: refetch
  } = useFetch(Endpoints.jobs.getShortlist(jobId), {}, [])
  const data = reqData?.data?.sort(
    (a: { updated_at: string }, b: { updated_at: string }) =>
      // @ts-ignore
      new Date(b.updated_at) - new Date(a.updated_at)
  )
  const lastData = useMemo(() => [data?.[0]], [data])

  const { put: onToApprovePreshortlist, response: resToApprove } = useFetch(
    Endpoints.jobs.toApprovePreshortlist(selectedList?.id)
  )

  const { post: onApprovePreshortlist, response: resApprove } = useFetch(
    Endpoints.jobs.approvePreshortlist(selectedList?.id)
  )

  // const { post: onRejectPreshortlist, response: resReject } = useFetch(
  //   Endpoints.jobs.rejectPreshortlist(selectedList?.id)
  // )

  const { request, response: resRemoveCandidate } = useFetch(
    Endpoints.jobs.removeCandidateFromList(selectedList?.id),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )

  const { put: onUpdateCandidateStatus, response: resUpdateStatus } = useFetch(
    Endpoints.jobs.candidateStatus(selectedList?.id)
  )

  const { put: onUpdatePosition, response: resUpdateUpdatePosition } = useFetch(
    Endpoints.jobs.updateCandidatePosition(selectedList?.id)
  )

  const userYwTypeForJob = useMemo(() => {
    const currentUserIsConductor = user_conductor?.id === id
    const currentUserIsQualifier =
      users_qualifier.find((uq) => uq.qualifier_id === id)?.qualifier_id === id

    if (currentUserIsConductor && currentUserIsQualifier)
      return UserYwTypesForJob.QUALIFIER_CONDUCTOR

    if (currentUserIsConductor) return UserYwTypesForJob.CONDUCTOR

    if (currentUserIsQualifier) return UserYwTypesForJob.QUALIFIER

    return UserYwTypesForJob.NOT_ASSIGNED
  }, [user_conductor?.id, id, users_qualifier])

  const getCandidatesLength = useMemo(() => {
    let candidatesLength = 0
    lastData?.forEach?.((preShortList: any) => {
      if (preShortList) {
        candidatesLength += preShortList?.job_list_candidate?.length
      }
    })
    return candidatesLength ?? 0
  }, [lastData])

  const toApprovePreShortlist = async () => {
    await onToApprovePreshortlist()

    if (resToApprove.status >= 400) {
      toast({
        title: 'Não foi possível enviar pré-shortlist para aprovação.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

      throw new Error(resToApprove?.data?.message)
    }

    userYwTypeForJob !== 'QUALIFIER_CONDUCTOR' &&
      toast({
        title: 'Sucesso!',
        description: 'Pré-Shortlist enviada para aprovação.',
        status: 'success',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

    setConfirm(false)
    setSelectedList({})
    refetch()
  }

  const handleApprove = async () => {
    if (userYwTypeForJob === 'QUALIFIER_CONDUCTOR') {
      await toApprovePreShortlist()
    }

    await onApprovePreshortlist({
      listNumber: selectedList?.list_number,
      conductorId: id
    })

    if (resApprove.status >= 400) {
      toast({
        title: 'Não foi possível aprovar a pré-shortlist.',
        description: 'Por favor, tente novamente.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

      throw new Error(resApprove?.data?.message)
    }
    toast({
      title: 'Sucesso!',
      description: 'Pré-shortlist aprovada.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })

    setSelectedList({})
    setConfirm(false)
    refetch()
  }

  // const handleReject = async () => {
  //   if (userYwTypeForJob === 'QUALIFIER_CONDUCTOR') {
  //     await toApprovePreShortlist()
  //   }
  //   await onRejectPreshortlist({
  //     listNumber: selectedList?.list_number,
  //     conductorId: id
  //     // observation
  //   })

  //   if (resReject.status >= 400) {
  //     toast({
  //       title: 'Não foi possível rejeitar a pré-shortlist.',
  //       description: 'Por favor, tente novamente.',
  //       status: 'error',
  //       duration: 3000,
  //       position: 'top-right',
  //       variant: 'subtle'
  //     })

  //     throw new Error(resReject?.data?.message)
  //   }

  //   toast({
  //     title: 'Sucesso!',
  //     description: 'Pré-shortlist rejeitada.',
  //     status: 'success',
  //     duration: 3000,
  //     position: 'top-right',
  //     variant: 'subtle'
  //   })

  //   setSelectedList({})
  //   setConfirm(false)
  //   refetch()
  // }

  const handleRemove = useCallback(
    async (candidate: any) => {
      if (!candidate?.id) return

      const body = { candidatesIds: [candidate?.id] }

      await request.delete(body)

      if (resRemoveCandidate.status >= 400) {
        toast({
          title: 'Não foi possível remover candidato da lista.',
          description: 'Por favor, tente novamente.',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          variant: 'subtle'
        })

        throw new Error(resRemoveCandidate?.data?.message)
      }

      toast({
        title: 'Sucesso!',
        description: 'Candidato removido da lista.',
        status: 'success',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

      refetch()
    },
    [
      refetch,
      request,
      resRemoveCandidate?.data?.message,
      resRemoveCandidate.status,
      toast
    ]
  )

  const ButtonForConductor = () => {
    return (
      <Fragment>
        {confirm ? (
          <VStack width="100%" alignItems="flex-start" spacing={4} mt="8">
            {approve && (
              <Button
                variant="solid"
                bg="brand.500"
                borderRadius="md"
                py="6"
                w="100%"
                onClick={() => handleApprove()}>
                Confirmar aprovação
              </Button>
            )}
            <Button
              variant="outline"
              colorScheme="brand"
              borderRadius="md"
              py="6"
              w="100%"
              onClick={() => setConfirm(false)}>
              Cancelar
            </Button>
          </VStack>
        ) : (
          <VStack width="100%" alignItems="flex-start" spacing={4} mt="8">
            <Button
              variant="solid"
              bg="brand.500"
              borderRadius="md"
              py="6"
              w="100%"
              onClick={() => {
                setConfirm(true)
                setApprove(true)
              }}>
              Aprovar Shortlist
            </Button>
          </VStack>
        )}
      </Fragment>
    )
  }

  const WarningForQualifier = () => {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        backgroundColor="neutralLight.400"
        w="100%"
        borderRadius="md"
        mt="8"
        py="6">
        <Text fontSize="lg" fontWeight="400" color="BlackText">
          Validar com o condutor para a aprovação da Shortlist
        </Text>
      </Flex>
    )
  }

  const ActionButtons = () => {
    if (
      userYwTypeForJob === 'QUALIFIER_CONDUCTOR' ||
      userYwTypeForJob === 'CONDUCTOR'
    ) {
      return <ButtonForConductor />
    }

    if (userYwTypeForJob === 'QUALIFIER') return <WarningForQualifier />

    return null
  }

  const handleUpdateStatus = useCallback(
    async (candidate: any, status: string) => {
      await onUpdateCandidateStatus({
        candidateId: candidate?.id,
        status
      })

      if (resUpdateStatus.status >= 400) {
        toast({
          title: 'Não foi possível atualizar o status do candidato.',
          description: 'Por favor, tente novamente.',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          variant: 'subtle'
        })

        throw new Error(resUpdateStatus?.data?.message)
      }

      toast({
        title: 'Sucesso!',
        description: 'Status do candidato atualizado.',
        status: 'success',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })

      await refetch()
    },
    [
      onUpdateCandidateStatus,
      refetch,
      resUpdateStatus?.data?.message,
      resUpdateStatus.status,
      toast
    ]
  )

  const onUpdatePositionCandidate = useCallback(
    async (candidateId: string, position: number) => {
      await onUpdatePosition(`/${candidateId}`, {
        candidate_position: position
      })

      if (resUpdateUpdatePosition.status >= 400) {
        toast({
          title: 'Não foi possível salvar a posição do candidato.',
          description: 'Por favor, tente novamente.',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          variant: 'subtle'
        })

        throw new Error(resUpdateUpdatePosition?.data?.message)
      }

      toast({
        title: 'Sucesso!',
        description: 'Posição do candidato salva.',
        status: 'success',
        duration: 3000,
        position: 'top-right',
        variant: 'subtle'
      })
    },
    [onUpdatePosition, resUpdateUpdatePosition, toast]
  )

  if (loading) {
    return (
      <Flex flexDir="column" py={8} alignItems="center">
        <Spinner />
      </Flex>
    )
  }

  const ItemHandle = SortableHandle(() => (
    <Tooltip label="Ordernar">
      <Box
        as="div"
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w={10}
        h={10}
        top={0}
        left={0}
        p={2}
        bg="neutralDark.500"
        borderTopLeftRadius="lg"
        cursor="grab"
        tabIndex={0}>
        <Icon as={FiMove} w={4} h={4} color="white" />
      </Box>
    </Tooltip>
  ))

  const Item = SortableElement(
    ({ candidate, candidate_id, listId, idx }: any) => (
      <CandidateCardForTheJob
        isInShortlist
        key={candidate_id}
        candidate={candidate}
        position={idx}
        listId={listId}
        jobId={jobId}
        onRemove={handleRemove}
        onUpdateStatus={handleUpdateStatus}
        handler={<ItemHandle />}
      />
    )
  )

  const ItemsContainer = SortableContainer(({ items, listId }: any) => (
    <div>
      {items.map((candidate: any, idx: number) => (
        <Item
          {...candidate}
          key={`item-${candidate?.candidate_id}`}
          listId={listId}
          idx={++idx}
          index={idx}
        />
      ))}
    </div>
  ))

  return (
    <Flex flexDir="column" px={4}>
      <Text fontSize="sm" fontWeight="500" mt="2.5">
        {`Lista possui ${getCandidatesLength} candidatos.`}
      </Text>
      {!loading && (!lastData || lastData.length === 0) && <NoDataMessage />}

      <HStack alignItems="flex-end" position="relative">
        {!!selectedList && <ActionButtons />}
      </HStack>
      {!!lastData.length &&
        lastData
          .filter((list: any) => list?.job_list_candidate?.length > 0)
          .map((preshortlist: any) => (
            <Accordion
              allowToggle
              key={`preshorlist_${preshortlist.id}`}
              w="full"
              mt="10"
              onChange={(d) => {
                setSelectedList(
                  d === 0
                    ? preshortlist.id !== selectedList?.id
                      ? preshortlist
                      : null
                    : null
                )
              }}>
              <AccordionItem>
                <Flex>
                  <AccordionButton
                    gap={6}
                    _expanded={{
                      borderBottomWidth:
                        selectedList?.id === preshortlist?.id ? 1 : 0,

                      borderBottomColor:
                        selectedList?.id === preshortlist?.id ? 'black' : 'none'
                    }}>
                    <Box flex="1" textAlign="left" fontWeight="500">
                      Shortlist {preshortlist?.list_number} -{' '}
                      {formatDate(
                        new Date(preshortlist?.created_at),
                        "dd'/'MM'/'yyyy"
                      )}{' '}
                    </Box>

                    {preshortlist?.status === 'DECLINED' && (
                      <Tooltip
                        position="absolute"
                        left="500%"
                        top={300}
                        label="Shortlist reprovada"
                        placement="bottom-start">
                        <FiAlertTriangle size={16} />
                      </Tooltip>
                    )}

                    <IconButton
                      isDisabled={!selectedList}
                      p={2}
                      bg="white"
                      borderRadius={6}
                      aria-label="Get PDF"
                      icon={<Icon as={FiFileText} h={6} w={6} color="black" />}
                      onClick={() =>
                        window.open(`/shortlist/${selectedList.id}`, '_blank')
                      }
                    />
                    <AccordionIcon />
                  </AccordionButton>
                </Flex>

                <AccordionPanel>
                  <Accordion
                    allowMultiple
                    w="full"
                    pointerEvents={canEdit ? 'auto' : 'none'}>
                    <ItemsContainer
                      // @ts-ignore
                      items={
                        selectedList?.job_list_candidate?.sort(
                          (a: any, b: any) =>
                            a.candidate_position - b.candidate_position
                        ) ?? []
                      }
                      listId={selectedList?.id}
                      onSortEnd={({ oldIndex, newIndex }) => {
                        const oldCurrentIndex = oldIndex - 1
                        const newCurrentIndex = newIndex - 1

                        setSelectedList({
                          ...selectedList,
                          job_list_candidate: arrayMove(
                            selectedList?.job_list_candidate.map(
                              (c: any, idx: number) => {
                                if (idx === oldCurrentIndex) {
                                  return {
                                    ...c,
                                    candidate_position: newCurrentIndex + 1
                                  }
                                }

                                return c
                              }
                            ),
                            oldCurrentIndex,
                            newCurrentIndex
                          )
                        })

                        onUpdatePositionCandidate(
                          selectedList?.job_list_candidate?.[oldCurrentIndex]
                            ?.candidate_id,
                          newCurrentIndex + 1
                        )
                      }}
                      useDragHandle={true}
                    />
                  </Accordion>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ))}
    </Flex>
  )
}
