/* eslint-disable indent */
import { Box, FormLabel, HStack, Switch, VStack } from '@chakra-ui/react'
import { Input } from 'components/atoms/Input'
import { Menu } from 'components/atoms/Menu'
import { Select } from 'components/atoms/Select'
import { Controller } from 'react-hook-form'

const boolOptions: any = [
  {
    value: true,
    label: 'Sim'
  },
  {
    value: false,
    label: 'Não'
  }
]

const MODELS: string[] = ['CLT', 'PJ']

export const Candidate = ({
  index,
  methods
}: {
  index: number
  methods: any
}) => {
  const { control, register, watch } = methods

  const isPlacement =
    watch(`candidates.${index}.is_placement`) !== '' &&
    JSON.parse(watch(`candidates.${index}.is_placement`))

  return (
    <>
      <HStack alignItems="flex-end" width="100%" spacing={4}>
        <Box width="30%">
          <Controller
            control={control}
            name={`candidates.${index}.candidate`}
            render={({ field: { ref, value, ...props } }) => (
              <Input
                {...props}
                value={value?.name ?? ''}
                type="text"
                label="Candidato"
                variant="filled"
                isReadOnly
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            control={control}
            name={`candidates.${index}.is_placement`}
            render={({ field: { ref, ...props } }) => (
              <Menu {...props} options={boolOptions} label="É placement?" />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            control={control}
            name={`candidates.${index}.interviewed_by_client`}
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                options={boolOptions}
                label="Foi entrevistado pelo cliente?"
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            control={control}
            name={`candidates.${index}.abandoned`}
            render={({ field: { ref, ...props } }) => (
              <Menu
                {...props}
                options={boolOptions}
                label="Abandonou o processo?"
                disabled={isPlacement}
              />
            )}
          />
        </Box>

        <Box flex={1}>
          <Controller
            control={control}
            name={`candidates.${index}.counter_proposal`}
            render={({ field: { ref, ...props } }) => (
              <Menu {...props} options={boolOptions} label="Contra-proposta?" />
            )}
          />
        </Box>
      </HStack>

      {isPlacement && (
        <>
          <Box flex={1}>
            <VStack alignItems="flex-start" spacing={4}>
              <FormLabel
                fontSize="md"
                color="black"
                htmlFor="placementByEmail"
                m={0}>
                Placement pelo e-mail de sucesso?
              </FormLabel>
              <Switch
                colorScheme="brand"
                id="placementByEmail"
                {...register(`candidates.${index}.placement_by_email`)}
              />
            </VStack>
          </Box>
          <HStack alignItems="flex-end" width="100%" spacing={4} pb={12}>
            <Box flex={1}>
              <Controller
                control={control}
                name={`candidates.${index}.closing_date`}
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    {...props}
                    type="text"
                    label="Data de fechamento"
                    variant="filled"
                    value={value}
                    mask="99/99/9999"
                    placeholder="--/--/----"
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name={`candidates.${index}.admission_date`}
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    {...props}
                    type="text"
                    label="Data de admissão"
                    variant="filled"
                    value={value}
                    mask="99/99/9999"
                    placeholder="--/--/----"
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name={`candidates.${index}.final_salary`}
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    {...props}
                    isMoney
                    type="tel"
                    label="Salário final"
                    variant="filled"
                    placeholder="Digite aqui"
                    tabIndex={0}
                    value={value || ''}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name={`candidates.${index}.hiring_model`}
                render={({ field: { ref, value, ...props } }) => (
                  <Select
                    {...props}
                    value={value?.toString()}
                    label="Modelo de contratação"
                    placeholder="Selecione"
                    variant="filled">
                    {MODELS.map((key, idx) => (
                      <option key={`model_${key}`} value={key}>
                        {key}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </HStack>
        </>
      )}
    </>
  )
}
