import { Box, VStack } from '@chakra-ui/react'
import { Resume as ResumeForm } from '../components/Create/Resume'

export const Resume = () => (
  <Box bg="white" flex={1} borderRadius="lg" p={6}>
    <VStack width="100%" alignItems="flex-start" spacing={6}>
      <ResumeForm />
    </VStack>
  </Box>
)
