/* eslint-disable indent */
/* eslint-disable camelcase */
import { format } from 'date-fns'
import { omit } from 'radash'
import { v4 as uuidv4 } from 'uuid'

export const formatTimeline = (time_line: any[]) => {
  const timelineYellow = time_line
    ?.filter(({ key }: { key: string }) => key === 'YELLOW')
    .map((time: any) =>
      omit({ ...time, date: format(new Date(time.date), 'dd/MM/yyyy') }, [
        'created_at',
        'updated_at',
        'job_id'
      ])
    )

  const timeLineFilterForClient = time_line?.filter(
    ({ key }: { key: string }) => key === 'CLIENT'
  )

  const timelineClient = timeLineFilterForClient.length
    ? timeLineFilterForClient.map((time: any) =>
        omit({ ...time, date: format(new Date(time.date), 'dd/MM/yyyy') }, [
          'created_at',
          'updated_at',
          'job_id'
        ])
      )
    : [
        {
          description: null,
          shortlist_number: null,
          date: null,
          duration: null,
          format: null,
          responsible: null
        }
      ]

  return {
    timelineYellow,
    timelineClient
  }
}
