import { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Button = {
  baseStyle: {
    borderRadius: '10px'
  },
  variants: {
    outline: (props: StyleFunctionProps) => {
      const { colorScheme: c } = props
      return {
        _disabled: {
          borderColor: '#DEDFE4',
          color: '#B6B7BB'
        },
        borderColor: `${c ?? 'brand'}.500`,
        color: 'black'
      }
    },
    solid: {
      _disabled: {
        bg: '#E9E9E9',
        color: '#8F8F8F'
      },
      _hover: {
        _disabled: {
          bg: '#E9E9E9'
        }
      },
      color: 'black'
    }
  }
}
