import { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Badge = {
  baseStyle: {
    px: 3,
    py: 1,
    textTransform: 'none'
  },
  variants: {
    outline: (props: StyleFunctionProps) => {
      const { colorScheme: c } = props
      return {
        borderColor: `${c}.300`,
        borderWidth: '1px',
        boxShadow: 'none'
      }
    }
  }
}
