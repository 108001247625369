type EnumObject = { [key: string]: string }

export const InvoiceStatusTypes: EnumObject = {
  OPEN: 'Aberta',
  CANCELED: 'Cancelada',
  DECLINED: 'Cdd. declinou',
  CREDIT: 'Crédito',
  ISSUED: 'Emitida',
  INVOICE: 'Fatura',
  EXCHANGE: 'Permuta'
}

export const InvoiceTypes = {
  OPENING: 'OPENING',
  DELIVERY: 'DELIVERY',
  CLOSURE: 'CLOSURE'
}
