type EnumObject = { [key: string]: string }

export const JobTypes: EnumObject = {
  CLT: 'CLT',
  PJ: 'PJ',
  COOPERADO: 'Cooperado',
  ASSOCIADO: 'Associado',
  BOLSISTA: 'Bolsista'
}

export const JobOptions: EnumObject = {
  CANCELED: 'Cancelada',
  NORMAL: 'Normal',
  ADITIONAL_POSITION: 'Posição adicional',
  WARRANTY: 'Garantia',
  MPC: 'MPC'
}
