/* eslint-disable indent */
/* eslint-disable camelcase */
import {
  Box,
  Divider,
  Flex,
  Icon,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Menu } from 'components/atoms/Menu'
import { OptionProp, Select } from 'components/atoms/Select'
import { TimeLine, TimeProps } from 'components/molecules/Timeline'
import { CheckListGroup } from 'components/organisms/CheckListGroup'
import { useErrorToast } from 'hooks/useErroToast'
import { isEmpty } from 'radash'
import { useMemo, useState } from 'react'
import { FiSave } from 'react-icons/fi'
import useFetch from 'use-http'
// import { Button } from '../../../../components/atoms/Button/index'
import { formatResponseUserYw } from '../../../../utils/formatResponseUserYw'
import { coerceNameString } from '../../../../utils/stringCoercion'
import { Comments } from '../Comments'
import { ChecklistModalAdd } from '../Modals/ChecklistModalAdd'

export type JobProps = {
  jobId: string
  time_line: TimeProps[]
  conductor_id: string
  user_conductor: {
    id: string
    user: { name: string }
  }
  users_qualifier: {
    id: string
    qualifier: {
      name: string
    }
    qualifier_id: string
  }[]
  checklist: string[]
  kickoff_date: string
  success_email_date: string
  end_date: string
  onCloseModal: () => void
  refetch: any
  canEdit: boolean
  board_status: string
  customer: {
    name: string
  }
  data?: any
}

export const TabJob = ({
  jobId,
  time_line,
  user_conductor,
  users_qualifier,
  kickoff_date,
  success_email_date,
  end_date,
  onCloseModal,
  canEdit,
  refetch,
  customer,
  board_status,
  data
}: JobProps) => {
  const toast = useToast()
  const onError = useErrorToast()
  const { put, response, loading } = useFetch(`/jobs/${jobId}`)
  const { data: userYw } = useFetch(
    Endpoints.users.findAllForKAM,
    {
      interceptors: {
        response: formatResponseUserYw
      }
    },
    [loading]
  )
  const [modalCheckList, setModalCheckList] = useState(false)

  const editorUsers = useMemo(() => {
    const editors: string[] = []

    if (user_conductor) editors.push(user_conductor?.id)

    users_qualifier?.forEach((userQualifier) => {
      if (!editors?.includes(userQualifier.qualifier_id)) {
        editors.push(userQualifier.qualifier_id)
      }
    })

    return editors
  }, [user_conductor, users_qualifier])

  const handleUpdateConductor = async (conductor_id: string) => {
    await put({ conductor_id })

    if (response.status !== 200) {
      onError({
        title: 'Erro.',
        description: 'Não foi possível alterar o condutor.'
      })
      return
    }

    toast({
      title: 'Sucesso.',
      description: 'Condutor alterado.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })

    await refetch()
  }

  const handleUpdateQualifier = async (qualifiers: any) => {
    await put({ users_qualifier: qualifiers.map((q: OptionProp) => q.value) })

    if (response.status !== 200) {
      onError({
        title: 'Erro.',
        description: 'Não foi possível alterar os qualificadores.'
      })
      return
    }

    toast({
      title: 'Sucesso.',
      description: 'Qualificadores alterado.',
      status: 'success',
      duration: 3000,
      position: 'top-right',
      variant: 'subtle'
    })

    await refetch()
  }

  const qualifiers = useMemo(() => {
    if (!userYw) return []

    return userYw.filter(
      (user: any) =>
        !!users_qualifier.find(
          (qualifier) => qualifier.qualifier_id === user.value
        ) && user
    )
  }, [userYw, users_qualifier])

  const conductor = useMemo(() => {
    if (!userYw || !user_conductor) return []

    if (response.data?.user_conductor)
      return userYw.find(
        (user: { label: string; value: string }) =>
          user.value === response.data.user_conductor.id
      )

    return userYw.find(
      (user: { label: string; value: string }) =>
        user.value === user_conductor.id
    )
  }, [userYw, user_conductor, response?.data])

  if (!conductor || !userYw) return null

  const coercedStatus = coerceNameString(board_status)

  const canSelect = (option: 'conductor' | 'qualifier'): boolean => {
    switch (option) {
      case 'conductor':
        return isEmpty(conductor) || canEdit

      case 'qualifier':
        return !qualifiers?.length || canEdit

      default:
        return false
    }
  }

  const getConductorValue = (selectedValue: string) => {
    let conductorValue

    userYw?.forEach((user: any) => {
      if (user?.value === selectedValue) conductorValue = selectedValue
    })

    return conductorValue
  }

  return (
    <Flex p="4" flexDir="column" pointerEvents={canEdit ? 'auto' : 'none'}>
      <Flex gap={10} mb="30" alignContent="center">
        <VStack alignItems="flex-start">
          <Text fontWeight="normal" fontSize="sm">
            Condutor
          </Text>
          <Box pointerEvents={canSelect('conductor') ? 'auto' : 'none'}>
            {/* @ts-ignore */}
            <Menu
              value={getConductorValue(user_conductor?.id)}
              options={userYw}
              onChange={handleUpdateConductor}
              rest={{ zIndex: 1400 }}
            />
          </Box>
        </VStack>

        <VStack alignItems="flex-start">
          <Text fontWeight="normal" fontSize="sm">
            Qualificador
          </Text>
          <Box pointerEvents={canSelect('qualifier') ? 'auto' : 'none'}>
            {/* @ts-ignore */}
            <Select
              isMulti
              heightIsMulti={48}
              options={userYw}
              value={qualifiers}
              onChange={(values) => handleUpdateQualifier(values)}
            />
          </Box>
        </VStack>
      </Flex>
      <TimeLine
        jobId={jobId}
        kickoffDate={kickoff_date}
        successEmailDate={success_email_date}
        endDate={end_date}
        timeline={time_line}
        onCloseModal={onCloseModal}
      />
      <Divider mt="10" />

      <Flex
        flexDir="row"
        mb="10"
        justifyContent="space-between"
        alignItems="center"
        mt="14">
        <Box>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center">
            <Icon as={FiSave} w={5} h={5} color="blackText" />

            <Box pl="3.5">
              <Text fontSize="lg" fontWeight="500">
                {customer.name}
              </Text>
              <Box fontSize="sm" fontWeight="400">
                {'em '}
                <Text textDecoration="underline" display="inline-block">
                  {coercedStatus}
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>
        {/* <Button
          w="147px"
          h="28px"
          border="1px solid #FDD600"
          borderRadius="8px"
          backgroundColor="transparent"
          onClick={() => {
            setModalCheckList(!modalCheckList)
          }}>
          <Text fontSize="10px" fontWeight="700">
            Adicionar checklist
          </Text>
        </Button> */}
      </Flex>

      <CheckListGroup jobId={jobId} editorUsers={editorUsers} />

      <Comments jobId={jobId} />

      <ChecklistModalAdd
        modalOpen={modalCheckList || false}
        onClose={() => {
          setModalCheckList(!modalCheckList)
        }}
      />
    </Flex>
  )
}
