import { Box, VStack } from '@chakra-ui/react'
import { Profile as ProfileForm } from '../components/Create/Profile'

export const Profile = ({
  loading,
  data
}: {
  loading?: boolean
  data?: any
}) => (
  <Box bg="white" flex={1} borderRadius="lg" p={6}>
    <VStack width="100%" alignItems="flex-start" spacing={6}>
      <ProfileForm data={data} loading={loading} />
    </VStack>
  </Box>
)
