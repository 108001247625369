import { Box, Center, Image, Stack } from '@chakra-ui/react'
import { ReactNode } from 'react'
import BgMap from '../../assets/images/bg-map.png'
import Logo from '../../assets/images/logo.svg'

type LoginTemplateType = {
  children: ReactNode
}

export const LoginTemplate = ({ children, ...rest }: LoginTemplateType) => (
  <Box
    width="100%"
    height="100%"
    backgroundImage={`url(${BgMap})`}
    backgroundRepeat="repeat-x"
    backgroundSize="50% 50%"
    {...rest}>
    <Center width="100%" height="100%">
      <Box
        bg="white"
        padding="8"
        borderTopLeftRadius={4}
        borderTopRightRadius={4}
        w={{ lg: '90%', xl: 'lg' }}>
        <Stack spacing={14}>
          <Center>
            <Image src={Logo} w={134} h={25} alt="Logo Yellow.rec" />
          </Center>

          <Stack spacing={10}>{children}</Stack>
        </Stack>
      </Box>
    </Center>
  </Box>
)
