import { Box } from '@chakra-ui/react'
import { Textarea } from 'components/atoms/Textarea'
import { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'
import { useEditJob } from '../../../../hooks/useEditJob'

export const Notes = () => {
  const { setValue, watch, getValues } = useFormContext()
  const canEdit = useEditJob(getValues())

  return (
    <Box
      bg="white"
      maxH="2xl"
      overflowY="scroll"
      borderRadius="lg"
      sx={{
        '&::-webkit-scrollbar': {
          height: '9px',
          width: '10px',
          backgroundColor: `rgba(0, 0, 0, 0.05)`
        },
        '&::-webkit-scrollbar-thumb': {
          height: '9px',
          borderRadius: '6px',
          backgroundColor: `rgba(0, 0, 0, 0.15)`
        }
      }}
      height="100%"
      py={12}
      px={6}
      pointerEvents={canEdit ? 'auto' : 'none'}>
      <Textarea
        label="Observações"
        cols={1}
        rows={30}
        mb="2"
        variant="filled"
        placeholder="Digite aqui"
        value={watch('notes')}
        onChange={(e) => setValue('notes', e.target.value)}
      />
    </Box>
  )
}
