import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  FormLabel,
  MenuButton,
  Menu as MenuChakra,
  MenuItem,
  MenuList,
  Portal,
  Text
} from '@chakra-ui/react'
import { FormTooltip } from 'modules/Jobs/components/FormTooltip/FormTooltip'

import { normalizeNumber } from 'theme/normalization'

interface MenuInterface {
  height?: string
  width?: string
  placeholder?: string
  value?: any
  defaultValue?: any
  onChange?: any
  options?: any
  label?: string
  rest?: any
  disabled?: boolean
  labelVariableName?: string
  valueVariableName?: string
  errorMessage?: string
  requiredInfo?: boolean
}

export const Menu = (props: MenuInterface) => {
  const findLabelByValue = (value: string) => {
    if (!props.options) return

    const wantedOption = props?.options.filter((option: any) => {
      if (props.valueVariableName)
        return option[props.valueVariableName] === value

      return option.value === value
    })

    if (props.labelVariableName)
      return wantedOption[0]?.[props.labelVariableName]

    return wantedOption[0]?.label
  }

  return (
    <Flex w="100%" flexDirection="column">
      {!!props.label && (
        <Flex>
          <FormLabel
            htmlFor={props.rest?.id}
            color={props.errorMessage ? 'negative.500' : 'black'}>
            {props.label}
          </FormLabel>
          {props.requiredInfo && <FormTooltip error={props.errorMessage} />}
        </Flex>
      )}

      <MenuChakra>
        <MenuButton
          isDisabled={props.disabled}
          as={Button}
          rightIcon={
            <ChevronDownIcon
              h="8"
              w="8"
              ml="3"
              fontWeight="500"
              color="neutralLight.500"
              _focus={{ color: 'grayPrimary' }}
              _hover={{ color: 'neutralDark.100' }}
            />
          }
          borderRadius="md"
          textAlign="start"
          height={`${normalizeNumber(54)}px`}
          w={props.width || '100%'}
          fontWeight="bold"
          fontSize={`${normalizeNumber(18)}px`}
          minWidth={`${normalizeNumber(90)}px`}
          bg={props.errorMessage ? 'negative.100' : 'neutralLight.300'}
          color={props.errorMessage ? 'negative.500' : 'grayPrimary'}
          _hover={{ bg: 'neutralLight.200' }}
          _active={{ bg: 'neutralLight.200' }}>
          {findLabelByValue(props.value) ||
            findLabelByValue(props.defaultValue) ||
            props.placeholder ||
            'Selecione'}
        </MenuButton>
        <Portal>
          <MenuList
            w="fit-content"
            shadow="md"
            overflowY="scroll"
            overflowX="hidden"
            maxH={props.height || 'md'}
            zIndex={props?.rest?.zIndex ?? '200'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '10px',
                backgroundColor: `rgba(0, 0, 0, 0.05)`
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '8px',
                backgroundColor: `rgba(0, 0, 0, 0.15)`
              }
            }}>
            {props.options?.map((option: any, i: number) => {
              return (
                <MenuItem
                  key={i}
                  py="4"
                  px="6"
                  fontSize={`${normalizeNumber(22)}px`}
                  fontWeight="400"
                  _hover={{ bg: 'neutralLight.400' }}
                  onClick={() => {
                    props.valueVariableName
                      ? props.onChange(option[props.valueVariableName])
                      : props.onChange(option.value)
                  }}>
                  {props.labelVariableName
                    ? option[props.labelVariableName]
                    : option.label}
                </MenuItem>
              )
            })}
          </MenuList>
        </Portal>
      </MenuChakra>
      {props.errorMessage && (
        <Text
          justifyContent="end"
          color="negative.500"
          fontSize="sm"
          mt="2.5"
          textAlign="end">
          {props?.errorMessage}
        </Text>
      )}
    </Flex>
  )
}
