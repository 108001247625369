import { Box, VStack, useToast } from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Breadcrumb } from 'components/atoms/Breadcrumb'
import { Tabs } from 'components/atoms/Tabs'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { useErrorToast } from 'hooks/useErroToast'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetch from 'use-http'
import { isBoolean } from 'utils/helper'
import { Form } from './components/Form'
import { ClientType } from './types/clients'

export const CreateClient = () => {
  const toast = useToast()
  const onError = useErrorToast()
  const navigate = useNavigate()

  const {
    post: createCustomer,
    loading: loadingCreate,
    response: resCreate
  } = useFetch(Endpoints.customers.root, {
    onError: () =>
      onError({
        title: 'Não foi possível criar cliente.',
        description: 'Por favor, tente novamente.'
      })
  })

  const handleCreateCustomer = async (values: ClientType) => {
    const body = {
      ...values,
      is_active: isBoolean(values.is_active),
      contacts: {
        create: [
          {
            name: values.contact_name,
            phone: values.contact_phone,
            email: values.contact_email
          }
        ]
      },
      kams: {
        create: values?.kams?.map(({ value }: any) => ({
          user_yw: {
            connect: {
              id: value
            }
          }
        }))
      }
    }

    // @ts-ignore
    delete body.kam
    // @ts-ignore
    delete body.annual_billing
    // @ts-ignore
    delete body.contact_email
    // @ts-ignore
    delete body.contact_name
    // @ts-ignore
    delete body.contact_phone
    // @ts-ignore
    delete body.business_area_id

    await createCustomer(body)
    if (resCreate.status !== 201) return

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Cliente criado com sucesso',
      variant: 'subtle'
    })

    navigate('/clientes')
  }

  return (
    <Fragment>
      <VStack spacing={2} alignItems="flex-start">
        <HeaderPage title="Empresas e Usuários" />
        <Breadcrumb
          menus={[
            {
              current: false,
              slug: '/clientes',
              title: 'Empresas e Usuários'
            },
            {
              current: true,
              slug: '/clientes/criar',
              title: 'Adicionar empresa'
            }
          ]}
        />
      </VStack>
      <Box mt={9}>
        <Tabs
          tabs={[
            {
              content: (
                <Form
                  values={null}
                  onSubmit={handleCreateCustomer}
                  loading={loadingCreate}
                />
              ),
              title: 'Empresa'
            }
            // {
            //   content: <></>,
            //   disabled: true,
            //   title: 'Usuários'
            // }
          ]}
        />
      </Box>
    </Fragment>
  )
}
