import { Endpoints } from 'api/endpoints'
import { SenioryTypes } from 'enum/user'
import { formatResponse } from 'utils/formatObjectValuesResponse'
import { FiltersOptionType } from '../../../../components/organisms/Filters'
import { OptionsStatusCandidateJob } from '../Cards/HeaderCardStatus'

type Props = {
  state?: string
}

export const filterOptions = ({ state = '' }: Props): FiltersOptionType[] => [
  {
    button: 'Qualificação',
    filters: {
      field: 'qualified',
      filterType: 'checkbox',
      disableSearch: true,
      filtersOptions: [
        { label: 'Qualificado', value: 'true' },
        { label: 'Não Qualificado', value: 'false' }
      ]
    }
  },
  /* {
    disabled: true,
    button: 'Qualificador',
    filters: {
      field: 'last_qualifier_id',
      filterType: 'checkbox',
      endpoint: `${Endpoints.users.findAll({
        page: 0,
        take: 20
      })}&accessCategory=RECRUTAMENTO`,
      endpointFields: ['user.name', 'id'],
      searchField: 'name'
    }
  },
  {
    disabled: true,
    button: 'Situação',
    filters: {
      field: 'situation',
      filterType: 'checkbox',
      filtersOptions: Object.keys(OptionsStatusCandidateJob).map((key) => ({
        label: OptionsStatusCandidateJob[key],
        value: key
      }))
    }
  },
  {
    disabled: true,
    button: 'Vertical',
    filters: {
      field: 'verticals',
      filterType: 'checkbox',
      filtersOptions: [
        {
          label: 'Tech',
          value: 'TECH_1'
        },
        {
          label: 'Engenharia',
          value: 'EXEC_ENG'
        },
        {
          label: 'F&A',
          value: 'EXEC_FeA'
        },
        {
          label: 'Mkt & Vendas',
          value: 'EXEC_MKT_e_VENDAS'
        }
      ]
    }
  }, */
  {
    button: 'Nome',
    filters: {
      field: 'candidateName',
      filterType: 'input'
    }
  },
  {
    button: 'Empresa',
    filters: {
      field: 'company',
      filterType: 'input'
    }
  },
  /* {
    disabled: true,
    button: 'Idade',
    filters: {
      field: 'age',
      filterType: 'range'
    }
  },
  {
    disabled: true,
    button: 'Job score',
    filters: {
      field: 'job_score',
      filterType: 'input'
    }
  }, */
  {
    button: 'Estado',
    filters: {
      field: 'state',
      filterType: 'checkbox',
      endpoint: Endpoints.lists.states,
      endpointFields: ['nome', 'sigla'],
      searchField: 'nome'
    }
  },
  {
    disabled: !state,
    button: 'Cidade',
    filters: {
      field: 'city',
      filterType: 'checkbox',
      endpoint: state && Endpoints.lists.citiesByState(state),
      endpointFields: ['nome', 'nome'],
      searchField: 'nome'
    }
  },
  /*  {
    disabled: true,
    button: 'Pretensão salarial',
    filters: {
      field: 'salary',
      filterType: 'range'
    }
  }, */
  {
    button: 'Idioma',
    filters: {
      field: 'language',
      filterType: 'checkbox',
      disableSearch: true,
      filtersOptions: ['Português', 'Inglês', 'Espanhol', 'Italiano'].map(
        (key) => ({
          label: key,
          value: key
        })
      )
    }
  },
  /*   {
    disabled: true,
    button: 'Cargo',
    filters: {
      field: 'role',
      filterType: 'input'
    }
  }, */
  {
    button: 'Senioridade',
    filters: {
      field: 'seniority',
      filterType: 'checkbox',
      disableSearch: true,
      filtersOptions: Object.keys(SenioryTypes).map((key) => ({
        label: formatResponse(key),
        value: key
      }))
    }
  },
  {
    button: 'Hard skill',
    filters: {
      field: 'hardSkills',
      filterType: 'checkbox',
      endpoint: Endpoints.lists.hardSkills,
      endpointFields: ['name', 'name'],
      searchField: 'name'
    }
  }
]
