/* eslint-disable indent */
import { Flex, Spinner } from '@chakra-ui/react'
import { Tabs } from 'components/atoms/Tabs'
import { useMemo, useState } from 'react'
import { Detailing } from './Detailing'
import { Info } from './Info'
import { Investigation } from './Investigation'
import { Notes } from './Notes'
import { Requirements } from './Requirements'
import { Timeline } from './Timeline'

const lockedTab = [false, true, true, true, true, false]

export const TabsJobDescription = ({
  defaultIndex,
  index,
  onChange,
  loading,
  errors,
  invoiceObs,
  setInvoiceObs
}: any) => {
  const [tabAcess, setTabAcess] = useState(false)

  const props = onChange
    ? {
        index,
        onChange
      }
    : {}

  const tabsComponents = useMemo(
    () =>
      [
        {
          content: (
            <Info
              errors={errors}
              setTabAcess={setTabAcess}
              tabAcess={tabAcess}
            />
          ),
          title: 'Infos básicas'
        },
        {
          content: <Requirements />,
          title: 'Requisitos'
        },
        {
          content: (
            <Detailing invoiceObs={invoiceObs} setInvoiceObs={setInvoiceObs} />
          ),
          title: 'Detalhamento'
        },
        {
          content: <Investigation />,
          title: 'Investigação'
        },
        {
          content: <Timeline />,
          title: 'Cronograma'
        },
        {
          content: <Notes />,
          title: 'Notas'
        }
      ].map((t, i) => {
        return { ...t, disabled: !tabAcess ? lockedTab?.[i] : false }
      }),
    [errors, tabAcess]
  )

  return loading ? (
    <Flex alignItems="center" justifyContent="center" h="100%" w="100%">
      <Spinner color="brand.500" size="xl" />
    </Flex>
  ) : (
    <Tabs {...props} defaultIndex={defaultIndex} tabs={tabsComponents} />
  )
}
