import {
  Box,
  Flex,
  HStack,
  Icon,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { Tooltip } from 'components/atoms/Tooltip'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { Fragment, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { useFetch } from 'use-http'
import { Filters } from './components/Filters'
import { TableUsers } from './components/TableUsers'

export const Users = () => {
  const toast = useToast()
  const location = useLocation()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [nameFilter, setNameFilter] = useState('')
  const [value] = useDebounce(nameFilter, 500)

  const {
    loading,
    data: users,
    response,
    get: refetch
  } = useFetch(
    Endpoints.users.findAll({ page }) + `&name=${value}`,
    {
      onError: () =>
        toast({
          containerStyle: {
            maxWidth: '312px'
          },
          description: 'Não foi possível carregar a lista de usuários.',
          position: 'top-right',
          status: 'warning',
          title: 'Erro',
          variant: 'subtle'
        })
    },
    [location.pathname === '/usuarios', value, page]
  )

  return (
    <Fragment>
      <Flex
        minH="14%"
        w="100%"
        flexDirection="column"
        justify="space-between"
        pb="5">
        <HStack mb="6">
          <HeaderPage title="Gestão de usuários" />
        </HStack>
        <HStack alignItems="center" justifyContent="space-between">
          <HStack>
            <Filters
              loading={loading}
              nameState={nameFilter}
              onChangeName={(name: string) => setNameFilter(name)}
            />
          </HStack>
          <HStack>
            {/*   <Tooltip label="Em breve">
              <Button
                disabled
                variant="outline"
                colorScheme="brand"
                fontSize="xs"
                fontWeight="bold"
                borderRadius="md"
                width={135}
                px="6"
                height={30}>
                Exportar base
              </Button>
            </Tooltip> */}

            <Button
              variant="solid"
              colorScheme="brand"
              fontSize="xs"
              fontWeight="bold"
              height={30}
              px="8"
              borderRadius="md"
              onClick={() => navigate('criar')}>
              Adicionar usuário
            </Button>
          </HStack>
        </HStack>
      </Flex>

      <Box
        h="78%"
        width="100%"
        bg="white"
        borderRadius="lg"
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: `rgba(0, 0, 0, 0.15)`
          }
        }}>
        {loading && !users && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Spinner color="brand.500" />
          </Box>
        )}

        {response.status === 200 && (
          <TableUsers data={users?.data} refetch={refetch} />
        )}
      </Box>

      <Flex h="8%" justifyContent="flex-end" w="100%">
        <Flex alignItems="center">
          <Text fontSize={14} mr={5}>{`${page * 20} - ${page * 20 + 20} de ${
            users?.total
          }`}</Text>

          <Button
            variant="outline"
            disabled={page <= 0}
            size="xs"
            height={7}
            borderRadius={4}
            mr={5}
            onClick={() => setPage((page) => --page)}>
            <Icon as={FiChevronLeft} />
          </Button>

          <Button
            variant="outline"
            disabled={users?.total / ((page + 1) * 20) < 1} // check if there is another page
            size="xs"
            height={7}
            borderRadius={4}
            onClick={() => setPage((page) => ++page)}>
            <Icon as={FiChevronRight} />
          </Button>
        </Flex>
      </Flex>
    </Fragment>
  )
}
