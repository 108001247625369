import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  VStack
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { FiMessageCircle } from 'react-icons/fi'

type ProfileDetailsAccordionProps = {
  phone: string
  linkedinProfileUrl: string
  professionalResume: string
  city: string
  state: string
  mpcStatus: boolean
  isWhatsapp: boolean
  email: string
}

export const ProfileDetailsAccordion = ({
  phone,
  linkedinProfileUrl,
  professionalResume,
  city,
  state,
  mpcStatus,
  isWhatsapp,
  email
}: ProfileDetailsAccordionProps) => {
  const handleOpenLinkdIn = () =>
    window.open(linkedinProfileUrl, '_blank')?.focus()

  const handleOpenWhatsApp = () =>
    window
      .open(`https://api.whatsapp.com/send?phone=${phone}`, '_blank')
      ?.focus()

  const nickLinkdIn = useMemo(
    () => linkedinProfileUrl?.split('in/')[1],
    [linkedinProfileUrl]
  )

  return (
    <AccordionItem py={5} px={0} mx={4}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Text fontSize="md" fontWeight="medium">
            Perfil
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Text fontSize="xs">{professionalResume}</Text>
        <Flex
          justifyContent="space-between"
          gap={3}
          p={3}
          mt={6}
          w="100%"
          bg="grayBackground"
          py={5}
          px={2}
          borderRadius="lg">
          <VStack alignItems="flex-start" w="fit-content" spacing={1.5}>
            <Text
              fontSize="xs"
              lineHeight="normal"
              fontWeight="500"
              color="BlackText">
              Localização
            </Text>
            <Text fontSize="sm" fontWeight="bold" lineHeight="normal">
              {city && state ? `${city}, ${state}` : '-'}
            </Text>
          </VStack>
          <VStack
            alignItems="flex-start "
            w="fit-content"
            overflow="hidden"
            noOfLines={[2, 3]}
            spacing={1.5}>
            <Text
              fontSize="xs"
              lineHeight="normal"
              fontWeight="500"
              color="BlackText">
              Linkedin
            </Text>
            <Text
              cursor="pointer"
              fontSize="sm"
              fontWeight="bold"
              lineHeight="normal"
              onClick={handleOpenLinkdIn}>
              {nickLinkdIn ? `@${nickLinkdIn}` : '-'}
            </Text>
          </VStack>
          <VStack alignItems="flex-start" w="fit-content" spacing={1.5}>
            <Text
              fontSize="xs"
              lineHeight="normal"
              fontWeight="500"
              color="BlackText">
              Telefone
            </Text>
            <HStack>
              <Text fontSize="sm" fontWeight="bold" lineHeight="normal">
                {phone ? `${phone}` : '-'}
              </Text>
              {phone ? (
                <Icon
                  cursor="pointer"
                  as={FiMessageCircle}
                  h={4}
                  w={4}
                  onClick={handleOpenWhatsApp}
                />
              ) : (
                ''
              )}
            </HStack>
          </VStack>
          <VStack
            alignItems="flex-start"
            w="fit-content"
            overflow="hidden"
            noOfLines={[2, 3]}
            spacing={1.5}>
            <Text
              fontSize="xs"
              lineHeight="normal"
              fontWeight="500"
              color="BlackText">
              E-mail
            </Text>
            <Text
              fontSize="sm"
              fontWeight="bold"
              lineHeight="normal"
              noOfLines={2}>
              {email ? `${email}` : '-'}
            </Text>
          </VStack>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  )
}
