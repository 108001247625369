import {
  Tooltip as TooltipChakra,
  TooltipProps as TooltipChakraProps
} from '@chakra-ui/react'

type TooltipProps = {
  isDisabled?: boolean
} & TooltipChakraProps

export const Tooltip = ({ label, children, ...props }: TooltipProps) => (
  <TooltipChakra
    hasArrow
    label={label}
    shadow="md"
    backgroundColor="neutralLight.400"
    color="BlackText"
    fontSize="sm"
    borderRadius="md"
    px="4"
    py="2"
    shouldWrapChildren
    {...props}>
    {children}
  </TooltipChakra>
)
