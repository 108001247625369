import { Box, Flex, VStack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Endpoints } from 'api/endpoints'
import { Button } from 'components/atoms/Button'
import { HeaderPage } from 'components/organisms/HeaderPage'
import { useErrorToast } from 'hooks/useErroToast'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import useFetch from 'use-http'
import * as yup from 'yup'
import { TabsJobDescription } from './components/Form/Tabs'

const formSchema = yup.object().shape({
  title: yup.string().required('Campo obrigatório'),
  level: yup.string().required('Campo obrigatório'),
  vertical: yup.string().required('Campo obrigatório'),
  yw_unity: yup.string().required('Campo obrigatório'),
  customer_code: yup.string().required('Campo obrigatório'),
  card_model: yup.string().required('Campo obrigatório'),
  job_code: yup.string().required('Campo obrigatório'),
  yellow_product_id: yup.string().required('Campo obrigatório'),
  urgency: yup.string().required('Campo obrigatório')
})

export const CreateJob = () => {
  const onError = useErrorToast()
  const toast = useToast()
  const navigate = useNavigate()
  const methods = useForm({
    resolver: yupResolver(formSchema)
  })
  const { formState } = methods
  const { errors } = formState

  useEffect(() => {
    Object.keys(errors).length > 0 &&
      onError({
        title: 'Não foi possível salvar.',
        description: 'Preencha os campos obrigatórios, tente novamente.'
      })
  }, [Object.keys(errors).length])

  const { post: createJob, response, loading } = useFetch(Endpoints.jobs.root)

  const onSubmit = async () => {
    const resCreate = await createJob({
      ...methods.getValues(),
      board_status: 'START'
    })

    if (response.status >= 400) {
      onError({
        title: 'Não foi possível criar a vaga.',
        description: 'Por favor, tente novamente.'
      })
      return
    }

    toast({
      containerStyle: {
        maxWidth: '312px'
      },
      position: 'top-right',
      status: 'success',
      title: 'Vaga criada com sucesso',
      variant: 'subtle'
    })

    navigate(`/pipeline-de-vagas/editar/${resCreate.id}`, {
      state: {
        fromCreate: true
      }
    })
  }

  return (
    <Box height="100%">
      <VStack spacing={2} alignItems="flex-start" height="10%">
        <HeaderPage title="Pipeline de vagas" subTitle="Job Description" />
      </VStack>
      <Box height="90%">
        <FormProvider {...methods}>
          <form
            onSubmit={(e) =>
              methods
                .handleSubmit(onSubmit)(e)
                .catch((err) => console.error({ err }))
            }
            style={{
              height: '100%'
            }}>
            <Box height="3.5xl">
              <Box h="93%">
                <TabsJobDescription errors={errors} />
              </Box>
              <Flex
                width="100%"
                h="7%"
                textAlign="right"
                alignItems="end"
                justifyContent="end">
                <Button
                  isDisabled={loading}
                  loading={loading}
                  px={7}
                  height={12}
                  variant="solid"
                  colorScheme="brand"
                  type="submit"
                  fontSize="md"
                  borderRadius="md">
                  Salvar
                </Button>
              </Flex>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Box>
  )
}
