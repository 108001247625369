import { extendTheme } from '@chakra-ui/react'
import { Alert } from './Alert'
import { Badge } from './Badge'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import colors from './Colors'
import { Input } from './Input'
import { normalize } from './normalization'
import { Select } from './Select'
import { Switch } from './Switch'
import { Tabs } from './Tabs'
import { Tag } from './Tag'
import { Textarea } from './Textarea'

const space = {
  px: '1px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  18: '4.5rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem'
}

const sizes = {
  ...space,
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '3.5xl': '51rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
  '8xl': '90rem'
}

const fontSizes = {
  xxs: '0.625rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem'
}
const lineHeights = {
  none: '1',
  shorter: '1.25',
  short: ' 1.375',
  base: '1.5',
  tall: '1.625',
  taller: '2',
  '3': '.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '7': '1.75rem',
  '8': '2rem',
  '9': '2.25rem',
  '10': '2.5rem'
}

export const theme = extendTheme({
  colors,
  components: {
    Alert,
    Badge,
    Button,
    Checkbox,
    Input,
    Select,
    Switch,
    Tabs,
    Tag,
    Textarea
  },

  fontSizes: normalize(fontSizes, 1.3),
  lineHeights: {
    normal: 'normal',
    ...normalize(lineHeights)
  },
  space: normalize(space),
  sizes: {
    ...normalize(sizes),
    container: normalize({
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px'
    }),
    max: 'max-content',
    min: 'min-content',
    full: '100%'
  },
  borderRadius: {
    radii: {
      none: '0',
      ...normalize({
        sm: '0.125rem',
        base: '0.25rem',
        md: '0.375rem',
        lg: '0.5rem',
        xl: '0.75rem',
        '2xl': '1rem',
        '3xl': '1.5rem'
      }),
      full: '9999px'
    }
  },
  semanticTokens: {
    colors: {
      assistant: 'assistant.500',
      brand: 'primary.500',
      negative: 'negative.500',
      neutralDark: 'neutralDark.500',
      neutralLight: 'neutralLight.500',
      positive: 'positive.500',
      warning: 'warning.500'
    }
  }
})

export default theme
