export const coerceNameString = (name: string) => {
  const words = name.split(' ')
  const newWords: string[] = []

  words.forEach((word) => {
    const firstLetter = word.slice(0, 1)
    const notFirstLetter = word.slice(1)

    const newWord = `${firstLetter.toLocaleUpperCase()}${notFirstLetter.toLocaleLowerCase()}`
    newWords.push(newWord)
  })

  return newWords.join(' ')
}
