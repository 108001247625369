/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable camelcase */
import { Box, Spinner, background } from '@chakra-ui/react'
import {
  Document,
  Font,
  Image,
  Link,
  PDFViewer,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import { Endpoints } from 'api/endpoints'
import JobIcon from 'assets/images/pdf/job-icon.jpg'
import Logo from 'assets/images/pdf/logo-V2.png'
import { differenceInYears, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { JobTypes } from 'enum/jobs'
import { CivilStateTypes, LanguageSkillLevelType } from 'enum/user'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from 'use-http'
import { transformDateArray } from 'utils/formatDate'
import { orderBy } from 'utils/orderBy'
import { maskedText } from 'utils/strings'

const LinkedInIcon = () => (
  <Svg width="22" height="22" viewBox="0 0 48 48">
    <Path
      fill="#0288d1"
      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
    />
    <Path
      fill="#ffffff"
      // eslint-disable-next-line max-len
      d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
    />
  </Svg>
)

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf'
})

Font.registerHyphenationCallback((word) => [word])

const styles = StyleSheet.create({
  page: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#F0F2F5',
    height: 'fit-content'
  },
  page_content: {
    padding: 20
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 16,
    borderBottomStyle: 'solid'
  },
  logo: {
    width: 80
  },
  text: {
    color: '#000000',
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    marginVertical: 2
  },
  section: {
    backgroundColor: '#f5f6fa',
    padding: 16,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: 14
  },
  divider: {
    marginTop: 20,
    height: 24,
    borderBottomWidth: 1,
    borderBottomColor: '#cecece',
    borderBottomStyle: 'solid'
  },
  pageContentBox: {
    backgroundColor: '#FFF',
    padding: 16,
    borderRadius: 14,
    flexDirection: 'row',
    marginBottom: 100,
    marginTop: 20,
    justifyContent: 'center',
    gap: 100
  },
  label: {
    color: '#8F8F8F',
    fontSize: 10,
    fontFamily: 'Roboto',
    fontWeight: 'light',
    marginVertical: 3
  }
})

export const Curriculum = () => {
  const { uuid }: any = useParams()

  const {
    get: getCandidate,
    data: candidate,
    loading: loadingCandidate
  } = useFetch(Endpoints.public.candidate(uuid))

  const loading = useMemo(
    () => loadingCandidate && !candidate,
    [loadingCandidate, candidate]
  )

  const documentTitle = useMemo(
    () => `Currículo - ${candidate?.name}`,
    [candidate]
  )

  useEffect(() => {
    if (candidate) {
      document.title = candidate?.name ? documentTitle : ''
    }
  }, [candidate, documentTitle])

  useEffect(() => {
    getCandidate()
  }, [getCandidate])

  return (
    <>
      {loading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Box>
      ) : (
        candidate && (
          <PDFViewer
            showToolbar
            height="100%"
            width="100%"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Document
              title={documentTitle}
              author="Yellow.Rec"
              creator="Yellow.Rec"
              producer="Yellow.Rec">
              {/* Pag 1 */}
              <Page wrap={false} size="A4" style={styles.page}>
                <View style={styles.page_content}>
                  <View
                    style={{ ...styles.header, justifyContent: 'flex-end' }}>
                    <View style={styles.logo}>
                      <Image src={Logo} />
                    </View>
                  </View>
                  <View
                    style={{
                      ...styles.pageContentBox,
                      marginBottom: 13,
                      marginTop: 20,
                      paddingBottom: 14
                    }}>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'column',
                        padding: 5
                      }}>
                      <View style={{ flex: 1 }}>
                        <Text style={{ fontSize: 18, marginBottom: 10 }}>
                          {candidate?.name}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: 'column'
                        }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: 'row'
                          }}>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 3.5
                            }}>
                            <Text style={styles.label}>Idade:</Text>
                            <Text style={styles.text}>
                              {candidate?.birth_date
                                ? `${differenceInYears(
                                    Date.now(),
                                    new Date(candidate.birth_date)
                                  )} anos`
                                : '-'}
                            </Text>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              gap: 3.5,
                              alignItems: 'center'
                            }}>
                            <Text style={styles.label}>Estado Civil:</Text>
                            <Text style={{ ...styles.text }}>
                              {candidate?.civil_state
                                ? CivilStateTypes?.[candidate?.civil_state]
                                : '-'}
                            </Text>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              gap: 3.5,
                              alignItems: 'center'
                            }}>
                            <Text style={styles.label}>Telefone:</Text>
                            <Text style={{ ...styles.text }}>
                              {candidate?.phone
                                ? maskedText(candidate.phone, '(##) #####-####')
                                : '-'}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignItems: 'center'
                          }}>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              gap: 3.5,
                              alignItems: 'center',
                              flexWrap: 'wrap'
                            }}>
                            <Text style={styles.label}>Localização:</Text>
                            <Text
                              style={{
                                ...styles.text,
                                textTransform: 'capitalize'
                              }}>
                              {candidate?.city
                                ? `${candidate?.city?.toLowerCase() ?? '–'}, ${
                                    candidate?.state ?? '–'
                                  }`
                                : '-'}
                            </Text>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              gap: 3.5,
                              alignItems: 'center',
                              flexWrap: 'wrap'
                            }}>
                            <Text style={styles.label}>E-mail:</Text>
                            <Text style={{ ...styles.text }}>
                              {candidate?.email ? candidate.email : '-'}
                            </Text>
                          </View>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start'
                            }}>
                            {candidate?.linkedin_profile_url ? (
                              <View
                                style={{
                                  marginTop: 0,
                                  padding: 0
                                }}>
                                <Link src={candidate?.linkedin_profile_url}>
                                  <LinkedInIcon />
                                </Link>
                              </View>
                            ) : null}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  {candidate?.superior_courses?.length > 0 && (
                    <View
                      style={{
                        ...styles.section,
                        backgroundColor: '#FFF',
                        paddingBottom: 14,
                        marginBottom: 13,
                        marginTop: 13
                      }}>
                      <View style={{ marginVertical: 0, width: '100%' }}>
                        <Text
                          style={{
                            ...styles.label,
                            fontSize: 16,
                            color: styles.text.color
                          }}>
                          Formação Acadêmica
                        </Text>
                        <View
                          style={{
                            ...styles.section,
                            backgroundColor:
                              styles.pageContentBox.backgroundColor
                          }}>
                          {candidate.superior_courses.map(
                            (
                              {
                                superior_course,
                                university,
                                start_date,
                                end_date
                              }: {
                                superior_course: { name: string }
                                university: { name: string }
                                start_date: string
                                end_date: string
                              },
                              idx: number
                            ) => {
                              const [year, month, day] = transformDateArray(
                                start_date?.split('T')?.[0]
                              )
                              const [yearEnd, monthEnd, dayEnd] =
                                transformDateArray(end_date?.split?.('T')?.[0])

                              return (
                                <View
                                  key={`superior_courses_${idx}`}
                                  style={{
                                    marginBottom:
                                      idx !==
                                      candidate.superior_courses.length - 1
                                        ? 12
                                        : 0,
                                    flexDirection: 'row',
                                    width: '100%'
                                  }}>
                                  <View
                                    style={{
                                      flex: 1,
                                      flexDirection: 'column'
                                    }}>
                                    <Text
                                      style={{
                                        ...styles.text,
                                        color: '#FFDD50',
                                        fontSize: 30,
                                        marginRight: 3,
                                        marginVertical: -8.5
                                      }}>
                                      {`\u2022 `}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flex: 20,
                                      flexDirection: 'column',
                                      borderBottom:
                                        idx !==
                                        candidate.superior_courses.length - 1
                                          ? '1px dashed #B3BDC4'
                                          : 'none'
                                    }}>
                                    <Text style={{ ...styles.text }}>
                                      {`${superior_course.name} - ${university.name} `}
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.text,
                                        fontSize: 9,
                                        color: styles.label.color
                                      }}>
                                      {
                                        <Text
                                          style={{
                                            ...styles.text,
                                            fontSize: 9,
                                            color: '#8F8F8F'
                                          }}>
                                          {year &&
                                            month &&
                                            day &&
                                            `${format(
                                              new Date(year, month - 1, day),
                                              "MMM' de 'yyyy",
                                              {
                                                locale: ptBR
                                              }
                                            )} - ${
                                              end_date
                                                ? `${format(
                                                    new Date(
                                                      yearEnd,
                                                      monthEnd - 1,
                                                      dayEnd
                                                    ),
                                                    "MMM' de 'yyyy",
                                                    {
                                                      locale: ptBR
                                                    }
                                                  )}`
                                                : 'Até o momento'
                                            }`}
                                        </Text>
                                      }
                                    </Text>
                                  </View>
                                </View>
                              )
                            }
                          )}
                        </View>
                      </View>
                    </View>
                  )}
                  {candidate?.languages?.length > 0 && (
                    <View
                      style={{
                        ...styles.section,
                        backgroundColor: '#FFF',
                        paddingBottom: 14,
                        marginBottom: 13,
                        marginTop: 13
                      }}>
                      <View
                        style={{
                          marginVertical: 0,
                          width: '100%'
                        }}>
                        <Text
                          style={{
                            ...styles.label,
                            fontSize: 16,
                            color: styles.text.color
                          }}>
                          Idiomas
                        </Text>
                        <View
                          style={{
                            ...styles.section,
                            backgroundColor:
                              styles.pageContentBox.backgroundColor
                          }}>
                          {candidate?.languages.map(
                            (
                              {
                                language,
                                level
                              }: {
                                language: { name: string }
                                level: string
                              },
                              idx: number
                            ) => (
                              <View
                                key={`language_${idx}`}
                                style={{
                                  marginBottom:
                                    idx !==
                                    candidate.superior_courses.length + 1
                                      ? 12
                                      : 0,
                                  flexDirection: 'row',
                                  width: '100%'
                                }}>
                                <View
                                  style={{
                                    flex: 1,
                                    flexDirection: 'column'
                                  }}>
                                  <Text
                                    style={{
                                      ...styles.text,
                                      color: '#FFDD50',
                                      fontSize: 30,
                                      marginRight: 3,
                                      marginVertical: -8.5
                                    }}>
                                    {`\u2022 `}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flex: 20,
                                    flexDirection: 'column',
                                    borderBottom:
                                      idx !== candidate?.languages.length - 1
                                        ? '1px dashed #B3BDC4'
                                        : 'none'
                                  }}>
                                  <Text style={{ ...styles.text }}>
                                    {language?.name}
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.text,
                                      fontSize: 9,
                                      color: styles.label.color
                                    }}>
                                    {LanguageSkillLevelType?.[level]}
                                  </Text>
                                </View>
                              </View>
                            )
                          )}
                        </View>
                      </View>
                    </View>
                  )}
                  {candidate?.professional_linkedin_resume && (
                    <View
                      style={{
                        ...styles.pageContentBox,
                        marginBottom: 28,
                        marginTop: 13
                      }}>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: 'column',
                          padding: 5
                        }}>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                          <Text style={{ fontSize: 16, marginBottom: 18 }}>
                            Sobre
                          </Text>
                          <View
                            style={{
                              ...styles.section
                            }}>
                            <Text style={styles.text}>
                              {candidate?.professional_linkedin_resume}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                </View>
              </Page>

              {/* Pag 2 */}
              {candidate.professional_experiences.length > 0 && (
                <Page wrap={false} size="A4" style={styles.page}>
                  <View style={styles.page_content}>
                    <View
                      fixed
                      style={{
                        ...styles.header,
                        justifyContent: 'flex-end'
                      }}>
                      <View style={styles.logo}>
                        <Image src={Logo} />
                      </View>
                    </View>

                    <View
                      style={{
                        ...styles.pageContentBox,
                        marginBottom: 30
                      }}>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: 'column',
                          padding: 5
                        }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'flex-start'
                          }}>
                          <Text style={{ fontSize: 16, flex: '1' }}>
                            Histórico profissional
                          </Text>
                          <View
                            style={{
                              ...styles.section,
                              flex: '6',
                              backgroundColor: '#FFF'
                            }}>
                            {orderBy(
                              candidate.professional_experiences,
                              'desc'
                            )?.map(
                              (
                                {
                                  position_in_the_company,
                                  description,
                                  company_name,
                                  actual_job,
                                  started_at,
                                  end_at,
                                  job_type
                                }: {
                                  position_in_the_company: string
                                  description: string
                                  company_name: string
                                  actual_job: boolean
                                  started_at: string
                                  end_at: string
                                  job_type: string
                                },
                                idx: number
                              ) => {
                                const [year, month, day] = transformDateArray(
                                  started_at?.split('T')?.[0]
                                )
                                const [yearEnd, monthEnd, dayEnd] =
                                  transformDateArray(end_at?.split?.('T')?.[0])

                                const actualMonthAndYear = format(
                                  new Date(),
                                  'MM/yyyy',
                                  {
                                    locale: ptBR
                                  }
                                ).split('/')

                                const yearDuration = !actual_job
                                  ? Number(yearEnd) - Number(year)
                                  : Number(actualMonthAndYear[1]) - Number(year)
                                const monthDuration = !actual_job
                                  ? Number(monthEnd) - Number(month)
                                  : Number(actualMonthAndYear[0]) -
                                    Number(month)

                                const getDurationString = (
                                  durationInMonths: number
                                ) => {
                                  const year =
                                    durationInMonths > 12
                                      ? Math.floor(durationInMonths / 12)
                                      : 0
                                  const month =
                                    durationInMonths > 12
                                      ? durationInMonths - year * 12
                                      : durationInMonths

                                  const stringYear = year > 1 ? 'anos' : 'ano'
                                  const stringMonth =
                                    month > 1 ? 'meses' : 'mês'
                                  const both = year > 0 && month > 0 ? 'e' : ''

                                  if (year > 0 && month > 0) {
                                    return `${
                                      year ? `${year} ${stringYear}` : ''
                                    } ${both} ${
                                      month ? `${month} ${stringMonth}` : ''
                                    }`
                                  }

                                  return year > 0
                                    ? `${year} ${stringYear}`
                                    : `${month} ${stringMonth}`
                                }

                                return (
                                  <View
                                    key={`professional_experiences_${idx}`}
                                    style={{
                                      ...styles.section,
                                      width: '100%',
                                      flexDirection: 'row',
                                      backgroundColor: '#FFF',
                                      borderRadius: 0,
                                      borderBottom:
                                        idx !==
                                        candidate.professional_experiences
                                          .length -
                                          1
                                          ? '1px dashed #B3BDC4'
                                          : 'none'
                                    }}>
                                    <View
                                      style={{
                                        flex: '1'
                                      }}>
                                      <View
                                        style={{
                                          marginTop: 14,
                                          height: 32,
                                          width: 32
                                        }}>
                                        <Image src={JobIcon} />
                                      </View>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.section,
                                        flexDirection: 'column',
                                        backgroundColor: '#FFF',
                                        flex: '8'
                                      }}>
                                      <Text
                                        style={{
                                          ...styles.text,
                                          fontSize: 12
                                        }}>
                                        {position_in_the_company || '-'}
                                      </Text>
                                      <Text
                                        style={{
                                          ...styles.text,
                                          fontSize: 9,
                                          textTransform: 'capitalize'
                                        }}>
                                        {`${company_name ?? '-'}  ${
                                          job_type
                                            ? `• ${JobTypes[job_type]}`
                                            : ''
                                        }`}
                                      </Text>
                                      <Text
                                        style={{
                                          ...styles.text,
                                          fontSize: 9,
                                          color: '#8F8F8F'
                                        }}>
                                        {year &&
                                          month &&
                                          day &&
                                          `${format(
                                            new Date(year, month - 1, day),
                                            "MMM' de 'yyyy",
                                            {
                                              locale: ptBR
                                            }
                                          )} - ${
                                            actual_job
                                              ? `Até o momento |  ${getDurationString(
                                                  yearDuration * 12 +
                                                    monthDuration
                                                )}`
                                              : end_at
                                              ? `${format(
                                                  new Date(
                                                    yearEnd,
                                                    monthEnd - 1,
                                                    dayEnd
                                                  ),
                                                  "MMM' de 'yyyy",
                                                  {
                                                    locale: ptBR
                                                  }
                                                )}  |  ${getDurationString(
                                                  yearDuration * 12 +
                                                    monthDuration
                                                )}`
                                              : ''
                                          }`}
                                      </Text>
                                      {description && (
                                        <View
                                          key={`professional_experiences_description${idx}`}
                                          style={{
                                            ...styles.section,
                                            flex: '5',
                                            width: '100%',
                                            padding: 14,
                                            marginTop: 10
                                          }}>
                                          <Text
                                            style={{
                                              ...styles.text,
                                              fontSize: 9,
                                              marginVertical: 0
                                            }}>
                                            {description || '-'}
                                          </Text>
                                        </View>
                                      )}
                                    </View>
                                  </View>
                                )
                              }
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </Page>
              )}

              {/* Pag 3 */}
              {candidate?.candidate_technical_or_complementary_courses.length >
              0 ? (
                <Page wrap={false} size="A4" style={styles.page}>
                  <View style={styles.page_content}>
                    <View
                      style={{
                        ...styles.header,
                        justifyContent: 'flex-end',
                        marginBottom: 20
                      }}>
                      <View style={{ ...styles.logo }}>
                        <Image src={Logo} />
                      </View>
                    </View>

                    {candidate?.candidate_technical_or_complementary_courses
                      .length > 0 && (
                      <View
                        style={{
                          ...styles.section,
                          backgroundColor: '#FFF',
                          paddingBottom: 14,
                          marginBottom: 28
                        }}>
                        <View style={{ marginVertical: 10, width: '100%' }}>
                          <Text
                            style={{
                              ...styles.label,
                              fontSize: 16,
                              color: styles.text.color
                            }}>
                            Cursos técnicos/complementares
                          </Text>
                          <View
                            style={{
                              ...styles.section,
                              backgroundColor:
                                styles.pageContentBox.backgroundColor
                            }}>
                            {candidate.candidate_technical_or_complementary_courses.map(
                              (
                                {
                                  complementary_course,
                                  institution
                                }: {
                                  institution: string
                                  complementary_course: {
                                    title: string
                                  }
                                },
                                idx: number
                              ) => (
                                <View
                                  key={`technical_or_complementary_${idx}`}
                                  style={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginBottom:
                                      idx !==
                                      candidate
                                        .candidate_technical_or_complementary_courses
                                        .length -
                                        1
                                        ? 12
                                        : 0
                                  }}>
                                  <View
                                    style={{
                                      flex: 1,
                                      flexDirection: 'column'
                                    }}>
                                    <Text
                                      style={{
                                        ...styles.text,
                                        fontSize: 30,
                                        color: '#FFDD50',
                                        marginRight: 3,
                                        marginVertical: -8.5
                                      }}>
                                      {`\u2022 `}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flex: 20,
                                      flexDirection: 'column',
                                      borderBottom:
                                        idx !==
                                        candidate
                                          .candidate_technical_or_complementary_courses
                                          .length -
                                          1
                                          ? '1px dashed #B3BDC4'
                                          : 'none'
                                    }}>
                                    <Text style={{ ...styles.text }}>
                                      {complementary_course?.title}
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.text,
                                        fontSize: 9,
                                        color: styles.label.color
                                      }}>
                                      {institution}
                                    </Text>
                                  </View>
                                </View>
                              )
                            )}
                          </View>
                        </View>
                      </View>
                    )}
                  </View>
                </Page>
              ) : (
                ''
              )}
            </Document>
          </PDFViewer>
        )
      )}
    </>
  )
}
