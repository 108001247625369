import { Box, HStack } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { normalizeNumber } from 'theme/normalization'

const urgencyElias: { [key: string]: number } = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
  LEVEL_4: 4,
  LEVEL_5: 5
}

export type UrgencyLevelProps = {
  urgencyLevel: string | number
  size?: 'md' | 'lg'
  onChange?: (level: number) => void
  reverse?: boolean
  mode?: 'default' | 'solid'
}

const sizes: { [key: string]: number } = {
  lg: normalizeNumber(20),
  md: normalizeNumber(10)
}

export const UrgencyLevel = ({
  size = 'md',
  urgencyLevel,
  onChange,
  reverse,
  mode = 'default'
}: UrgencyLevelProps) => {
  const URGENCY = useMemo(
    () => [
      {
        primaryColor: 'positive.500',
        secondaryColor: 'positive.100'
      },
      {
        primaryColor: '#BAC20F',
        secondaryColor: '#DEE1A3'
      },
      {
        primaryColor: 'brand.500',
        secondaryColor: 'brand.100'
      },
      {
        primaryColor: '#EA6721',
        secondaryColor: '#FFB58D'
      },
      {
        primaryColor: 'negative.500',
        secondaryColor: 'negative.100'
      }
    ],
    []
  )

  const bgColor = (index: number) => {
    const level =
      typeof urgencyLevel === 'string'
        ? urgencyElias[urgencyLevel] - 1
        : urgencyLevel - 1
    if (index === level) return URGENCY[level].primaryColor
    if (index < level)
      return URGENCY[mode === 'solid' ? index : level][
        mode === 'solid' ? 'primaryColor' : 'secondaryColor'
      ]
    return '#E9E9E9'
  }

  const props = useCallback(
    (size: string) => {
      if (!onChange) return {}
      return {
        _hover: {
          borderColor: 'gray.400',
          borderWidth: 1,
          h: `${sizes[size] + 1}px`,
          w: `${sizes[size] + 1}px`
        }
      }
    },
    [onChange]
  )

  const urgencyLevels = useMemo(
    () => (reverse ? URGENCY.reverse() : URGENCY),
    [URGENCY, reverse]
  )

  return (
    <HStack spacing="1">
      {urgencyLevels.map((_, i) => (
        <Box
          key={i}
          h={`${sizes[size]}px`}
          w={`${sizes[size]}px`}
          borderRadius={`${sizes[size] / 2}px`}
          bg={bgColor(i)}
          onClick={() => onChange?.(i + 1)}
          {...props(size)}
        />
      ))}
    </HStack>
  )
}
