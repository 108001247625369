/* eslint-disable max-len */
export const Endpoints = {
  auth: {
    login: '/auth/login',
    confirm: '/auth/confirm',
    resendCode: '/auth/resend-code'
  },
  public: {
    candidate: (id: string) => `/public/candidate/${id}`,
    job: (id: string) => `/public/job/${id}`,
    shortlist: (id: string) => `/public/shortlist/${id}`
  },
  users: {
    findAll: ({ page = 0, take = 20 }: { page?: number; take?: number }) =>
      `/users/yw?take=${take}&skip=${page * 20}`,
    create: '/users/yw',
    userById: (id: string) => `/users/yw/${id}`,
    findAllForKAM: `/users/yw?skip=0&take=200&area=MASTER,RECRUTAMENTO&status=true`,
    userByEmail: (email: string) => `users/yw/email/${email}`
  },
  lists: {
    benefits: '/lists/benefits',
    superiorCourses: '/lists/superior-courses',
    universities: '/lists/universities',
    languages: '/lists/languages',
    hardSkills: '/lists/hard-skills',
    yellowProduct: '/lists/yellow-product',
    cancelJobOptions: '/lists/reason-canceled',
    states: '/federations/state',
    citiesByState: (state: string) => `/federations/${state}`
  },
  usersCandidate: {
    root: '/users/candidate',
    userById: (id: string) => `/users/candidate/${id}`,
    update: (id: string) => `/users/candidate/${id}`,
    linkedin: '/linkedin',
    applications: (id: string) => `/users/candidate/${id}/applications`
  },
  interviewForm: {
    root: (candidateId: string, jobId: string) =>
      `/interview-form/${candidateId}?job_id=${jobId}`,
    create: (jobId: string, candidateId: string) =>
      `/interview-form/job/${jobId}/user/${candidateId}`
  },
  closeForm: {
    root: (jobId: string) => `/closing-form/${jobId}`
  },
  jobChecklist: {
    root: '/job-checklists',
    createItems: '/job-checklists/checklist-item',
    createChecklist: '/job-checklists/checklist-item',
    checklistByJobId: (jobId: string) => `/job-checklists/${jobId}`,
    checkListItemByCheckItemId: (checkId: string) =>
      `/checklist-item/${checkId}`,
    deleteItem: (itemId: string) => `/job-checklists/checklist-item/${itemId}`,
    updateItem: (itemId: string) => `/job-checklists/checklist-item/${itemId}`,
    deleteChecklist: (checklistId: string) =>
      `/job-checklists/checklist/${checklistId}`,
    deleteChecklistItem: (checkId: string) =>
      `/job-checklists/checklist-item/${checkId}`
  },
  customers: {
    root: '/customers',
    allCustomers: '/customers?take=1000&skip=0',
    list: (page: number, name: string = '') =>
      `/customers?take=20&skip=${page * 20}&name=${name}`,
    businessAreas: 'customers/business-area',
    findOne: (uuid: string) => `/customers/${uuid}`,
    update: (id: string) => `/customers/${id}`,
    customerById: (id: string) => `/customers/${id}`
  },
  jobs: {
    root: '/jobs',
    findOne: (jobId: string) => `/jobs/${jobId}`,
    candidatesMatrix: (page: number, jobId: string) =>
      `/candidates-matrix?jobId=${jobId}&skip=${page}&take=20`,
    getLonglist: (jobId: string) =>
      `/candidates-matrix/list/${jobId}?isLonglist=true`,
    getShortlist: (jobId: string) =>
      `/candidates-matrix/list/${jobId}?isLonglist=false`,
    getList: (listId: string) => `/candidates-matrix/list/shortlist/${listId}`,
    exportList: () => '/candidates-matrix/list/export',
    getApprovedShortlist: (jobId: string) =>
      `/candidates-matrix/list/${jobId}/approved`,
    comments: (jobId: string) => `/jobs/${jobId}/comments`,
    observations: (listId: string) =>
      `/candidates-matrix/list/${listId}/observation`,
    getListPDF: (listId: string) => `/candidates-matrix/list/${listId}/pdf`,
    list: '/candidates-matrix/list', // POST: para longlist => {is_long_list: true} | para shortlist => {is_long_list: false}
    update: (id: string) => `/jobs/${id}`,
    toApprovePreshortlist: (listId: string) =>
      `/candidates-matrix/list/${listId}/move`,
    approvePreshortlist: (listId: string) =>
      `/candidates-matrix/list/${listId}/approve`,
    rejectPreshortlist: (listId: string) =>
      `/candidates-matrix/list/${listId}/decline`,
    addCandidatesToList: (listId: string) =>
      `/candidates-matrix/list/${listId}/candidates`,
    listStatus: (listId: string) =>
      `/candidates-matrix/list/${listId}/customer_approve`,
    candidateStatus: (listId: string) =>
      `/candidates-matrix/list/${listId}/status`,
    removeCandidateFromList: (listId: string) =>
      `/candidates-matrix/list/${listId}/candidates`,
    updateCandidatePosition: (jobListId: string) =>
      `/candidates-matrix/list/${jobListId}/position`
  },
  external: {
    cpbj: (cnpj: string) => `/external/cnpj/${cnpj}`
  },
  companies: {
    root: '/companies',
    '/companies': '/companies'
  },
  forms: {
    open: ({ page = 0, take = 20 }: { page?: number; take?: number }) =>
      `/invoices?take=${take}&skip=${page * take}&type=OPENING`,
    delivery: ({ page = 0, take = 20 }: { page?: number; take?: number }) =>
      `/invoices?take=${take}&skip=${page * take}&type=DELIVERY`,
    closure: ({ page = 0, take = 20 }: { page?: number; take?: number }) =>
      `/invoices?take=${take}&skip=${page * take}&type=CLOSURE`,
    update: (formId: string) => `/invoices/${formId}`,
    candidates: ({ page = 0, take = 20 }: { page?: number; take?: number }) =>
      `/closing-form/candidates?take=${take}&skip=${page * take}`,
    updateCandidate: (formId: string) => `/closing-form/candidates/${formId}`,
    exportCandidates: '/closing-form/candidates/export',
    invoices: () => '/invoices',
    findOne: (jobId: string, type: string) =>
      `/invoices/findOne?job_id=${jobId}&type=${type}`
  }
}
