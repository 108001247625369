import { Box, Grid, GridItem, Spinner } from '@chakra-ui/react'
import { Header } from 'components/organisms/Header'
import { Sidebar } from 'components/organisms/Sidebar'
import { Fragment, Suspense } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import useSidebarStore from 'stores/sidebar'
import useUserStore from 'stores/user'
import { normalizeNumber } from 'theme/normalization'

export const PageTemplate = () => {
  const location = useLocation()
  const { user } = useUserStore()

  const { opened: sidebarOpened } = useSidebarStore()

  return (
    <Fragment>
      <Grid
        position="relative"
        height="100%"
        templateAreas={`
          "header header"
          "nav main"
        `}
        gridTemplateRows={`${normalizeNumber(72)}px 1fr`}
        gridTemplateColumns={`${
          sidebarOpened ? normalizeNumber(352) : normalizeNumber(72)
        }px 1fr`}>
        <GridItem area="header">
          <Header />
        </GridItem>
        <GridItem area="nav">
          <Sidebar active={location.pathname} userLogged={user} />
        </GridItem>
        <GridItem
          area="main"
          p={10}
          pb={6}
          height="100%"
          overflowY="auto"
          overflowX="hidden">
          <Suspense
            fallback={
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center">
                <Spinner color="brand.500" />
              </Box>
            }>
            <Outlet />
          </Suspense>
        </GridItem>
      </Grid>
    </Fragment>
  )
}
