import { Avatar, Box, Icon, IconButton, Text } from '@chakra-ui/react'
import { Fragment } from 'react'
import { FiTrash, FiUser } from 'react-icons/fi'

export const Photo = () => (
  <Fragment>
    <Box
      w={300}
      h={300}
      borderRadius="lg"
      bg="#E9E9E9"
      mt={12}
      ml={8}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      fontWeight="semibold"
      opacity={0.7}>
      <Avatar
        size="2xl"
        bg={{ base: 'transparent', md: 'white' }}
        color={{ base: 'white', md: '#1B1C1E' }}
        position="relative"
        icon={<FiUser fontSize="4.5rem" />}>
        <Box position="absolute" top={-6} right={1}>
          <IconButton
            isDisabled
            isRound
            type="button"
            bg="white"
            size="xs"
            variant="solid"
            aria-label="Remove Photo"
            icon={<Icon as={FiTrash} />}
            onClick={() => {}}
            boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
            _hover={{
              bg: 'brand.500'
            }}
            _active={{
              bg: 'brand.500'
            }}
          />
        </Box>
      </Avatar>

      <Text color="#1B1C1E" mt={4}>
        Adicionar foto
      </Text>
    </Box>
  </Fragment>
)
