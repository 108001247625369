export const orderBy = (array: Record<any, any>, order: string = 'desc') => {
  const compareFunction = (a: any, b: any) => {
    if (a.end_at === null && b.end_at === null) {
      return 0
    }

    if (a.end_at === null) {
      return -1
    }

    if (b.end_at === null) {
      return 1
    }

    const aValue: any = new Date(a.end_at)
    const bValue: any = new Date(b.end_at)
    const compareResult = aValue - bValue

    return order === 'desc' ? -compareResult : compareResult
  }

  return array.sort(compareFunction)
}
