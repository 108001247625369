import { isObject } from 'radash'

export const isBoolean = (value: string | boolean) => {
  if (typeof value === 'boolean') return value

  return value === 'true'
}

export function deepEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }
  return true
}

export const removeAccents = (string: string): string =>
  string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const areArraysEmpty = (obj: Record<string, Array<string>>): boolean =>
  Object.values(obj).every(
    (array) => Array.isArray(array) && array.length === 0
  )

export const capitalize = (string: string) =>
  string
    .split(' ')
    .map(
      (palavra) =>
        palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase()
    )
    .join(' ')
