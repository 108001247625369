import {
  Checkbox as CheckboxChakra,
  CheckboxProps,
  FormControl
} from '@chakra-ui/react'
import { RefCallback } from 'react'

interface CheckboxType extends CheckboxProps {
  ref?: RefCallback<any>
  label: string
  disabled?: boolean
  required?: boolean
  value?: any
}

export const Checkbox = ({ label, ...rest }: CheckboxType): JSX.Element => (
  <FormControl isRequired={rest.required}>
    <CheckboxChakra
      {...rest}
      colorScheme="brand"
      iconColor="black"
      isInvalid={rest.isInvalid}
      spacing={2}
      iconSize="xs"
      size="xl">
      {label}
    </CheckboxChakra>
  </FormControl>
)
