/* eslint-disable indent */
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Select as SelectChakra,
  SelectProps
} from '@chakra-ui/react'
import { FormTooltip } from 'modules/Jobs/components/FormTooltip/FormTooltip'
import { useMemo } from 'react'
import { Control, Controller } from 'react-hook-form'
import ReactSelect, {
  ActionMeta,
  ControlProps,
  MultiValue,
  MultiValueGenericProps,
  MultiValueRemoveProps
} from 'react-select'
import ReactSelectAsync from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import { Input } from 'theme/Input'
import { normalizeNumber } from 'theme/normalization'
import { Tag, TagCloseButton, TagLabel } from '../Tag'

export type OptionProp = { label: string; value: any }

interface SelectType extends SelectProps {
  label?: string
  disabled?: boolean
  errorMessage?: string
  required?: boolean
  isInvalid?: any
  isMulti?: boolean
  creatable?: boolean
  heightIsMulti?: number
  options?: OptionProp[]
  loading?: boolean
  loadOptions?: (
    searchTerm: string,
    callback: ({ label, value }: { label: string; value: string }) => void
  ) => void
  value?: any
  defaultValue?: any
  control?: Control<any>
  onCreateOption?: any
  requiredInfo?: boolean
}

const SelectComponent = {
  IS_MULTI: ReactSelect,
  IS_MULTY_ASYNC: ReactSelectAsync,
  IS_MULTY_CREATE: CreatableSelect
}

const getSelectComponentType = (loadOptions: any, creatable?: boolean) => {
  if (creatable) return 'IS_MULTY_CREATE'

  if (loadOptions) return 'IS_MULTY_ASYNC'

  return 'IS_MULTI'
}

export const Component = ({
  label,
  errorMessage,
  isMulti,
  creatable,
  options,
  value,
  defaultValue,
  heightIsMulti,
  loading,
  loadOptions,
  onCreateOption,
  onChange,
  ...rest
}: SelectType & {
  onChange?: (newValue: MultiValue<any>, actionMeta: ActionMeta<any>) => void
}): JSX.Element => {
  const isError = useMemo(
    () => rest?.isInvalid && errorMessage,
    [errorMessage, rest.isInvalid]
  ) as boolean

  const ReactSelectComponent: any =
    SelectComponent[getSelectComponentType(loadOptions, creatable)]

  return (
    <FormControl
      isInvalid={isError}
      isRequired={rest.required}
      isDisabled={loading}>
      {!!label && (
        <Flex>
          <FormLabel
            htmlFor={rest.id}
            color={rest.isInvalid ? 'negative.500' : 'black'}>
            {label}
          </FormLabel>
          {rest.requiredInfo && <FormTooltip error={errorMessage} />}
        </Flex>
      )}
      <InputGroup width="100%" color="black" zIndex="110">
        {isMulti || creatable ? (
          <ReactSelectComponent
            {...rest}
            {...(getSelectComponentType(loadOptions, creatable) ===
            'IS_MULTY_CREATE'
              ? {
                  value,
                  onCreateOption,
                  isClearable: creatable,
                  formatCreateLabel: (v: string) => <>{`Criar "${v}"`}</>
                }
              : {})}
            loading={loading}
            isLoading={loading}
            isDisabled={loading}
            styles={{
              container: () => ({
                fontSize: normalizeNumber(20),
                minWidth: `${normalizeNumber(180)}px`,
                width: '100%',
                height: `${normalizeNumber(54)}px`,
                zIndex: '110'
              }),
              menu: (baseStyle: any) => ({
                ...baseStyle,
                marginTop: '14px',
                minWidth: 'fit-content'
              }),
              menuList: (baseStyle: any) => ({
                ...baseStyle,
                '&::-webkit-scrollbar': {
                  width: '10px',
                  backgroundColor: `rgba(0, 0, 0, 0.05)`
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '6px',
                  backgroundColor: `rgba(0, 0, 0, 0.15)`
                }
              }),
              option: () => ({
                padding: '12px 16px',
                fontWeight: '400',
                '&:hover': {
                  backgroundColor: '#E1E6E9'
                },
                fontSize: `${normalizeNumber(22)}px`,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }),
              placeholder: (baseStyle: any) => ({
                ...baseStyle,
                color: rest.isInvalid ? 'negative.500' : 'black',
                fontWeight: 'bold',
                fontSize: `${normalizeNumber(18)}px`
              })
            }}
            options={options}
            onChange={onChange}
            placeholder={rest.placeholder || 'Selecione'}
            isClearable={false}
            isMulti={isMulti}
            defaultValue={value ?? defaultValue}
            noOptionsMessage={() => 'Nada encontrado'}
            components={{
              Control: ({
                children,
                innerProps,
                isDisabled,
                isFocused
              }: ControlProps) => (
                <Box width="100%" h={`${normalizeNumber(54)}px`}>
                  <Flex
                    sx={{
                      ...Input.variants.filled.field,
                      h: `${normalizeNumber(54)}px`,
                      overflow: 'hidden',
                      p: 0,
                      fontSize: normalizeNumber(16),
                      bg: 'neutralLight.300',
                      borderRadius: 'md'
                    }}
                    {...innerProps}
                    data-focus={isFocused ? true : undefined}
                    data-invalid={isError ? true : undefined}
                    {...(heightIsMulti
                      ? {
                          h: `${normalizeNumber(heightIsMulti)}px`,
                          py: 1
                        }
                      : {})}
                    data-disabled={isDisabled ? true : undefined}>
                    {children}
                  </Flex>
                </Box>
              ),
              IndicatorSeparator: () => <></>,
              MultiValueContainer: ({ children }: MultiValueGenericProps) => (
                <Tag
                  size="lg"
                  variant="solid"
                  mx={1}
                  py="1.5"
                  borderRadius="md">
                  {children}
                </Tag>
              ),
              MultiValueLabel: ({ children }: MultiValueGenericProps) => (
                <TagLabel>{children}</TagLabel>
              ),
              MultiValueRemove: ({
                children,
                innerProps: { onClick },
                data: { isFixed }
              }: MultiValueRemoveProps<any>) => {
                if (isFixed) return null
                return (
                  <TagCloseButton onClick={onClick}>{children}</TagCloseButton>
                )
              }
            }}
          />
        ) : (
          <SelectChakra
            {...rest}
            value={value}
            defaultValue={defaultValue}
            fontSize={`${normalizeNumber(18)}`}
            color="grayPrimary"
            borderRadius="md"
            onChange={onChange}
            height={`${normalizeNumber(54)}px`}
            errorBorderColor="transparent"
          />
        )}
      </InputGroup>
      {isError && (
        <FormErrorMessage justifyContent="end" color="negative.500">
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export const Select = ({
  control,
  name,
  ...props
}: SelectType & {
  onChange?: (newValue: MultiValue<any>, actionMeta: ActionMeta<any>) => void
}): JSX.Element =>
  control && name ? (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field: { ref, value, ...fieldProps }, fieldState }) => (
        <Component
          value={value}
          {...fieldProps}
          {...props}
          isInvalid={fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  ) : (
    <Component {...props} />
  )
